import { makeRequest } from '../makeRequest';
import { API_HEADER, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { SUPPLIERURLS } from '../../shared/constants/appUrls'
import { setSupplierTable } from '../../redux/actions/supplierManagement';
import { setLoadingFlag } from '../../redux/actions';

export function getSupplierDetails() {
  return async (dispatch) => {
    await makeRequest({
      method: GET,
      url: SUPPLIERURLS.getSupplier,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setSupplierTable(response.data))
        dispatch(setLoadingFlag(false))
      })
  }

}

export async function getSupplierList() {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: SUPPLIERURLS.getSupplier,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      res = false
      errRes = err
    });
  return { res:res, err:errRes };
}

export async function addSupplierDetails({
  supplierName, 
  supplierSiteCode,
  termsDate,
  commission, 
}) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: SUPPLIERURLS.addSupplier,
    data: {
      supplierName: supplierName,
      supplierCode: supplierSiteCode,
      supplierCommision:parseFloat(commission).toFixed(2),
      termsDate: Number(termsDate),
    },
    headers: API_HEADER,
  })
    .then(function(){
      res = true
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });
  return { res:res, err:errRes };
}

export async function getSupplierByName(supplierName) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: SUPPLIERURLS.getSupplierByName + supplierName,
    data:undefined, 
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      res = false
      errRes = err
    });
  return { res:res, err:errRes };
}

export async function updateSupplier({ supplierId, supplierName, supplierSiteCode, 
  commission, termsDate }){
  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url:SUPPLIERURLS.editSupplier,
    data: {
      id: supplierId,
      supplierName: supplierName,
      supplierCode: supplierSiteCode,
      termsDate: Number(termsDate),
      supplierCommision: parseFloat(commission).toFixed(2),
    },
    headers: API_HEADER,
  })
    .then(function (response) {
      res = true
    }
    )
    .catch(function (err) {
      res = false
      errRes = err
    });
  return { res:res, err:errRes };
}

export function deleteSupplierById(supplierID, moduleSupplierList){
  return async (dispatch) =>{
    await makeRequest({
      method:PUT,
      url:SUPPLIERURLS.deleteSupplier,
      data:{
        supplierID: supplierID,
        moduleSupplierList  : moduleSupplierList,
      },
      headers:API_HEADER,
    })
      .then(function (response) {
        dispatch(setLoadingFlag(false));
        dispatch(getSupplierDetails())
      })
      .catch(function(err){
        
      })
      
  }
}