import { SET_SUPPLIER_TABLE, SUPPLIER_PAGE_NUMBER } from '../../shared/constants/actionType';
const INIT_STATE = {
  supplierTable :[],
  pageNumber: 0,
}

const userManagementReducer = (state = INIT_STATE, action) => {
  switch(action.type){  
  
  case SET_SUPPLIER_TABLE:
    return {
      ...state,
      supplierTable: action.payload,
    }
  case SUPPLIER_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.payload,
    }
  default: return state;
  }
}

export default userManagementReducer