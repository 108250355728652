import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css'
import { TABLEHEADERS, INVOICESTATUS, INVOICE_EXPORT_HEADERS, INVOICE_EXPORT_FILE_PREFIX, INVOICES_EDITABLE } from '../invoiceListingConstants';
import uploadIcon from '../../../assets/icons/uploadIcon.svg'
import mailIcon from '../../../assets/icons/mailFilledIconWhite.svg'
import InvoiceFileUploadPopUp from '../../../shared/Components/InvoiceFileUploadPopUp';
import CustomSearchBox from '../../../shared/Components/CustomSearchBox';
import { FILETYPE, globalDateFormat, TABS } from '../../../shared/constants/appConstants';
import '../../../styles/invoiceListing/inputDateCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getInvoiceDetails, sendInvoices, downloadCSV, getInvoiceSummary, updateInvoiceStatus } from '../../../api/services/invoiceListingService';
import { setLoadingFlag, setError } from '../../../redux/actions';
import { format } from 'date-fns';
import SuccessPopUp from '../../../shared/Components/SuccessPopUp';
import CustomDatePicker from '../../../shared/Components/CustomDatePicker';
import DownloadCSVBtn from '../../../shared/Components/DownloadCSVBtn';
import exportFileIcon  from '../../../assets/icons/exportFileIconTwo.svg';
import DashboardFilterDropdown from '../../../shared/Components/DashboardFilterDropdown';
import { getModuleDetailsToDisplay } from '../../../api/services/moduleConfigurationService';
import { getStationDetailsToDisplay } from '../../../api/services/stationManagementService';
import CustomPopup from '../../../shared/Components/CustomPopup';
import { updateInvoiceFilter, updateInvoicePageNumber } from '../../../redux/actions/invoiceListing';
import PenaltyInvoicePopUp from '../../../shared/Components/PenaltyInvoicePopUp';
import mailFilledIcon from '../../../assets/icons/mailFilledIcon.svg'
import CustomTable from '../../../shared/Components/CustomTable';
import { ROUTES } from '../../../shared/constants/appRoute';
import { useNavigate } from 'react-router-dom';
import editPenIconLight from '../../../assets/icons/editPenWhiteIcon.svg';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import popUpStyle from '../../../styles/adtConfiguration/ConfigurationScreen.module.css';
const ENWORDS = require('../../../shared/local/en.json');
const SCREENS  = require('../../../shared/local/screens.json');

const InvoiceListingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const fileInputRef = useRef();
  const invoiceData = useSelector(state=> state.invoiceListing.invoiceData)
  const invoiceFilterSite = useSelector(state=> state.invoiceListing.invoiceFilter.selectedSite)
  const invoiceFilterToDate = useSelector(state=> state.invoiceListing.invoiceFilter.toDate)
  const invoiceFilterFromDate = useSelector(state=> state.invoiceListing.invoiceFilter.fromDate)
  const invoiceFilterInvoiceStatus = useSelector(state=> state.invoiceListing.invoiceFilter.selectedInvoiceStatus)
  const invoiceFilterModuleId = useSelector(state=> state.invoiceListing.invoiceFilter.selectedModuleId)
  const invoiceFilterModuleName = useSelector(state=> state.invoiceListing.invoiceFilter.selectedModuleName)
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ isGetInvoiceDetailsCalled, setIsGetInvoiceDetailsCalled ] = useState(false)

  const [ searchText, setSearchText ] = useState('');
  const [ selectedInvoiceStatus, setSelectedInvoiceStatus ] = useState(invoiceFilterInvoiceStatus ? invoiceFilterInvoiceStatus : INVOICESTATUS.all)
  const [ fileVisibility, setFileVisibility ] = useState(false);
  const [ visibility, setVisibility ] = useState(false);
  const [ selectedFile, setSelectedFile ] = useState('');
  const [ isFilePicked, setIsFilePicked ] = useState(false);
  const [ filteredData, setfilteredData ] = useState(invoiceData);
  const [ roleAccess, setRoleAccess ] = useState();
  const [ showPopUP, setShowPopUP ] = useState(false);
  const [ modules, setModules ] = useState();
  const [ sites, setSites ] = useState();
  const [ invoiceStatuses, setInvoiceStatuses ] = useState();
  const [ fromDate, setFromDate ]  = useState();
  const [ toDate, setToDate ]  = useState();
  const [ selectedModuleName, setSelectedModuleName ] = useState(invoiceFilterModuleName ? invoiceFilterModuleName : 'All');
  const [ selectedModuleId, setSelectedModuleId ] = useState(invoiceFilterModuleId ? invoiceFilterModuleId : 0);
  const [ selectedSite, setSelectedSite ] = useState(invoiceFilterSite ? invoiceFilterSite : 'All');
  const [ modulesCompleteData, setModulesCompleteData ] = useState([]);
  const [ pendingPenaltyInvoices, setPendingPenaltyInvoices ] = useState('')
  const [ visibilityForPenaltyInvoice, setVisibilityForPenaltyInvoice ] = useState(false)
  const [ isPenaltyPopupClosed, setIsPenaltyPopupClosed ] = useState(false)
  const [ selectedRows, setSelectedRows ] = useState([]);
  const [ showEditPopUp, setShowEditPopUp ] = useState(false);
  const [ selectedInvoiceStatusForUpdate, setSelectedInvoiceStatusForUpdate ] = useState('Generated')

  const showFilePopUp = () =>{
    setFileVisibility(true);
  }
  const showPopUp = () =>{
    setVisibility(true);
    
  }

  const filePopupCloseHandler = (e) => {
    setFileVisibility(e);
    setIsFilePicked(false)
  };

  const popupCloseHandler = (isClosed) => {
    setVisibility(isClosed);
    setFileVisibility(isClosed);
    setIsFilePicked(isClosed)
  };

  const popupCloseHandlerForPenaltyInvoice = (isClosed) => {
    setIsPenaltyPopupClosed(true)
    setVisibilityForPenaltyInvoice(isClosed);
  }

  const changeHandler = (e) =>{
    setSelectedFile(e.target.files[ 0 ]);
    setIsFilePicked(true)
  }

  const onBlukUpload = () =>{
    showFilePopUp()
  }

  const getInvoiceDetailByDate = () =>{
    if (fromDate && toDate){
      const fromDateFormat =  format(fromDate, 'yyyy-MM-dd');
      const toDateFormat =  format(toDate, 'yyyy-MM-dd');
      dispatch(setLoadingFlag(true))
      dispatch(getInvoiceDetails(fromDateFormat, toDateFormat, selectedModuleId))
      setIsGetInvoiceDetailsCalled(true)
    }
  }
  const submitInvoiceSummary = async (invoices) => {
    let isCheckboxNotSelected = true
    let isApExportChecked = false
    let isArExportChecked = false
    let isNlExportChecked = false
    modulesCompleteData.map((moduleObj) => {
      if (moduleObj.id === selectedModuleId) {
        isApExportChecked = moduleObj.apExport === 1 
        isArExportChecked = moduleObj.arExport === 1 
        isNlExportChecked = moduleObj.nlExport === 1 
        if (moduleObj.apExport && moduleObj.arExport && moduleObj.nlExport) {
          isCheckboxNotSelected = false

          return moduleObj
        }
      }
      return moduleObj
    })
    if ( isApExportChecked || isArExportChecked || isNlExportChecked) {
      if (isArExportChecked) {
        dispatch(setLoadingFlag(true));
        await downloadCSV(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'), selectedModuleId, selectedModuleName, 'AR_Export', invoices);
        dispatch(setLoadingFlag(false));
      }
      if (isNlExportChecked) {
        dispatch(setLoadingFlag(true));
        await downloadCSV(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'), selectedModuleId, selectedModuleName, 'NL_Export', invoices);
        dispatch(setLoadingFlag(false));
      }
      if (isApExportChecked) {
        dispatch(setLoadingFlag(true));
        await downloadCSV(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'), selectedModuleId, selectedModuleName, 'AP_Export', invoices);
        dispatch(setLoadingFlag(false));
      }
      setSelectedRows([])
    } else if (isCheckboxNotSelected) {
      setShowPopUP(true)
    }
  };

  const onclickDownloadCSV = async (inovices) =>
  {
    submitInvoiceSummary(inovices);
  }
  const sendInvoiceEmail = async (invoices) =>{
    dispatch(setLoadingFlag(true));
    const res = await sendInvoices(invoices);
    if (res.res){
      dispatch(setLoadingFlag(true));
      getInvoiceDetailByDate();
      setSelectedRows([]);
      showPopUp();
    }
  }
  const filteration = useCallback(
    () =>{
      let userFilterData = invoiceData;
  
      if (searchText.length  >0){
        userFilterData = userFilterData.filter((item)=>{
          const searchTerm = Object.values(item)?.join().toLocaleLowerCase();
          return searchTerm?.includes(searchText?.toLowerCase());
        })
      }
  
      if (selectedInvoiceStatus.toLowerCase() !== 'all'){
        userFilterData = userFilterData.filter((item)=>{
          const invoiceStatusText = item.status?.toLowerCase().split(' ').join('')
          return invoiceStatusText === selectedInvoiceStatus.toLowerCase()
        })
      }

      if (selectedSite.toLowerCase() !== 'all') {
        userFilterData = userFilterData.filter((item)=>{
          const invoiceStatusText = item.siteID?.toLowerCase().split(' ').join('')
          return invoiceStatusText === selectedSite.toLowerCase()
        })
      }
      return userFilterData
    },
    [ searchText, selectedInvoiceStatus, selectedSite, invoiceData ]
  )

  const invoiceRowClicked = async ( invoiceStatus,invoiceNo, billingPeriod, siteID, cmID, moduleID, mail ) =>{
    dispatch(setLoadingFlag(true))
    const invoiceSummaryRes = await getInvoiceSummary(
      {
        invoiceId: invoiceNo,
        siteID: siteID,
        cmID: cmID,
        moduleID: moduleID,
        mail: mail,
      }
    )

    const invoiceRes = invoiceSummaryRes.res;
    if (invoiceRes){
      dispatch(setLoadingFlag(false))
      // invoiceRes.supplierInvoiceDetails.pdfUrl = invoiceRes.pdfUrl;
      navigate(ROUTES.INVOICEDETAILS, { state: 
        { invoiceValue: invoiceRes.supplierInvoiceDetails,
          transactionValue: invoiceRes.transacionDetails,
          resValue : invoiceRes.status,
          billingPeriod: billingPeriod,
          invoiceNo: invoiceNo,
          writeAccess: roleAccess?.writeAccess,
          invoiceStatus : invoiceStatus,
          fileData : invoiceRes.pdfFileData,
          documentDate: invoiceRes.date, 
        } } )
    }
    
  }
  
  useEffect(() => {
    if (isFilePicked){
      showFilePopUp()
    }
  }, [ isFilePicked ])

  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.invoiceListing){
        if (!item.writeAccess){
          delete TABLEHEADERS[ 'invoiceCheckbox' ];
          delete TABLEHEADERS[ 'invoiceAction' ];
        }
        setRoleAccess(item)
      }
    })

  }

  const setInitialDate = () =>{
    const toSetFromDate = new Date();
    const toSetToDate = new Date();
    toSetFromDate.setMonth(toSetFromDate.getMonth()- 4 );
    toSetFromDate.setDate(toSetFromDate.getDate() - (toSetFromDate.getDate() + 1));
    setFromDate(invoiceFilterFromDate ? invoiceFilterFromDate : toSetFromDate);
    setToDate(invoiceFilterToDate ? invoiceFilterToDate : toSetToDate);
  }

  const populateModuleArray = async  (moduleRes) =>{
    let moduleCopy = []
    const modulesData = []
    moduleRes.forEach((moduleItem)=>{
      modulesData.push(moduleItem)
      const arrStruc = {}
      arrStruc[ String(moduleItem.id) ] = moduleItem.moduleName
      moduleCopy = [ ...moduleCopy, arrStruc ]
    });
    moduleCopy.splice(0, 0, { 0:'All' });
    setModules(moduleCopy)
    setModulesCompleteData(modulesData)
  }

  const populateStationArray = async (siteRes) =>{
    let stationDataCopy = []
    siteRes.forEach((siteItem)=>{
      const arrStruc = {}
      arrStruc[  siteItem.siteId ] = siteItem.siteId
      stationDataCopy = [ ...stationDataCopy, arrStruc ]
    })
    stationDataCopy.splice(0, 0, { 0:'All' });
    setSites(stationDataCopy)
  }

  const populateInitialData = async () =>{
    dispatch(setLoadingFlag(true));
    const moduleResponse = await getModuleDetailsToDisplay();
    const siteResponse  = await getStationDetailsToDisplay();
    const moduleSuccess = moduleResponse.res
    const siteSuccess = siteResponse.res

    if (moduleSuccess){
      dispatch(setLoadingFlag(false))
      populateModuleArray(moduleResponse.res);
    }
    if (siteSuccess){
      dispatch(setLoadingFlag(false))
      populateStationArray(siteResponse.res)
    }
    const invoiceStatusList = Object.keys(INVOICESTATUS)
    let invoiceStatusListLocal = []
    invoiceStatusList.map((key) => {
      const invoiceStatusObj = {}
      invoiceStatusObj[ key ] = INVOICESTATUS[ key ]
      invoiceStatusListLocal = [ ...invoiceStatusListLocal, invoiceStatusObj ]
      return key
    })
    setInvoiceStatuses(invoiceStatusListLocal)
  }

  useEffect(()=>{
    setInitialDate()
    populateInitialData();
  },[])
  
  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  useEffect(()=>{
    const countData = invoiceData.filter((obj) => (obj.status === 'Generated' || obj.status === 'Regenerated') && obj.moduleName === TABS.adtAlarmMonitoring)
    let titleMessage = ''
    if (countData.length > 0 && !isPenaltyPopupClosed) {
      if (countData.length === 1) {
        titleMessage = countData.length.toString() + ' penalty invoice is pending.'
      } else {
        titleMessage = countData.length.toString() + ' penalty invoices are pending.'
      }
      setPendingPenaltyInvoices(titleMessage)
      setVisibilityForPenaltyInvoice(true)
    } else {
      setPendingPenaltyInvoices('')
      setVisibilityForPenaltyInvoice(false)
    }
  }, [ invoiceData ])

  useEffect(() => {
    setfilteredData(filteration())
    setSelectedRows([])
  }, [ searchText, selectedInvoiceStatus, selectedSite, invoiceData ])

  useEffect(() => {
    dispatch(updateInvoicePageNumber(0))
    dispatch(updateInvoiceFilter({ selectedSite, selectedInvoiceStatus, toDate, fromDate, selectedModuleId, selectedModuleName }))
  }, [ selectedInvoiceStatus, selectedSite, toDate, fromDate, selectedModuleId ])

  useEffect(() => {
    if (!isGetInvoiceDetailsCalled) {
      getInvoiceDetailByDate()
    }
  }, [ toDate, fromDate, selectedModuleId ])

  const onFromDateChange = (date) =>{
    setFromDate(date)
    setIsGetInvoiceDetailsCalled(false)
  }
  
  const updateStatus =async (row)=>{
    dispatch(setLoadingFlag(true))
    const res = await updateInvoiceStatus([ { status: row?.status, invoiceNo: row?.id } ])
    if(res?.res === true){
      getInvoiceDetailByDate();
    }else{
      dispatch(setError(res.err))
      dispatch(setLoadingFlag(false))
    }
  }

  const editInvoiceStatus = async() => {
    const filterData = selectedRows.map((obj) => {
      return { status: selectedInvoiceStatusForUpdate, invoiceNo: obj }
    }) 
    if(filterData.length > 0){
      dispatch(setLoadingFlag(true))
      const res = await updateInvoiceStatus(filterData)
      if(res?.res === true){
        getInvoiceDetailByDate();
        setShowEditPopUp(false)
        setSelectedRows([])
      }else{
        dispatch(setError(res.err))
        dispatch(setLoadingFlag(false))
      }
    }
  }

  const onClearClicked = () =>{
    setInitialDate();
    if (modules.length >0 && sites.length > 0){
      const firstModuleData = modules[ 0 ];
      const firstSiteData = sites[ 0 ];
      setSelectedModuleName(Object.values(firstModuleData)[ 0 ])
      setSelectedModuleId(0)
      setSelectedSite(Object.values(firstSiteData)[ 0 ])
      setSelectedInvoiceStatus(INVOICESTATUS.all)
      setIsGetInvoiceDetailsCalled(false)
    }
  }

  const onToDateChange = (date) =>{
    setToDate(date);
    setIsGetInvoiceDetailsCalled(false)
  }

  const modData = (e) =>{
    const selectedIndex = e.target.options.selectedIndex;
    const moduleKey = e.target.options[ selectedIndex ].getAttribute('datakey');
    setSelectedModuleName(e.target.value);
    setSelectedModuleId(Number(moduleKey))
    setIsGetInvoiceDetailsCalled(false)
  }

  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateInvoicePageNumber(0))
  }

  return (
      <div className={styles.mainContainer}>
          <div className={styles.leftContainer}>
              <div>              
                  <div className={styles.leftFilterText}>
                      <p className={styles.mainFilterText}>{ENWORDS.filters}</p>
                      <button className={styles.btnClearFilters} onClick = {onClearClicked}>{ENWORDS.clear}</button>
                  </div>
                  <div className={styles.filterContainer}>
                      <div className={styles.dateContainer}>
                          <CustomDatePicker 
                            title = {ENWORDS.from}
                            startDate = {fromDate}
                            endDate = {toDate}
                            dateFormat={globalDateFormat}
                            showMonthYearPicker ={false}
                            onChange = {(date) => onFromDateChange(date)}
                            wrapperClass = 'dashboardFilter'
                            isHidden={false} />
                            
                          <CustomDatePicker 
                            title = {ENWORDS.to}
                            startDate = {toDate}
                            minDate = {fromDate}
                            endDate = {new Date(new Date().getFullYear(), new Date().getMonth()+1, 1)}
                            dateFormat={globalDateFormat}
                            showMonthYearPicker ={false}
                            onChange = {(date) => onToDateChange(date)}
                            wrapperClass = 'dashboardFilter'
                            isHidden = {false} />
                      </div>
                      
                      <DashboardFilterDropdown
                        title = {ENWORDS.modulePlaceholder}
                        selectedValue = {selectedModuleName}
                        onChange={e => modData(e)}
                        dropdownData = {modules}
                      />
                      <DashboardFilterDropdown
                        title = {ENWORDS.station}
                        selectedValue = {selectedSite}
                        onChange={e => setSelectedSite(e.target.value)}
                        dropdownData = {sites }
                      />
                      <DashboardFilterDropdown
                        title = {ENWORDS.invoiceStatus}
                        selectedValue = {selectedInvoiceStatus}
                        onChange={e => setSelectedInvoiceStatus(e.target.value)}
                        dropdownData = { invoiceStatuses }
                      />
                  </div>
              </div>
          </div>
          <div className={styles.rightContainer}>
              <CustomPopup
                title={ENWORDS.editInvoice}
                onConfirm={editInvoiceStatus}
                showPopUp={showEditPopUp}
                onReject={()=>{ setShowEditPopUp(false); setSelectedInvoiceStatusForUpdate('') }}
                style={{ width : '30%' }}
              >
                  <div className={popUpStyle.popUpBody}>
                      <CustomDropDown
                        title={ENWORDS.status}
                        name='status'
                        fieldValues={[ 'Generated', 'Completed' ]}
                        selectedValue={selectedInvoiceStatusForUpdate}
                        onChange={e => setSelectedInvoiceStatusForUpdate(e.target.value)}
                      />
                  </div>
              </CustomPopup>
              <InvoiceFileUploadPopUp
                isFile = {isFilePicked}
                inputFile = {selectedFile}
                show={fileVisibility}
                onClose={filePopupCloseHandler}
                onUploaded={getInvoiceDetailByDate}
              />
              <SuccessPopUp
                title = {ENWORDS.mailSuccess}
                show = {visibility}
                onClose={popupCloseHandler}
              />
              <div className={styles.filtersContainer}>
                  <div className={styles.leftFilterContainer}>

                      <CustomSearchBox
                        placeholder = {ENWORDS.invoiceSearchPlaceholder} 
                        title = {ENWORDS.search}
                        value = {searchText}
                        onChange = {e => onChangeSearch(e)}
                      />
                  </div>

                  <div className={styles.functionBtnContainer} >
                      {roleAccess && roleAccess.writeAccess ?
                          <div className={styles.mainBtnContainer}title = {ENWORDS.generateInvoiceTooltip}>
                              <input
                                type="file"
                                accept={FILETYPE}
                                onChange={changeHandler}
                                ref={fileInputRef}
                                hidden
                              />
                              <button className= {styles.createBtn} onClick={onBlukUpload}>
                                  <div className={styles.btnContainer}>
                                      <img src={uploadIcon} alt = {ENWORDS.uploadIcon} />
                                  </div>
                              </button>
                          </div>
                        : null}
                      <div className={styles.mainBtnContainer}>
                          <DownloadCSVBtn
                            disabled = {selectedModuleId === 0 || selectedRows.length === 0}
                            tableData={filteredData.filter((object)=> selectedRows.includes(object.id))}
                            popUpText={ENWORDS.downloadInvoiceCSVText}
                            popUpTitle = {ENWORDS.downloadInvoiceCSVTile}
                            screenName = {ENWORDS.invoiceListingCSV}
                            toolTitle = {ENWORDS.downloadInvoiceTooltip}
                            headers={INVOICE_EXPORT_HEADERS}
                            fileNamePrefix={INVOICE_EXPORT_FILE_PREFIX}
                            setSelectedRows = {setSelectedRows}
                          />
                      </div>
                      {roleAccess && roleAccess.writeAccess ?
                          <div className={styles.mainBtnContainer}>
                              <button
                                disabled = {selectedModuleId === 0 || selectedRows.length ===0 || filteredData.some((data)=> selectedRows.includes(data.id) && [ 'Completed' ].includes(data.status)) } 
                                className={selectedModuleId === 0 || selectedRows.length ===0 || filteredData.some((data)=> selectedRows.includes(data.id) && [ 'Completed' ].includes(data.status)) ? styles.featureButtonDisable : styles.featureButton}  
                                onClick = {() => sendInvoiceEmail(selectedRows)}>
                                  <div className={styles.btnContainer}>
                                      <img src={mailIcon} alt = {ENWORDS.mail} />
                                  </div>
                              </button>
                          </div>
                        : null }
                      <div className={styles.mainBtnContainer} title = {ENWORDS.exportInvoiceTooltip}>
                          <button 
                            disabled = {selectedModuleId === 0 || selectedRows.length ===0}
                            className={selectedModuleId === 0 || selectedRows.length ===0 ? styles.featureButtonDisable : styles.featureButton}  
                            onClick={() => onclickDownloadCSV(selectedRows)}>
                              <div className={styles.btnContainer}>
                                  <img src={exportFileIcon} alt = {ENWORDS.downloadIcon} />
                              </div>
                          </button>
                      </div>
                      <div className={styles.mainBtnContainer} title = {ENWORDS.editInvoice}>
                          <button 
                            className={selectedModuleId === 0 || selectedRows.length ===0 || filteredData.some((data)=> selectedRows.includes(data.id) && data.status === 'Emailed')? styles.featureButtonDisable : styles.featureButton}
                            onClick={() =>setShowEditPopUp(true)} 
                            disabled = {selectedModuleId === 0 || selectedRows.length ===0 || filteredData.some((data)=> selectedRows.includes(data.id) && data.status === 'Emailed')}>
                              <div className={styles.btnContainer}>
                                  <img src={editPenIconLight} alt ={ENWORDS.editPenIcon}/>
                              </div>
                          </button>
                      </div>
                  </div>

              </div>
      
              <CustomTable 
                headerData={TABLEHEADERS}
                data={filteredData}
                onClickKey={'id'}
                selectedRows={selectedRows}
                editableColumns={ INVOICES_EDITABLE }
                onSaveClick={updateStatus}
                setSelectedRows={setSelectedRows}
                writeAccess={roleAccess?.writeAccess}
                onRowClick={(event, uniqueId)=>{
                  const item = filteredData.find(row=> row.id === uniqueId);
                  event.stopPropagation();
                  if(item){
                    invoiceRowClicked( item.status,item.id, item.billingPeriod, item.siteID, item.cmId, item.moduleId, true) }}
                }
                actions={
                  [ 
                    { name : ENWORDS.mail, 
                      src : mailFilledIcon, 
                      actionToolTipTxt : ENWORDS.sendMailTxt,
                      columnName : ENWORDS.actions,
                      onClick : (event, id, row)=>{ event.stopPropagation(); sendInvoiceEmail([ id ]) } , 
                      condition : { columnName: 'status', values : [ 'Generated','Regenerated', 'Emailed' ], restrictAccess : !roleAccess?.writeAccess },
                    }
                  ]}
                editRowCondition={ { columnName: 'status', values : [ { value: 'Generated', condition: true }, { value: 'Completed', condition: true }, { value: 'Regenerated', condition: true } ] } } 
              />
          </div>
          <CustomPopup
            showPopUp={showPopUP}
            onConfirm={()=>{setShowPopUP(false)}}
            title={ENWORDS.errorLogTitle}
            onReject={()=>{setShowPopUP(false)}}
            cancelText = {ENWORDS.cancel}
            confirmText = {ENWORDS.confirm}
            isNotShowButton = {true}
          >
              <p>{ENWORDS.exportNetSuiteValidationText}</p>
          </CustomPopup>
          <PenaltyInvoicePopUp
            title = {pendingPenaltyInvoices}
            show = {visibilityForPenaltyInvoice}
            onClose={popupCloseHandlerForPenaltyInvoice}/>
      </div>
  )
}

export default InvoiceListingScreen