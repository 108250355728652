import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import conStyles from '../../../../styles/moduleConfiguration/addconfig.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import arrowUp from '../../../../assets/icons/arrowUp.svg';
import arrowDown from '../../../../assets/icons/arrowDownDark.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { useDispatch, useSelector } from 'react-redux';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import { setLoadingFlag } from '../../../../redux/actions';
import { checkManagerDateIntersection, isoDateToMillisecond, isoStringToDate, milliSecondToIsoString, sortByDate } from '../../../../shared/commonFunctions';
import binIcon from '../../../../assets/icons/binIcon.svg';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import globalStyles from '../../../../styles/global.module.css';
import moment from 'moment';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import editPenIconLight from '../../../../assets/icons/editPenWhiteIcon.svg';
import {
  ALL_EMAIL_RG,
  ALPHA_NUM_REGEX,
  globalDateFormat
} from '../../../../shared/constants/appConstants';
import { addOrEditArea, deleteAreaById } from '../../../../api/services/areaManagementService';
import { getRegionsData } from '../../../../api/services/regionManagementService';
import { useDebounce } from 'use-debounce';
import { GLOBALSTATUS } from '../../../BaseConfiguration/baseConfigurationConstant';
import CustomDatePicker from '../../../../shared/Components/CustomDatePicker';
import CustomInput from '../../../../shared/Components/CustomInput';
import { REGIONNAME } from '../../stationListingConstant';
import CustomPopup from '../../../../shared/Components/CustomPopup';

const PLACEHOLDER = require('../../../../shared/local/placeholder.json');
const ERRORWORDS = require('../../../../shared/local/error.json');

const AreaDetailsScreen = ({ mode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location  = useLocation();
  const locationState = location.state;
  const [ mainData, setMainData ] = useState({});
  const [ managerPopupVisible, setManagerPopupVisible ] = useState(false);
  const [ areaPopupVisible, setAreaPopupVisible ] = useState(false);
  const [ hiddenArea, setHiddenArea ] = useState(false);
  const [ hiddenManager, setHiddenManager ] = useState(false);
  const [ visibleSection, setVisibleSection ] = useState('areaDetails');
  const [ toDeleteId, setToDeleteId ] = useState();
  const [ formError, setFormError ] = useState({});
  const [ activeManagerForm, setActiveManagerForm ] = useState(0);
  const [ fromId, setFormId ] = useState('');
  const [ managerDetailsList, setManagerDetailsList ] = useState([]);
  const [ headerTitle, setHeader ] = useState([ ENWORDS.addNewRegion ]);
  const [ areaName, setAreaName ] = useState('');
  const [ areaStatus, setAreaStatus ] = useState(1);
  const regions = useSelector(state => state.regionsReducer.regionsTableData);
  const [ region, setRegion ] = useState(regions[ 0 ]?.id);
  const [ managerDetailsError, setManagerDetailsError ] = useState({});
  const [ enableEdit, setEnableEdit ] = useState(false)
  const [ newManagerIndex, setNewManagerIndex ] = useState(0);

  const [ formValue ] = useDebounce(managerDetailsList, 500);

  const areaRef = useRef(null);
  const managerRef = useRef(null);
  const mainRef = useRef(null);

  const sectionRefs = [
    { section: ENWORDS.areaRefSection, ref: areaRef },
    { section: ENWORDS.managerRefSection, ref: managerRef }
  ];
  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const scrollTo = ele => {
    window.scrollTo({
      top: ele.offsetTop - mainRef.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  // const popupCloseHandler = e => {
  //   setManagerPopupVisible(e);
  // };

  const toggleAreaClass = () => {
    scrollTo(areaRef.current);
  };
  const toggleManagerClass = () => {
    scrollTo(managerRef.current);
  };

  const onCancelClicked = () => {
    navigate(ROUTES.STATIONAREAS);
  };

  const addManagerBlock = () => {
    let managerDetailsCopy = [ ...managerDetailsList ];
    var lenOfManagerDetails = managerDetailsList.length
    let initialStartDate ;
    let initialEndDate;
    let afterInitialStart;
    let afterInitialEnd;
    let struct;
    const managerId = (newManagerIndex + 1)*(-1)
    if(lenOfManagerDetails === 0){
      initialStartDate = Date.now()
      const momentEndDate = moment(initialStartDate);
      initialEndDate = (momentEndDate.add(1 ,'Y')).valueOf();
      struct = {
        id: managerId,
        name: '',
        address: '',
        email: '',
        startDate: initialStartDate,
        endDate: initialEndDate ,
      }
    }else{
      managerDetailsCopy.forEach((value) =>{
        if(value.id === managerDetailsCopy[ 0 ].id ){
          const endDate = value.endDate;
          afterInitialStart = moment(endDate).add(1,'d').valueOf() ;
          afterInitialEnd = moment(afterInitialStart).add(1,'Y').valueOf()
        }
      })
      struct = {
        id:managerId,
        name:'',
        address:'',
        startDate:afterInitialStart,
        endDate:afterInitialEnd,
      }
    }
    managerDetailsCopy = [ ...managerDetailsCopy, struct ];
    setActiveManagerForm(struct.id);
    setManagerDetailsList(sortByDate(managerDetailsCopy,'startDate'));
    setNewManagerIndex(prevId=>prevId+1)
  }

  const validate = async () => {
    dispatch(setLoadingFlag(true))
    const data = {
      id: mainData?.id,
      areaName:areaName,
      areaStatus:areaStatus,
      regionId : region,
      areaManagers: managerDetailsList.map((item, index) => {
        const toDate = milliSecondToIsoString(item.startDate)
        const fromDate = milliSecondToIsoString(item.endDate)
        const isActive = index === 0 ? 1: 0;
        return { ...item, startDate: toDate, endDate: fromDate, isActive: isActive }
      }),
    }
    const res = await addOrEditArea(data, mode)
    dispatch(setLoadingFlag(false)) 
    if (res.res) {  
      navigate(ROUTES.STATIONAREAS)
    }
  }
  const checkValidation = () => {
    let formErrorVariable = formError;
    const managerDetailsErrorCopy = managerDetailsError;

    if (managerDetailsList.length === 0) {
      formErrorVariable = {
        ...formErrorVariable,
        managerDetailsError: ERRORWORDS.managerDetailsListError,
      };
    }

    managerDetailsList.forEach((managerErrorRow, index) => {
      if (!managerDetailsErrorCopy[ managerErrorRow.id ]) {
        managerDetailsErrorCopy[ managerErrorRow.id ] = {};
      }
      if (
        managerErrorRow.name?.trimStart() === '' ||
        !ALPHA_NUM_REGEX.test(managerErrorRow.name)
      ) {
        managerDetailsErrorCopy[ managerErrorRow.id ].formManagerNameError =
          ERRORWORDS.managerNameError;
      }
      if (
        managerErrorRow.name?.trimStart() !== '' &&
        ALPHA_NUM_REGEX.test(managerErrorRow.name)
      ) {
        managerDetailsErrorCopy[ managerErrorRow.id ].formManagerNameError = '';
      }
      if (
        managerErrorRow.address?.trimStart() === '' ||
        !ALPHA_NUM_REGEX.test(managerErrorRow.address)
      ) {
        managerDetailsErrorCopy[ managerErrorRow.id ].formManagerAddressError =
          ERRORWORDS.managerAddressError;
      }
      if (
        managerErrorRow.address?.trimStart() !== '' &&
        ALPHA_NUM_REGEX.test(managerErrorRow.address)
      ) {
        managerDetailsErrorCopy[ managerErrorRow.id ].formManagerAddressError = '';
      }
      if (!ALL_EMAIL_RG.test(managerErrorRow.email?.trim())) {
        managerDetailsErrorCopy[ managerErrorRow.id ].formManagerEmailError =
          ERRORWORDS.managerEmailError;
      }
      if (ALL_EMAIL_RG.test(managerErrorRow.email?.trim())) {
        managerDetailsErrorCopy[ managerErrorRow.id ].formManagerEmailError = '';
      }
    });

    setFormError(formErrorVariable);
    setManagerDetailsError({ ...managerDetailsErrorCopy });

    const isFormErrorEmpty = Object.values(formErrorVariable).every(value => {
      if (value === null || value === '') {
        return true;
      } 
      return false;
    });
    const isManagerErrorEmpty = Object.keys(managerDetailsErrorCopy).filter(e => {
      return (
        managerDetailsErrorCopy[ e ]?.formManagerNameError ||
        managerDetailsErrorCopy[ e ]?.formManagerEmailError ||
        managerDetailsErrorCopy[ e ]?.formEndDateError ||
        managerDetailsErrorCopy[ e ]?.formManagerAddressError ||
        managerDetailsErrorCopy[ e ]?.formStartDateError
      );
    });
    if (
      isFormErrorEmpty &&
      isManagerErrorEmpty.length === 0 
    ) {
      validate()
    }
    // setIsDone(!isDone);
  };

  // const showManagerPopup = id => {
  //   setManagerPopupVisible(true);
  //   setToDeleteId(id);
  // };

  const onDoneClicked = () => {
    checkValidation();
  };

  const deleteManagerDetailsById = () => {
    const managerDetailsListCopy = managerDetailsList;
    managerDetailsListCopy.forEach((item, idForm) => {
      if (item.id === toDeleteId) {
        managerDetailsListCopy.splice(idForm, 1);
        if (managerDetailsError[ item.id ]) {
          managerDetailsError[ item.id ] = undefined;
        }
        setManagerDetailsList([ ...managerDetailsListCopy ]);
      }
    });
    setManagerPopupVisible(false)
    setToDeleteId(0)
  };
  const onDeleteManagerClick = (event, managerId) => {
    event.stopPropagation();
    setToDeleteId(managerId)
    setManagerPopupVisible(true)
  }

  const onDeleteAreaClick = (event, regionId) => {
    event.stopPropagation();
    setToDeleteId(regionId)
    setAreaPopupVisible(true)
  }
  
  const deleteArea = (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    dispatch(deleteAreaById(toDeleteId))
    setAreaPopupVisible(false)
    setToDeleteId(0)
    navigate(ROUTES.STATIONAREAS)
  }

  const setAreaData = () => {
    if(mainData){
      setAreaName(mainData.areaName)
      mainData.areaManagers?.forEach((item)=>{
        item.startDate = isoDateToMillisecond(item.startDate)
        item.endDate = isoDateToMillisecond(item.endDate)
      })
      setManagerDetailsList(sortByDate(mainData?.areaManagers, 'startDate'))
      setAreaStatus(mainData.areaStatus);
      setRegion(mainData.regionId)
    }
  }
  const editArea = async () =>{
    dispatch(setLoadingFlag(true))
  
    if (mainData){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITAREA, { state:{ data: mainData } })
      setActiveManagerForm(0)
    }

  }

  useEffect(() =>{
    if(mainData){
      if (mainData.areaManagers){
        if((mainData.areaManagers).length !== 0){
  
          setActiveManagerForm(mainData.areaManagers[ 0 ].id);
        }
      }
      if( Object.keys(mainData).length !== 0){
        setAreaData();
      }  
    }  
  },[ mainData ])

  useEffect(() => {
    const managerListCopy = managerDetailsList.map(manager=>{
      if(manager.id === activeManagerForm){
        return { ...manager, endDate : moment(manager.startDate).add(1,'Y').valueOf() }
      }
      return manager
    })
    setManagerDetailsList(managerListCopy)

  }, [ activeManagerForm ])
  
  useEffect(() => {
    if (managerDetailsList.length !== 0) {
      setActiveManagerForm(managerDetailsList[ 0 ].id);
    }
  }, [ managerDetailsList ]);

  useEffect(() => {
    let errorVariable = formError;
    if (
      areaName?.trim() !== '' &&
      ALPHA_NUM_REGEX.test(areaName) !== false
    ) {
      errorVariable = { ...errorVariable, regionNameError: '' };
    }
    if (managerDetailsList.length !== 0) {
      errorVariable = { ...errorVariable, managerDetailsError: '' };
    }
    setFormError(errorVariable);
  }, [ areaName, managerDetailsList ]);

  useEffect(() => {
    const formErrorVariable = { ...managerDetailsError };
    managerDetailsList.forEach(formRow => {
      if (formErrorVariable[ formRow.id ] !== undefined) {
        if (formRow.name?.trim() !== '') {
          formErrorVariable[ formRow.id ].formManagerNameError = '';
        }
        if (formRow.address?.trim() !== '') {
          formErrorVariable[ formRow.id ].formManagerAddressError = '';
        }
        if (formRow.email?.trim() !== '') {
          formErrorVariable[ formRow.id ].formEmailError = '';
        }
        if (ALL_EMAIL_RG.test((formRow.email)?.trim())) {
          formErrorVariable[ formRow.id ].formManagerEmailError = '';
        }
      }
    });
    setManagerDetailsError(formErrorVariable);
  }, [ managerDetailsList ]);

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(areaRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ visibleSection ]);

  useEffect(() => {
    dispatch(setLoadingFlag(true));
    if (locationState){
      setMainData(locationState?.data);
    }
    dispatch(setLoadingFlag(false));
  }, [ locationState ]);

  useEffect(() =>{
    if(fromId !== ''){
      const { formErrorVariable, isError } = checkManagerDateIntersection(fromId, managerDetailsList, managerDetailsError)
      setManagerDetailsError(formErrorVariable)
      if(!isError){
        setActiveManagerForm(sortByDate(managerDetailsList,'startDate')[ 0 ]?.id)
        setManagerDetailsList(sortByDate(managerDetailsList,'startDate'));
      }
    }
  },[ formValue ])

  const onManagerDetailsUpdate = (value, formID, nameField, objKey) => {
    const managerAreaTempList = managerDetailsList.map(i => {
      if (i.id === formID) {

        if (objKey === 'startDate') {
          setFormId(formID);
          return {
            ...i,
            [ nameField ]: value.getTime(),
            endDate: moment(value).add(1, 'Y').valueOf(),
          };
        } else if (objKey === 'endDate') {
          setFormId(formID);
          return { ...i, [ nameField ]: value.getTime() };
        } else {
          return { ...i, [ nameField ]: value };
        }
      }
      return i;
    });

    setManagerDetailsList(sortByDate(managerAreaTempList, 'startDate'));
  };

  useEffect(() => {
    dispatch(getRegionsData())

    switch(mode){
    case ENWORDS.viewMode:
      setHeader(ENWORDS.viewAreas);
      break;
    case ENWORDS.editMode:
      setHeader(ENWORDS.editAreas);
      setEnableEdit(true)
      break;
    case ENWORDS.addMode:
      setHeader(ENWORDS.addAreas);
      setEnableEdit(true)
      if(managerDetailsList.length === 0 ){
        addManagerBlock();
      }
      break;
    default: 
      setHeader('')
    }

  }, [ mode ] );

  return (
      <div>
          <div className={styles.mainContainer} ref={mainRef}>
              <CustomPopup
                showPopUp={managerPopupVisible}
                onConfirm={deleteManagerDetailsById}
                title={ENWORDS.removeManagerTitle}
                onReject={()=>{setManagerPopupVisible(false); setToDeleteId(0)}}
              >
                  <p>{ENWORDS.removeManagerText}</p>
              </CustomPopup>
              <CustomPopup
                showPopUp={areaPopupVisible}
                onConfirm={deleteArea}
                title={ENWORDS.removeRegionTitle}
                onReject={()=>{setAreaPopupVisible(false); setToDeleteId(0)}}
              >
                  <p>{ENWORDS.removeRegionText}</p>
              </CustomPopup>
              <div className={conStyles.leftContainerStation}>
                  <div className={conStyles.innerSideContainer}>
                      <p
                        className={
                          visibleSection === ENWORDS.areaRefSection
                            ? conStyles.activeText
                            : conStyles.inactiveText
                        }
                        onClick={toggleAreaClass}
                      >
                          {ENWORDS.areaDetails}
                      </p>
                      <p
                        className={
                          visibleSection === ENWORDS.managerRefSection
                            ? conStyles.activeText
                            : conStyles.inactiveText
                        }
                        onClick={toggleManagerClass}
                      >
                          {ENWORDS.areaManager}
                      </p>
                  </div>
              </div>

              <div className={conStyles.rightContainer}>
                  <div className={styles.content}>
                      <div id={ENWORDS.areaRefSection} ref={areaRef}>
                          <div className={conStyles.moduleDetailsTopContainer}>
                              <ListingHeader
                                title={headerTitle}
                                navigationPath={ROUTES.STATIONAREAS}
                                state={locationState}
                              />
                              {
                                locationState?.roleAccess && locationState?.roleAccess.writeAccess && mode === ENWORDS.viewMode

                                  ? 
                                      <div className={conStyles.buttonContainer}>
                                          <button className={conStyles.btnCancel} onClick={(event)=> onDeleteAreaClick(event, mainData.id)}>
                                              <img src={binIcon} alt = {ENWORDS.deleteIcon}/>
                                          </button>
                                          <button className={conStyles.btnDone} onClick={() => editArea()}>
                                              <img src={editPenIconLight} alt ={ENWORDS.editPenIcon}/>
                                          </button>
                                      </div>
                                  :null} 
                          </div>
                          <div className={styles.siteNavContainer}>
                              <div className={styles.siteNavigation}>
                                  <p className={styles.robotoMediam}>
                                      {ENWORDS.areaDetails}
                                  </p>
                                  <button onClick={() => setHiddenArea(!hiddenArea)}>
                                      <img
                                        src={!hiddenArea ? arrowUp : arrowDown}
                                        alt={ENWORDS.sortingArrow}
                                        className={styles.upArrow}
                                      />
                                  </button>
                              </div>
                          </div>

                          {!hiddenArea ? (
                              <div className={styles.siteForm}>
                                  <div className={inputStyles.detailsRow}>

                                      {enableEdit? 
                                          <CustomInput
                                            title={ENWORDS.areaName}
                                            placeholder={PLACEHOLDER.area}
                                            value= {areaName ?? ''}
                                            //value={values.region[ 0 ].regionName ?? regionName}
                                            error={formError.areaNameError}
                                            onChange={e => setAreaName(e.trim())}
                                          />:
                                          <div className={styles.formFieldContainer}>
                                              <p>{ENWORDS.areaName}</p>
                                              <div className={styles.displayBox}>{areaName}</div>
                                          </div>
                                      }
                                      {enableEdit? 
                                          <CustomDropDown
                                            title={ENWORDS.region}
                                            name={ENWORDS.region}
                                            fieldValues={regions ?? ''}
                                            renderKey = {'id'}
                                            renderKeyOne = {REGIONNAME}
                                            isDisabled={false}
                                            selectedValue={region}
                                            onChange={e =>
                                              setRegion(Number(e.target.value))
                                            }
                                          />:
                                          <div className={styles.formFieldContainer}>
                                              <p>{ENWORDS.region}</p>
                                              <div className={styles.displayBox}>{regions.find(reg=> reg.id === region)?.regionName}</div>
                                          </div>
                                      }
                                      {enableEdit? 
                                          <CustomDropDown
                                            title={ENWORDS.status}
                                            name={ENWORDS.status}
                                            fieldValues={GLOBALSTATUS ?? ''}
                                            selectedValue={areaStatus}
                                            isDisabled={false}
                                            onChange={e =>
                                              setAreaStatus(Number(e.target.value))
                                            }
                                          />
                                        :
                                          <div className={styles.formFieldContainer}>
                                              <p>{ENWORDS.status}</p>
                                              <div className={styles.displayBox}>{GLOBALSTATUS[ areaStatus ]}</div>
                                          </div>
                                      }
                                  </div>
                              </div>
                          ) : (
                              <div></div>
                          )}
                          <hr />
                      </div>

                      <div id={ENWORDS.managerRefSection} ref={managerRef}>
                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.activeManager}</p>
                              </div>
                          </div>
                          <div className={styles.cmMainForm}>
                              {
                                  <p
                                    className={
                                      formError.managerDetailsError
                                        ? globalStyles.errorMessageShow
                                        : globalStyles.errorMessage
                                    }
                                  >
                                      {formError.managerDetailsError}
                                  </p>
                              }
                              {enableEdit && (
                              <div className={styles.cmBtnContainer}>
                                  <div className={styles.addBtn}>
                                      <button onClick={addManagerBlock}>
                                          <img src={plusIcon} alt={ENWORDS.addBtn} />
                                      </button>
                                  </div>
                              </div>
                              )}

                              {managerDetailsList &&
                    managerDetailsList.map((formData) => {
                      return (
                          <div key={formData.id}>
                              {formData.id === activeManagerForm ? (
                                  <div
                                    className={`${ styles.cmForm }`}
                                    key={formData.id}
                                  >
                                      {enableEdit && (
                                      <div className={styles.deleteBtnCMContainer}>
                                          <button
                                            className={styles.deleteBtnCM}
                                            onClick={(event) =>
                                              onDeleteManagerClick(event, formData.id)
                                            }
                                          >
                                              <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                          </button>
                                      </div>
                                      )}
                                      <div className={inputStyles.detailsRow}>
                                          {enableEdit? 
                                              <CustomInput
                                                title={ENWORDS.Name}
                                                value={formData.name}
                                                placeholder={PLACEHOLDER.fullName}
                                                error={
                                                  managerDetailsError[ formData.id ] &&
                                    managerDetailsError[ formData.id ]?.formManagerNameError
                                                }
                                                onChange={e =>
                                                  onManagerDetailsUpdate(
                                                    e,
                                                    formData.id,
                                                    'name',
                                                    'name'
                                                  )
                                                }
                                              />
                                            :
                                              <div className={styles.formFieldContainer}>
                                                  <p>{ENWORDS.Name}</p>
                                                  <div className={styles.displayBox}>{formData.name}</div>
                                              </div>
                                          }
                                          {enableEdit ?
                                              <CustomInput
                                                title={ENWORDS.Address}
                                                value={formData.address}
                                                placeholder={PLACEHOLDER.address}
                                                error={
                                                  managerDetailsError[ formData.id ] &&
                                    managerDetailsError[ formData.id ]
                                      ?.formManagerAddressError
                                                }
                                                onChange={e =>
                                                  onManagerDetailsUpdate(
                                                    e,
                                                    formData.id,
                                                    'address',
                                                    'address'
                                                  )
                                                }
                                              />:
                                              <div className={styles.formFieldContainer} title = {formData.address}>
                                                  <p>{ENWORDS.Address}</p>
                                                  <div className={styles.displayBox}>{(formData.address)?.slice(0,15) + '...'}</div>
                                              </div>
                                          }
                                          {enableEdit? 
                                              <CustomInput
                                                title={ENWORDS.Email}
                                                value={formData.email}
                                                placeholder={PLACEHOLDER.commonEmail}
                                                error={
                                                  managerDetailsError[ formData.id ] &&
                                    managerDetailsError[ formData.id ]
                                      ?.formManagerEmailError
                                                }
                                                maintainDefaultCase={true}
                                                onChange={e =>
                                                  onManagerDetailsUpdate(
                                                    e.trim(),
                                                    formData.id,
                                                    'email',
                                                    'email'
                                                  )
                                                }
                                              />
                                            :
                                              <div className={styles.formFieldContainer}>
                                                  <p>{ENWORDS.Email}</p>
                                                  <div className={styles.displayBox}>{formData.email}</div>
                                              </div>}
                                          {enableEdit? 
                                              <CustomDatePicker
                                                title={ENWORDS.startDate}
                                                startDate={formData.startDate}
                                                dateFormat = {globalDateFormat}
                                                onChange={(e) => onManagerDetailsUpdate(e, formData.id, 'startDate','startDate')}
                                                wrapperClass = 'stationDate'
                                                errorMessage= {
                                                  managerDetailsError[ formData.id ] && managerDetailsError[ formData.id ]?.formStartDateError
                                                }
                                              />
                                            :
                                              <div className={styles.formFieldContainer}>
                                                  <p>{ENWORDS.startDate}</p>
                                                  <div className={styles.displayBox}>{isoStringToDate(formData.startDate)}</div>
                                              </div>}
                                      </div>
                                  </div>
                              ) : null}
                          </div>
                      );
                    })}
                          </div>
                          <hr />

                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.previousManager}</p>
                                  <button onClick={() => setHiddenManager(!hiddenManager)}>
                                      <img
                                        src={!hiddenManager ? arrowUp : arrowDown}
                                        alt={ENWORDS.sortingArrow}
                                        className={styles.upArrow}
                                      />
                                  </button>
                              </div>
                          </div>
                          <div className={styles.cmMainForm}>
                              {!hiddenManager ? (
                                  <div>
                                      { managerDetailsList &&
                        managerDetailsList.map((formData) => {
                          return (
                              <div key={formData.id}>
                                  {formData.id !== activeManagerForm ? (
                                      <div
                                        className={`${ styles.cmForm }`}
                                        key={formData.id}
                                      >
                                          {enableEdit &&
                                          <div className={styles.deleteBtnCMContainer}>
                                              <button
                                                className={styles.deleteBtnCM}
                                                onClick={(event) =>
                                                  onDeleteManagerClick(event, formData.id)
                                                }
                                              >
                                                  <img
                                                    src={binIcon}
                                                    alt={ENWORDS.deleteIcon}
                                                  />
                                              </button>
                                          </div>
                                          }
                                          <div className={inputStyles.detailsRow}>
                                              {enableEdit? 
                                                  <CustomInput
                                                    title={ENWORDS.Name}
                                                    value={formData.name}
                                                    placeholder={PLACEHOLDER.fullName}
                                                    error={
                                                      managerDetailsError[ formData.id ] &&
                                        managerDetailsError[ formData.id ]
                                          ?.formManagerNameError
                                                    }
                                                    onChange={e =>
                                                      onManagerDetailsUpdate(
                                                        e,
                                                        formData.id,
                                                        'name',
                                                        'name'
                                                      )
                                                    }
                                                  />
                                                :
                                                  <div className={styles.formFieldContainer}>
                                                      <p>{ENWORDS.Name}</p>
                                                      <div className={styles.displayBox}>{formData.name}</div>
                                                  </div>}
                                              {enableEdit? 
                                                  <CustomInput
                                                    title={ENWORDS.Address}
                                                    value={formData.address}
                                                    placeholder={PLACEHOLDER.address}
                                                    error={
                                                      managerDetailsError[ formData.id ] &&
                                        managerDetailsError[ formData.id ]
                                          ?.formManagerAddressError
                                                    }
                                                    onChange={e =>
                                                      onManagerDetailsUpdate(
                                                        e,
                                                        formData.id,
                                                        'address',
                                                        'address'
                                                      )
                                                    }
                                                  />:
                                                  <div className={styles.formFieldContainer}>
                                                      <p>{ENWORDS.Address}</p>
                                                      <div className={styles.displayBox}>{formData.address}</div>
                                                  </div>}
                                              {enableEdit? 
                                                  <CustomInput
                                                    title={ENWORDS.Email}
                                                    value={formData.email}
                                                    placeholder={PLACEHOLDER.commonEmail}
                                                    error={
                                                      managerDetailsError[ formData.id ] &&
                                        managerDetailsError[ formData.id ]
                                          ?.formManagerEmailError
                                                    }
                                                    maintainDefaultCase={true}
                                                    onChange={e =>
                                                      onManagerDetailsUpdate(
                                                        e.trim(),
                                                        formData.id,
                                                        'email',
                                                        'email'
                                                      )
                                                    }
                                                  />:
                                                  <div className={styles.formFieldContainer}>
                                                      <p>{ENWORDS.Email}</p>
                                                      <div className={styles.displayBox}>{formData.email}</div>
                                                  </div>}
                                              {enableEdit? 
                                                  <CustomDatePicker
                                                    title={ENWORDS.startDate}
                                                    startDate={formData.startDate}
                                                    dateFormat = {globalDateFormat}
                                                    onChange={(e) => onManagerDetailsUpdate(e, formData.id, 'startDate','startDate')}
                                                    wrapperClass = 'stationDate'
                                                    errorMessage= {
                                                      managerDetailsError[ formData.id ] && managerDetailsError[ formData.id ]?.formStartDateError
                                                    }
                                                  />:
                                                  <div className={styles.formFieldContainer}>
                                                      <p>{ENWORDS.startDate}</p>
                                                      <div className={styles.displayBox}>{isoStringToDate(formData.startDate)}</div>
                                                  </div>}
                                              {enableEdit? 
                                                  <CustomDatePicker
                                                    title={ENWORDS.endDate}
                                                    startDate = {formData.endDate}
                                                    dateFormat = {globalDateFormat}
                                                    onChange={(e) => onManagerDetailsUpdate(e, formData.id, 'endDate', 'endDate')}
                                                    wrapperClass = 'stationDate'
                                                    errorMessage= {
                                                      managerDetailsError[ formData.id ] && managerDetailsError[ formData.id ]?.formStartDateError
                                                    }
                                                  />:
                                                  <div className={styles.formFieldContainer}>
                                                      <p>{ENWORDS.endDate}</p>
                                                      <div className={styles.displayBox}>{isoStringToDate(formData.endDate)}</div>
                                                  </div>}
                                          </div>
                                      </div>
                                  ) : null}
                              </div>
                          );
                        })}
                                  </div>
                              ) : (
                                  <div></div>
                              )}
                              {enableEdit &&
                              <div className={styles.btnContainer}>
                                  <div className={styles.cancelBtn}>
                                      <button onClick={onCancelClicked}>
                                          {ENWORDS.cancel}
                                      </button>
                                  </div>
                                  <div className={styles.doneBtn}>
                                      <button onClick={onDoneClicked}>{ENWORDS.done}</button>
                                  </div>
                              </div>
                              }
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default AreaDetailsScreen;
