import React from 'react'
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
const ENWORDS = require('../../../../shared/local/en.json')
const SettingBlock = ({ data }) => {
  
  return (

      <div className={supplierStyles.contentContainer}>
          <div className={styles.settingsForm}>
              <div className={inputStyles.detailsRow}>
                  <div className={styles.formFieldContainer}>
                      <p>{ENWORDS.tokenValidation}</p>
                      <div className={styles.displayBox}> {data.tokenValidation ?? ''}</div>
                  </div>   
              </div>

          </div>
      </div>
      
  )
}

export default SettingBlock