// User management table header
export const TABLEHEADERS = {
  name: 'Name',
  email: 'Email',
  roleName: 'Role',
  action:'Action',
}

// Module assignment table headers
export const MODULEASSIGNMENT = {
  'invoice': 'Invoice',
  'access': 'Access',
}

export const SCREENASSIGNMENT = {
  'screen' : 'Screen',
  'access':'Access',
}

// Dropdown status values
export const STATUS = {
  'all':'All',
  'activated':'Activated',
  'deactivated':'Deactivated',
}

// Screen type values used in image popup 
export const SCREENTYPE = {
  'addScreen':'AddScreen',
  'editScreen':'EditScreen',
}
