import { setLoadingFlag } from '../../redux/actions';
import { setAreasTable } from '../../redux/actions/areaListing';
import { API_HEADER, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { AREAURL, STATIONURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
const ENWORDS = require('../../shared/local/en.json');

export function getAreas() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: AREAURL.getAreas,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setAreasTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function getAreaById(areaId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ AREAURL.getAreaById + areaId }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function addOrEditArea(data,type = ENWORDS.addMode) {
  const { id, areaName, areaManagers, areaStatus ,regionId } = data
  let res;
  let errRes = {};
  await makeRequest({
    method: type === ENWORDS.editMode? PUT : POST,
    url: type === ENWORDS.editMode ? AREAURL.updateArea +'?id='+ id : AREAURL.addArea ,
    data: {
      areaName:areaName,
      areaManagers:areaManagers,
      areaStatus:areaStatus,
      regionId : regionId,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function deleteManagerById( id ) { 
  let isManagerDeleted = false;
  await makeRequest({
    method: DELETE ,
    url: `${ AREAURL.deleteManagerById + id }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function(){
      isManagerDeleted=  true;
    })
    .catch(function(){
      isManagerDeleted = false;
    })
  return isManagerDeleted;

}

export function deleteAreaById( id ) { 
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ AREAURL.deleteArea + id }`,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function(res){
        dispatch(setLoadingFlag(true))
        dispatch(getAreas())
        dispatch(setLoadingFlag(false))
      }).catch(function (err) {
        console.log(err)
      });

  }
}