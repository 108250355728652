import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/inputs.module.css'
import globalStyles from '../../styles/global.module.css';
import arrowDown from '../../assets/icons/arrowDownFilled.svg';
import arrowUp from '../../assets/icons/arrowUpFilled.svg';
const ENWORDS = require('../../shared/local/en.json')

const CustomDropDown = ({ title, name, type,
  selectedValue, onChange, fieldValues,
  isDisabled, defaultValue,
  renderKey = 'id', renderKeyOne, renderKeyTwo = '', wrapperClass, error,
}) => {
  const changeDropDown = (e) => {
    if (onChange) {
      onChange(e);
    }
  }

  return (
      <div>
          {
              <div className={globalStyles.roleContainer}>
                  {title && 
                  <p className={globalStyles.filterText}>{title}</p>
                  }
                  <div style= { (error !== undefined || '' || null) && fieldValues === null ? { border : '1px solid red' , borderRadius:'4px' } : null} className={type === ENWORDS.custom ? globalStyles.selectionBox  :  wrapperClass === 'customDropDown' ? globalStyles.selectionBoxs : styles.selectionBox}>
                      <select name={name} id={name}
                        onChange={changeDropDown}
                        defaultValue={defaultValue}
                        value={selectedValue}
                        disabled={isDisabled}>
                          {Array.isArray(fieldValues) ?
                            fieldValues && fieldValues.map((item) => {
                              return <option
                                key={item[ renderKey ]}
                                value={item[ renderKey ]?? item}
                                datakey={item[ renderKey ]}
                              >{item[ renderKeyOne ?? 'roleName' ]?? item} </option>
                            }) :
                            Object.keys(fieldValues).map((key, id) =>
                                <option key={id} value={key}>{fieldValues[ key ]} </option>
                            )
                          }
                      </select>
                  </div>
                  <div>
                      {<p className={error ? globalStyles.errorMessageShow: globalStyles.errorMessage}>{error}</p> }
                  </div>
              </div>
          }
      </div>
  )
}
export const MultiSeleteDropDown = ({ title, name, selectedValue, type, selectedPlaceHolder, onChange, fieldValues, isDisabled, notSelectedPlaceHolder, idKey, renderKeyOne }) => {
  const [ visible, setVisible ] = useState(false);
  const [ selectBox, setSelelectedBox ] = useState(selectedValue ?? []);
  const containerRef = useRef(null);

  useEffect(() => {
    if (selectedValue && selectedValue !== '') {
      setSelelectedBox(selectedValue)
    }
  }, [ selectedValue ])
  const changeDropDown = (e) => {
    if (onChange) {
      const data = new Set(selectBox);
      if (data.has(e)) {
        data.delete(e);
      } else {
        data.add(e);
      }
      setSelelectedBox(Array.from(data))
      onChange(Array.from(data));
    }
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    // Attach the click event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
    // Clean up the listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
      <div className={globalStyles.roleContainer} ref={containerRef}>
          <p className={globalStyles.filterText}>{title}</p>
          <div className={type === ENWORDS.custom ? globalStyles.selectionBox : styles.dropdownBox}

            onClick={() => setVisible(!visible)}>
              <p>{selectBox && selectBox.length > 0 ? selectedPlaceHolder : notSelectedPlaceHolder} {selectBox.length === 0 ? '' : selectBox.length}</p>
              <img src = { visible ? arrowUp:  arrowDown} alt="Arrow"/>
          </div>
          {
            visible ?
                <div className={styles.dropdownContainer} >
                    {
                      fieldValues && fieldValues.map((item) => {
                        return (
                            <label key={item[ idKey ]}>
                                <input type={'checkbox'}
                                  checked={
                                    selectBox.some((e) => e === item[ idKey ])
                                  }
                                  onChange={() => changeDropDown(item[ idKey ])}
                                  id={item[ idKey ]} />{item[ renderKeyOne ?? 'roleName' ]}
                            </label>)
                      })}

                </div>
              :null
          }
      </div>
  );
}
export default CustomDropDown