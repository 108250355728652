import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useAccess = (screenName) => {
  const [ hasWriteAccess, setHasWriteAccess ] = useState(false);
  const roleLevelAccess = useSelector(
    state => state.roleManagement.roleLevelAccess
  );

  useEffect(() => {
    setHasWriteAccess(roleLevelAccess.find(item => item.screenName === screenName)?.writeAccess ?? false)
  }, [ screenName, roleLevelAccess ]);

  return hasWriteAccess;
};

export default useAccess;
