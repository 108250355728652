import React, { useState, useEffect } from 'react'
import baseStyles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import styles from '../../../../styles/stationListing/StationListingScreen.module.css'
import ENWORDS from '../../../../shared/local/en.json';
import { ROUTES }  from '../../../../shared/constants/appRoute';
import { useNavigate } from 'react-router-dom';
import { AREASTATUSSTYLE, AREA_EXPORT_FILE_PREFIX, AREA_EXPORT_HEADERS, STATIONAREASHEADER } from '../../stationListingConstant';
import { useSelector, useDispatch } from 'react-redux';
import CustomSearchBox from '../../../../shared/Components/CustomSearchBox';
import { setLoadingFlag } from '../../../../redux/actions';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import { GLOBALSEARCHSTATUS, GLOBALSTATUS } from '../../../BaseConfiguration/baseConfigurationConstant';
import DownloadCSVBtn from '../../../../shared/Components/DownloadCSVBtn';
import { deleteAreaById, getAreaById, getAreas } from '../../../../api/services/areaManagementService';
import CustomTable from '../../../../shared/Components/CustomTable';
import editPenIcon from '../../../../assets/icons/editPenIcon.svg';
import deleteIcon from '../../../../assets/icons/binIcon.svg';
import CustomPopup from '../../../../shared/Components/CustomPopup';
import SideNavigation from '../../../../shared/Components/SideNavigation';
import { TABS } from '../../../../shared/constants/appConstants';
import { updateAreaFilter, updateAreaPageNumber } from '../../../../redux/actions/areaListing';
const SCREENS  = require('../../../../shared/local/screens.json');

const AreasListingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const areasData = useSelector(state => state.areaReducer.areasTableData);
  const areasStatusFilter = useSelector(state => state.areaReducer.areaStatusFilter);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ searchText, setSearchText ] = useState('');
  const [ roleAccess, setRoleAccess ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState(areasStatusFilter ? areasStatusFilter : GLOBALSEARCHSTATUS.All);
  const [ toDeleteId, setToDeleteId ] = useState()
  const [ showDeletePopUp, setShowDeletePopUp ] = useState(false)

  useEffect(() => {
    dispatch(getAreas())
  }, [])

  useEffect(() => {
    setFilteredData(areasData ?? []);
  }, [ areasData ])

  const filteration = () => {
    let areaFilterData = areasData
    if (searchText.length > 0) {
      areaFilterData = areaFilterData.filter((item) => {
        const name = (item[ 'areaName' ]?.toLowerCase())
        const areaManager = (item[ 'areaManagerName' ]?.toLowerCase())
        const managerEmail = (item[ 'areaManagerEmail' ]?.toLowerCase())
        const region = (item[ 'regionName' ]?.toLowerCase())
        return name?.includes(searchText?.toLowerCase()) 
        || areaManager?.includes(searchText?.toLowerCase()) || managerEmail?.includes(searchText?.toLowerCase()) 
        || region?.includes(searchText?.toLowerCase())
      });
    }
    
    if(selectedStatus.toLowerCase() !== 'all'){

      areaFilterData = areaFilterData.filter((item) =>{

        return (item.areaStatus).toString() === selectedStatus;
      })
    }

    return areaFilterData
  }
  useEffect(() => {
    setFilteredData(filteration())

  }, [ searchText, selectedStatus, areasData ])

  useEffect(() => {
    dispatch(updateAreaFilter(selectedStatus))
    dispatch(updateAreaPageNumber(0))
  }, [ selectedStatus ])
  
  const addArea = async (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))

    dispatch(setLoadingFlag(false))
    navigate(ROUTES.ADDAREA)

  }
  
  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.areas){
        if (!item.writeAccess){
          delete STATIONAREASHEADER[ 'actions' ];
        }
        setRoleAccess(item)
      }
    })

  }

  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  const onRowClick = async (event, areaId) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    const areaData = await getAreaById(areaId);
    dispatch(setLoadingFlag(false));
    if(areaData){
      navigate(ROUTES.AREADETAILS, {
        state: {
          data: areaData.res,
          roleAccess: roleAccess,
        },
      });
    }
  }

  const editArea = async (event, areaId) => {
    dispatch(setLoadingFlag(true));
    event.stopPropagation();
    const areaData = await getAreaById(areaId);
    if (areaData) {
      dispatch(setLoadingFlag(false));
      navigate(ROUTES.EDITAREA,
        {
          state: {
            data: areaData.res,
          },
        }
      );
    }
  };

  const deleteArea = (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    dispatch(deleteAreaById(toDeleteId))
    setShowDeletePopUp(false)
    dispatch(getAreas())
  }

  const onDeleteClick = (event, areaId) => {
    event.stopPropagation();
    setToDeleteId(areaId)
    setShowDeletePopUp(true)
  }

  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateAreaPageNumber(0))
  }

  return (
      <div className={baseStyles.mainContainer}>
          <CustomPopup
            showPopUp={showDeletePopUp}
            onConfirm={deleteArea}
            title={ENWORDS.removeAreaTitle}
            onReject={()=>{setShowDeletePopUp(false)}}
          >
              <p>{ENWORDS.removeAreaText}</p>
          </CustomPopup>

          <div className = {baseStyles.leftContainer}>
              <SideNavigation parentName={TABS.setup}
              />
          </div>

          <div className={baseStyles.rightContainer}> 
              <div className = {baseStyles.siteHeader}>
                  <div className={styles.stationHeaderContainer}>
                      <div className={styles.filterContainer}>
                          <div className={styles.leftFilterContainer}>

                              <div className={styles.searchBoxContainer}>
                                  <CustomSearchBox 
                                    placeholder = {ENWORDS.stationPlaceholder}  
                                    title={ENWORDS.search} 
                                    onChange={e => onChangeSearch(e)} />
                              </div>
                              <CustomDropDown
                                title={ENWORDS.status}
                                name = {ENWORDS.status?.toLowerCase()}
                                fieldValues={GLOBALSEARCHSTATUS}
                                type = {ENWORDS.custom}
                                selectedValue={selectedStatus}
                                onChange={e =>
                                  setSelectedStatus(String(e.target.value))
                                }
                              />
                          </div>
                  
                          <div className={baseStyles.btnContainer}>
                              <div className={baseStyles.addContainer}>
                                  <DownloadCSVBtn 
                                    popUpText={ENWORDS.downloadAreaCSVText}
                                    popUpTitle = {ENWORDS.downloadAreaCSVTitle}
                                    screenName = {ENWORDS.stationCSV}
                                    toolTitle = {ENWORDS.downloadAreaTooltip}
                                    tableData={filteredData.map(item=> {return { ...item , areaStatus : GLOBALSTATUS[ item.areaStatus ] } })}
                                    headers={AREA_EXPORT_HEADERS}
                                    fileNamePrefix={AREA_EXPORT_FILE_PREFIX}
                                  />
                              </div>
                              {roleAccess && roleAccess.writeAccess ? 
                                  <div className={baseStyles.addContainer} onClick={addArea }>
                                      <button>
                                          <img src={plusIcon} alt={ENWORDS.addBtn} />
                                      </button>
                                  </div>
                                :null
                              }
                          </div>
                      </div>
                  </div> 
              </div>
              <CustomTable 
                data = {filteredData} 
                headerData = {STATIONAREASHEADER} 
                tableName = {ENWORDS.areaTable}
                onRowClick = {onRowClick}
                actions={
                  [ { name : ENWORDS.deleteIcon, actionToolTipTxt : ENWORDS.deleteDataTxt, src : deleteIcon,columnName :  ENWORDS.actions, onClick : onDeleteClick },
                    { name : ENWORDS.editPenIcon, actionToolTipTxt : ENWORDS.editDataTxt, src : editPenIcon,columnName :  ENWORDS.actions, onClick : editArea } 
                  ]}
                statusStyle={AREASTATUSSTYLE}
                isFromPage='area'
              />
          </div> 
      </div>
  )
}

export default AreasListingScreen