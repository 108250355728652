import axios from 'axios';
import  Cookies  from 'universal-cookie';
import { BASE_URL } from '../../shared/constants/appConstants';

const jwtAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);
export const setAuthToken = (token) => {
  const cookies = new Cookies();
  if (token) {
    jwtAxios.defaults.headers.common[ 'x-auth-token' ] = token;
    cookies.set('token', token);
  } else {
    delete jwtAxios.defaults.headers.common[ 'x-auth-token' ];
    cookies.remove('token');
  }
};

export default jwtAxios;