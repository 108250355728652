import React, { useEffect, useState }  from 'react';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import { useNavigate } from 'react-router-dom';
import ENWORDS from '../../../../shared/local/en.json';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import CustomInput from '../../../../shared/Components/CustomInput';
import ERRORWORDS from '../../../../shared/local/error.json';
import { useDispatch } from 'react-redux';
import { setLoadingFlag } from '../../../../redux/actions';
import ListingHeader from '../../../../shared/Components/ListingHeader'
import { ALPHA_NUM_REGEX, IS_ALPHA } from '../../../../shared/constants/appConstants';
import { addSupplierDetails } from '../../../../api/services/supplierManagementServices';
import { ROUTES } from '../../../../shared/constants/appRoute';
import inputStyles from '../../../../styles/inputs.module.css';
import { clamp, commonScrollFunction } from '../../../../shared/commonFunctions';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json')

const  AddNewSupplier = () => {
  const navigate = useNavigate();
  const dispatch  = useDispatch();
  const[ supplierSiteCode, setSupplierSiteCode ] = useState('');
  const [ supplierName, setSupplierName ] = useState('');
  const [ commission, setComission ] = useState('');
  const [ termsDate, setTermDate ] = useState(''); 
  const [ error, setError ] = useState({});
  const [ isDone, setIsDone ] = useState(false);
  const validated = async () =>{
    dispatch(setLoadingFlag(true))
    const res = await addSupplierDetails(
      { 
        supplierName: supplierName?.trim(),
        supplierSiteCode: supplierSiteCode?.trim(),
        commission: commission,
        termsDate : parseInt(termsDate),
      })
    const successRes = res.res
    if (successRes){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.SUPPLIERMANAGEMENT)
    }

  }

  const checkValidation = () => {
    let errorVariable = error
    if (!IS_ALPHA.test(supplierName?.trim())){
      errorVariable= { ...errorVariable, supplierNameError: ERRORWORDS.supplierNameError }
    }
    if(!ALPHA_NUM_REGEX.test(supplierSiteCode?.trim())){
      errorVariable = { ...errorVariable, supplierSiteCodeError: ERRORWORDS.supplierSiteCodeError }
    }
    if(parseFloat(commission) < 0 || parseFloat(commission) > 100 || commission === ''){
      errorVariable = { ...errorVariable, supplierCommission: ERRORWORDS.supplierCommission }
    }
    if (parseInt(termsDate) <= 0 || parseInt(termsDate) > 365 || termsDate === ''){
      errorVariable = { ...errorVariable, termDateError: ERRORWORDS.termDateError }
    }
    const isErrorEmpty = Object.keys(errorVariable).filter((item)=>{
      return errorVariable[ item ] !== ''
    })
    
    if (isErrorEmpty.length === 0 ){
      validated()
    }
    setError(errorVariable)
    setIsDone(!isDone);
  }

  const onDoneClicked = () => {
    checkValidation()
  }
  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])
  useEffect(()=>{
    let errorVariable = error
    if (supplierName?.trim() !== '' && IS_ALPHA.test(supplierName?.trim())){
      errorVariable= { ...errorVariable, supplierNameError: '' }
    }
    if(supplierSiteCode?.trim() !== '' && ALPHA_NUM_REGEX.test(supplierSiteCode?.trim())){
      errorVariable = { ...errorVariable, supplierSiteCodeError: '' }
    }
    if(parseFloat(commission) >= 0 && parseFloat(commission) <= 100){
      errorVariable = { ...errorVariable, supplierCommission: '' }
    }
    if(parseInt(termsDate) >= 1 && parseInt(termsDate) <= 365 && termsDate !== ''){
      errorVariable = { ...errorVariable, termDateError: '' }
    }
    setError(errorVariable)
      
  },[ supplierSiteCode, supplierName, commission, termsDate ])
  
  return (
      <div className={supplierStyles.mainContainer}>
          <div className={supplierStyles.subContainer}>
              <ListingHeader
                title = {ENWORDS.addNewSupplier}
                navigationPath = {-1}
              />            
              <div className={supplierStyles.contentContainer}>
                  <div className={supplierStyles.content}>
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.supplierDetails}</p>
                          </div>
                      </div>
                      <div className={styles.supplierForm}>
                          <div className ={inputStyles.detailsRow}>
                              <CustomInput 
                                title = {ENWORDS.supplierName} 
                                value = {supplierName}
                                isFocused = {true}
                                placeholder = {PLACEHOLDER.fullName}
                                error = {error.supplierNameError}
                                onChange = {e => setSupplierName((e).trimStart())}
                              />
                              <CustomInput 
                                title = {ENWORDS.supplierSiteCode} 
                                value = {supplierSiteCode}
                                placeholder = {PLACEHOLDER.siteCode}
                                error= {error.supplierSiteCodeError} 
                                onChange = {e=> setSupplierSiteCode((e).trimStart())}/>
                           
                              <CustomInput 
                                title={ ENWORDS.termsDate }
                                value = {termsDate}
                                placeholder = '15'
                                maxLength = {3}
                                error = {error.termDateError}
                                onChange = { (e) => setTermDate(Number(e) > 0 ? clamp(e, 1, 365 ) : '' )}
                              />
                              <CustomInput 
                                title = {ENWORDS.commissionPercentage} 
                                value = {commission}
                                placeholder = {PLACEHOLDER.percent}
                                error= {error.supplierCommission} 
                                maxLength = {4}
                                onChange = {e=> setComission(Number(e) >= 0 ? clamp(e,0,100)  : '')}/>

                          </div>

                      </div> 
                      <div className={styles.btnContainer}>
                          <div className={styles.cancelBtn}>
                              <button onClick={() => navigate(-1) }>{ENWORDS.cancel}</button>
                          </div>
                          <div className={styles.doneBtn}>
                              <button onClick={onDoneClicked}>{ENWORDS.done}</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default AddNewSupplier