import { SET_EMAIL_LOGS_REPORT_DATA } from '../../shared/constants/actionType';
const INIT_STATE = {
  emailLogReportTable: [],
  emailLogsFilter: {
    logsDate:'',
    selectedSites:'',
    selectedType:'',
  },
};

const emailLogReportReducer = (state = INIT_STATE, action)=>{
  switch (action.type) {
  case SET_EMAIL_LOGS_REPORT_DATA:
    return {
      ...state,
      emailLogReportTable: action.payload,
    };
  default:
    return state;
  }
}
export default emailLogReportReducer;