import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import ReactPaginate from 'react-paginate';
import globalTableStyles from '../../../styles/table.module.css'
import tableStyles from '../../../styles/userManagement/UserManagementTable.module.css'
import editPen from '../../../assets/icons/editPenIcon.svg';
import arrowUp from '../../../assets/icons/arrowLightUp.svg'
import arrowDown from '../../../assets/icons/arrowDownLight.svg'
import { ROUTES } from '../../../shared/constants/appRoute'
import { setLoadingFlag } from '../../../redux/actions';
import { useDispatch, useSelector  } from 'react-redux';
import { globalSortBy } from '../../../shared/commonFunctions';
import { deleteUser, getUserById } from '../../../api/services/userManagementService';
import { getCompany, getCompanyModuleMapping, getUserRoles } from '../../../api/services/commonService';
import deleteIcon from '../../../assets/icons/binIcon.svg';
import PopUp from '../../../shared/Components/PopUp';
import Paginator from '../../../shared/Components/Paginator';

const ENWORDS = require('../../../shared/local/en.json');

const UserTable = ({ data, headerData,  roleWriteAccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [ filteredData, setfilteredData ] = useState(data);
  const [ sortState, setSortState ] = useState('default');
  const [ selectedSort, setSelectedSort ] = useState('');
  const [ visibility, setVisibility ] = useState(false);
  const [ toDeleteName, setToDeleteName ] = useState('');
  const [ toDeleteId, setToDeleteId ] = useState(0);
  const currentUserData = useSelector(state => state.auth.user)
  const [ azureID, setAzureID ] = useState();
  const [ slicedData, setSlicedData ] = useState(data);

  const onDeleteClick = ( event,id , name, adID) => {
    setVisibility(true)
    setToDeleteId(id);
    setToDeleteName(name);
    setAzureID(adID)
  }
  const onDeleteUser = () => {
    dispatch(setLoadingFlag(true))
    dispatch(deleteUser(toDeleteId, azureID));
  }
  
  // Api call to fetch user details
  const getUserDetailsByID = async (userId) => {
    const res = await getUserById(userId)
    const userResData = res.res;

    if (userResData){
      return userResData
    }
  }
    
  // Api call to get user roles
  const getRoles  =  async ()  => {
    const res  = await getUserRoles()
    const rolesRes = res.res
    if (rolesRes){
      return rolesRes
    }
  }
  const onEditClick = async (event, userId) => {
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    const res = await getUserDetailsByID(userId);
    const role = await getRoles();
    const company = await getCompany();
    if (res && role && company){
      dispatch(setLoadingFlag(true))
      navigate(ROUTES.EDITUSERSCREEN, { state: { data: res, rolesData: role,companyData:company.res } })
    }
  }

  const tblRowClicked = async (event, userId) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    const res = await getUserDetailsByID(userId);
    const role = await getRoles();
    dispatch(getCompanyModuleMapping());
    if (res && role){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.USERDETAILSSCREEN, { state: { data: res, rolesData:role, roleAccess: roleWriteAccess } })
    }
    
  }

  const userManagementData = () => slicedData
    .map((item) => {
      return (
          <tr 
            key={item[ Object.keys(item)[ 0 ] ] + item[ Object.keys(item)[ 1 ] ] } 
          >
              <td key='name' onClick={(event)=>tblRowClicked(event,item.id)}>
                  <div className={tableStyles.contentRow}>
                      <div className={`${ tableStyles.avatar } ${ tableStyles.avatarLetters }`}>
                          { item.profilePicture === null || item.profilePicture === '' ? 
                            item.firstName.charAt(0) + item.lastName.charAt(0) : <img src={ENWORDS.base64Prefix+item.profilePicture} alt = 'Profile Logo' />}
                      </div>
                      <p className={tableStyles.contentRowNamed}>{item.firstName + ' ' + item.lastName}</p>
                  </div>
              </td>
              <td className={tableStyles.tdLowerCase} key='email' onClick={(event)=>tblRowClicked(event,item.id)}>
                  <p>{item.email}</p>
              </td>
              <td className={tableStyles.tdCapitalized} key='roleName' onClick={(event)=>tblRowClicked(event,item.id)}>
                  <p>{item.roleName}</p>
              </td>
              {
                roleWriteAccess && roleWriteAccess?.writeAccess ? 
                    <td key='action' >
                        <div className={tableStyles.actionRow}>                          
                            {
                              item.id != currentUserData.id ?
                                  <button className={ tableStyles.btnEditUser } onClick={(event) => onDeleteClick(event, item.id, item.firstName, item.azureID)}>
                                      <img src={deleteIcon} alt={ENWORDS.editPenIcon} className={tableStyles.editPenIcon} />
                                  </button> : null
                            }
                            <button className={ tableStyles.btnEditUser } onClick={(event) => onEditClick(event, item.id)}>
                                <img src={editPen} alt={ENWORDS.editPenIcon} className={tableStyles.editPenIcon} />
                            </button>
                        </div>
                    </td>
                  :<div></div>
              }

          </tr>
      );
    });

  const onGlobalSortBy = (keyValue) =>{  
    
    const sortedData = globalSortBy(data, keyValue === 'name' ? 'firstName' :keyValue, sortState);
    setfilteredData([ ...sortedData[ 0 ] ]);
    setSelectedSort(keyValue);
    setSortState(sortedData[ 1 ]);
  }

  const defaultSortBY = () => {
    let newFilteredData = data
    setSelectedSort('name')
    setSortState(ENWORDS.ascending)
    newFilteredData = newFilteredData.sort((a, b) => a[ 'firstName' ].localeCompare(b[ 'firstName' ]))
    setfilteredData(newFilteredData)
  }

  useEffect(() => {
    defaultSortBY()
  }, [ data ])
  const popupCloseHandler = (e) =>{
    setVisibility(e)
    navigate(ROUTES.USERMANAGEMENTSCREEN, { state: {
      data: data, headerData: headerData, roleWriteAccess: roleWriteAccess,
    } })
  }
  return (
      <div className={globalTableStyles.dataTable}>
          <PopUp
            onClose={popupCloseHandler}
            show={visibility}
            title={ENWORDS.deleteUserTitle}
            text={ ENWORDS.removeSiteText}
            selectedName={toDeleteName}
            selectedId={toDeleteId}
            screenName={ ENWORDS.userManagement}
            downloadCSVData = {onDeleteUser}
          />
          <div className={globalTableStyles.tableContainer}>

              <table className={globalTableStyles.tableTag}>
                  <thead className={globalTableStyles.tableHead}>
                      <tr>
                          {
                            Object.keys(headerData).map(key =>
                                <th className={key === 'action' ? tableStyles.namedHeader : tableStyles.moduleHeader} key={key}>
                                    {
                                      key === 'action' ?
                                        headerData[ key ]
                                        : (
                                            <div className={key === 'name' ? globalTableStyles.namedSortContainer : globalTableStyles.sortContainer}>
                                                <button className={globalTableStyles.btnSort} onClick={() => onGlobalSortBy(key)}>
                                                    <p>{headerData[ key ]}</p>
                                                    <img src={sortState === 'asc' && selectedSort === key ? arrowUp : arrowDown} alt={ENWORDS.sortBy} />
                                                </button>
                                            </div>
                                        )
                                    }

                                </th>
                            )}
                      </tr>
                  </thead>
                  <tbody>
                      {userManagementData()}
                  </tbody>
              </table>
              <div>
              </div>
          </div>
          <Paginator
            filteredData={filteredData}
            dataLength={data.length}
            setSlicedData={setSlicedData}
            isFromPage='user'
          />

      </div>

  )
}

export default UserTable