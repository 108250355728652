import React, { useEffect, useState } from 'react'
import { useNavigate , useLocation } from 'react-router-dom';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import binIcon from '../../../../assets/icons/binIcon.svg';
import editPenIconLight from '../../../../assets/icons/editPenWhiteIcon.svg';
import PopUp from '../../../../shared/Components/PopUp';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import roleStyles from '../../../../styles/baseconfiguration/RoleManagement.module.css';
import { ROUTES } from '../../../../shared/constants/appRoute';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import ModuleAssignmentTable from '../../../../shared/Components/ModuleAssignmentTable';
import { SCREENASSIGNMENT } from '../../../UserManagement/userManagementConstants';
import { useDispatch } from 'react-redux';
import { setLoadingFlag } from '../../../../redux/actions';
import { getRoleById } from '../../../../api/services/roleManagementService';
 
const AboutRole = () =>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = location.state;
  const [ visibility, setVisibility ] = useState(false);
  const [ toDeleteName, setToDeleteName ] =  useState('');
  const [ toDeleteUserName, setToDeleteUserName ] = useState('');
  const [ roleDetails, setRoleDetails ] = useState('');
  
  const [ access, setAccess ] = useState(roleDetails.roleAccess);
  
  const editRole  = async()=>{
    dispatch(setLoadingFlag(true));
    const res = await getRoleById(roleDetails.roleId);
    
    if(res)
    {
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITROLE, { state:{ rolesData: res } })
    }

  }
  const showPopUp = (popUpName, popUpUserName) =>{
    setVisibility(true);
    setToDeleteName(popUpName);
    setToDeleteUserName(popUpUserName)
  }
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };
  useEffect(() =>{
    if(location?.state.data){
      
      setRoleDetails(location?.state.data )
      setAccess(location?.state.data.roleAccess)
    }
  } ,[] )
  return(
      <div className={styles.mainContainer}>
          <PopUp
            onClose={popupCloseHandler}
            show={visibility}
            title={ENWORDS.deleteRole}
            selectedId = {roleDetails.roleId}
            text={ENWORDS.removeSiteText}
            selectedName = { toDeleteName }
            selectedUserName = { toDeleteUserName }
            screenName = {ENWORDS.deleteRole}
          />
          <div className={supplierStyles.subContainer}>
              <div className= {supplierStyles.displayHeaderContainer}>
                  <div className={supplierStyles.displayNavigateContainer} >
                      <ListingHeader
                        title={ ENWORDS.roleDetails }
                        navigationPath={ROUTES.ROLEMANAGEMENT}
                      />
                      {
                        roleDetails.roleName?.toLowerCase() !== 'default user' ? 
                        
                          locationState.roleAccess && locationState.roleAccess.writeAccess  && !locationState.isCurrentUser
                            ?
                                <div className={styles.cmBtnContainer}>
                                    <div className={styles.binBtn}>
                                        <button onClick = {()=> showPopUp(roleDetails.roleName,roleDetails.roleId)}><img  src={binIcon} alt={ENWORDS.deleteIcon}/></button>
                                    </div>
                                    <div className={styles.addBtn}>
                                        <button onClick={editRole}><img  src={editPenIconLight} alt={ENWORDS.editPen}/></button>
                                    </div>
                                </div> 
                            :
                            null : null
                      }
                          
                  </div>
              </div>
              <div className={supplierStyles.contentContainer}>
                  <div className={supplierStyles.content}>
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.roleDetails}</p>                           
                          </div>
                      </div>
                      <div className={roleStyles.siteForm}>
                          <div className={styles.formFieldContainer}>
                              <p>{ENWORDS.roleName}</p>
                              <div className={styles.displayBox}>{roleDetails.roleName}</div>   
                          </div>  
                          <div className={styles.displayCheckboxContainer}>
                              <input className={styles.checkboxInput} 
                                type="checkbox" 
                                id="isExtraPrivileged" 
                                name="isExtraPrivileged" 
                                value={roleDetails.isExtraPrivileged}
                                checked={roleDetails.isExtraPrivileged}
                                disabled/>
                              <p className={styles.exportButtons}>{ENWORDS.isExtraPrivileged}</p>
                          </div>                   
                      </div>
                      <div className={inputStyles.mainModuleContainer}>
                          <p>{ENWORDS.screenAssignment}</p>

                          <ModuleAssignmentTable 
                            headerData={SCREENASSIGNMENT}  
                            canEdit = {false}  
                            isEmpty = {false}
                            onSetAccessDetails = {access}
                            screenType = {ENWORDS.assignScreen}
                          />

                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}
export default AboutRole