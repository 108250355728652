import React from 'react';
import styles from '../../styles/errorPopUp.module.css';
import ENWORD from '../local/en.json';
import crossIcon from '../../assets/icons/plusIcon.svg';
import PropTypes from 'prop-types';

const PenaltyInvoicePopUp = (props) => {
  const { onClose, show } = props;
  const closeHandler = (e) => {
    onClose(false);
  };
  
  return (
      <div className={show ? styles.penaltyInvoiceMainContainer : styles.mainContainerHidden}>
          <div
            style={{
              visibility: show ? 'visible' : 'hidden',
              opacity: show ? '1' : '0',
            }}
            className={styles.errorPopUp}>
              <div className={styles.penaltyInvoiceErrorContainer}>
                  <div className={styles.errorMsgContainer}>
                      <div className={styles.helpText}>
                          {props.title}
                      </div>
                  </div>
                  <div className={styles.close} onClick={closeHandler}>
                      <img className={styles.crossIcon} src={crossIcon} alt={ENWORD.crossIcon} />
                  </div>
              </div>
          </div>
      </div>
  );
}
PenaltyInvoicePopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default PenaltyInvoicePopUp
