import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import ENWORDS from '../../../shared/local/en.json';
import { PENALTIESSTATUS, REPORTSHEADERS, REPORTS_FILE_PREFIX } from '../adtMonitoringConstant';
import uploadBtnStyles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import { GLOBAL_DATE_FORMAT_DB, TABS, globalDateFormat } from '../../../shared/constants/appConstants';
import CustomDatePicker from '../../../shared/Components/CustomDatePicker';
import style from '../../../styles/adtConfiguration/ConfigurationScreen.module.css';
import SideNavigation from '../../../shared/Components/SideNavigation';
import CustomTable from '../../../shared/Components/CustomTable';
import { getLogReportsData, sendReportsLogOnMail } from '../../../api/services/adtReportService';
import { setReportsTable } from '../../../redux/actions/adtReports';
import { setErrorFlag, setLoadingFlag } from '../../../redux/actions';
import { getStationDetailsToDisplay } from '../../../api/services/stationManagementService';
import { format } from 'date-fns';
import DownloadCSVBtn from '../../../shared/Components/DownloadCSVBtn';
import mailIcon from '../../../assets/icons/mailFilledIconWhite.svg';
import CustomLogMailPopup from '../../../shared/Components/CustomLogMailPopup';
import { getDailyLogEmail } from '../../../api/services/adtDailyLogService';
import SuccessPopUp from '../../../shared/Components/SuccessPopUp';
const SCREENS  = require('../../../shared/local/screens.json');

const SiteReportsScreen = () => {
  const dispatch = useDispatch();
  const reportsData = useSelector(state => state.reportsReducer.reportsTable);
  const roleLevelAccess = useSelector(
    state => state.roleManagement.roleLevelAccess
  );
  const [ filterData, setFilterData ] = useState(reportsData);
  const [ roleAccess, setRoleAccess ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState('All');
  const [ selectedSites, setSelectedSites ] = useState('All');
  const [ fromDate, setFromDate ] = useState(new Date().setMonth(new Date().getMonth() - 1));
  const [ toDate, setToDate ] = useState(new Date());
  const [ sites, setSites ] = useState();
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showEmailPopup, setShowEmailPopup ] = useState(false);

  useEffect(() => {
    setFilterData(reportsData);
  }, [ reportsData ]);

  const checkRoleAccess = () => {
    roleLevelAccess.forEach(item => {
      if (item.screenName === SCREENS.adtLogReport) {
        if (!item.writeAccess) {
          delete REPORTSHEADERS[ 'configActions' ];
        }
        setRoleAccess(item);
      }
    });
  };

  const onFromDateChange = date => {
    setFromDate(date);
  };

  const fetchLogReportsData = async () =>{
    dispatch(setLoadingFlag(true));
    const logReportsData  =  await getLogReportsData(
      format(fromDate,GLOBAL_DATE_FORMAT_DB),
      format(toDate,GLOBAL_DATE_FORMAT_DB),
      selectedSites,
      selectedStatus
    );

    dispatch(setLoadingFlag(false))
    dispatch(setReportsTable(logReportsData.res.data));
  }

  const populateInitialData = async () =>{
    dispatch(setLoadingFlag(true));
    const { res }  = await getStationDetailsToDisplay();
    if (res){
      dispatch(setLoadingFlag(false))
      setSites([ { 'siteId' : 'All' },...res ])
    }
  }

  const sendEmail = () => {
    setShowEmailPopup(true);
  }
  
  const onSendMailClick = async data => {
    const res = await sendReportsLogOnMail(
      format(fromDate, GLOBAL_DATE_FORMAT_DB),
      format(toDate, GLOBAL_DATE_FORMAT_DB),
      selectedSites,
      selectedStatus,
      data
    );
    dispatch(setLoadingFlag(false));

    if (res.res) {
      setShowSuccess(true);
    } else {
      dispatch(setErrorFlag(true));
    }
  };

  const filteration = () => {
    let reportsFilterData = reportsData;
    if (selectedStatus?.toLowerCase() !== 'all') {
      reportsFilterData = reportsFilterData.filter(item => {
        return item.status === selectedStatus;
      });
    }

    if (selectedSites?.toLowerCase() !== 'all') {
      reportsFilterData = reportsFilterData.filter(item => {
        return item.siteID === selectedSites;
      });
    }

    setFilterData(reportsFilterData)
  };

  useEffect(() => {
    filteration()
  }, [ selectedStatus, selectedSites ]);

  useEffect(() => {
    checkRoleAccess();
  }, [ roleLevelAccess ]);

  useEffect(() => {
    populateInitialData();
  }, []);

  useEffect(() => {
    if (String(selectedStatus) && selectedSites && fromDate && toDate){  
      fetchLogReportsData()
    }
  }, [ selectedStatus, selectedSites, fromDate, toDate ])

  return (
      <div className={styles.mainContainer}>

          <div className={styles.leftContainer}>
              <SideNavigation parentName={TABS.adtAlarmMonitoring} />
          </div>

          <div className={styles.rightContainer}>
              <div className={styles.siteHeader}>
                  <div className={style.stationHeaderContainer}> 
                      <div className={style.leftFilterContainer}>
                          <CustomDropDown
                            title={ENWORDS.site}
                            name={ENWORDS.site.toLowerCase()}
                            defaultValues={{ selectedSites }}
                            type={ENWORDS.custom}
                            onChange={e => setSelectedSites(e.target.value)}
                            renderKey='siteId'
                            renderKeyOne='siteId'
                            fieldValues = {sites ?? [] }
                          />

                          <CustomDropDown
                            title={ENWORDS.status}
                            name={ENWORDS.status.toLowerCase()}
                            fieldValues={{ 'All': 'All',...PENALTIESSTATUS }}
                            type={ENWORDS.custom}
                            onChange={e => setSelectedStatus(e.target.value)}
                          />
                          <div className={style.datePickerContainer}>
                              <CustomDatePicker
                                title={ENWORDS.periodFrom}
                                startDate={fromDate}
                                endDate={toDate}
                                dateFormat={globalDateFormat}
                                onChange={date => onFromDateChange(date)}
                                wrapperClass = 'reportLogsDatePicker'
                              />

                              <CustomDatePicker
                                title={ENWORDS.periodTo}
                                startDate={toDate}
                                minDate={fromDate}
                                endDate={new Date(Date.now())}
                                dateFormat={globalDateFormat}
                                onChange={date => setToDate(date)}
                                wrapperClass = 'reportLogsDatePicker'
                              />
                          </div>
                      </div>
                      <div className={styles.btnContainer}>
                          <div
                            className={uploadBtnStyles.mainBtnContainer}
                            title={ENWORDS.exportReportsTooltip}
                          >
                              <DownloadCSVBtn 
                                tableData={filterData}
                                popUpText={ENWORDS.downloadReportsCSVText}
                                popUpTitle = {ENWORDS.downloadDashboardCSVTitle}
                                screenName = {ENWORDS.reportsCSV}
                                toolTitle = {ENWORDS.downloadReportsTooltip}
                                headers={REPORTSHEADERS}
                                fileNamePrefix={REPORTS_FILE_PREFIX}
                              />
                          </div>
                          {  roleAccess && roleAccess.writeAccess ?
                              <div className={uploadBtnStyles.mainBtnContainer}>
                                  <button
                                    className={uploadBtnStyles.featureButton}  
                                    title={ENWORDS.sendEmailTitle}
                                    onClick = {() => sendEmail()}
                                  >
                                      <div className={uploadBtnStyles.btnContainer}>
                                          <img src={mailIcon} alt = {ENWORDS.mail} />
                                      </div>
                                  </button>
                              </div>
                            : null}
                      </div>
                      <SuccessPopUp
                        title = {ENWORDS.commonMailSuccess}
                        show = {showSuccess}
                        onClose={()=>{ 
                          setShowSuccess(false)                     
                          dispatch(setLoadingFlag(true));
                          dispatch(getDailyLogEmail());
                        }}
                      />
                      < CustomLogMailPopup
                        title = {ENWORDS.ReportsMailPopUpTxt}
                        show={showEmailPopup}
                        onClose={setShowEmailPopup}              
                        setShowSuccess = {showSuccess}           
                        onSendClick = {onSendMailClick}
                      />
                  </div>
              </div>
              <CustomTable 
                data = {filterData} 
                headerData = {REPORTSHEADERS} 
                tableName = {ENWORDS.reportsTable}               
              />
          </div>
      </div>
  );
};

export default SiteReportsScreen;
