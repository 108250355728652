import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import SupplierHeader from './SupplierManagementHome/SupplierHeader.js';
import BaseConfigurationTable from '../BaseConfigurationTable';
import { SUPPLIERHEADERS } from '../baseConfigurationConstant';
import { setLoadingFlag } from '../../../redux/actions';
import { getSupplierDetails } from '../../../api/services/supplierManagementServices';
import SideNavigation from '../../../shared/Components/SideNavigation';
import { TABS } from '../../../shared/constants/appConstants';
const ENWORDS = require('../../../shared/local/en.json');

const SupplierManagementScreen = () =>{
  const dispatch =  useDispatch()
  const supplierData = useSelector(state => state.supplierManagement.supplierTable)
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ filterData , setFilterData ] = useState(supplierData);
  const [ roleAccess, setRoleAccess ] = useState();
  const fetchSupplier = async () =>{
    dispatch(setLoadingFlag(true))
    dispatch(getSupplierDetails())
  }
  useEffect(() => {
    setFilterData(supplierData)
  }, [ supplierData ])

  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === 'Supplier Management'){
        if (!item.writeAccess){
          delete SUPPLIERHEADERS[ 'supplierAction' ];
        }
        setRoleAccess(item)
      }
    })

  }

  useEffect(()=>{
    fetchSupplier()
    
  }, [])

  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  return(
      <div className={styles.mainContainer}>          
          <div className = {styles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   />
              {/* <SideNavigation parentName={TABS.baseConfiguration}   /> */}
          </div>
          <div className={styles.rightContainer}>
              <div className = {styles.siteHeader}>
                  <SupplierHeader 
                    setFilterData = {setFilterData} 
                    roleAccess = {roleAccess}/>
              </div>
              <BaseConfigurationTable 
                tableData = {filterData} 
                headerData = {SUPPLIERHEADERS} 
                roleAccess = {roleAccess}
                tableName = {ENWORDS.supplierTable}
                popUpTitle = {ENWORDS.deleteSupplierTitle}
                shouldVerify = {true}
                isFromPage='supplier'
              />

          </div>
      </div>
  )
}

export default SupplierManagementScreen