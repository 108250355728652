const { ALPHA_NUM_REGEX } = require('../../../shared/constants/appConstants');
const ERRORWORDS = require('../../../shared/local/error.json');

export const dupliFieldCheck = ( fieldList, fieldError) =>{
  var fieldListCopy = [ ...fieldList ];
  var fieldErrorCopy = [ ...fieldError ];
  for(let i = 0 ; i < fieldListCopy.length; i++){
    const currentIndex = fieldErrorCopy.findIndex((e)=> e.errorId === fieldListCopy[ i ].id )
    if(currentIndex === -1){
      const temp  = { errorId:fieldListCopy[ i ].id,fieldNameError:  '' ,  fieldNameDBError: '' }
      fieldErrorCopy = [ ...fieldErrorCopy , temp ]
    }
  }
  for(let i = 0 ; i < fieldListCopy.length; i++){
    var flag = false;
    for(let j = 0 ; j< fieldListCopy.length; j++){
      if(i === j || fieldListCopy[ i ].fieldName ==='' || fieldListCopy[ j ].fieldName === '')
        continue;
      if(fieldListCopy[ i ].fieldName === fieldListCopy[ j ].fieldName){
        flag = true;
        fieldErrorCopy[ i ].fieldNameError = ERRORWORDS.duplicateFieldName;
      }
    }
    
    if(flag === false){
      fieldErrorCopy[ i ].fieldNameError = ''
    }
  }
  return fieldErrorCopy;
}

// export const duplicateFieldNameValidation  = (fieldList, fieldError, inputValue, currentFieldId, objIndex, objKey) =>{
    
//   const fieldErrorCopy = fieldError
//   const existingFieldName = '';
//   const breaking = false;

//   fieldErrorCopy.some((errCopy)=>{
//     if (currentFieldId === errCopy.errorId){
//       existingFieldName = errCopy.fieldNameError
//     }
//     return currentFieldId === errCopy.errorId
//   })
//   fieldList.forEach((lstItem)=>{
//     const currentIndex = fieldErrorCopy.findIndex((e)=> e.errorId === currentFieldId )
//     if (currentFieldId !== lstItem.id &&  !breaking){
//       const valToCheck = (lstItem.fieldName).toLowerCase()
//       if (valToCheck?.trim() === (inputValue).toLowerCase()?.trim()){  
//         if (currentIndex > -1){ 
//           if (!ALPHA_NUM_REGEX.test(inputValue)){
//             fieldErrorCopy[ currentIndex ].fieldNameError = ERRORWORDS.fieldNameError
//           }
//           else{    
//             fieldErrorCopy[ currentIndex ].fieldNameError = ERRORWORDS.duplicateFieldName
//             breaking = true
//           }
//           if(objKey !== 'fieldName'){
//             fieldErrorCopy[ currentIndex ].fieldNameError = existingFieldName
//           }
//           objKey === 'fieldName' 
//             ? 
//             fieldErrorCopy[ currentIndex ].fieldNameDBError = existingFieldNameDB
//             :
//             fieldErrorCopy[ currentIndex ].fieldNameError = existingFieldName
//         } 
//         else{
//           const temp  = { errorId:currentFieldId,fieldNameError:  ERRORWORDS.duplicateFieldName ,  fieldNameDBError: '' }
//           fieldErrorCopy = [ ...fieldErrorCopy , temp ]
//           breaking = true
//         } 
//       }
//       else{
//         if (currentIndex > -1  && currentFieldId !== lstItem.id){
//           if (!ALPHA_NUM_REGEX.test(inputValue)){
//             fieldErrorCopy[ currentIndex ].fieldNameError = ERRORWORDS.fieldNameError
//           }
//           else{
//             fieldErrorCopy[ currentIndex ].fieldNameError = ''
//           }
//         }
//       }
//     }

//   })

//   return {
//     errorData: fieldErrorCopy,
//     fieldValue: (inputValue.charAt(0).toUpperCase() + inputValue.slice(1)).trim(),
//   }
  
// }