import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import paginatorStyles from '../../styles/paginator.module.css'
import { useEffect } from 'react';
import { memo } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { updateInvoicePageNumber } from '../../redux/actions/invoiceListing'
import { updateUserPageNumber } from '../../redux/actions/userManagement'
import { updatModulePageNumber } from '../../redux/actions/moduleConfiguration'
import { updateDailyLogsPageNumber } from '../../redux/actions/dailyLogs'
import { updateComponyPageNumber } from '../../redux/actions/companyManagement'
import { updateSupplierPageNumber } from '../../redux/actions/supplierManagement'
import { updateRolePageNumber } from '../../redux/actions/roleManagement'
import { updateStationPageNumber } from '../../redux/actions/stationListing'
import { updateAreaPageNumber } from '../../redux/actions/areaListing'
import { updateRegionPageNumber } from '../../redux/actions/regionsListing'
import { updateOpsRegionPageNumber } from '../../redux/actions/opsRegionsListing'
const ENWORDS  = require('../local/en.json');

const Paginator = ({ filteredData, dataLength, setSlicedData, pages =[ 50, 100, 150, 200, 300, 400 , 500 ], isFromPage }) => {
  const dispatch = useDispatch()
  const [ pageNumber, setPageNumber ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(pages[ 0 ])
  const [ pageCount, setPageCount ] = useState(Math.ceil(filteredData.length / rowsPerPage))
  const invoiceFilterPageNumber = useSelector(state=> state.invoiceListing.pageNumber)
  const userFilterPageNumber = useSelector(state=> state.userManagement.pageNumber)
  const moduleFilterPageNumber = useSelector(state=> state.moduleConfiguration.pageNumber)
  const dailyLogsFilterPageNumber = useSelector(state=> state.dailyLogsReducer.pageNumber)
  const componyFilterPageNumber = useSelector(state=> state.companyManagement.pageNumber)
  const supplierFilterPageNumber = useSelector(state=> state.supplierManagement.pageNumber)
  const roleFilterPageNumber = useSelector(state=> state.roleManagement.pageNumber)
  const stationFilterPageNumber = useSelector(state=> state.stationListing.pageNumber)
  const areaFilterPageNumber = useSelector(state=> state.areaReducer.pageNumber)
  const regionFilterPageNumber = useSelector(state=> state.regionsReducer.pageNumber)
  const opsRegionFilterPageNumber = useSelector(state=> state.opsRegionsReducer.pageNumber)

  useEffect(() => {
    switch(isFromPage) {
    case 'invoice':
      setPageNumber(invoiceFilterPageNumber !== 0 ? invoiceFilterPageNumber : 0)
      break
    case 'user':
      setPageNumber(userFilterPageNumber !== 0 ? userFilterPageNumber : 0)
      break
    case 'module':
      setPageNumber(moduleFilterPageNumber !== 0 ? moduleFilterPageNumber : 0)
      break
    case 'dailyLog':
      setPageNumber(dailyLogsFilterPageNumber !== 0 ? dailyLogsFilterPageNumber : 0)
      break
    case 'compony':
      setPageNumber(componyFilterPageNumber !== 0 ? componyFilterPageNumber : 0)
      break
    case 'supplier':
      setPageNumber(supplierFilterPageNumber !== 0 ? supplierFilterPageNumber : 0)
      break
    case 'roleManagement':
      setPageNumber(roleFilterPageNumber !== 0 ? roleFilterPageNumber : 0)
      break
    case 'station':
      setPageNumber(stationFilterPageNumber !== 0 ? stationFilterPageNumber : 0)
      break
    case 'area':
      setPageNumber(areaFilterPageNumber !== 0 ? areaFilterPageNumber : 0)
      break
    case 'region':
      setPageNumber(regionFilterPageNumber !== 0 ? regionFilterPageNumber : 0)
      break
    case 'opsRegion':
      setPageNumber(opsRegionFilterPageNumber !== 0 ? opsRegionFilterPageNumber : 0)
      break
    default:
      setPageNumber(0)
      break
    }
    setPageCount(Math.ceil(filteredData.length / rowsPerPage))
  }, [ filteredData, rowsPerPage ])

  const onChangePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(()=>{
    switch(isFromPage) {
    case 'invoice':
      dispatch(updateInvoicePageNumber(pageNumber))
      break
    case 'user':
      dispatch(updateUserPageNumber(pageNumber))
      break
    case 'module':
      dispatch(updatModulePageNumber(pageNumber))
      break
    case 'dailyLog':
      dispatch(updateDailyLogsPageNumber(pageNumber))
      break
    case 'compony':
      dispatch(updateComponyPageNumber(pageNumber))
      break
    case 'supplier':
      dispatch(updateSupplierPageNumber(pageNumber))
      break
    case 'roleManagement':
      dispatch(updateRolePageNumber(pageNumber))
      break
    case 'station':
      dispatch(updateStationPageNumber(pageNumber))
      break
    case 'area':
      dispatch(updateAreaPageNumber(pageNumber))
      break
    case 'region':
      dispatch(updateRegionPageNumber(pageNumber))
      break
    case 'opsRegion':
      dispatch(updateOpsRegionPageNumber(pageNumber))
      break
    default:
      break
    }
  }, [ pageNumber ])

  useEffect(()=>{
    setSlicedData(filteredData.slice((pageNumber * rowsPerPage), (pageNumber * rowsPerPage) + rowsPerPage))
  }, [ filteredData, pageNumber, rowsPerPage ])
  
  return (
      <div className={paginatorStyles.paginationContainer}>
          <div className={paginatorStyles.pageCount}>
              <p>  
                  <span className={paginatorStyles.paginationValues}>{dataLength}</span>
                  {ENWORDS.records}
              </p>
              {filteredData?.length >= pages[ 0 ] && 
              <select
                className={paginatorStyles.dropDown}
                onChange={(e) => setRowsPerPage(Number(e.target.value))}
                value={rowsPerPage}>
                  {
                    pages && pages.map((item) => {
                      return <option
                        key={item}
                        value={item}
                        datakey={item}
                      >{ item} </option>
                    })
                  }
              </select>
              }
              <p>  
                  <span className={paginatorStyles.paginationValues}> </span>
                  {ENWORDS.perPage}
              </p>
          </div>
          
          {filteredData.length > rowsPerPage &&
    (

    <ReactPaginate
      breakLabel={ENWORDS.breakLabel}
      previousLabel={ENWORDS.previous}
      nextLabel={ENWORDS.next}
      pageCount={pageCount}
      onPageChange={onChangePage}
      containerClassName={paginatorStyles.paginationBtn}
      disabledClassName={paginatorStyles.paginationDisabled}
      previousLinkClassName={paginatorStyles.prev}
      nextLinkClassName={paginatorStyles.next}
      activeClassName={paginatorStyles.paginationActive}
      pageRangeDisplayed={3}
      forcePage={pageNumber}
    />

    )}
    
      </div>
  )
}
export default memo(Paginator);