import React, { useState, useEffect } from 'react'
import tableStyles from '../../styles/table.module.css'
import styles from '../../styles/userManagement/UserManagementTable.module.css'
import PropTypes from 'prop-types';

const ENWORDS = require('../../shared/local/en.json')

const InvoiceAssignmentTable = ( props ) => {
  const { onSetAccessDetails,  onGetAccessDetails, canEdit }  = props;

  const moduleArr = props.data;
  
  const [ accessResponse, setAccessResponse ] = useState(onSetAccessDetails??[]);
  const sendAccess = (value) =>{
    if (canEdit){
      onGetAccessDetails(value)
    }
  }

  function onChangeReadValue(e) {

    const objIndex = accessResponse.findIndex((obj => {
      return obj.moduleID === Number(e.target.value)
    }));
      
    if ( objIndex >= 0){
      accessResponse[ objIndex ].readAction = e.target.checked ? 1 : 0
      accessResponse[ objIndex ].writeAction = 0
      setAccessResponse( [ ...accessResponse ] )
      sendAccess(accessResponse);
    }
    else{
      const moduleStructure = {
        moduleID: Number(e.target.value),
        readAction: e.target.checked ? 1 : 0,
        writeAction: 0,
      }

      setAccessResponse( [ ...accessResponse, moduleStructure ] )
      sendAccess([ ...accessResponse, moduleStructure ]);
    } 
    
  }
  
  function onChangeWriteValue(e) {
    const objIndex = accessResponse.findIndex((obj => obj.moduleID === Number(e.target.value)));
    if ( objIndex >= 0){
      accessResponse[ objIndex ].writeAction = e.target.checked ? 1 : 0
      accessResponse[ objIndex ].readAction = 1
      setAccessResponse( [ ...accessResponse ] )
      sendAccess(accessResponse);
    }
    else{
      const moduleStructure = {
        moduleID: Number(e.target.value),
        readAction:e.target.checked ? 1 : 0 ,
        writeAction: e.target.checked ? 1 : 0 ,
      }
      setAccessResponse( [ ...accessResponse, moduleStructure ] )
      sendAccess([ ...accessResponse, moduleStructure ]);
    }

  }

  const   maintainDuplicateAccess = () =>{
    
    if (accessResponse){

      const filterdAccessResponse = accessResponse?.filter(e => moduleArr?.some(xy=> xy.moduleId === e.moduleID));
      
      if (filterdAccessResponse){
        setAccessResponse([ ...filterdAccessResponse ]);
      }
    }
  }

  useEffect(()=>{
    if (moduleArr?.length !== 0){
      
      sendAccess(accessResponse);
    }
  }, [ accessResponse ]);
  
  useEffect(()=>{
    maintainDuplicateAccess()
  }, [ moduleArr ])

  return (
      <div className={tableStyles.tableContainer}>
          <table>
              <thead>
                  <tr>
                      {
                        Object.keys(props.headerData).map(key => 
                            <th className={styles.modulesHeader} key={key}>{props.headerData[ key ]}</th>
                        )}
                  </tr>
              </thead>
              <tbody>

                  {
                    accessResponse &&  moduleArr && moduleArr.map((item)=>{
                      return(
                          <tr key ={item.moduleId}>
                              <td key='module' className={styles.modulesColumn}>{item.moduleName}</td>
                              <td key='access'>
                                  { 

                                      <div key = {item.moduleId} className = {styles.moduleContentRow}>
                                          <div className={styles.checkBoxContainer} >
                                              <input type="checkbox" 
                                                value = {item.moduleId}   
                                                onChange={onChangeReadValue} 
                                                checked = {accessResponse.some(e=>
                                                  item.moduleId === e.moduleID && e.readAction)} 
                                                disabled =  { !props.canEdit}  />
                                              <p className={styles.contentRowText}>{ENWORDS.read}</p>
                                          </div>
                                          <div className={styles.checkBoxContainer}>
                                              <input type="checkbox" 
                                                value = {item.moduleId} 
                                                onChange={onChangeWriteValue} 
                                                checked = {accessResponse.some(e=>
                                                  item.moduleId === e.moduleID && e.writeAction)} 
                                                disabled =  { !props.canEdit}  />
                                              <p className={styles.contentRowText}>{ENWORDS.write}</p>
                                          </div>
        
                                      </div>
                                  }
                              </td>

                          </tr>
                      )
                    }
                 
                    )
                  }
              </tbody>
          </table>
          <div>
          </div>
      </div>
  )
}
InvoiceAssignmentTable.propTypes = {
  onGetAccessDetails: PropTypes.func,
  isValidatedAccess: PropTypes.bool,
  onSetAccessDetails: PropTypes.array,
  canEdit: PropTypes.bool,
  screenType: PropTypes.string,
};
export default InvoiceAssignmentTable;
