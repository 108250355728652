import React from 'react';
import styles from '../../../styles/Login/Login.module.css';
import dataProcessorLogo from '../../../assets/icons/dataProcessorLogo.svg';
import globalStyles from '../../../styles/global.module.css';
const ENWORDS = require('../../../shared/local/en.json')

export function LoginScreen(props) {
  return (
      <div>
          <div className={`${ styles.entireScreen }  ${ globalStyles.moduleCenter }`}>
              <div className={styles.LoginDiv}>
                  <div className= {`${ styles.middleDiv }`} >
                      <div className= {`${ styles.row } ${ styles.logoDiv }`}>
                          <div>
                              <img src={dataProcessorLogo} className={styles.logo} alt= {ENWORDS.dataProcessorLogo}></img>
                          </div>
                      </div>
                      <div className={styles.authContainer}>
                          <div>
                              {props.children}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );

}

export default LoginScreen;