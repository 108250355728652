import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, 
  SET_ERROR_FLAG, SHOW_MESSAGE, UPDATING_CONTENT, 
  SET_LOAD_ERROR, SET_IS_LOGGED_OUT, SET_COMPANY_MODULE_MAPPING, SET_PROGRESSBAR_FLAG, SET_LOADING_FLAG } from '../../shared/constants/actionType';

export const fetchStart = () => {
  return (dispatch) => dispatch({ type: FETCH_START });
};
  
export const fetchSuccess = () => {
  return (dispatch) => dispatch({ type: FETCH_SUCCESS });
};
export const updatingContent = () => {
  return (dispatch) => dispatch({ type: UPDATING_CONTENT });
};
  
export const fetchError = (error) => {
  return (dispatch) => dispatch({ type: FETCH_ERROR, payload: error });
};
  
export const showMessage = (message) => {
  return (dispatch) => dispatch({ type: SHOW_MESSAGE, payload: message });
};

export const setLoadingFlag = (loadingStatus) => {
  return {
    type: SET_LOADING_FLAG,
    payload: loadingStatus,
  }
}
export const setProgressbarFlag = (progressBarValue) => {
  return {
    type: SET_PROGRESSBAR_FLAG,
    payload: progressBarValue,
  }
}

export const setErrorFlag = (errorStatus) =>{
  return{
    type:SET_ERROR_FLAG,
    payload:errorStatus,
  }
}

export const setLoadError = (error) =>{
  
  return {
    type:SET_LOAD_ERROR,
    payload:error,
  }
}

export const setIsLoggedOut = (isLogOut) =>{
  
  return {
    type:SET_IS_LOGGED_OUT,
    payload:isLogOut,
  }
}
export const setCompanyModuleMapping = (data) =>{
  
  return {
    type:SET_COMPANY_MODULE_MAPPING,
    payload:data,
  }
}

export const displayError = (fieldError,fieldId, type) =>{
  const currentIndex = fieldError.findIndex(e => e.errorId === fieldId);
  if (currentIndex > -1){
    return fieldError[ currentIndex ][ type ]
  }

}