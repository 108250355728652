import React, { useState, useEffect } from 'react'
import tableStyles from '../../styles/table.module.css'
import styles from '../../styles/userManagement/UserManagementTable.module.css'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setLoadingFlag } from '../../redux/actions';
import { getScreens } from '../../api/services/roleManagementService';
import arrowUp from '../../assets/icons/arrowLightUp.svg';
import arrowDown from '../../assets/icons/arrowDownLight.svg';
import { globalSortBy } from '../commonFunctions';
const ENWORDS = require('../../shared/local/en.json')

const ModuleAssignmentTable = ( props ) => {
  const { screenType, onSetAccessDetails, isEmpty, onGetAccessDetails, canEdit } = props;
  const dispatch = useDispatch();

  const [ moduleArr, setModuleArr ] = useState([]);
  const [ accessResponse, setAccessResponse ] = useState(onSetAccessDetails);
  const [ sortState, setSortState ] = useState(ENWORDS.ascending);
  const [ selectedSort, setSelectedSort ] = useState('');
  const sendAccess = () =>{
    if (canEdit){
      onGetAccessDetails(accessResponse)
    }
  }
  
  function onChangeReadValue(e) {
    let objIndex;
    if(screenType === ENWORDS.assignScreen ){
      objIndex = accessResponse.findIndex((obj => {
        return obj.screenName === e.target.value
      }));
      
      if ( objIndex >= 0){
        accessResponse[ objIndex ].readAccess = e.target.checked ? true : false
        accessResponse[ objIndex ].writeAccess = false
        setAccessResponse( [ ...accessResponse ] )
      }
      else{
        const screenStructure = {
          roleId:0,
          screenName: e.target.value,
          readAccess: e.target.checked ? true : false,
          writeAccess: false,
        }
        
        setAccessResponse( [ ...accessResponse, screenStructure ] )
      } 
    } else{
      objIndex = accessResponse.findIndex((obj => {
        return obj.moduleID === Number(e.target.value)
      }));
      
      if ( objIndex >= 0){
        accessResponse[ objIndex ].readAction = e.target.checked ? 1 : 0
        accessResponse[ objIndex ].writeAction = 0
        setAccessResponse( [ ...accessResponse ] )
      }
      else{
        const moduleStructure = {
          moduleID: Number(e.target.value),
          readAction: e.target.checked ? 1 : 0,
          writeAction: 0,
        }

        setAccessResponse( [ ...accessResponse, moduleStructure ] )
      } 
    }

  }
  
  function onChangeWriteValue(e) {
    let objIndex;
    if(screenType === ENWORDS.assignScreen){
      objIndex = accessResponse.findIndex((obj => obj.screenName === e.target.value));
      if ( objIndex >= 0){
        accessResponse[ objIndex ].writeAccess = e.target.checked ? true : false
        accessResponse[ objIndex ].readAccess = true
        setAccessResponse( [ ...accessResponse ] )
      }
      else{
        const screenStructure = {
          roleId:0,
          screenName: e.target.value,
          readAccess: e.target.checked ? true : false ,
          writeAccess: e.target.checked ? true : false ,
        }
        setAccessResponse( [ ...accessResponse, screenStructure ] )
      }
    }else{

      objIndex = accessResponse.findIndex((obj => obj.moduleID === Number(e.target.value)));
      if ( objIndex >= 0){
        accessResponse[ objIndex ].writeAction = e.target.checked ? 1 : 0
        accessResponse[ objIndex ].readAction = 1
        setAccessResponse( [ ...accessResponse ] )
      }
      else{
        const moduleStructure = {
          moduleID: Number(e.target.value),
          readAction:e.target.checked ? 1 : 0 ,
          writeAction: e.target.checked ? 1 : 0 ,
        }
        setAccessResponse( [ ...accessResponse, moduleStructure ] )
      }
    }

  }
  
  useEffect(()=>{
    sendAccess()
  }, [ accessResponse ]);

  const setInitialAccess = (moudleArrCopy) =>{
    let tempArray = accessResponse;
    if(props.screenType === ENWORDS.assignScreen){
      moudleArrCopy.forEach((value) => {
        const screenStructure = {
          roleId:0,
          screenName: value.screenName,
          readAccess: false,
          writeAccess: false,
        }
        if (tempArray && tempArray.length!==0){
          tempArray = [ ...tempArray, screenStructure ];
        }
        else{
          tempArray= [ screenStructure ] 
        }
      });
    }else{

      moudleArrCopy.forEach((value) => {
        const moduleStructure = {
          moduleID: value.id,
          readAction: 0,
          writeAction: 0,
        }
        if (tempArray && tempArray.length!==0){
          tempArray = [ ...tempArray, moduleStructure ];
        }
        else{
          tempArray= [ moduleStructure ] 
        }
      });
    }

    setAccessResponse(tempArray);
  }
  
  const populateModuleArray = (moduleData) =>{
    
    if(screenType === ENWORDS.assignScreen){
      const moduleCopy = moduleData.map(value => {
        const data={};
        data[ value.screenName ] = value.screenName;
        data.screenId = value.screenId;
        data.screenName = value.screenName;
        return data;
      });

      const sortedDefaultData = globalSortBy(moduleCopy, ENWORDS.screenName, ENWORDS.descending);
      setModuleArr([ ...sortedDefaultData[ 0 ] ]);
      if (isEmpty){
        setInitialAccess(moduleCopy)
      }
    }else{
      const moduleCopy = moduleData.map(value => {
        const data={};
        data[ value.id ] = value.moduleName;
        data.id = value.id;
        return data;
      });
      setModuleArr(moduleCopy)
      if (isEmpty){
        setInitialAccess(moduleCopy)
      }
    }

  }
  useEffect(()=>{
    if (!isEmpty){
      setAccessResponse(props.onSetAccessDetails)
    }
  }, [ props.onSetAccessDetails ])

  const getScreenData = async() =>{
    dispatch(setLoadingFlag(true));
    const screenRes = await getScreens();
    if(screenRes){
      dispatch(setLoadingFlag(false))
      populateModuleArray(screenRes)
    }
  }

  const onGlobalSortBy = keyValue => {
    const sortedData = globalSortBy(moduleArr, keyValue, sortState);
    setModuleArr([ ...sortedData[ 0 ] ]);
    setSelectedSort(keyValue);
    setSortState(sortedData[ 1 ]);
  };

  useEffect(()=>{
    if(props.screenType === ENWORDS.assignScreen){
      getScreenData();
    }
  }, [])

  useEffect(()=>{
    if (props.data){    
      populateModuleArray(props.data);
    }
  }, [ props.data ])
  return (
      <div className={tableStyles.tableContainer}>
          <table>
              <thead className={tableStyles.tableHead}>
                  <tr>
                      {Object.keys(props.headerData).map(key => (
                          <th className={styles.modulesHeader} key={key}>
                              { key === 'screen' ? 
                                (
                                    <div className={tableStyles.sortContainer}>
                                        <button
                                          className={tableStyles.btnSort}
                                          onClick={() => onGlobalSortBy(ENWORDS.screenName)}
                                        >
                                            <p>{props.headerData[ key ]}</p>
                                            <img
                                              src={
                                                sortState === ENWORDS.descending && selectedSort === ENWORDS.screenName
                                                  ? arrowDown
                                                  : arrowUp
                                              }
                                              alt={ENWORDS.sortBy}
                                            />
                                        </button>
                                    </div>
                                ) : props.headerData[ key ]}
                          </th>
                      ))}
                  </tr>
              </thead>
              <tbody>
                  {
                    accessResponse &&  moduleArr&& moduleArr.map((item, id)=>
                    
                        <tr key ={Object.keys(item)[ 0 ]}>
                            <td key='module' className={styles.modulesColumn}>{Object.values(item)[ 0 ]}</td>
                            <td key='access'>
                                { 
                                  (props.screenType === 'assignScreen')  ?

                                      <div key = {Object.keys(item)[ 0 ]} className = {styles.moduleContentRow}>
                                          <div className={styles.checkBoxContainer} >
                                              <input type="checkbox" value = {item.screenName}   onChange={onChangeReadValue} checked = {accessResponse.some(e=>item.screenName === e.screenName && e.readAccess)} disabled =  { !props.canEdit}  />
                                              <p className={styles.contentRowText}>{ENWORDS.read}</p>
                                          </div>
                                          <div className={styles.checkBoxContainer}>
                                              <input type="checkbox" value = {item.screenName} onChange={onChangeWriteValue} checked = {accessResponse.some(e=>item.screenName === e.screenName && e.writeAccess)} disabled =  { !props.canEdit}  />
                                              <p className={styles.contentRowText}>{ENWORDS.write}</p>
                                          </div>
      
                                      </div>
                                    :
                                        
                                      <div key = {Object.keys(item)[ 0 ]} className = {styles.moduleContentRow}>
                                          <div className={styles.checkBoxContainer} >
                                              <input type="checkbox" value = {item.id}   onChange={onChangeReadValue} checked = {accessResponse.some(e=>item.id === e.moduleID && e.readAction)} disabled =  { !props.canEdit}  />
                                              <p className={styles.contentRowText}>{ENWORDS.read}</p>
                                          </div>
                                          <div className={styles.checkBoxContainer}>
                                              <input type="checkbox" value = {item.id} onChange={onChangeWriteValue} checked = {accessResponse.some(e=>item.id === e.moduleID && e.writeAction)} disabled =  { !props.canEdit}  />
                                              <p className={styles.contentRowText}>{ENWORDS.write}</p>
                                          </div>
            
                                      </div>
                                }
                            </td>

                        </tr>
                    )
                  }
              </tbody>
          </table>
          <div>
          </div>
      </div>
  )
}
ModuleAssignmentTable.propTypes = {
  onGetAccessDetails: PropTypes.func,
  isValidatedAccess: PropTypes.bool,
  onSetAccessDetails: PropTypes.array,
  canEdit: PropTypes.bool,
  screenType: PropTypes.string,
};
export default ModuleAssignmentTable;
