import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uploadBtnStyles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css';
import { DAILYLOGHEADERS, STATUS, LOG_STATUS_COMMITTED, LOG_STATUS_OPEN, LOG_UNIQUE_ID, LOG_STATUS_SENT } from '../adtMonitoringConstant';
import { useEffect } from 'react';
import uploadIcon from '../../../assets/icons/uploadIcon.svg';
import CustomDatePicker from '../../../shared/Components/CustomDatePicker';
import { GLOBAL_DATE_FORMAT_DB, TABS, globalYearMonthDateFormat } from '../../../shared/constants/appConstants';
import { setError, setErrorFlag, setLoadingFlag } from '../../../redux/actions';
import baseStyles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import styles from '../../../styles/stationListing/StationListingScreen.module.css';
import SideNavigation from '../../../shared/Components/SideNavigation';
import { format } from 'date-fns';
import { getDailyLogData, getDailyLogEmail, sendLogsAdtMail, getExcludedContractNumbers, revertCommit } from '../../../api/services/adtDailyLogService';
import CustomTable from '../../../shared/Components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../shared/constants/appRoute';
import editPenIcon from '../../../assets/icons/editPenIcon.svg';
import mailFilledIcon from '../../../assets/icons/mailFilledIcon.svg'
import revertIcon from '../../../assets/icons/revert.svg';
import DailyLogUploadPopup from './DailyLogUploadPopup';
import useAccess from '../../../utility/appHooks/useAccess';
import CustomLogMailPopup from '../../../shared/Components/CustomLogMailPopup';
import SuccessPopUp from '../../../shared/Components/SuccessPopUp';
import { updateDailyLogsFilter, updateDailyLogsPageNumber } from '../../../redux/actions/dailyLogs';
import { FILETYPE } from '../../../shared/constants/appConstants';
import ContractNumberFileUploadPopUp  from './ContractNumberFileUploadPopUp'
import CustomPopup from '../../../shared/Components/CustomPopup';
const ENWORDS = require('../../../shared/local/en.json');
const SCREENS  = require('../../../shared/local/screens.json');

function DailyLogsScreen() {
  const tableData = useSelector(
    state => state.dailyLogsReducer.adtDailyLogsTable
  );
  const dailyLogsDateFilter = useSelector(state => state.dailyLogsReducer.dailyLogsDateFilter)
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const [ showUploadPopup, setShowUploadPopup ] = useState(false);
  const [ logsDate, setLogsDate ] = useState(dailyLogsDateFilter ? dailyLogsDateFilter : new Date());
  const [ showEmailPopup, setShowEmailPopup ] = useState(false);
  const [ logId , setLogId ] = useState('');
  const [ successMessage, setSuccessMessage ] = useState();
  const [ selectedFile, setSelectedFile ] = useState('');
  const [ fileVisibility, setFileVisibility ] = useState(false);
  const [ isFilePicked, setIsFilePicked ] = useState(false);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const userData = useSelector(state => state.auth?.user)
  const [ revertLogId, setRevertLogId ] = useState()

  const dispatch = useDispatch();
  const hasWriteAccess = useAccess(SCREENS.adtDailyLogs)
  const openDetails = async (uniqueId, editEnabled) =>{
    navigate(ROUTES.ADTDAILYLOGDETAILS,
      {
        state: {
          data: tableData.find(item=> item.logUniqueId === uniqueId),
          isEdit : editEnabled,
        },
      }
    );
  }
  const onRowClick = async (event, uniqueId) =>{
    event.stopPropagation();
    openDetails(uniqueId,false)
  }

  const editDailyLog = (event, uniqueId) => {
    event.stopPropagation();
    openDetails(uniqueId, true)
  };

  const openEmailPopup = (event, uniqueId) =>{
    event.stopPropagation();
    setShowEmailPopup(true)
    setLogId(uniqueId);
  }

  const onRevertLogClick =  (event, uniqueId) =>{
    event.stopPropagation();
    setRevertLogId(uniqueId);
  }

  const onRevertCommit = async () => {
    dispatch(setLoadingFlag(true));
    const res = await revertCommit(revertLogId);
    dispatch(setLoadingFlag(false));
    if (res.res) {
      setSuccessMessage(ENWORDS.revertSuccessful);
    } else {
      dispatch(setError({ statusCode: res.err?.response?.status, errorStatus: true, errorMessage: res.err?.response?.data }))
    }
    setRevertLogId(undefined)
  };

  const onSendMailClick = async (data) => {
    const res = await sendLogsAdtMail(logId, data);
    dispatch(setLoadingFlag(false));

    if (res.res) {
      setSuccessMessage(ENWORDS.commonMailSuccess);
    } else {
      dispatch(setErrorFlag(true));
    }
  };
  
  useEffect(() => {
    if (logsDate !== '') {
      dispatch(updateDailyLogsPageNumber(0))
      dispatch(updateDailyLogsFilter(logsDate))
      dispatch(getDailyLogData(format(logsDate,GLOBAL_DATE_FORMAT_DB)));
    }
  }, [ logsDate ])

  useEffect(() => {
    if(!showEmailPopup && logsDate !== '' && successMessage){
      dispatch(getDailyLogData(format(logsDate,GLOBAL_DATE_FORMAT_DB)));
    }
  }, [ showEmailPopup, successMessage ])
  
  const changeHandler = (e) =>{
    setSelectedFile(e.target.files[ 0 ]);
    setIsFilePicked(true)
  }
  const showFilePopUp = () =>{
    setFileVisibility(true);
  }
  const onBlukUpload = () =>{
    showFilePopUp()
  }

  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.adtDailyLogs){
        if (!item.writeAccess){
          delete DAILYLOGHEADERS[ 'actions' ];
        }
      }
    })
  }

  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  useEffect(() => {
    if (isFilePicked){
      showFilePopUp()
    }
  }, [ isFilePicked ])
  const filePopupCloseHandler = (e) => {
    setFileVisibility(e);
    setIsFilePicked(false)
  };

  useEffect(() => {
    dispatch(getExcludedContractNumbers())
  }, [ ])
  return (
      <div className={baseStyles.mainContainer}>
          <CustomPopup
            showPopUp={revertLogId}
            onConfirm={onRevertCommit}
            title={ENWORDS.revertCommitTitle}
            onReject={()=>{setRevertLogId(undefined)}}
          >
              <p>{ENWORDS.revertCommitText}</p>
          </CustomPopup> 
          <SuccessPopUp
            header = {ENWORDS.revertCommit}
            title = {successMessage}
            show = {successMessage}
            onClose={()=>{ 
              setSuccessMessage(undefined)                     
              dispatch(setLoadingFlag(true));
              dispatch(getDailyLogEmail());
            }}
          />
          < CustomLogMailPopup
            title = {ENWORDS.adtMailPopUpTxt}
            show={showEmailPopup}
            onClose={setShowEmailPopup}
            logId={logId}
            setSuccessMessage = {successMessage}           
            onSendClick = {onSendMailClick}
          />
          {showUploadPopup && 
          <DailyLogUploadPopup 
            showPopUp={showUploadPopup} 
            closePopup={()=>{setShowUploadPopup(false); dispatch(getDailyLogData(format(logsDate, GLOBAL_DATE_FORMAT_DB)))}}
          />
          }

          <ContractNumberFileUploadPopUp
            isFile = {isFilePicked}
            inputFile = {selectedFile}
            show={fileVisibility}
            onClose={filePopupCloseHandler}
            onUploaded={()=> {dispatch(getExcludedContractNumbers());dispatch(getDailyLogData(format(logsDate, GLOBAL_DATE_FORMAT_DB)))}}
          />

          <div className={baseStyles.leftContainer}>
              <SideNavigation parentName={TABS.adtAlarmMonitoring} />
          </div>

          <div className={baseStyles.rightContainer}>
              <div className={baseStyles.siteHeader}>
                  <div className={styles.stationHeaderContainer}>
                      <div className={styles.filterContainer}>
                          <div className={styles.leftFilterContainer}>
                              <CustomDatePicker
                                title={ENWORDS.month}
                                startDate={logsDate}
                                endDate = {new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)}
                                dateFormat={globalYearMonthDateFormat}
                                showMonthYearPicker={true}
                                onChange={date => setLogsDate(date)}
                                wrapperClass='stationDate'
                                isHidden= {true}
                              />
                          </div>
                          {hasWriteAccess && 
                          <div className={baseStyles.btnContainer}>
                              <div className={styles.addContainer}>
                                  <div className={uploadBtnStyles.mainBtnContainer}title = {ENWORDS.createExcludedContractNumbersTooltip}>
                                      <input
                                        type="file"
                                        accept={FILETYPE}
                                        onChange={changeHandler}
                                        ref={fileInputRef}
                                        hidden
                                      />
                                      <button className= {styles.createBtn} onClick={onBlukUpload}>
                                          <p>Exclude Sites</p>
                                      </button>
                                  </div>
                                  <div
                                    className={uploadBtnStyles.mainBtnContainer}
                                    title={ENWORDS.dailyLogsUploadBtnTooltipTxt}
                                  >
                                      <button
                                        className={uploadBtnStyles.featureButton}
                                        onClick={()=>{setShowUploadPopup(true)}}
                                      >
                                          <div className={uploadBtnStyles.btnContainer}>
                                              <img src={uploadIcon} alt={ENWORDS.uploadIcon} />
                                          </div>
                                      </button>
                                  </div>
                              </div>
                          </div>
                          }
                      </div>
                  </div>
              </div>
              <CustomTable 
                data = {tableData} 
                headerData = {DAILYLOGHEADERS} 
                tableName = {ENWORDS.dailyLogTable}
                onRowClick = {onRowClick}
                actions={
                  [ 
                    { name : ENWORDS.mailIcon, 
                      src : mailFilledIcon, 
                      actionToolTipTxt : ENWORDS.sendMailTxt,
                      columnName : ENWORDS.actions,
                      onClick : openEmailPopup , 
                      condition : { columnName: STATUS, values : [ LOG_STATUS_COMMITTED ] } },
                      
                    { name : ENWORDS.editPenIcon, 
                      src : editPenIcon, 
                      actionToolTipTxt : ENWORDS.editDataTxt,
                      columnName :  ENWORDS.actions,
                      onClick : editDailyLog , 
                      condition : { columnName: STATUS, values : [ LOG_STATUS_OPEN ],  restrictAccess : !hasWriteAccess } },
                    { name : ENWORDS.revertCommit, 
                      src: revertIcon,
                      actionToolTipTxt : ENWORDS.revertCommit,
                      columnName :  ENWORDS.actions,
                      onClick : onRevertLogClick , 
                      condition : { columnName: STATUS, values : [ LOG_STATUS_COMMITTED, LOG_STATUS_SENT ],  restrictAccess : userData.isExtraPrivileged !== 1 } } 
                  ]}
                onClickKey={LOG_UNIQUE_ID}
                isFromPage='dailyLog'
              />
          </div>
      </div>
  );
}
export default DailyLogsScreen;
