import React from 'react'
import InvoiceListingScreen from './InvoiceListingScreen'

const InvoiceListing = () => {
  return (
      <InvoiceListingScreen/>
  )
}

export default InvoiceListing
