import { SET_COUNTERS_DATA } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  countersTable: [],
};

function groupBySiteId(objectArray) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[ 'siteId' ];
    if (!acc[ key ]) {
      acc[ key ] = [];
    }
    acc[ key ].push(obj);
    return acc;
  }, {});
}

const countersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_COUNTERS_DATA:
    const groupedData = groupBySiteId(action.payload)
    const result = []
    Object.keys(groupedData).forEach((key) => {
      const data = { siteId: key, total: 0, status: '', jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0, jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0 }
      groupedData[ key ].map((object) => {
        data.siteName = object.siteName
        data.contractNumber =object.contractNumber
        data.status =  data.status ? data.status + ', ' + object.status : object.status;
        data.total += object.counter
        switch (object.monthColumn) {
        case '1':
          data.jan = object.counter
          break
        case '2':
          data.feb = object.counter
          break
        case '3':
          data.mar = object.counter
          break
        case '4':
          data.apr = object.counter
          break
        case '5':
          data.may = object.counter
          break
        case '6':
          data.jun = object.counter
          break
        case '7':
          data.jul = object.counter
          break
        case '8':
          data.aug = object.counter
          break
        case '9':
          data.sep = object.counter
          break
        case '10':
          data.oct = object.counter
          break
        case '11':
          data.nov = object.counter
          break
        case '12':
          data.dec = object.counter
          break
        default:
          break
        }
        return object
      })
      result.push(data)
    } )
    return {
      ...state,
      countersTable: result,
    };

  default:
    return state;
  }
};

export default countersReducer;
