import React from 'react'
import globalPopUpStyles  from '../../styles/popUp.module.css';
import styles from '../../styles/fileUploadPopUp.module.css'
import inputStyles from '../../styles/inputs.module.css';
import PropTypes from 'prop-types';
import cancelIcon from '../../assets/icons/cancelIcon.svg'
import successIcon from '../../assets/icons/successIcon.png';

const ENWORDS  = require('../../shared/local/en.json')

const SuccessPopUp = (props) => {
  const { onClose, show , header = ENWORDS.emailStatus } = props;
  const closeHandler = (e) => {
    onClose(false);
  };

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={globalPopUpStyles.overlay}
      >
          <div className={globalPopUpStyles.popup}>
              <div className={globalPopUpStyles.topSection}>
                  <p> {header}</p>
                  <button className={globalPopUpStyles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt= {ENWORDS.cancelIcon} />
                  </button>
              </div>
              <div className={styles.successStatusValueContainer}>
                  <div>
                      <img src={successIcon} alt = {ENWORDS.done} />
                  </div>
                  <p className={styles.invalidText}>
                      {props.title}
                  </p>
              </div>
          </div>
      </div>
  )
}
SuccessPopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export const DisplayPopUp = (props) => {
  const { onClose, show , onDownloadPDF } = props;
  const closeHandler = (e) => {
    onClose(false);
  };

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={globalPopUpStyles.overlay}
      >
          <div style={{ width: '90%', margin: 'auto' }} className={globalPopUpStyles.popup}>
              <div className={globalPopUpStyles.topSection}>
                  <p> {ENWORDS.invoiceDetails}</p>
                  <div>
                  </div>
                  <div className={inputStyles.buttonContainer}>
                      <button className={inputStyles.btnPDFDownload} onClick={onDownloadPDF} >{ENWORDS.download}</button>
                      <button className={globalPopUpStyles.btnCancel} onClick={closeHandler}>
                          <img src={cancelIcon} alt= {ENWORDS.cancelIcon} />
                      </button>
                  </div>
              </div>
              {props.component}
          </div>
      </div>
  )
}
export default SuccessPopUp