import React, { useContext } from 'react';
import { authContext } from './AuthProvoider';
import { Navigate, useLocation } from 'react-router-dom';
const AuthRouter = ({ children, isValid, ...props }) => {
  const auth = useContext(authContext)
  const location = useLocation();

  if(auth.user){
    const data = auth.user.roleAccess.find((obj) => obj.readAccess === true)
    return <Navigate  to={'/'+data.routeIdentity} state={{ from: location }} replace />;
  }

  return children
    
}

export default AuthRouter;