import { APPEND_USERS, USERS_FILTER, USERS_PAGE_NUMBER } from '../../shared/constants/actionType';

export const appendUsers = (userData) => {
  return {
    type: APPEND_USERS,
    payload: userData,
  }
}
export const updateUserRoleFilter = (data) => {
  return {
    type: USERS_FILTER,
    payload: data,
  }
}

export const updateUserPageNumber = (data)=>{
  return{
    type: USERS_PAGE_NUMBER,
    payload: data,
  }
}
