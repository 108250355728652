import React from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import '../../styles/invoiceListing/inputDateCalendar.css';
import  dashboardStyle from '../../styles/dashboard/AdminDashboardScreen.module.css';
import stationStyle from '../../styles/baseconfiguration/AddNewSite.module.css';
import invoiceStyle from '../../styles/invoiceListing/InvoiceListingScreen.module.css';
import { useState } from 'react';
import { useEffect } from 'react';

const CustomDatePicker = ({ 
  title, styleTag, startDate, 
  endDate, dateFormat, onChange, minDate, disabled = false,
  wrapperClass = '', showMonthYearPicker = false, errorMessage = '', isHidden = false, wrapperStyle }) => {
  const [ selectDate, setSelectDate ] = useState(startDate);
  useEffect(() => {
    setSelectDate(startDate)
  },[ startDate ])

  const onFromDateChange = (date) =>{
    setSelectDate(date)
    if(onChange){
      onChange(date);
    }
  }
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return (
      <div className={ 
        wrapperClass === 'dashboardFilter' ?
          dashboardStyle.fromDate 
          : wrapperClass === 'invoiceDatePicker'? 
            invoiceStyle.datePickerStyle 
            :wrapperClass === 'reportLogsDatePicker'? invoiceStyle.reportDatePickerStyle
              :stationStyle.formDataContainer} style={wrapperStyle}>
          <p className={
            wrapperClass === 'dashboardFilter' ?
              dashboardStyle.filterText:
              stationStyle.dateTitle}>{title}</p>
          <DatePicker 
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
                <div
                  style={{
                    margin: '0.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                    <button hidden={isHidden} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {'<'}
                    </button>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select hidden={isHidden}
                      value={months[ getMonth(date) ]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button hidden={isHidden} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {'>'}
                    </button>
                </div>
            )}
            disabled={disabled}
            placeholderText='dd-mm-yyyy'
            selected={selectDate} 
            onChange={(date) => onFromDateChange(date)} 
            wrapperClassName= {wrapperClass}
            dateFormat= {dateFormat}
            showMonthDropdown
            showMonthYearPicker = {showMonthYearPicker}
            minDate={minDate? minDate:null}
            maxDate={endDate}/>
          <p className={ errorMessage !== '' ? 
            stationStyle.errorMessageDate :
            stationStyle.errorMessage}> {errorMessage}</p>
      </div>
  )
}

export default CustomDatePicker