import React, { useState, useEffect } from 'react'
import baseStyles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import styles from '../../../../styles/stationListing/StationListingScreen.module.css'
import ENWORDS from '../../../../shared/local/en.json';
import { ROUTES }  from '../../../../shared/constants/appRoute';
import { useNavigate } from 'react-router-dom';
import { OPSREGIONSTATUSSTYLE, OPSREGION_EXPORT_FILE_PREFIX, OPSREGION_EXPORT_HEADERS, STATIONAREASHEADER, STATIONOPSREGIONSHEADER } from '../../stationListingConstant';
import { useSelector, useDispatch } from 'react-redux';
import CustomSearchBox from '../../../../shared/Components/CustomSearchBox';
import { setLoadingFlag } from '../../../../redux/actions';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import { GLOBALSEARCHSTATUS, GLOBALSTATUS } from '../../../BaseConfiguration/baseConfigurationConstant';
import DownloadCSVBtn from '../../../../shared/Components/DownloadCSVBtn';
import CustomTable from '../../../../shared/Components/CustomTable';
import editPenIcon from '../../../../assets/icons/editPenIcon.svg';
import deleteIcon from '../../../../assets/icons/binIcon.svg';
import CustomPopup from '../../../../shared/Components/CustomPopup';
import { deleteOpsRegionById, getOpsRegionsData, getOpsRegionById } from '../../../../api/services/opsRegionManagementService';
import SideNavigation from '../../../../shared/Components/SideNavigation';
import { TABS } from '../../../../shared/constants/appConstants';
import { updateOpsRegionFilter, updateOpsRegionPageNumber } from '../../../../redux/actions/opsRegionsListing';
const SCREENS  = require('../../../../shared/local/screens.json');

const OpsRegionListingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const opsRegionsData = useSelector(state => state.opsRegionsReducer.opsRegionsTableData);
  const opsRegionStatusFilter = useSelector(state => state.opsRegionsReducer.opsRegionStatusFilter);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ searchText, setSearchText ] = useState('');
  const [ roleAccess, setRoleAccess ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState(opsRegionStatusFilter ? opsRegionStatusFilter : GLOBALSEARCHSTATUS.All);
  const [ toDeleteId, setToDeleteId ] = useState()
  const [ showDeletePopUp, setShowDeletePopUp ] = useState(false)

  useEffect(() => {
    dispatch(getOpsRegionsData())
  }, [])

  useEffect(() => {
    setFilteredData(opsRegionsData ?? []);
  }, [ opsRegionsData ])

  const filteration = () => {
    let opsRegionFilterData = opsRegionsData
    if (searchText.length > 0) {
      opsRegionFilterData = opsRegionFilterData.filter((item) => {
        const name = (item[ 'opsRegionName' ]?.toLowerCase())
        const opsRegionManager = (item[ 'opsRegionalManagerName' ]?.toLowerCase())
        const managerEmail = (item[ 'opsRegionalManagerEmail' ]?.toLowerCase())

        return name?.includes(searchText?.toLowerCase()) 
        || opsRegionManager?.includes(searchText?.toLowerCase()) || managerEmail?.includes(searchText?.toLowerCase()) 
      });
    }

    if(selectedStatus.toLowerCase() !== 'all'){
      
      opsRegionFilterData = opsRegionFilterData.filter((item) =>{

        return (item.opsRegionStatus).toString() === selectedStatus;
      })
    }
    
    return opsRegionFilterData
  }
  useEffect(() => {
    setFilteredData(filteration())

  }, [ searchText, selectedStatus, opsRegionsData ])

  useEffect(() => {
    dispatch(updateOpsRegionFilter(selectedStatus))
    dispatch(updateOpsRegionPageNumber(0))
  }, [ selectedStatus ])
  
  const addOpsRegion = async (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))

    dispatch(setLoadingFlag(false))
    navigate(ROUTES.ADDOPSREGION)

  }
  
  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.opsRegions){
        if (!item.writeAccess){
          delete STATIONOPSREGIONSHEADER[ 'actions' ];
        }
        setRoleAccess(item)
      }
    })

  }

  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  const onRowClick = async (event, opsRegionId) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    const opsRegionData = await getOpsRegionById(opsRegionId);
    dispatch(setLoadingFlag(false));
    if(opsRegionData){
      navigate(ROUTES.OPSREGIONDETAILS, {
        state: {
          data: opsRegionData.res,
          roleAccess: roleAccess,
        },
      });
    }
  }

  const editOpsRegion = async (event, opsRegionId) => {
    dispatch(setLoadingFlag(true));
    event.stopPropagation();
    const opsRegionData = await getOpsRegionById(opsRegionId);
    if (opsRegionData) {
      dispatch(setLoadingFlag(false));
      navigate(ROUTES.EDITOPSREGIONS,
        {
          state: {
            data: opsRegionData.res,
          },
        }
      );
    }
  };

  const deleteOpsRegion = (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    dispatch(deleteOpsRegionById(toDeleteId))
    setShowDeletePopUp(false)
    dispatch(getOpsRegionsData())
  }

  const onDeleteClick = (event, opsRegionId) => {
    event.stopPropagation();
    setToDeleteId(opsRegionId)
    setShowDeletePopUp(true)
  }
  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateOpsRegionPageNumber(0))
  }
  return (
      <div className={baseStyles.mainContainer}>
          <CustomPopup
            showPopUp={showDeletePopUp}
            onConfirm={deleteOpsRegion}
            title={ENWORDS.removeOpsRegionTitle}
            onReject={()=>{setShowDeletePopUp(false)}}
          >
              <p>{ENWORDS.removeOpsRegionText}</p>
          </CustomPopup>

          <div className = {baseStyles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   
              />
          </div>

          <div className={baseStyles.rightContainer}>
              <div className = {baseStyles.siteHeader}>
                  <div className={styles.stationHeaderContainer}>
                      <div className={styles.filterContainer}>
                          <div className={styles.leftFilterContainer}>

                              <div className={styles.searchBoxContainer}>
                                  <CustomSearchBox 
                                    placeholder = {ENWORDS.stationPlaceholder}  
                                    title={ENWORDS.search} 
                                    onChange={e => onChangeSearch(e)} />
                              </div>
                              <CustomDropDown
                                title={ENWORDS.status}
                                name = {ENWORDS.status?.toLowerCase()}
                                fieldValues={GLOBALSEARCHSTATUS}
                                type = {ENWORDS.custom}
                                selectedValue= {selectedStatus}
                                onChange={e =>
                                  setSelectedStatus(String(e.target.value))
                                }
                              />
                          </div>
                  
                          <div className={baseStyles.btnContainer}>
                              <div className={baseStyles.addContainer}>
                                  <DownloadCSVBtn 
                                    popUpText={ENWORDS.downloadOpsRegionCSVText}
                                    popUpTitle = {ENWORDS.downloadOpsRegionCSVTitle}
                                    screenName = {ENWORDS.stationCSV}
                                    toolTitle = {ENWORDS.downloadOpsRegionTooltip}
                                    tableData={filteredData.map(item=> {return { ...item , opsRegionStatus : GLOBALSTATUS[ item.opsRegionStatus ] } })}
                                    headers={OPSREGION_EXPORT_HEADERS}
                                    fileNamePrefix={OPSREGION_EXPORT_FILE_PREFIX}
                                  />
                              </div>
                              {roleAccess && roleAccess.writeAccess ? 
                                  <div className={baseStyles.addContainer} onClick={addOpsRegion }>
                                      <button>
                                          <img src={plusIcon} alt={ENWORDS.addBtn} />
                                      </button>
                                  </div>
                                :null }
                          </div>
                      </div>
                  </div> 
              </div> 
              <CustomTable 
                data = {filteredData} 
                headerData = {STATIONOPSREGIONSHEADER} 
                tableName = {ENWORDS.opsRegionTable}
                onRowClick = {onRowClick}
                actions={
                  [ { name : ENWORDS.deleteIcon, actionToolTipTxt : ENWORDS.deleteDataTxt, src : deleteIcon,columnName :  ENWORDS.actions, onClick : onDeleteClick },
                    { name : ENWORDS.editPenIcon, actionToolTipTxt : ENWORDS.editDataTxt, src : editPenIcon,columnName :  ENWORDS.actions, onClick : editOpsRegion } 
                  ]}
                statusStyle={OPSREGIONSTATUSSTYLE}
                isFromPage='opsRegion'
              />
          </div> 
      </div>
  )
}

export default OpsRegionListingScreen