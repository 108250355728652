import { DELETE, GET, POST, PUT, API_HEADER } from '../../shared/constants/appConstants';
import { ROLE_URL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
import { setRoleTable } from '../../redux/actions/roleManagement';
import { setErrorFlag, setLoadingFlag } from '../../redux/actions';

export function getRoleData (){
  return async  (dispatch) => {
    await makeRequest({
      method:GET,
      url:ROLE_URL.getRoles,
      data:undefined,
      headers:API_HEADER,
    })
      .then(function (response) {
        dispatch(setRoleTable(response.data.data));
        dispatch(setLoadingFlag(false))
      }).catch(function(error){
        dispatch(setErrorFlag(true))
      })
  }
}

export async function checkRoleName(roleId, username) {
  let res ;
  await makeRequest({
    method: GET,
    url: ROLE_URL.checkRole + roleId+'&roleName='+ username,
    data: undefined,
    showPopUp: false,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
      
    })
    .catch(function(err){
      res= false
    })
  return res
}
export async function getScreens() {
  let res;
  await makeRequest({
    method:GET,
    url:ROLE_URL.getScreens,
    data: undefined,
    headers:API_HEADER,
  })
    .then(function(response){
      res = response.data.data
    })
    .catch(function(err) {
      
    })
  return res;
}

export async function getRoleById(roleId){
  
  let res;
  await makeRequest({
    method:GET,
    url: ROLE_URL.getRoleById + roleId,
    data:undefined,
    headers:API_HEADER,
  })
    .then(function(response){
      res = response.data.data
    })
  return res;
}

export function deleteRole(id){
  return async (dispatch) =>{
    await makeRequest({
      method:DELETE,
      url:ROLE_URL.deleteRole + id,
      data:undefined,
      headers:API_HEADER,
    })
      .then(function (){
        dispatch(getRoleData())
        dispatch(setLoadingFlag(false))
      })
      .catch(function(response){
        dispatch(setLoadingFlag(false))
      });
  }
}

export async function addRole( roleName, access, isExtraPrivileged){
  let res;

  await makeRequest({
    method:POST,
    url:ROLE_URL.addRole,
    data:{
      roleName: roleName,
      roleAccess : access,
      isExtraPrivileged: isExtraPrivileged,
    },
    headers:API_HEADER,
  })
    .then(function(response){
      
      res = true
    })
    .catch(function(response){
      res = false
    });
  return res
}

export async function updateRole(roleId, roleName, roleAccess, isExtraPrivileged){
  let res;
  await makeRequest({
    method:PUT,
    url:ROLE_URL.updateRole,
    data:{
      roleId: roleId,
      roleName: roleName,
      roleAccess: roleAccess,
      isExtraPrivileged: isExtraPrivileged,
    },
    headers:API_HEADER,
  })
    .then(function(response){
      
      res = true
    })
    .catch(function(response){
      res = false
    });
  return res;
}
