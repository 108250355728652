import React, { useState, useEffect, useRef } from 'react';
import ListingHeader from '../../../shared/Components/ListingHeader';
import { useNavigate, useLocation } from 'react-router';
import { useDebounce } from 'use-debounce';
import styles from '../../../styles/moduleConfiguration/addconfig.module.css';
import inputStyles from '../../../styles/inputs.module.css'
import CustomInput from '../../../shared/Components/CustomInput';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import arrowDown from '../../../assets/icons/arrowDownDark.svg'
import arrowUp from '../../../assets/icons/arrowUp.svg'
import binIcon from '../../../assets/icons/binIcon.svg'
import plusIcon from '../../../assets/icons/plusIcon.svg'
import { CONFIG_EXCEPTIONS_TYPES, MANDATE } from '../moduleConfigurationConstant';
import { addModule, checkModuleAvailability, checkPrefixAvailability } from '../../../api/services/moduleConfigurationService';
import { displayError, setLoadingFlag } from '../../../redux/actions';
import { ROUTES } from '../../../shared/constants/appRoute'
import { ALPHA_NUM_REGEX, IS_ALPHA } from '../../../shared/constants/appConstants';
import MultiSelectDropdown from '../../../shared/Components/MultiSelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { commonScrollFunction } from '../../../shared/commonFunctions';
import { dupliFieldCheck } from '../ModuleConstants/CommonFunctions';
import globalStyles from  '../../../styles/global.module.css';

const ENWORDS = require('../../../shared/local/en.json')
const ERRORWORDS = require('../../../shared/local/error.json')
const PLACEHOLDER = require('../../../shared/local/placeholder.json')

const AddModuleConfiguration = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user)
  const [ moduleName, setModuleName ] = useState('');
  const [ itemName, setItemName ] = useState('');
  const [ transactionType, setTransactionType ] = useState('');
  const [ prefix, setPrefix ] = useState('');
  const [ error, setError ] = useState({});
  const [ fieldError, setFieldError ] = useState([])
  const [ isValidated, setIsValidated ] = useState(false);
  const [ showDetails, setShowDetails ] = useState(true)
  const [ showFields, setShowField ] = useState(true)
  const [ fieldList, setFieldList ] = useState([]);
  const [ copyFieldList, setCopyFieldList ] = useState([]);
  const [ visibleSection, setVisibleSection ] = useState('moduleDetails');
  const [ companyArr, setCompanyArr ] = useState([]);
  const [ companyData, setCompanyData ] = useState([]);
  const [ supplierDataList, setSupplierDataList ] = useState([]);
  const [ isDone, setIsDone ] = useState(false);
  const [ moduleValue ] = useDebounce(moduleName, 500)
  const [ prefixValue ] = useDebounce(prefix, 500)
  const [ selectedSupplierId, setSelectedSupplierId ] = useState(0);
  const [ filterFieldNameDBArr, setFilterFieldNameDBArr ] = useState([])
  const [ listValue ] = useDebounce(fieldList , 500);
  const [ apExport, setAPExport ] = useState(0);
  const [ arExport, setARExport ] = useState(0);
  const [ nlExport, setNLExport ] = useState(0);
  const [ exception, setException ] = useState(CONFIG_EXCEPTIONS_TYPES[ 'exception 1' ]);

  const moduleRef = useRef(null)
  const fieldRef = useRef(null)
  const mainRef = useRef(null)
  const sectionRefs = [
    { section: 'moduleDetails', ref: moduleRef },
    { section: 'fieldDetails', ref: fieldRef }
  ];
  
  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };
  const scrollTo = ele => {
    window.scrollTo({
      top: ele.offsetTop - mainRef.current.offsetTop,
      left : 0,
      behavior: 'smooth',
    });
  };

  const toggleModuleClass = () =>{
    scrollTo(moduleRef.current);
  };

  const toggleFieldClass = () =>{
    scrollTo(fieldRef.current);
  };
  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(moduleRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ visibleSection ]);

  const addBlock = () => {
    let fieldLstCopy = fieldList;
    let fieldErrorCopy = fieldError;
    const lenOfFieldLst = fieldList.length
    let blockId = 0
    if (fieldList.length !== 0){
      const nThLst = fieldLstCopy.at(lenOfFieldLst-1)
      blockId = nThLst.id +1
    }

    const defaultFieldNameDb = filterFieldNameDBArr.filter(e=> e.selectedId === null  )[ 0 ]?.fieldName;
    if (defaultFieldNameDb){

      const dataTypeArr = copyFieldList.filter((e)=> e.fieldNameDB === defaultFieldNameDb)[ 0 ];
      const struct = {
        id: blockId,
        fieldName:'', 
        fieldNameDB:defaultFieldNameDb,
        dataTypeValues:dataTypeArr?.dataTypeValues,
        fieldDataType:dataTypeArr?.dataTypeValues[ 0 ].key,
        mandate: 0,
        canUpdate: dataTypeArr?.canUpdate,
        defaultValue : null,
      }

      const currentSelectedIndex = filterFieldNameDBArr.findIndex((fieldEvent)=> fieldEvent.fieldName === defaultFieldNameDb);
      filterFieldNameDBArr[ currentSelectedIndex ].selectedId = blockId;
      fieldLstCopy = [ ...fieldLstCopy, struct  ]
    
      setFieldList(fieldLstCopy)

      const errorStruct = {
        errorId: blockId,
        fieldNameError:'',
        fieldNameDBError:'',
      }
      fieldErrorCopy = [ ...fieldErrorCopy, errorStruct ];
      setFieldError(fieldErrorCopy);
      
    }
  }
  useEffect(() =>{
    const data = dupliFieldCheck(fieldList, fieldError );
    setFieldError(data)
  },[ listValue ])
  
  const checkValidation = async () => {
    let errorCopy = error
    let fieldErrorCopy = fieldError
    let foundDateCombination;
    let stationSiteRefConfiguration;
    
    const foundUnitCombination =  fieldList.filter(e=> e.fieldNameDB.includes('Units') || e.fieldNameDB.includes('MFG Unit Price') );
    if(foundUnitCombination.length!==1){
      foundDateCombination =  fieldList.filter(e=> e.fieldNameDB.includes('Period From') || e.fieldNameDB.includes('Period To') );
      if(foundDateCombination.length !== 1){
        stationSiteRefConfiguration = fieldList.filter(e => e.fieldNameDB.includes('Station ID') || e.fieldNameDB.includes('Retailer ID'))
      }
    }

    if (!IS_ALPHA.test(moduleName?.trim())) {
      errorCopy={ ...errorCopy, moduleName: ERRORWORDS.moduleName }
    }
    
    if (!IS_ALPHA.test(itemName?.trim())) {
      errorCopy={ ...errorCopy, itemName: ERRORWORDS.itemName }
    }
    if (!ALPHA_NUM_REGEX.test(prefix?.trim())) {
      errorCopy = { ...errorCopy, prefix: ERRORWORDS.prefixError }
    }
    if (companyData && companyData.length === 0) {
      errorCopy = { ...errorCopy, companyError: ERRORWORDS.companyError }
    }
    
    if(foundUnitCombination.length === 1 ){
      errorCopy = { ...errorCopy, fieldCombinationError: ERRORWORDS.unitCombinationErrorMessage }
    }else if(foundDateCombination.length === 1){
      errorCopy = { ...errorCopy, fieldCombinationError: ERRORWORDS.dateCombinationErrorMessage }
    }else if(stationSiteRefConfiguration.length <= 0)
    {
      errorCopy = { ...errorCopy, fieldCombinationError: ERRORWORDS.stationRetailerErrorMessage }
    }
    else{
      errorCopy = { ...errorCopy, fieldCombinationError: '' }
    }

    fieldList.forEach((fieldRow) => {
      const currentIndex = fieldErrorCopy.findIndex((e)=> e.errorId === fieldRow.id )
      if (currentIndex > -1){
        if (!ALPHA_NUM_REGEX.test(fieldRow.fieldName)){
          fieldErrorCopy[ currentIndex ].fieldNameError = ERRORWORDS.fieldNameError
        }
        if (!ALPHA_NUM_REGEX.test(fieldRow.fieldNameDB)){
          fieldErrorCopy[ currentIndex ].fieldNameDBError = ERRORWORDS.fieldNameDBError
        }
      }
      else{
        const errorStr= { 
          errorId:fieldRow.id, 
          fieldNameError: '' ,
          fieldNameDBError:  '',
        }
        if (!ALPHA_NUM_REGEX.test(fieldRow.fieldName)){
          errorStr.fieldNameError = ERRORWORDS.fieldNameError
        }
        if (!ALPHA_NUM_REGEX.test(fieldRow.fieldNameDB)){
          errorStr.fieldNameDBError = ERRORWORDS.fieldNameDBError
        }
        fieldErrorCopy = [ ...fieldErrorCopy , errorStr ]
      }
    })

    setError(errorCopy);
    setFieldError(fieldErrorCopy)
    
    const result = fieldErrorCopy.filter((e) => {
      return  e.fieldNameError !== '' ||  e.fieldNameDBError !== ''
    })
    
    const isErrorEmpty = Object.keys(errorCopy).filter((item)=>{
      return errorCopy[ item ] !== ''
    })
    
    if (isErrorEmpty.length === 0   && 
        result.length === 0){
      setIsValidated(true)
    } 
    setIsDone(!isDone);
  }

  const checkModuleAval = async () => {
    let errorCopy  = { ...error }
    const moduleNameValidation = await checkModuleAvailability(-1, moduleName)
    
    if (moduleName?.trim()  !== ''){
      if (moduleNameValidation){
        errorCopy  = { ...errorCopy, moduleName: ERRORWORDS.duplicateModule }
      }
      else{
        if (IS_ALPHA.test(moduleName)) {
          errorCopy = { ...errorCopy, moduleName: '' };
        }
      }
    }
       
    setError(errorCopy)
  }

  const checkPrefixAval = async () => {
    const prefixValidation = await checkPrefixAvailability(prefix)
    let errorCopy  = error
    
    if (prefixValidation){
      errorCopy  = { ...errorCopy, prefix: ERRORWORDS.duplicatePrefix }
    }
    else{
      if (ALPHA_NUM_REGEX.test(prefix?.trim())){
        errorCopy  = { ...errorCopy, prefix: '' }
      }
      else{
        errorCopy  = { ...errorCopy, prefix: ERRORWORDS.prefixError }
      }
    } 
    setError(errorCopy)
  }
    
  const deleteFieldByid = (fieldItem) =>{
      
    const fieldErrorCopy = fieldError;
    const fieldLstCopy = fieldList
    fieldLstCopy.forEach((item, idABC)=>{
      if (item.id ===  fieldItem.id){
        fieldLstCopy.splice(idABC,1)  
        setFieldList([ ...fieldLstCopy ])
      }
    })
    fieldErrorCopy.forEach((item, idABC)=>{
      if (item.errorId ===  fieldItem.id){
        fieldErrorCopy.splice(idABC,1)
        setFieldError([ ...fieldErrorCopy ]);
      }
    })
    const indexOfFieldNameDB = filterFieldNameDBArr.findIndex((e)=> e.fieldName === fieldItem.fieldNameDB);
    
    filterFieldNameDBArr[ indexOfFieldNameDB ].selectedId = null;

    let errorCopy = error
    let foundDateCombination;
    let stationSiteRefConfiguration;
    const foundUnitCombination =  fieldList.filter(e=> e.fieldNameDB.includes('Units') || e.fieldNameDB.includes('MFG Unit Price') );
    if(foundUnitCombination.length!==1){
      foundDateCombination =  fieldList.filter(e=> e.fieldNameDB.includes('Period From') || e.fieldNameDB.includes('Period To') );
      if(foundDateCombination.length !== 1){
        stationSiteRefConfiguration = fieldList.filter(e => e.fieldNameDB.includes('Station ID') || e.fieldNameDB.includes('Retailer ID'))
      }
    }

    if(foundUnitCombination.length === 1 ){
      errorCopy = { ...errorCopy, fieldCombinationError: ERRORWORDS.unitCombinationErrorMessage }
    }else if(foundDateCombination.length === 1){
      errorCopy = { ...errorCopy, fieldCombinationError: ERRORWORDS.dateCombinationErrorMessage }
    }else if(stationSiteRefConfiguration.length <= 0)
    {
      errorCopy = { ...errorCopy, fieldCombinationError: ERRORWORDS.stationRetailerErrorMessage }
    }
    else{
      errorCopy = { ...errorCopy, fieldCombinationError: '' }
    }
    setError(errorCopy); 
  }

  const validated = async () =>{
    dispatch(setLoadingFlag(true));
    const requestBody = {
      id: 0,
      moduleName: (moduleName.trim()).charAt(0).toUpperCase() + (moduleName.trim()).slice(1),
      itemName: (itemName.trim()).charAt(0).toUpperCase() + (itemName.trim()).slice(1),
      transactionType : transactionType.trim(),
      prefix: prefix.trim(),
      fieldMapper: fieldList,
      companyAssignment: companyData,
      userId: userData.id,
      supplierId: selectedSupplierId,
      apExport: apExport,
      arExport: arExport,
      nlExport: nlExport,
      exception: exception,
    }
    const res = await addModule(requestBody)

    const rolesRes = res.res
    if (rolesRes){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.MODULECONFIGURATION)
    }
    else{
      dispatch(setLoadingFlag(false))
    }
    setIsValidated(false)
  }
  
  const onDoneClicked = () =>{
    checkValidation()
  }
  
  useEffect(() => { 
    let errorCopy = error

    if (itemName?.trim()  !== '' && IS_ALPHA.test(itemName)) {
      errorCopy={ ...errorCopy, itemName: '' }
    }
    if (companyData?.length > 0){
      errorCopy = { ...errorCopy, companyError: '' }
    }

    setError(errorCopy)
  }, [ itemName ,prefix, companyData ]) 

  useEffect(() => {
    if (moduleName?.trim()  !== '' &&   IS_ALPHA.test(moduleName)) {
      checkModuleAval()
    }
  }, [ moduleValue ])

  useEffect(() => {
    if (prefix?.trim()  !== '' &&   ALPHA_NUM_REGEX.test(prefix)) {
      checkPrefixAval()
    }
  }, [ prefixValue ])

  useEffect(()=>{
    if(isValidated){
      validated();
    }
  }, [ isValidated ])

  useEffect(()=>{
    const inputError  =commonScrollFunction();
    if(!inputError){
      commonScrollFunction('h1','screenError');
    }
  }, [ isDone ])
  
  const updateFieldNameDB = (fieldArr) =>{

    const duplicateDBArr = [];
    fieldArr.forEach((fieldItem, index)=>{
      const fieldNameDBObj = {}
      fieldNameDBObj[ 'fieldName' ] = fieldItem.fieldNameDB;
      fieldNameDBObj[ 'selectedId' ] = null;
      // fieldNameDBObj[ 'selectedId' ] = fieldItem.isFieldRequired ? index : null;
      duplicateDBArr.push(fieldNameDBObj);
    })
    // const lst = fieldArr.filter((e) => e.isFieldRequired);
    // setFieldList(lst);

    setFilterFieldNameDBArr(duplicateDBArr);
  }

  const  onFieldDetailsUpdate  = (e, fieldID, objKey) =>{
    const objIndex = fieldList.findIndex((obj =>  
      obj.id ===  fieldID 
    ));
    if ( objIndex >= 0){
      if (objKey === 'fieldName'){
        // duplicateFieldChecker((e), fieldID, objIndex, objKey )
        fieldList[ objIndex ].fieldName = (e.charAt(0).toUpperCase() + e.slice(1)).trim();
      }
      else if (objKey === 'fieldNameDB'){
        const currentSelectedIndex = filterFieldNameDBArr.findIndex((fieldEvent)=> fieldEvent.fieldName === e.target.value);
        const previousSelectedIndex = filterFieldNameDBArr.findIndex((fieldEvent)=> fieldEvent.fieldName === fieldList[ objIndex ].fieldNameDB);
        
        filterFieldNameDBArr[ previousSelectedIndex ].selectedId = null;
        filterFieldNameDBArr[ currentSelectedIndex ].selectedId = fieldID;
        
        const matchingValue = copyFieldList.filter((event)=> event.fieldNameDB === filterFieldNameDBArr[ currentSelectedIndex ].fieldName)[ 0 ];
        const dataTypeArr = matchingValue?.dataTypeValues;

        fieldList[ objIndex ].dataTypeValues= dataTypeArr;
        fieldList[ objIndex ].fieldNameDB = e.target.value;
        fieldList[ objIndex ].fieldDataType = dataTypeArr[ 0 ].key  ;
        fieldList[ objIndex ].isFieldRequired = matchingValue.isFieldRequired;
        fieldList[ objIndex ].defaultValue = null;
      }
      else if (objKey === 'mandate'){
        fieldList[ objIndex ].mandate = e.target.options.selectedIndex;
      }
      else if (objKey === 'fieldDataType'){
        fieldList[ objIndex ].fieldDataType = e.target.value;
      }
      else if (objKey === 'defaultValue'){
        if(e === ''){

          fieldList[ objIndex ].defaultValue = null;
        }else{

          fieldList[ objIndex ].defaultValue = e
        }
      }
      setFieldList( [ ...fieldList ] );
    }
  }
  useEffect(() => {
    if (locationState){
      const fieldListData = locationState.fieldValues;
      setCompanyArr(locationState.companyList);
      setSupplierDataList(locationState.supplierDataList);
      setSelectedSupplierId(locationState.supplierDataList[ 0 ].id??0);
      // setFieldList(fieldListData);
      setCopyFieldList(JSON.parse(JSON.stringify(fieldListData)));
      updateFieldNameDB(fieldListData??[]);
    }
  }, [])

  const onChangeAPExportValue = (e) => {
    const value = e.target.checked ? 1 : 0
    setAPExport(value)
  }
  const onChangeARExportValue = (e) => {
    const value = e.target.checked ? 1 : 0
    setARExport(value)
  }
  const onChangeNLExportValue = (e) => {
    const value = e.target.checked ? 1 : 0
    setNLExport(value)
  }

  return (
      <div className= {styles.mainContainer} ref={mainRef}>
          <div className={styles.leftContainer}>
              <div className= {styles.innerSideContainer}>
                  <p className={visibleSection ===  'moduleDetails' ? styles.activeText : styles.inactiveText } onClick= {toggleModuleClass} >{ENWORDS.moduleDetails}</p>
                  <p className={visibleSection ===  'fieldDetails'  ? styles.activeText: styles.inactiveText } onClick = {toggleFieldClass}>{ENWORDS.fieldDetails}</p>
              </div>
          </div>
          <div  className={styles.rightContainer}>
              <ListingHeader
                title = {ENWORDS.addNewModule}
                navigationPath = {ROUTES.MODULECONFIGURATION}
              />
              <div className={styles.detailsContainer} id ='moduleDetails' ref = {moduleRef}>
                  <div className = {styles.headerContainer}>
                      <p>{ENWORDS.moduleDetails}</p>  
                      <button onClick={()=>setShowDetails(!showDetails)}>
                          <img src = {showDetails ? arrowUp: arrowDown} alt = 'arrow' className= {styles.downArrow}/>
                      </button>
                  </div>
        
                  <div className={showDetails? styles.detailsTopContainer: styles.detailsTopContainerHidden} >
                      <div className={inputStyles.detailsRow}>

                          <CustomInput
                            title = {ENWORDS.moduleName} 
                            value = {moduleName} 
                            error= {error.moduleName}
                            isFocused = {true}
                            placeholder = {PLACEHOLDER.moduleName}
                            
                            onChange={e=> setModuleName(e)}/>

                          <CustomInput
                            title = {ENWORDS.itemName} 
                            value = {itemName} 
                            error= {error.itemName}
                            placeholder = {PLACEHOLDER.itemName}
                            onChange={e=> setItemName(e)}/>
                          <CustomInput
                            title = {ENWORDS.transactionType} 
                            value = {transactionType} 
                            // error= {error.transactionType}
                            placeholder = {PLACEHOLDER.transactionType}
                            onChange={e=> setTransactionType(e)}/>

                          <CustomInput
                            title = {ENWORDS.prefix} 
                            value = {prefix}
                            error= {error.prefix}
                            maxLength = {4}
                            placeholder = {PLACEHOLDER.prefix}
                            onChange={e=> setPrefix((e).trim())}/>

                          <CustomDropDown
                            title={ENWORDS.supplierDetails}
                            name = 'role'
                            fieldValues={supplierDataList}
                            renderKey = {'id'}
                            renderKeyOne = {'supplierName'}
                            onChange={(e)=> setSelectedSupplierId(Number(e.target.value))}
                          />

                          <MultiSelectDropdown
                            title = {ENWORDS.companies} 
                            fieldValues = {companyArr}
                            error= {error.companyError}
                            onChange={e => setCompanyData(e)}
                          />
                          <CustomDropDown
                            title={ENWORDS.exception}
                            name = 'exception'
                            fieldValues={CONFIG_EXCEPTIONS_TYPES}
                            renderKey = {'id'}
                            selectedValue={exception}
                            renderKeyOne = {'exception'}
                            onChange={(e)=> setException((e.target.value))}
                          />
                          <div className={styles.checkboxDiv}>
                              <div className={styles.inputContainer}>
                                  <input className={styles.checkboxInput} 
                                    type="checkbox" 
                                    id="arExport" 
                                    name="arExport" 
                                    value={arExport}
                                    onChange={onChangeARExportValue}/>
                                  <p className={globalStyles.readOnlyText}>{ENWORDS.arExport}</p>
                              </div>
                              <div className={styles.inputContainer}>
                                  <input className={styles.checkboxInput} 
                                    type="checkbox" 
                                    id="apExport" 
                                    name="apExport" 
                                    value={apExport}
                                    onChange={onChangeAPExportValue}/>
                                  <p className={globalStyles.readOnlyText}>{ENWORDS.apExport}</p>
                              </div>
                              <div className={styles.inputContainer}>
                                  <input className={styles.checkboxInput} 
                                    type="checkbox" 
                                    id="nlExport" 
                                    name="nlExport" 
                                    value={nlExport}
                                    onChange={onChangeNLExportValue}/>
                                  <p className={globalStyles.readOnlyText}>{ENWORDS.nlExport}</p>
                              </div>
                          </div>
                      </div>
                  </div>
                 
              </div> 
              <div className = {styles.divider}></div> 
              <div className={styles.fieldContainer} id='fieldDetails' ref = {fieldRef}>
                  <div className = {styles.headerContainer}>
                      <p>{ENWORDS.fieldDetails} 
                          {error.fieldCombinationError!== ''?
                              <h1 className = {inputStyles.screenError}>{error.fieldCombinationError}</h1>:
                            null
                          }
                      </p>
                      <button onClick={()=>setShowField(!showFields)}>
                          <img src = {showFields ? arrowUp: arrowDown} alt = 'arrow' className= {styles.downArrow}/>
                      </button>
                  </div>
                  
                  <div className = { showFields? styles.innerFieldContainer : styles.innerFieldContainerHidden}>
                      <div className={styles.buttonContainer} onClick={addBlock}>
                          <button className={styles.btnDone} >
                              <img src={plusIcon} alt ={ENWORDS.editPenIcon}/>
                          </button>
                      </div>
                      {
                        fieldList && fieldList.map((fieldItem)=>{
                          return ( 
                              <div>
                                  
                                  <div key = {fieldItem.id} className= {`${ styles.fieldDetailsBlock } ${ inputStyles.detailsRow }`}>

                                      <div className={styles.deleteContainer}>
                                          <button className={styles.btnCancel} type="button" onClick = {()=> deleteFieldByid(fieldItem)}>
                                              <img src={binIcon} alt = {ENWORDS.deleteIcon}/>
                                          </button>
                                      </div>
                                      
                                      <div className= {styles.innerBlock}>
                                          <CustomInput
                                            title = {ENWORDS.fieldName} 
                                            error = { displayError(fieldError, fieldItem.id, 'fieldNameError') }
                                            value  = {fieldItem.fieldName}
                                            placeholder = {PLACEHOLDER.fieldName}
                                            onChange={(e)=> onFieldDetailsUpdate(e,fieldItem.id, 'fieldName')}
                                          />

                                          <CustomDropDown 
                                            title={ENWORDS.fieldNameDB}
                                            selectedValue = {fieldItem.fieldNameDB}
                                            fieldValues={ filterFieldNameDBArr.filter(e=> e.selectedId === fieldItem.id  || e.selectedId === null  )?? []}
                                            name = {'fieldname'}
                                            renderKey = {'fieldName'}
                                            renderKeyOne= {'fieldName'}
                                            // isDisabled = {!fieldItem.canUpdate}
                                            onChange={(e)=> onFieldDetailsUpdate(e,fieldItem.id, 'fieldNameDB')}
                                          />

                                          <CustomDropDown
                                            title={ENWORDS.fieldDatatype}
                                            selectedValue= { fieldItem.fieldDataType }
                                            fieldValues={ fieldItem.dataTypeValues ?? []}
                                            renderKey = {'key'}
                                            renderKeyOne= {'value'}
                                            // isDisabled = {!fieldItem.canUpdate}
                                            onChange={(e)=> onFieldDetailsUpdate(e,fieldItem.id, 'fieldDataType')}
                                          />
                                          <CustomDropDown
                                            title={ENWORDS.mandate}
                                            name = 'mandate'
                                            selectedValue={ fieldItem.mandate === 1 ? 'yes': 'no'}
                                            fieldValues={MANDATE}
                                            // isDisabled = {!fieldItem.canUpdate}
                                            onChange={(e)=> onFieldDetailsUpdate(e,fieldItem.id, 'mandate')}
                                          />
                                          <CustomInput
                                            title={ENWORDS.defaultValueTitle}
                                            value  = {fieldItem.defaultValue}
                                            type = { (fieldItem.fieldDataType).toLowerCase() === 'string'? 'text': (fieldItem.fieldDataType).toLowerCase() === 'date'? 'date' : 'number'}
                                            placeholder = {PLACEHOLDER.defaultValue}
                                            onChange={(e)=> onFieldDetailsUpdate(e,fieldItem.id, 'defaultValue')}
                                          />
                                      </div>

                                  </div>
                               
                              </div> 
                          )

                        })
                      }
                  </div>

              </div>  
              <div className={styles.buttonContainer}>
                  <button className={styles.btnCancel} onClick= {()=>navigate(-1)}>{ENWORDS.cancel}</button>
                  <button className={styles.btnDone} onClick ={onDoneClicked} >{ENWORDS.done}</button>
              </div>
                    
          </div>
      </div>
  )
}

export default AddModuleConfiguration