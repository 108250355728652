import React from 'react';
import styles from '../../styles/inputs.module.css'
import globalStyles from '../../styles/global.module.css';

const InvoiceDropdown = ({ title, name,selectedValue,
  onChange, fieldValues, isDisabled,
  idKey,renderKey,
}) => {
  const changeDropDown = (e) =>{
    if(onChange){
      onChange(e);
    }
  }

  return (
      <div className={globalStyles.roleContainer}>
          <p className={globalStyles.filterText}>{title}</p>
          <div className={styles.invoiceSelectionBox}>
              <select name= {name} id= {name} onChange={changeDropDown} value  = {selectedValue} disabled = {isDisabled} >
                  {
                    fieldValues.length === 0?
                        <option hidden>{'Data not found'}</option>
                      :null
                  }
                  {fieldValues.map((item,key)=>{

                    return <option 
                      datakey = {item[ idKey ]}  
                      key={item[ idKey ]} 
                      value={item[ idKey ]}>
                        {item[ renderKey ]} 
                    </option>
                  })}  
              </select>
          </div>
      </div> 

  )
}
export default InvoiceDropdown