import React,{ useState, useEffect }  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import CustomInput from '../../../../shared/Components/CustomInput';
import ERRORWORDS from '../../../../shared/local/error.json';
import { updateSupplier } from '../../../../api/services/supplierManagementServices';
import { useDispatch } from 'react-redux';
import { setLoadError, setLoadingFlag } from '../../../../redux/actions';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import { ALPHA_NUM_REGEX, IS_ALPHA } from '../../../../shared/constants/appConstants';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { clamp, commonScrollFunction } from '../../../../shared/commonFunctions';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json')

const  EditSupplierDetails = () => {
  const location =  useLocation();
  const locationState = location.state;
  const dispatch =  useDispatch();
  const navigate =  useNavigate()
  const [ supplierId, setSupplierId ] = useState(0);
  const [ supplierDetails, setSupplierDetails ] = useState();
  const [ supplierName, setSupplierName ] = useState('');
  const[ supplierSiteCode, setSupplierSiteCode ] = useState('');
  const [ commission, setCommission ] = useState(0);
  const [ ledgerAccNo, setLedgerAccNo ] = useState(''); 
  const [ termsDate, setTermsDate ] = useState('');   
  const [ error, setError ] = useState({});
  const [ isDone, setIsDone ] = useState(false);
  const setDetails =  () => {
    setSupplierId(supplierDetails.id)
    setSupplierName(supplierDetails.supplierName)
    setSupplierSiteCode(supplierDetails.supplierCode)
    setCommission(String(supplierDetails.supplierCommision))
    setTermsDate(supplierDetails.termsDate) 
    setLedgerAccNo(supplierDetails.ledgerAccNo); 
  }

  const validated = async () =>{
    dispatch(setLoadingFlag(true))
    const res = await updateSupplier(
      { supplierId, 
        supplierName, 
        supplierSiteCode, 
        termsDate, 
        commission,
        ledgerAccNo,
      })

    const successRes = res.res
    const errRes = res.err
    if (successRes){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.SUPPLIERMANAGEMENT)
    }
    else{
      dispatch(setLoadingFlag(false))
      dispatch(setLoadError ({ statusCode: errRes.response.status, errorStatus: true, errorMessage: errRes.response.data.message }))
    }
  }
  const checkValidation = () => {
    let errorVariable = error
    if (!IS_ALPHA.test(supplierName?.trim())){
      errorVariable= { ...errorVariable, supplierNameError: ERRORWORDS.supplierNameError }
    }
    if(!ALPHA_NUM_REGEX.test(supplierSiteCode?.trim())){
      errorVariable = { ...errorVariable, supplierSiteCodeError: ERRORWORDS.supplierSiteCodeError }
    }
    if(!ALPHA_NUM_REGEX.test(ledgerAccNo?.trim())){
      errorVariable = { ...errorVariable, ledgerAccNoError: ERRORWORDS.ledgerAccountError }
    }
    if (parseFloat(commission) < 0 || parseFloat(commission) > 100 || commission === ''){
      errorVariable = { ...errorVariable, supplierCommission: ERRORWORDS.supplierCommission }
    }
    if (parseInt(termsDate) <= 0 || parseInt(termsDate) > 365 || termsDate === ''){
      errorVariable = { ...errorVariable, termDateError: ERRORWORDS.termDateError }
    }
    const isErrorEmpty = Object.keys(errorVariable).filter((item)=>{
      return errorVariable[ item ] !== ''
    })
    
    if (isErrorEmpty.length === 0 ){
      validated()
    }
    setError(errorVariable);
    setIsDone(!isDone);
  }
  const onDoneClicked  = () =>{
    checkValidation()
  }
  const toSupplierListing = () =>{
    navigate(ROUTES.SUPPLIERMANAGEMENT)
  }

  useEffect(()=>{
    if (supplierDetails){
      setDetails()
    }
  }, [ supplierDetails ])
  
  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])
  useEffect(()=>{
    let errorVariable = error
    if (supplierName?.trim() !== '' && IS_ALPHA.test(supplierName?.trim())){
      errorVariable= { ...errorVariable, supplierNameError: '' }
    }
    if(supplierSiteCode?.trim() !== '' && ALPHA_NUM_REGEX.test(supplierSiteCode?.trim())){
      errorVariable = { ...errorVariable, supplierSiteCodeError: '' }
    }

    if(parseFloat(commission) >= 0 && parseFloat(commission) <= 100){
      errorVariable = { ...errorVariable, supplierCommission: '' }
    }
    if(parseInt(termsDate) >= 1 && parseInt(termsDate) <= 365 && termsDate !== ''){
      errorVariable = { ...errorVariable, termDateError: '' }
    }
    setError(errorVariable)
    
  },[ supplierSiteCode, commission , termsDate, supplierName ])

  useEffect(() => {
    if (locationState){
      setSupplierDetails( locationState?.data)
    }
  }, [])
  
  return (
      <div className={supplierStyles.mainContainer}>
          <div className={supplierStyles.subContainer}>
              <ListingHeader
                title = {ENWORDS.editSupplier}
                navigationPath = {-1}
              />           
              <div className={supplierStyles.contentContainer}>
                  <div className={supplierStyles.content}>
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.supplierDetails}</p>                           
                          </div>
                      </div>
                      <div className={styles.supplierForm}>
                          <div className={inputStyles.detailsRow}>

                              <CustomInput 
                                title = {ENWORDS.supplierName} 
                                value = {supplierName}
                                placeholder = {PLACEHOLDER.fullName}
                                error = {error.supplierNameError}
                                onChange = {e => setSupplierName(e)}/>

                              <CustomInput 
                                title = {ENWORDS.supplierSiteCode} 
                                value = {supplierSiteCode}
                                placeholder = {PLACEHOLDER.siteCode}
                                error= {error.supplierSiteCodeError} 
                                onChange = {e=> setSupplierSiteCode(e)}/>

                              <CustomInput 
                                title={ ENWORDS.termsDate }
                                value = {termsDate}
                                placeholder = '15'
                                maxLength = {3}
                                error = {error.termDateError}
                                onChange = { (e) => setTermsDate(Number(parseInt(e)) > 0 ? clamp(parseInt(e), 1, 365 ) : '' )}
                              />

                              <CustomInput 
                                title = {ENWORDS.commissionPercentage} 
                                value = {commission}
                                placeholder = {PLACEHOLDER.percent}
                                maxLength = {4}
                                error= {error.supplierCommission} 
                                onChange = {e=> setCommission(Number(e) >= 0 ? clamp(e,0,100)  : '')}/>
                          </div>
                      </div> 
                      <div className={styles.btnContainer}>
                          <div className={styles.cancelBtn}>
                              <button onClick={toSupplierListing } >{ENWORDS.cancel}</button>
                          </div>
                          <div className={styles.doneBtn}>
                              <button onClick={onDoneClicked}>{ENWORDS.done}</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    
  )
}

export default EditSupplierDetails