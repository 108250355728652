import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DAILYLOGDETAILSHEADERS, DETAILED_LOGS_EDITABLE, DETAILED_LOG_ID } from '../adtMonitoringConstant';
import CustomSearchBox from '../../../shared/Components/CustomSearchBox';
import globalPopUpStyles from '../../../styles/popUp.module.css';
import style from '../../../styles/adtConfiguration/ConfigurationScreen.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { commitDailyLogsById, getDetailedLogsById, revertCommit, updateDetailedLog } from '../../../api/services/adtDailyLogService';
import { setError, setLoadingFlag } from '../../../redux/actions';
import CustomTable from '../../../shared/Components/CustomTable';
import { ROUTES } from '../../../shared/constants/appRoute';
import CustomPopup from '../../../shared/Components/CustomPopup';
import ListingHeader from '../../../shared/Components/ListingHeader';
import SuccessPopUp from '../../../shared/Components/SuccessPopUp';
const ENWORDS = require('../../../shared/local/en.json');

const DailyLogsDetailsScreen = (props) => {
  const location  = useLocation();
  const locationState = location.state;
  const adtLogsDetailsData = useSelector(
    state => state.dailyLogsReducer.adtDailyLogsDetailsTable
  );
  const [ filterData, setFilterData ] = useState(adtLogsDetailsData);
  const [ searchText, setSearchText ] = useState('');
  const [ enableEdit, setEnableEdit ] = useState(false)
  const [ showPopup, setShowPopup ] = useState(false)
  const [ showRevertPopup, setShowRevertPopup ] = useState(false)
  const [ successMessage, setSuccessMessage ] = useState();
  const userData = useSelector(state => state.auth?.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDetailedLogData = ()=>{
    dispatch(setLoadingFlag(true));
    if (locationState){
      setEnableEdit(locationState?.isEdit)
      dispatch(getDetailedLogsById(locationState?.data?.logUniqueId));
    }
  }

  const filteration = () => {
   
    let logsFilterData = adtLogsDetailsData;
    if (searchText.length > 0) {
      logsFilterData = logsFilterData.filter(item => {
        const siteId = item[ 'siteId' ]?.toLowerCase();
        const siteName = item[ 'siteName' ]?.toLowerCase();
        const statuses = item[ 'status' ]?.toLowerCase();
        const remark = item[ 'remark' ]?.toLowerCase();
        return (
          siteId?.includes(searchText.toLowerCase()) ||
          siteName?.includes(searchText.toLowerCase()) ||
          remark?.includes(searchText.toLowerCase()) ||
          statuses?.includes(searchText.toLowerCase())
        );
      });
    }
    return logsFilterData;
  };

  const updateRecord =async (row)=>{
    dispatch(setLoadingFlag(true))
    row.status = row.status === '' ? null : row.status;
    const res = await updateDetailedLog(row)
    if(res.res === true){
      getDetailedLogData()
    }
  }

  const commitChanges = async () =>{
    dispatch(setLoadingFlag(true));
    const res = await commitDailyLogsById(locationState?.data?.logUniqueId)
    dispatch(setLoadingFlag(false));
    setShowPopup(false)
    if(res.res){
      navigate(ROUTES.ADTDAILYLOGS, { state: locationState });
    }
  }

  const onRevertCommit = async () => {
    dispatch(setLoadingFlag(true));
    const res = await revertCommit(locationState?.data?.logUniqueId);
    dispatch(setLoadingFlag(false));
    if (res.res) {
      setSuccessMessage(ENWORDS.revertSuccessful);
    } else {
      dispatch(setError({ statusCode: res.err?.response?.status, errorStatus: true, errorMessage: res.err?.response?.data }))
    }
    setShowRevertPopup(false)
  };
  useEffect(() => {
    setFilterData(filteration());
  }, [ searchText ]);

  useEffect(() => {
    getDetailedLogData()
  }, [ locationState ]);

  useEffect(() => {
    setFilterData(adtLogsDetailsData);
  }, [ adtLogsDetailsData ]);

  return (
      <div>
          <CustomPopup
            showPopUp={showPopup}
            onConfirm={commitChanges}
            title={ENWORDS.commitChangesTitle}
            onReject={()=>{ setShowPopup(false)}}
          >
              <p>{ENWORDS.commitChangeText}</p>
          </CustomPopup>
          <CustomPopup
            showPopUp={showRevertPopup}
            onConfirm={onRevertCommit}
            title={ENWORDS.revertCommitTitle}
            onReject={()=>{setShowRevertPopup(false)}}
          >
              <p>{ENWORDS.revertCommitText}</p>
          </CustomPopup> 
          <SuccessPopUp
            header = {ENWORDS.revertCommit}
            title = {successMessage}
            show = {successMessage}
            onClose={()=>{ 
              setSuccessMessage(undefined)                     
              dispatch(setLoadingFlag(true));
              navigate(ROUTES.ADTDAILYLOGS, { state: locationState });
            }}
          />
          <div className={style.topContainer}>
              <div className={style.subContainer} style={{ paddingTop: '2rem' }}>
                  <ListingHeader
                    title = {ENWORDS.dailyLogDetails}
                    navigationPath = {ROUTES.ADTDAILYLOGS}
                    state={locationState}
                  />
                  <div className={style.functionBtnItemContainer}>
                      <div className={style.searchStatusDiv}>
                          <div className={style.searchBoxContainer}>
                              <CustomSearchBox
                                placeholder={ENWORDS.stationPlaceholder}
                                title={ENWORDS.search}
                                onChange={e => setSearchText(e.target.value.trimStart())}
                              />
                          </div>

                          <div
                            className={style.statusFieldContainer}
                          >
                              <p>{ENWORDS.status}</p>
                              <div style={{ color: 'black' }}>{locationState?.data?.status?.toUpperCase()}</div>
                          </div>
                      </div>
                      <div className={style.buttonContainer}>
                          { enableEdit && locationState?.data?.status && locationState?.data?.status === 'Open' &&
                              <button
                                className={globalPopUpStyles.largeBtnContainer}
                                onClick={() => setShowPopup(true)}
                              >
                                  {ENWORDS.commitChange}
                              </button>
                          }

                          {locationState?.data?.status && locationState?.data?.status === 'Committed' && userData?.isExtraPrivileged === 1 &&
                          <button
                            className={globalPopUpStyles.largeBtnContainer}
                            onClick={() => setShowRevertPopup(true)}
                          >
                              {ENWORDS.revertCommit}
                          </button>
                          }
                      </div>
                  </div>
              </div>
          </div>

          <div style={{ margin: '0 2rem' }}>
              <CustomTable 
                data = {filterData} 
                headerData = {DAILYLOGDETAILSHEADERS} 
                tableName = {ENWORDS.dailyLogDetails}

                onClickKey={DETAILED_LOG_ID}
                editableColumns={ enableEdit? DETAILED_LOGS_EDITABLE : [] }
                onSaveClick={updateRecord}
              />
          </div>
      </div>
  );
};

export default DailyLogsDetailsScreen;
