import Cookies from 'universal-cookie';
import { setLoadingFlag } from '../../redux/actions';
import { setDailyLogDetailsTable, setDailyLogMailData, setDailyLogsTable, setExcludedContractNumbers } from '../../redux/actions/dailyLogs';
import { APITIMEOUT, API_HEADER, BASE_URL, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { ADTMONITORINGURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
import axios from 'axios';
import { convertTimetoMinutes } from '../../shared/commonFunctions';
import moment from 'moment';
import { BASE_TIME } from '../../modules/StationListing/stationListingConstant';

export const uploadDailyLogs = async (formdata)=>{
  const TOKEN = new Cookies().get('token');
  let uploadRes;
  let returnVal = false;
  const headersList = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text/plain',
    'Authorization': 'Bearer '+ TOKEN,
  }

  const reqOptions = {
    timeout:APITIMEOUT,
    url:`${ BASE_URL }${ ADTMONITORINGURL.uploadExcel }`,
    method: POST,
    headers: headersList,
    data: formdata ,
  }

  await axios.request(reqOptions)
    .then(function (response) {
      uploadRes = response
      returnVal =  true
    })
    .catch(function(err){
      const mainErrorRes = err.response;
      if(!mainErrorRes.data.data){
        uploadRes = mainErrorRes.data
      }
      else if (mainErrorRes.data.data === ''){
        uploadRes = mainErrorRes.data.message
      }
      else{

        if (typeof(JSON.parse(mainErrorRes.data.data)) ==='object'){
          uploadRes = JSON.parse(mainErrorRes.data.data ? mainErrorRes.data.data: mainErrorRes.data)
        }
        else if (typeof(mainErrorRes.data.message)=== 'string'){
          uploadRes = mainErrorRes.data.message
        }
      }
      returnVal =  false
    });
  return { res: uploadRes, err:returnVal }
}

export function getDailyLogData( date ) {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: ADTMONITORINGURL.getDailyLogs + date ,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setDailyLogsTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export const generateLogs = async (formdata)=>{
  const TOKEN = new Cookies().get('token');
  let uploadRes;
  const headersList = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text/plain',
    'Authorization': 'Bearer '+ TOKEN,
  }

  const reqOptions = {
    timeout:APITIMEOUT,
    url:`${ BASE_URL }${ ADTMONITORINGURL.generateLogs }`,
    method: POST,
    headers: headersList,
    data: formdata ,
  }

  await axios.request(reqOptions)
    .then(function (response) {
      uploadRes = response
    })
    .catch(function(err){
      console.log(err)
    });
  return uploadRes
}

export async function updateDailyLog(data) {
  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url: ADTMONITORINGURL.updateDailyLog,
    data: data,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export function getDetailedLogsById( uniqueId ) {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: ADTMONITORINGURL.getDetailedLog + uniqueId ,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setDailyLogDetailsTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function updateDetailedLog({ detailsLogId, logUniqueId, status, remark, actualClosingTime, actualOpeningTime, openingDate, closingDate }) {
  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url: ADTMONITORINGURL.updateDetailedLog,
    data: {
      detailsLogId : detailsLogId,
      logUniqueId : logUniqueId,
      status : status,
      remark : remark,
      actualOpeningTime : actualOpeningTime === '' ? 0 : convertTimetoMinutes(moment(BASE_TIME).add(actualOpeningTime, 'minutes').toDate()),
      actualClosingTime: actualClosingTime === '' ? 0 : convertTimetoMinutes(moment(BASE_TIME).add(actualClosingTime, 'minutes').toDate()),
      openingDate : openingDate,
      closingDate : closingDate,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function getDistinctYears() {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: ADTMONITORINGURL.getYearsForLogs,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function(response){
      res = response.data.data
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function commitDailyLogsById( logId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: ADTMONITORINGURL.commitLogs + logId ,
    data: undefined ,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export function getDailyLogEmail() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: ADTMONITORINGURL.getAdtEmail,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setDailyLogMailData(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function addDailyLogsAdtMail(data) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: ADTMONITORINGURL.addAdtEmail,
    data: data ,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export function deleteMailOnPopUpById( id ) { 
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ ADTMONITORINGURL.deleteAdtEmail + id }`,
      data: undefined,
      headers: API_HEADER,
    })
      .then(()=>{
        dispatch(getDailyLogEmail())
        dispatch(setLoadingFlag(false))
      }
      )
  }
}

export async function sendLogsAdtMail(logUniqueId, data) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: `${ ADTMONITORINGURL.sendLogsEmails + logUniqueId }`,
    data: data,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = true;
    })
    .catch(function (err) {
      res = false;
      errRes = err;
    });
  return { res: res, err: errRes };
}

export async function revertCommit(logUniqueId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: `${ ADTMONITORINGURL.revertCommit + logUniqueId }`,
    data : undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = true;
    })
    .catch(function (err) {
      res = false;
      errRes = err;
    });
  return { res: res, err: errRes };
}

export const uploadExcludedContractNumbersExcel = async (formdata)=>{
  const TOKEN = new Cookies().get('token');
  let uploadRes;
  let returnVal = false;
  const headersList = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text/plain',
    'Authorization': 'Bearer '+ TOKEN,
  }
  const reqOptions = {
    timeout:APITIMEOUT,
    url:`${ BASE_URL }${ ADTMONITORINGURL.uploadExcludedContractNumbersExcel }`,
    method: POST,
    headers: headersList,
    data: formdata ,
  }

  await axios.request(reqOptions)
    .then(function (response) {
      uploadRes = response
      returnVal =  true
    })
    .catch(function(err){
      const mainErrorRes = err.response;
      if(!mainErrorRes.data.data){
        uploadRes = mainErrorRes.data
      }
      else if (mainErrorRes.data.data === ''){
        uploadRes = mainErrorRes.data.message
      }
      else{

        if (typeof(JSON.parse(mainErrorRes.data.data)) ==='object'){
          uploadRes = JSON.parse(mainErrorRes.data.data ? mainErrorRes.data.data: mainErrorRes.data)
        }
        else if (typeof(mainErrorRes.data.message)=== 'string'){
          uploadRes = mainErrorRes.data.message
        }
      }
      returnVal =  false
    });
  return { res: uploadRes, err:returnVal }
}

export function getExcludedContractNumbers() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: ADTMONITORINGURL.getExcludedContractNumbers,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setExcludedContractNumbers(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}
