import { UPDATE_INVOICE_DATA, INVOICE_FILTER, INVOICE_PAGE_NUMBER } from '../../shared/constants/actionType';

export const updateInvoice = (data)=>{
  return{
    type: UPDATE_INVOICE_DATA,
    payload: data,
  }
}
export const updateInvoiceFilter = (data)=>{
  return{
    type: INVOICE_FILTER,
    payload: data,
  }
}

export const updateInvoicePageNumber = (data)=>{
  return{
    type: INVOICE_PAGE_NUMBER,
    payload: data,
  }
}
