import  Cookies  from 'universal-cookie';
import jwtAxios from '../../api/auth/jswtAPi';
import { SET_AUTH_TOKEN, SIGNOUT_AUTH_SUCCESS, UPDATE_AUTH_USER } from '../../shared/constants/actionType';
import { fetchError, fetchStart, fetchSuccess, setIsLoggedOut, setLoadError } from './common';
import { datePlus1day, GET } from '../../shared/constants/appConstants';
import { AUTHURL } from '../../shared/constants/appUrls';
import { AUTHROUTES } from '../../shared/constants/appRoute';
import { setLoadingFlag } from './common';
import { makeRequest } from '../../api/makeRequest';
import { isoDateToMillisecond } from '../../shared/commonFunctions';

export const loadJWTUser = (token) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      dispatch(fetchSuccess());
      let currentUserRes = {};
      await makeRequest({
        method: GET ,
        url:AUTHURL.getCurrentUser,

        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      })
        .then(function (response) {
          currentUserRes = response.data.data
        })  
      const fullName = currentUserRes.firstName + ' ' + currentUserRes.lastName;
      const body  = {
        firstName: currentUserRes.firstName,
        lastName: currentUserRes.lastName,
        displayName: fullName,
        email: currentUserRes.email,
        role: currentUserRes.roleName,
        profilePicture: currentUserRes.profilePicture,
        id: currentUserRes.id,
        roleId: currentUserRes.roleId,
        token: token,
        roleAccess: currentUserRes.roleAccess,
        isExtraPrivileged: currentUserRes.isExtraPrivileged,
      }
      
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: body,
      });

    } catch (err) {
      
      dispatch({
        type: UPDATE_AUTH_USER,
        payload:null })
      dispatch(fetchError(err.response?.message));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onRequestOTP = ({ email, password, rememberMe, navigate }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, password };
    try {
      const res = await jwtAxios.post(AUTHURL.requestOtp, body);
      if(res.data.statusCode === 200){

        if (res.data.data.firstTimeLogin){
          navigate(AUTHROUTES.CHANGEPASSWORDSCREEN, { state: { email, password, firstTimeLogin: true } });
        }
        else{
          navigate(AUTHROUTES.OTPSCREEN, { state: { email, password, rememberMe } });
        }
        dispatch(setLoadingFlag(false))

      }
      dispatch(fetchSuccess());
    } catch (err) {
      dispatch(fetchError(err.response?.data.message));
      dispatch(setLoadingFlag(false))
    }
  };
};

export const onResendOTP = ({ email, password }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, password };
    try {
      await jwtAxios.post(AUTHURL.requestOtp, body);
      dispatch(fetchSuccess());
      dispatch(setLoadingFlag(false))
    } catch (err) {
      dispatch(fetchError(err.response?.data.message));
      dispatch(setLoadingFlag(false))
    }
  };
};

export const onJwtSignIn = ({ email, password, rememberMe, otp }) => {
  return async (dispatch) => {
    dispatch(fetchStart());

    const body = { email, password,rememberMe, otp };
    
    try {
      const res = await jwtAxios.post(AUTHURL.login, body);
      const expireTime = isoDateToMillisecond(res.data.data.expireTime);
      const cookies = new Cookies();
      cookies.set('token', res.data.data?.token, { expires:  new Date(expireTime) });
      cookies.set('refreshToken', res.data?.data.refreshToken , { expires:  new Date(expireTime + datePlus1day) });
      dispatch(setJWTToken(res.data.data?.token));
      dispatch(loadJWTUser(res.data.data?.token));
      dispatch(setLoadingFlag(false))
    } catch (err) {
      if(err.response?.data.message){
        //send to login page
        dispatch(fetchError(err.response?.data.message));
        dispatch(setLoadingFlag(false))
      }
      dispatch(setLoadingFlag(false))
    }
  };
};

export const resetToken = () =>{
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const cookies = new Cookies();
      dispatch(loadJWTUser(cookies.get('refreshToken') ));
      dispatch(setLoadingFlag(false))
    } catch (err) {
      if(err.response?.data.message){
        //send to login page
        dispatch(fetchError(err.response?.data.message));
      }
      dispatch(setLoadingFlag(false))
    }
  };
}

export const refreshTokenApiCall = () =>{
  return async (dispatch) => {
    const cookies = new Cookies();
    dispatch(setLoadingFlag(true))

    const body = { 'token': cookies.get('token'), 'refreshToken':cookies.get('refreshToken') }
    try { 
      const res = await jwtAxios.post(AUTHURL.refreshToken, body);
      if (res.status){
        const expireTime = isoDateToMillisecond(res.data.data.expireTime);
        dispatch(setLoadingFlag(false))
        cookies.set('token', res.data.data?.token, { expires:  new Date(expireTime) });
        cookies.set('refreshToken', res.data?.data.refreshToken, { expires:  new Date(expireTime + datePlus1day) });
        window.location.reload();
      }
    } catch (err) {
      dispatch(setLoadingFlag(false));
      dispatch(setIsLoggedOut(true))
    }
  };
}

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    const cookies = new Cookies();
    cookies.remove('token');
    dispatch({ type: SIGNOUT_AUTH_SUCCESS });
    dispatch(setIsLoggedOut(false))
  };
};

export const onForgotPasswordOTP = ({ email,  navigate }) => {

  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email };
    try {
      const res = await jwtAxios.post(AUTHURL.forgotPassword, body);
      if(res.data.statusCode === 200){
        dispatch(setLoadingFlag(false))
        navigate(AUTHROUTES.CHANGEPASSWORDSCREEN, { state: { email } });
      }
      dispatch(fetchSuccess());
    } catch (err) {
      dispatch(fetchError(err.response?.data.message));
      dispatch(setLoadingFlag(false));
    }
  };
};

export const onChangePassword = ({ email, otp, password, confirmPassword, isFirstTime,navigate }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, otp, password, confirmPassword };
    try {
      let res;
      if (isFirstTime){
        res = await jwtAxios.post(AUTHURL.resetFirstTimePassword, body);
      }
      else{
        res = await jwtAxios.post(AUTHURL.verifyForgotPassword, body);
      }

      if(res.data.statusCode === 200){
        dispatch(setLoadingFlag(false))
        if(res.data.message !== 'Invalid OTP'){
          navigate(AUTHROUTES.LOGINSCREEN);
        }
        else{
          dispatch(fetchError(res?.data.message));
        }
      }
      dispatch(fetchSuccess());
    } catch (err) {
      if(err.response?.data.message?.includes('Email is not verified')){
        dispatch(setLoadingFlag(false))
      }
      dispatch(fetchError(err.response?.data.message));
      dispatch(setLoadingFlag(false))
    }
  };
};

export const setError = (errorObj) =>{
  return async (dispatch) => {
    
    if (errorObj){
      dispatch(setLoadError(errorObj))
    }
  };
}

export const setLoading = (loadingStatus) =>{
  return async (dispatch) => {
    dispatch(setLoadingFlag(loadingStatus))
  };
}

export const setSessionExpire = () =>{
  return async (dispatch) => {
    dispatch(setIsLoggedOut(true))
  };
}