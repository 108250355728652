import React, { useState } from 'react'
import styles from '../../styles/Login/Login.module.css';
import eyeClosed  from '../../assets/icons/passwordEyeCloseLight.svg';
import eyeOpen  from '../../assets/icons/show.svg';
const ENWORDS = require('../local/en.json')

const LoginCustomInput = ({ 
  title, isFocused, fieldType  = 'text',
  onChange, value, maxLength = 500,
  placeholder = '',
  error, type }) => {
  const [ isPasswordVisibile, setPasswordText ]  = useState(true);
  const togglePassword = () => {
    setPasswordText(!isPasswordVisibile)
  } 
  
  return (
      <div className={styles.inputContainer}>
          <label className={styles.inputLabel}>{title}</label>
          { fieldType === 'password' ? 
              <div className={error ? `${ styles.mandatoryFieldBorder } ${ styles.flexProprty }`: `${ styles.inputField } ${ styles.flexProprty }`}>
                  <input 
                    className={styles.innerInputField} 
                    type= {isPasswordVisibile? 'password': 'text'}  
                    autoComplete="off"
                    placeholder={placeholder}
                    value={value} onChange={onChange}  />

                  <img onClick={togglePassword} src={ isPasswordVisibile? eyeOpen: eyeClosed } 
                    alt={ENWORDS.passwordIcon} className={styles.passwordImage} />
              </div>
            :  
              <div className={ error ? styles.mandatoryFieldBorder: styles.inputField} >
                  <input  className={styles.innerInputField} 
                    maxLength={maxLength} 
                    onChange={onChange} 
                    value = {value}  
                    type= {type} 
                    placeholder={placeholder}
                    autoComplete="off"
                    autoFocus = {isFocused} />
              </div> 
          }
          <style>
              {`
              ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba(255, 255, 255, 0.801);
                font-size: 0.8rem
                opacity: 1; /* Firefox */
              }
              
              :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba(255, 255, 255, 0.801);
              }
              
              ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba(255, 255, 255, 0.801);
              }`
              }
          </style>
      </div> 
  )
}

export default LoginCustomInput