import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styles from '../../../styles/Login/Login.module.css';
import LoginScreenBackground from '../Login/LoginScreenBackground';
import { AUTHROUTES } from '../../../shared/constants/appRoute.js';
import { useDispatch, useSelector } from 'react-redux';
import { onChangePassword, onForgotPasswordOTP } from '../../../redux/actions/auth';
import { fetchError, setLoadingFlag } from '../../../redux/actions/common';
import { OTP_REGEX, PASSWORD_REGEX } from '../../../shared/constants/appConstants.js';
import LoginCustomInput from '../../../shared/Components/LoginCustomInput';
const ENWORDS = require('../../../shared/local/en.json')
const ERRORWORDS = require('../../../shared/local/error.json')
const PLACEHOLDER = require('../../../shared/local/placeholder.json')

function ChangePassword() {
  const{ state } = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const serverError = useSelector(value => value.auth.fetchError)
  const[ newPassword, setNewPassword ] = useState('');
  const[ confirmPassword, setConfirmPassword ] = useState('');
  const[ verificationCode, setVerificationCode ] = useState('');
  const[ error, setError ] = useState({}); 

  const onSubmitClicked = async (e) => {
    e.preventDefault();
    let errorCopy = error
    if (
      !OTP_REGEX.test(verificationCode) &&
      !PASSWORD_REGEX.test(newPassword) && 
      !PASSWORD_REGEX.test(confirmPassword)
    ){
      errorCopy={ ...errorCopy, error: ERRORWORDS.invalidFields,
        isVerificationCodeError: true,
        isConfirmauthPasswordError: true,
        isNewauthPasswordError: true }
    }
    else{
      
      if (!OTP_REGEX.test(verificationCode)) {
        errorCopy={ ...errorCopy, error: ERRORWORDS.otpError  ,isVerificationCodeError: true }
      }
      if(!PASSWORD_REGEX.test(newPassword)){
        errorCopy={ ...errorCopy, error: ERRORWORDS.authPasswordError  ,isNewauthPasswordError: true }
      }
      if (!PASSWORD_REGEX.test(confirmPassword)){
        errorCopy={ ...errorCopy, error: ERRORWORDS.authPasswordError  ,isConfirmauthPasswordError: true }
      }
      if(newPassword !== confirmPassword ){
        errorCopy={ ...errorCopy, error: ERRORWORDS.passwordDoNotMatchError  ,isConfirmauthPasswordError: true }
      }
      if (
        OTP_REGEX.test(verificationCode) &&
        newPassword === confirmPassword &&
        PASSWORD_REGEX.test(newPassword) &&
        PASSWORD_REGEX.test(confirmPassword)
      ){
        dispatch(setLoadingFlag(true))
        dispatch(onChangePassword({ email: state.email, password: newPassword, confirmPassword, otp: verificationCode, isFirstTime: state.firstTimeLogin, navigate }));
      }
    }
    setError(errorCopy)
	  
  }

  const resendOTP = async (e) => {
    dispatch(setLoadingFlag(true))
    dispatch(onForgotPasswordOTP({ email: state.email, navigate }))
  }

  const navigateToLogin = () =>{
    dispatch(fetchError(''))
    navigate(AUTHROUTES.LOGINSCREEN)
  }
  const clearForm = () =>{
    setNewPassword('')
    setConfirmPassword('')
    setVerificationCode('')
  }
  useEffect(()=>{
    let errorCopy = error;
    if (OTP_REGEX.test(verificationCode) && verificationCode.length === 4) {
      errorCopy={ ...errorCopy, isVerificationCodeError: false }
    }

    if(PASSWORD_REGEX.test(newPassword)){
      errorCopy={ ...errorCopy, isNewauthPasswordError: false }
    }

    if (PASSWORD_REGEX.test(confirmPassword)){
      errorCopy={ ...errorCopy, isConfirmauthPasswordError: false }
    }
    if(OTP_REGEX.test(verificationCode) && verificationCode.length === 4 && PASSWORD_REGEX.test(newPassword) &&  PASSWORD_REGEX.test(confirmPassword)){
      errorCopy = { ...errorCopy , error:'' }
      dispatch(fetchError(''))
    }
    setError(errorCopy)
  },[ verificationCode, newPassword, confirmPassword ])

  useEffect(()=>{
    dispatch(fetchError(''))
  },[])

  useEffect(()=>{
    if(serverError){
      setLoadingFlag(false)
    }
    setError({ error:serverError })
  },[ serverError ])

  return(
      <LoginScreenBackground>
          <div>
              <div className={styles.loginLabel}>{ENWORDS.changePassword}</div> 
              <div className={styles.form}>
                  {
                      <form className={styles.formSpace} onSubmit={onSubmitClicked}>
                          <LoginCustomInput 
                            title = {ENWORDS.verificationCode}
                            isFocused  ={true}
                            type= 'text'
                            onChange  = {(e) => setVerificationCode(e.target.value)}
                            value  = {verificationCode} 
                            maxLength = {4}
                            error = {error.isVerificationCodeError ? error.error: ''}
                          />
                          <div className={styles.resentOTPContainer}>
                              <div className={styles.forgotPass} onClick={() => resendOTP()}><label>{ENWORDS.resendOtp}</label></div>
                          </div>
                          <LoginCustomInput 
                            title = {ENWORDS.newPassword}
                            fieldType  = 'password'
                            type= 'password'
                            placeholder = {PLACEHOLDER.newPassword}
                            onChange  = {(e) => setNewPassword(e.target.value)?.trim()}
                            value  = {newPassword?.trim()} 
                            error = {error.isNewauthPasswordError ? error.error: ''}
                          />

                          <LoginCustomInput 
                            title = {ENWORDS.confirmNewPassword}
                            fieldType  = 'password'
                            type= 'password'
                            placeholder = {PLACEHOLDER.confirmPassword}
                            onChange  = {(e) => setConfirmPassword(e.target.value)?.trim()}
                            value  = {confirmPassword?.trim()} 
                            error = {error.isConfirmauthPasswordError ? error.error: ''}
                          />
                          <div className={styles.emptyDiv }></div>
                          <div className={styles.inputContainer}>
                              <button className={styles.otpButton} type="submit">{ENWORDS.submit}</button>
                          </div>
                          <div className={styles.checkboxContainer}>
                              <div>
                                  <div className={styles.checkboxLabel} onClick={clearForm}>{ENWORDS.clearLogin}</div>
                              </div>
                              <hr className={styles.verticalLine}></hr>
                              <div>
                                  <div className={styles.forgotPass} onClick={navigateToLogin}><label>{ENWORDS.login}</label></div>
                              </div>
                          </div>
                      </form>
                  }
              </div>
              <div>
                  <div className={error.error ? `${ styles.emptyDiv } ${ styles.errorMessage } `: styles.hideError}>{error.error}</div>
              </div>
          </div>
      </LoginScreenBackground>
  );
}

export default ChangePassword;