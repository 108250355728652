import React from 'react'
import UserListingScreen from './UserListing/UserListingScreen.js'

const UserManagement = () => {
  return (
      <UserListingScreen/>
  )
}

export default UserManagement
