import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import styles from '../../../styles/Login/Login.module.css';
import LoginScreenBackground from '../../auth/Login/LoginScreenBackground';
import {  AUTHROUTES } from '../../../shared/constants/appRoute.js';
import { onForgotPasswordOTP } from '../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, setLoadingFlag } from '../../../redux/actions/common';
import { ALL_EMAIL_RG } from '../../../shared/constants/appConstants.js';
import LoginCustomInput from '../../../shared/Components/LoginCustomInput';
const ENWORDS = require('../../../shared/local/en.json')
const ERRORWORDS = require('../../../shared/local/error.json');
const PLACEHOLDER = require('../../../shared/local/placeholder.json');

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serverError = useSelector(state => state.auth.fetchError)
  const[ inputEmail, setInputEmail ] = useState('');
  const[ error, setError ] = useState({});

  const submitEmailVerification = async () => {
    dispatch(fetchError(''))
    if (ALL_EMAIL_RG.test(inputEmail)) {
      dispatch(setLoadingFlag(true))
      dispatch(onForgotPasswordOTP({ email: inputEmail, navigate }))
      setError({ emailError:serverError })
    } else {
      setError({ emailError:ERRORWORDS.emailError });
    }
  }
  const navigateToLogin = () =>{
    dispatch(fetchError(''))
    navigate(AUTHROUTES.LOGINSCREEN)
  }
  useEffect(()=>{
    setError({ emailError:serverError })
  },[ serverError ])

  useEffect(()=>{
    if (ALL_EMAIL_RG.test(inputEmail) ) {
      setError({})
    }
  },[ inputEmail ])

  useEffect(()=>{
    dispatch(fetchError(''))
  },[])

  return(
      <LoginScreenBackground>
          <div>
              <div className={styles.loginLabel}>{ENWORDS.forgotPasswordLabel}</div> 
              <div className={styles.form}>
                  {
                      <div className={styles.formSpace}>
                          <LoginCustomInput 
                            title = {ENWORDS.email}
                            isFocused  ={true}
                            fieldType  = 'email'
                            type= 'email'
                            placeholder = {PLACEHOLDER.email}
                            onChange  = {(e) => setInputEmail(e.target.value)}
                            value  = {inputEmail} 
                            error = {error.emailError}
                          />
                          <div className={ styles.emptyDiv }></div>
                          <div className={styles.inputContainer}>
                              <button 
                                className={styles.otpButton} 
                                onClick={() => submitEmailVerification()} 
                                type="submit">
                                  {ENWORDS.sendVerification}
                              </button>
                              <button 
                                className={styles.transparentButton} 
                                onClick={navigateToLogin} 
                                type="submit">
                                  {ENWORDS.login}
                              </button>
                          </div>
                      </div>
                  }
              </div>
              <div>
                  <div 
                    className={error.emailError ? `${ styles.emptyDiv } ${ styles.errorMessage } ` : styles.hideError}>
                      {error.emailError}
                  </div>
              </div>
          </div>
      </LoginScreenBackground>
  );
}

export default ForgotPassword;