import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/invoiceListing/inputDateCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import CustomInput from '../../../shared/Components/CustomInput';
import popUpStyle from '../../../styles/adtConfiguration/ConfigurationScreen.module.css';
import ERRORWORDS from '../../../shared/local/error.json';
import { setLoadingFlag } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import {
  addPenaltyConfiguration,
  getPenaltyConfiguration,
  getVatCodesToDisplay
} from '../../../api/services/penaltyConfiguration';
import { PENALTY_CONFIG_TYPES } from '../adtMonitoringConstant';
import CustomPopup from '../../../shared/Components/CustomPopup';
const ENWORDS = require('../../../shared/local/en.json');

const AddPenaltyConfigPopup = ({ tableData, onClose , show }) => {
  const dispatch = useDispatch();
  const [ type, setType ] = useState(PENALTY_CONFIG_TYPES.Warning)
  const [ vatCode, setVatCode ] = useState();
  const [ applicableFine, setApplicableFine ] = useState();
  const [ error, setError ] = useState({});
  const [ selectedVatCode, setSelectedVatCode ] = useState('');

  const onCloseHandler = () =>{
    setApplicableFine()
    setType(PENALTY_CONFIG_TYPES.Warning)
    onClose()
  }

  const addPenaltyConfig = async () => {
    const tableDataCopy = [ ...tableData ];
    dispatch(setLoadingFlag(true));
    const data = {
      sequenceNumber: tableData.length + 1,
      type: type,
      applicableFine: applicableFine,
      vatCode:selectedVatCode,
    };

    tableDataCopy.push(data);
    const res = await addPenaltyConfiguration(tableDataCopy);
    onCloseHandler();
    dispatch(setLoadingFlag(false));

    if (res.res) {
      dispatch(getPenaltyConfiguration());
    }
  };

  const checkValidation = async () => {
    let errorVariable = error;
    if (type === PENALTY_CONFIG_TYPES.Notice && (applicableFine === '' || isNaN(applicableFine))
    ) {
      errorVariable = { ...errorVariable, amountError: ERRORWORDS.amountError };
    }
    setError(errorVariable);

    const isErrorEmpty = Object.values(errorVariable).every(value => {
      if (value === null || value === '' || value === undefined) {
        return true;
      }
      return false;
    });

    if (isErrorEmpty) {
      addPenaltyConfig();
    }
  };

  const populateVatCodes = async () =>{
    dispatch(setLoadingFlag(true));
    const { res }  = await getVatCodesToDisplay();
    if (res){
      dispatch(setLoadingFlag(false))
      setVatCode([ { 'vatCode' : '' },...res ])
     
    }
  }

  useEffect(() => {
    populateVatCodes();
  }, []);

  return (
      <CustomPopup
        title={ENWORDS.addPenaltyConfiguration}
        onConfirm={checkValidation}
        showPopUp={show}
        onReject={onCloseHandler}
        style={{ width : '50%' }}
      >
          <div className={popUpStyle.popUpBody}>
              <CustomDropDown
                title={ENWORDS.type}
                name='type'
                fieldValues={PENALTY_CONFIG_TYPES}
                selectedValue={type}
                onChange={e => setType(e.target.value)}
                error={error.typeError}
              />

              {type === 'Notice' ? (
                  <div style={{ display: 'flex' }}>
                      <CustomInput
                        type='number'
                        title={ENWORDS.amount}
                        name='applicableFine'
                        error={error.amountError}
                        onChange={value =>setApplicableFine(value)}
                      />

                      <CustomDropDown
                        title={ENWORDS.vatCode}
                        defaultValues={{ selectedVatCode }}
                        type={ENWORDS.custom}
                        onChange={e => setSelectedVatCode(e.target.value)}
                        renderKey='vatCode'
                        renderKeyOne='vatCode'
                        fieldValues = {vatCode ?? [] }
                      />
                  </div>
              ) : null}
          </div>
      </CustomPopup>

  );
};

AddPenaltyConfigPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default AddPenaltyConfigPopup;
