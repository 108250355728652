import axios from 'axios';
import Cookies from 'universal-cookie';
import { INVOICEURLS } from '../../shared/constants/appUrls';
import { BASE_URL, APITIMEOUT, POST } from '../../shared/constants/appConstants';

export const uploadExcel = async (formdata) =>{
  const TOKEN = new Cookies().get('token');
  let returnVal = false;
  let uploadRes;
  const headersList = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text/plain',
    'Authorization': 'Bearer '+ TOKEN,
  }

  const reqOptions = {
    timeout:APITIMEOUT,
    url:`${ BASE_URL }${ INVOICEURLS.uploadExcel }`,
    method: POST,
    headers: headersList,
    data: formdata ,
  }

  await axios.request(reqOptions)
    .then(function (response) {
      uploadRes = response
      returnVal =  true
    })
    .catch(function(err){
      const mainErrorRes = err.response;
      if(!mainErrorRes.data.data){
        uploadRes = mainErrorRes.data
      }
      else if (mainErrorRes.data.data === ''){
        uploadRes = mainErrorRes.data.message
      }
      else{

        if (typeof(JSON.parse(mainErrorRes.data.data)) ==='object'){
          uploadRes = JSON.parse(mainErrorRes.data.data ? mainErrorRes.data.data: mainErrorRes.data)
        }
        else if (typeof(mainErrorRes.data.message)=== 'string'){
          uploadRes = mainErrorRes.data.message
        }
      }
      returnVal =  false
    });
  return { res: uploadRes, err:returnVal }
}

export const generateInvoice = async (formdata)=>{
  const TOKEN = new Cookies().get('token');
  let uploadRes;
  const headersList = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text/plain',
    'Authorization': 'Bearer '+ TOKEN,
  }

  const reqOptions = {
    timeout:APITIMEOUT,
    url:`${ BASE_URL }${ INVOICEURLS.generateInvoice }`,
    method: POST,
    headers: headersList,
    data: formdata ,
  }

  await axios.request(reqOptions)
    .then(function (response) {
      uploadRes = response
    })
    .catch(function(err){
      console.log(err)
    });
  return uploadRes
}