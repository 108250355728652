import React , { useEffect, useState }from 'react'
import ENWORDS from '../../../../shared/local/en.json';
import BaseConfigurationTable from '../../BaseConfigurationTable';

const  RoleTable = ({ headerData, roleAccess, filterData }) => {
  const [ filteredData, setFilteredData ] = useState(filterData);

  useEffect(()=>{
    setFilteredData(filterData)
  },[ filterData ])
    
  return (
      <BaseConfigurationTable 
        tableData={filteredData} 
        headerData = {headerData} 
        tableName = {ENWORDS.roleTable}
        roleAccess = {roleAccess}
        popUpTitle = {ENWORDS.deleteRoleTitle}
        isFromPage='roleManagement'
      />
  )
}

export default RoleTable