import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import tableStyles from '../../styles/table.module.css';
import editPenIcon from '../../assets/icons/editPenIcon.svg';
import { ROUTES } from '../../shared/constants/appRoute';
import arrowUp from '../../assets/icons/arrowLightUp.svg';
import arrowDown from '../../assets/icons/arrowDownLight.svg';
import deleteIcon from '../../assets/icons/binIcon.svg';
import baseStyles from '../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import PopUp from './PopUp';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFlag } from '../../redux/actions';
import { defaultSortBy, globalSortBy } from '../commonFunctions';
import {
  checkIfInvoiceExist,
  getCompany,
  getSupplierDetailsToDisplay,
  getUserRoles
} from '../../api/services/commonService';
import {
  deleteModuleById,
  getModuleById,
  getModuleDetailsToDisplay,
  getModuleStationMapping,
  getRequiredFieldsData
} from '../../api/services/moduleConfigurationService';
import { getStationById } from '../../api/services/stationManagementService';
import InvoiceVerificationPopUp from './InvoiceVerificationPopUp';
import { getSupplierList } from '../../api/services/supplierManagementServices';
import Paginator from './Paginator';
import { TABS } from '../../shared/constants/appConstants';
import { getAreaById } from '../../api/services/areaManagementService';
const ENWORDS = require('../local/en.json');

const DataTable = ({
  data,
  headerData,
  tableName,
  billingDate,
  roleLevelAccess,
  shouldVerify = false,
  onClickKey = 'id',
  popupTitle= ENWORDS.deactivateTitle,
  popupText= ENWORDS.deactivateText,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ filteredData, setfilteredData ] = useState(data);
  const [ sortState, setSortState ] = useState(ENWORDS.ascending);
  const [ selectedSort, setSelectedSort ] = useState('');
  const [ visibility, setVisibility ] = useState(false);
  const [ toDeleteName, setToDeleteName ] = useState('');
  const [ toDeleteId, setToDeleteId ] = useState();
  const [ invoiceList, setInvoiceList ] = useState([]);
  const [ invoicePopUpVisibility, setInvoicePopupVisibility ] = useState(false);
  const [ slicedData, setSlicedData ] = useState(data);

  const regionsData = useSelector(state => state.regionsReducer.regionsTableData);
  const areasData = useSelector(state => state.areaReducer.areaTable);
  const [ selectedRows, setSelectedRows ] = useState([  ]);
  const [ isAllChecked, setIsAllChecked ] = useState(false);

  const getStationDetailsById = async (siteId) => {
    const res = await getStationById(siteId);
    const stationRes = res.res;
    if (stationRes) {
      return stationRes;
    }
  };

  const getModules = async () => {
    const moduleResponse = await getModuleDetailsToDisplay();

    const moduleSuccess = moduleResponse.res;
    if (moduleSuccess) {
      return moduleResponse.res;
    }
  };

  const getStations = async () => {
    const siteResponse = await getSupplierDetailsToDisplay();

    const siteSuccess = siteResponse.res;

    if (siteSuccess) {
      return siteResponse.res;
    }
  };

  const onEditStationClicked = async (event, siteId) => {
    dispatch(setLoadingFlag(true));
    event.stopPropagation();
    const stationDetailsData = await getStationDetailsById(siteId);
    const companies = await getCompany();

    if (stationDetailsData  && companies) {
      dispatch(setLoadingFlag(false));
      navigate(ROUTES.EDITSTATION, {
        state: {
          data: stationDetailsData,
          companyValue: companies,
        },
      });
    }
  };

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };
  const invoicePopupCloseHandler = (e) => {
    setInvoicePopupVisibility(e);
  };

  const getModuleDetailsById = async (moduleId) => {
    const moduleRes = await getModuleById(moduleId);

    const moduleResData = moduleRes.res;

    if (moduleResData) {
      return moduleResData;
    }
  };

  const fetchUserRoles = async () => {
    const res = await getUserRoles();
    const rolesRes = res.res;

    if (rolesRes) {
      return rolesRes;
    }
  };

  const getStationData = async () => {
    const stationRes = await getModuleStationMapping();
    const stationResData = stationRes.res;
    if (stationResData) {
      return stationResData.map((val) => ({
        value: val,
        label: val,
      }));
    }
  };

  const onEditModuleClicked = async (event, moduleId) => {
    event.stopPropagation();
    dispatch(setLoadingFlag(true));

    const moduleDetails = await getModuleDetailsById(moduleId);

    const fieldData  = await getRequiredFieldsData();

    const stationData = await getStationData();

    const userRole = await fetchUserRoles();

    const companyResponse = await getCompany();
    const supplierData = await getSupplierList();
    
    if (moduleDetails && fieldData && stationData && userRole) {
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITMODULECONFIGURATION, {
        state: {
          data: moduleDetails,
          fieldValues: fieldData.res,
          stationValue: stationData,
          roleValue: userRole,
          companyList: companyResponse.res,
          supplierDataList : supplierData.res,
        },
      });
    }
  };

  const isRowChecked = (event,invoiceNumber) =>{
    event.stopPropagation();
    const existingid = new Set(selectedRows);
    if(existingid.has(invoiceNumber)){
      existingid.delete(invoiceNumber);
    }else{
      existingid.add(invoiceNumber);
    }
    setSelectedRows(Array.from(existingid));
    setIsAllChecked(Array.from(existingid).length === filteredData.length);
  }

  const showPopUp = (event, moduleName, id) => {
    event.stopPropagation();
    setVisibility(true);
    setToDeleteName(moduleName);
    setToDeleteId(id);
  };
  const showVerificationPopUp = (event, moduleName, id) => {
    event.stopPropagation();
    setInvoicePopupVisibility(true);
    setToDeleteName(moduleName);
    setToDeleteId(id);
  };

  const tblRowClicked = async (event, tblId, moduleAccess = 0) => {
    event.stopPropagation();
    
    if (tableName === ENWORDS.moduleTable) {
      dispatch(setLoadingFlag(true));
      const moduleDetails = await getModuleDetailsById(tblId);

      if (moduleDetails) {
        dispatch(setLoadingFlag(false));
        navigate(ROUTES.VIEWMODULECONFIGURATION, {
          state: {
            data: moduleDetails,
            roleAccess: roleLevelAccess,
            moduleAccess: moduleAccess,
          },
        });
      }
    } else if (tableName === ENWORDS.stationTable) {
      event.stopPropagation();
      dispatch(setLoadingFlag(true));
      
      const stationDetailsData = await getStationDetailsById(tblId);
      const moduleData = await getModules();
      const stationData = await getStations();
      if (stationDetailsData && moduleData && stationData) {
        dispatch(setLoadingFlag(false));
        navigate(ROUTES.STATIONDETAILS, {
          state: {
            data: stationDetailsData,
            moduleValue: moduleData,
            stationValue: stationData,
            roleAccess: roleLevelAccess,
          },
        });
      }
    } else if (tableName === ENWORDS.regionTable && regionsData) {
      event.stopPropagation();
      dispatch(setLoadingFlag(true));
      navigate(ROUTES.REGIONDETAILS, {
        state: {
          data: regionsData,
          // roleAccess: roleLevelAccess,
        },
      });
    } else if (tableName === ENWORDS.areaTable) {
      event.stopPropagation();
      dispatch(setLoadingFlag(true));
      const areaData = await getAreaById(tblId);
      dispatch(setLoadingFlag(false));
      if(areaData){
        navigate(ROUTES.AREADETAILS, {
          state: {
            data: areaData.res,
            // roleAccess: roleLevelAccess,
          },
        });
      }
    }
  };

  const invoiceVerficationCall = async (event, formName, formId) => {
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    const verificationCall = await checkIfInvoiceExist(formId, 'moduleId');

    if (verificationCall.statusCode === 200) {
      dispatch(setLoadingFlag(false));
      setInvoiceList(verificationCall.res);
      showVerificationPopUp(event, formName, formId);
    }
  };

  const displayTableData = () =>
    slicedData
      .map((item, index) => {
        return (
            <tr
              key={item[ Object.keys(item)[ 0 ] ] + item[ Object.keys(item)[ 2 ] ]}
              onClick={
                tableName === ENWORDS.moduleTable
                  ? (event) => { tblRowClicked(event, item[ onClickKey ], item.moduleAccess)}
                  : (event) => { tblRowClicked(event, item[ onClickKey ])}
              }
            >
                {Object.keys(headerData).map((key) =>

                  key === 'moduleAction' && item.moduleAccess === 2 ? (
                      <td key="action">
                          <div>
                              { item.moduleName !== TABS.adtAlarmMonitoring ?
                                  <button className={tableStyles.btnOne}>
                                      <img
                                        src={deleteIcon}
                                        alt={ENWORDS.deleteIcon}
                                        className={tableStyles.iconOne}
                                        onClick={(event) =>
                                          invoiceVerficationCall(
                                            event,
                                            item.moduleName,
                                            item.id
                                          )
                                        }
                                      />
                                  </button> : <></> }
                              <button className={tableStyles.btnTwo}>
                                  <img
                                    src={editPenIcon}
                                    alt={ENWORDS.mailIcon}
                                    className={tableStyles.iconTwo}
                                    onClick={(event) => onEditModuleClicked(event, item.id)}
                                  />
                              </button>
                          </div>
                      </td>
                  ) : key === 'siteStatus' ? (
                      <td key={key}>
                          <div
                            className={
                              item.siteStatus === 'Coming Soon'
                                ? baseStyles.userStatusComingSoon
                                : baseStyles[ 'userStatus'+item.siteStatus ]
                            }
                          ></div>
                          {item.siteStatus}
                      </td>
                  ) : key === 'stationAction' ? (
                      <td key="action">
                          <div className={tableStyles.contentRow}>
                              <button className={tableStyles.btnOne}>
                                  <img
                                    src={deleteIcon}
                                    alt={ENWORDS.deleteIcon}
                                    className={tableStyles.iconOne}
                                    onClick={(event) =>
                                      showPopUp(event, item.siteName, item.siteId)
                                    }
                                  />
                              </button>
                              <button className={tableStyles.btnTwo}>
                                  <img
                                    src={editPenIcon}
                                    alt={ENWORDS.mailIcon}
                                    className={tableStyles.iconTwo}
                                    onClick={(event) =>
                                      onEditStationClicked(event, item.siteId)
                                    }
                                  />
                              </button>
                          </div>
                      </td>
                  ) : key === 'billingPeriod' ? (
                      <td key={key}>
                          <p>
                              {item[ key ] === null
                                ? ENWORDS.NA
                                : item[ key ]?.substring(0, 7)}
                          </p>
                      </td>
                  ) : key === 'invoiceDate' ? (
                      <td key={key}>
                          <p>
                              {item[ key ] === null
                                ? ENWORDS.NA
                                : item[ key ]?.substring(0, 10)}
                          </p>
                      </td>
                  ) : key === 'emailStatus' ? (
                      <td key={key}>
                          <p>{item[ key ] === true ? ENWORDS.sent : ENWORDS.pending}</p>
                      </td>
                  ) : (
                      <td key={key} title = {item[ key ]} className= {key.toLowerCase().includes('amount') ? tableStyles.amountCell : null}>
                          <p>{item[ key ] === null ? ENWORDS.NA : item[ key ]}</p>
                      </td>
                  )
                )}
            </tr>
        );
      });

  const onGlobalSortBy = (keyValue) => {
    const x = globalSortBy(data, keyValue, sortState);
    setfilteredData([ ...x[ 0 ] ]);
    setSelectedSort(keyValue);
    setSortState(x[ 1 ]);
  };

  const onDefaultSort = () => {
    setSortState(ENWORDS.ascending);
    const response = defaultSortBy(headerData, data);
    setfilteredData(response[ 0 ]);
    setSelectedSort(response[ 1 ]);
  };

  useEffect(() => {
    onDefaultSort();
  }, [ data ]);

  const deleteRow = () => {
    dispatch(setLoadingFlag(true));
    dispatch(deleteModuleById(toDeleteId));
    navigate(ROUTES.MODULECONFIGURATION);
  };
  return (
      <div className={tableStyles.dataTable}>
          {shouldVerify ? (
              <InvoiceVerificationPopUp
                onClose={invoicePopupCloseHandler}
                show={invoicePopUpVisibility}
                title={ENWORDS.deleteModuleTitle}
                text={invoiceList.length >0 ?'The module'+ ENWORDS.deleteCMText: ENWORDS.commonCheckboxMsg}
                checkboxMsg={ENWORDS.commonCheckboxMsg}
                selectedName={''}
                selectedId={toDeleteId}
                screenName={ENWORDS.editStation}
                invoiceList={invoiceList ?? []}
                onDelete={deleteRow}
              />
          ) : (
              <PopUp
                onClose={popupCloseHandler}
                show={visibility}
                title={popupTitle}
                text={popupText}
                selectedName={toDeleteName}
                selectedId={toDeleteId}
                screenName={tableName}
              />
          )}

          <div className={tableStyles.tableContainer}>
              <table className={tableStyles.tableTag}>
                  <thead className={tableStyles.tableHead}>
                      <tr>
                          {Object.keys(headerData).map((key) => (
                              <th
                                className={tableStyles.header}
                                key={key}>
                                  {key.toLowerCase().includes('action')? (
                                    headerData[ key ]
                                  ) : (
                                      <div className={tableStyles.sortContainer}>
                                          <button
                                            className={tableStyles.btnSort}
                                            onClick={() => onGlobalSortBy(key)}
                                          >
                                              <p>{headerData[ key ]}</p>
                                              <img
                                                src={
                                                  sortState === 'asc' && selectedSort === key
                                                    ? arrowUp
                                                    : arrowDown
                                                }
                                                alt={ENWORDS.sortBy}
                                              />
                                          </button>
                                      </div>
                                  )}
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>{displayTableData()}</tbody>
              </table>
              <div></div>
          </div>
          <Paginator
            filteredData={filteredData}
            dataLength={data.length}
            setSlicedData={setSlicedData}
            isFromPage='module'
          />
      </div>
  );
};
export default DataTable;
