import { setLoadingFlag } from '../../redux/actions';
import { setRegionsTable } from '../../redux/actions/regionsListing';
import { API_HEADER, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { REGIONSURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
const ENWORDS = require('../../shared/local/en.json');

export function getRegionsData() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: REGIONSURL.getRegion,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setRegionsTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function getRegionById(regionId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ REGIONSURL.getRegionById + regionId }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function addOrEditRegion(data,type = ENWORDS.addMode) {
  const { id, regionName, regionManagers, regionStatus ,regionId, opsRegionId } = data
  let res;
  let errRes = {};
  await makeRequest({
    method: type === ENWORDS.editMode? PUT : POST,
    url: type === ENWORDS.editMode ? REGIONSURL.updateRegion +'?id='+ id : REGIONSURL.addRegion ,
    data: {
      regionName:regionName,
      regionManagers:regionManagers,
      regionStatus:regionStatus,
      regionId : regionId,
      opsRegionId : opsRegionId,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export function deleteRegionById( id ) { 
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ REGIONSURL.deleteRegion + id }`,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function(res){
        dispatch(setLoadingFlag(true))
        dispatch(getRegionsData())
        dispatch(setLoadingFlag(false))
      }).catch(function (err) {
        console.log(err)
      });

  }
}