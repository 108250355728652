import { SET_ADT_POPUP_DATA, SET_DAILY_LOGS_DATA, SET_DAILY_LOGS_DETAILS_DATA, DAILY_LOGS_FILTER,SET_EXCLUDED_CONTRACT_NUMBERS, DAILY_LOGS_PAGE_NUMBER } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  adtDailyLogsTable: [],
  adtDailyLogsDetailsTable: [],
  adtMailPopupData : [],
  dailyLogsDateFilter: '',
  pageNumber: 0,
  excludedContractNumbers: [],
};

const dailyLogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_DAILY_LOGS_DATA:
    return {
      ...state,
      adtDailyLogsTable: action.payload,
    };

  case SET_DAILY_LOGS_DETAILS_DATA:
    return {
      ...state,
      adtDailyLogsDetailsTable: action.payload,
    };

  case SET_ADT_POPUP_DATA:
    return {
      ...state,
      adtMailPopupData: action.payload,
    } 
  case DAILY_LOGS_FILTER:
    return {
      ...state,
      dailyLogsDateFilter: action.payload,
    }
  case DAILY_LOGS_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.payload,
    }
  case SET_EXCLUDED_CONTRACT_NUMBERS:
    return {
      ...state,
      excludedContractNumbers: action.payload,
    }
  default:
    return state;
  }
};

export default dailyLogsReducer;
