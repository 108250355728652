import { SET_ADT_POPUP_DATA, SET_DAILY_LOGS_DATA, SET_DAILY_LOGS_DETAILS_DATA, DAILY_LOGS_FILTER, DAILY_LOGS_PAGE_NUMBER, SET_EXCLUDED_CONTRACT_NUMBERS } from '../../shared/constants/actionType'

export const setDailyLogsTable = (data)=>{
  return{
    type: SET_DAILY_LOGS_DATA,
    payload: data,
  }
}

export const setDailyLogDetailsTable = (data)=>{
  return{
    type: SET_DAILY_LOGS_DETAILS_DATA,
    payload: data,
  }
}

export const setDailyLogMailData = (data)=>{
  return{
    type: SET_ADT_POPUP_DATA,
    payload: data,
  }
}

export const updateDailyLogsFilter = (data)=>{
  return{
    type: DAILY_LOGS_FILTER,
    payload: data,
  }
}

export const updateDailyLogsPageNumber = (data)=>{
  return{
    type: DAILY_LOGS_PAGE_NUMBER,
    payload: data,
  }
}

export const setExcludedContractNumbers = (data)=>{
  return{
    type: SET_EXCLUDED_CONTRACT_NUMBERS,
    payload: data,
  }
}