import { makeRequest } from '../makeRequest';
import { API_HEADER, GET, POST, PUT } from '../../shared/constants/appConstants';
import { INVOICEURLS } from '../../shared/constants/appUrls'
import { updateInvoice } from '../../redux/actions/invoiceListing';
import { exportCSVFile } from '../../shared/commonFunctions';
import { setLoadingFlag } from '../../redux/actions';

export function getInvoiceDetails(fromDate, toDate, selectedModuleId) {

  return async (dispatch) => {
    await makeRequest({
      method: GET,
      url: INVOICEURLS.getInvoiceByDateRange+'?fromDate='+fromDate+'&toDate='+toDate+'&moduleId='+selectedModuleId,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(updateInvoice(response.data))
        dispatch(setLoadingFlag(false))
      })  
  }
}

export async function getCanUploadExcel(moduleID,billingDate) {
  let serverStatusCode  =  1;
  let serverStatus = false;
  let serverMessage = '';
  await makeRequest({
    method: GET,
    url: INVOICEURLS.canUploadExcel + moduleID + INVOICEURLS.billingValue + billingDate,
    headers: API_HEADER,
    showPopUp :false,
  })
    .then(function (response) {
      serverStatusCode  = 1
      serverStatus = false
      serverMessage = '';
    })
    .catch(function (err) {
      serverStatusCode  = err.response.status
      serverStatus = true
      serverMessage = err.response.data
    });
  return { statusCode: serverStatusCode, status: serverStatus, message: serverMessage }
}

export async function getInvoiceSummary(requestObj){

  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url:INVOICEURLS.invoiceSummary,
    data: requestObj,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });
  return { res:res, err:errRes };
}
export async function updateInvoiceSummary(requestObj){

  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url:INVOICEURLS.updateInvoice,
    data: requestObj,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });
  return { res:res, err:errRes };
}
export async function sendInvoices(requestBody){

  let res = true;
  let errRes = {};
  await makeRequest({
    method: POST,
    url:INVOICEURLS.sendInvoice,
    data: requestBody,
    headers: API_HEADER,
  })
    .then(function (response) { 
      res = true
    })
    .catch(function (err) {
      res = false
      errRes = err.response
      
    });
  return { res:res, err:errRes };
}
export async function downloadCSV(fromDate, toDate, moduleId, moduleName, exportType, invoices){

  let res = true;
  let errRes = {};
  if (exportType === 'NL_Export') {
    exportCSVFile(null,[],'NetSuite_SL_'+exportType+'-'+moduleName+'-'+ toDate);
    res = true
  } else {
    await makeRequest({
      method: POST,
      url:INVOICEURLS.downloadCSV+`?moduleId=${ moduleId }&fromDate=${ fromDate }&toDate=${ toDate }&exportType=${ exportType }`,
      data: invoices,
      headers: API_HEADER,
    })
      .then(function (response) {
        if(response.data){
          exportCSVFile(null,response.data,'NetSuite_SL_'+exportType+'-'+moduleName+'-'+ toDate);
        } 
        res = true
      })
      .catch(function (err) {
        res = false
        errRes = err.response
      });
  }
  return { res:res, err:errRes };
}

export async function createInvoiceForPenalty(requestObj) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: INVOICEURLS.addInvoiceDetails,
    data: requestObj,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function updateInvoiceStatus(requestBody){

  let res = true;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url:INVOICEURLS.updateInvoiceStatus,
    data: requestBody,
    headers: API_HEADER,
  })
    .then(function (response) { 
      res = true
    })
    .catch(function (err) {
      res = false
      errRes = err.response
      
    });
  return { res:res, err:errRes };
}
