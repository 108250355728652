import React from 'react'
import styles from  '../../styles/userManagement/UserManagementScreen.module.css'
import globalStyle from  '../../styles/global.module.css'
import searchIcon from '../../assets/icons/searchIcon.svg'
const ENWORDS = require('../local/en.json')
const CustomSearchBox = ({ placeholder, title, value , onChange }) => {
  return (
      <div className={styles.searchContainer}>
          <p className={globalStyle.filterText}>{title}</p>
          <div className={globalStyle.searchBox}>
              <img src={searchIcon} alt= {ENWORDS.searchIcon} className={globalStyle.searchIcon} />
              <input type='text' 
                placeholder = {placeholder} 
                value = {value} 
                onChange={onChange} 
                autoFocus={true} 
                maxLength={100} />
          </div>
      </div>
  )
}

export default CustomSearchBox