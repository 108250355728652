import { SET_ROLE_TABLE, SET_USER_LEVEL_ACCESS, ROLE_PAGE_NUMBER } from '../../shared/constants/actionType';

const INIT_STATE = {
  roleTable:[],
  roleLevelAccess:[],
  pageNumber: 0,
}

const roleManagementReducer = (state = INIT_STATE, action) => {
  switch(action.type){
  case SET_ROLE_TABLE:
    return{
      ...state,
      roleTable: action.payload,
    }
  case SET_USER_LEVEL_ACCESS:
    return{
      ...state,
      roleLevelAccess: action.payload,
    }
  case ROLE_PAGE_NUMBER:
    return{
      ...state,
      pageNumber: action.payload,
    }
  default : return state
  }
}
export default roleManagementReducer