import { setLoadingFlag } from '../../redux/actions';
import { setCountersTable } from '../../redux/actions/counters';
import { API_HEADER, GET, POST } from '../../shared/constants/appConstants';
import { ADTMONITORINGURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';

export function getCountersData(year) {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true));
    await makeRequest({
      method: GET,
      url: ADTMONITORINGURL.getCounters + year,
      data: undefined,
      headers: API_HEADER,
    }).then(function (response) {
      dispatch(setCountersTable(response.data.data));
      dispatch(setLoadingFlag(false));
    });
  };
}

export async function sendCountersLogOnMail(year, data) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: `${ ADTMONITORINGURL.sendCountersLogs + year }`,
    data: data,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = true;
    })
    .catch(function (err) {
      res = false;
      errRes = err;
    });
  return { res: res, err: errRes };
}
