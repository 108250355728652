import { APPEND_USERS, USERS_FILTER, USERS_PAGE_NUMBER } from '../../shared/constants/actionType.js'
const INIT_STATE = {
  userManagementList: [],
  userManagementRoleFilter: '', 
  pageNumber: 0,
}

const userManagementReducer = (state = INIT_STATE, action) => {
  switch(action.type){  
  
  case APPEND_USERS:
    return {
      ...state,
      userManagementList: action.payload,
    }
  case USERS_FILTER:
    return {
      ...state,
      userManagementRoleFilter: action.payload,
    }
  case USERS_PAGE_NUMBER:
    return{
      ...state,
      pageNumber: action.payload,
    }
  default: return state;
  }
}

export default userManagementReducer;