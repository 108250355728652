import { SET_SUPPLIER_TABLE, SUPPLIER_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setSupplierTable = (data) => {
  return {
    type:SET_SUPPLIER_TABLE,
    payload:data,
  }
}

export const updateSupplierPageNumber = (data) => {
  return {
    type:SUPPLIER_PAGE_NUMBER,
    payload:data,
  }
}
