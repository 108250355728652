import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import ENWORDS from '../../../shared/local/en.json';
import { CONFIGURATIONHEADERS, CONFIGURATIONSCREENHEADERS } from '../adtMonitoringConstant';
import plusIcon from '../../../assets/icons/plusIcon.svg';
import style from '../../../styles/adtConfiguration/ConfigurationScreen.module.css'
import ConfigTable from './ConfigTable';
import { TABS } from '../../../shared/constants/appConstants';
import SideNavigation from '../../../shared/Components/SideNavigation';
import { addPenaltyConfiguration, getPenaltyConfiguration } from '../../../api/services/penaltyConfiguration';
import editPenIconLight from '../../../assets/icons/editPenWhiteIcon.svg';
import conStyles from '../../../styles/moduleConfiguration/addconfig.module.css';
import globalPopUpStyles from '../../../styles/popUp.module.css';
import globalStyles from '../../../styles/global.module.css';
import CustomPopup from '../../../shared/Components/CustomPopup';
import AddPenaltyConfigPopup from './AddPenaltyConfigPopup';
import useAccess from '../../../utility/appHooks/useAccess';
const SCREENS  = require('../../../shared/local/screens.json');
const ConfigurationScreen = () => {
  const dispatch = useDispatch();
  const configData = useSelector(
    state => state.configurationReducer.configurationTable
  );
  const [ filterData, setFilterData ] = useState([]);
  const [ popupVisibility, setPopUpVisibility ] = useState(false);
  const [ editClicked, setEditClicked ] = useState(false);
  const [ saveEditedData, setSaveEditedData ] = useState(false);
  const [ deleteId, setToDeleteId ] = useState(0);
  const hasWriteAccess = useAccess(SCREENS.adtPenaltyConfig)

  useEffect(() => {
    setFilterData(configData ?? [] );
  }, [ configData ]);

  const showAddPopUp = () => {
    setPopUpVisibility(true);
  };

  const addData = () => {
    showAddPopUp();
  };

  const editData = () => {
    setEditClicked(true);
  }

  async function removeObjectWithId(arr, id) {
    const arrCopy = Array.from(arr);
    const objWithIdIndex = arrCopy.findIndex((obj) => obj.penaltyId === id);
    arrCopy.splice(objWithIdIndex, 1);
    return arrCopy;
  }

  const onDeleteClick = async (event, id) => {
    setToDeleteId(id);
  };

  const onPenaltyConfigDeleteConfirm = async () => {
    const updatedData = await removeObjectWithId(filterData, deleteId);
    setToDeleteId(undefined);
    setFilterData(updatedData);
  }

  const onCancelPenaltyConfigEditedData = async () => {
    setEditClicked(false);
    setSaveEditedData(false);
    dispatch(getPenaltyConfiguration());
    setFilterData(configData);
  };

  const onClickSavePenaltyConfigEditedData = async () => {
    setSaveEditedData(true);
    const filterDataCopy = filterData.map((item, index)=>{
      return { ...item, sequenceNumber :index + 1 }
    })
    const res = await addPenaltyConfiguration(filterDataCopy);
    if (res.res) {
      dispatch(getPenaltyConfiguration());
    }
    setEditClicked(false);
  }

  const popupCloseHandler = (e) => {
    setPopUpVisibility(false);
  };

  useEffect(() => {
    dispatch(getPenaltyConfiguration())
  }, [])

  return (
      <div className={styles.mainContainer}>

          <div className={styles.leftContainer}>
              <SideNavigation parentName={TABS.adtAlarmMonitoring} />
          </div>

          <CustomPopup
            showPopUp={deleteId}
            onConfirm={onPenaltyConfigDeleteConfirm}
            title={ENWORDS.removeConifgTitle}
            onReject={()=>{setToDeleteId(undefined)}}
          >
              <p>{ENWORDS.removeConfigText}</p>
          </CustomPopup> 
          <AddPenaltyConfigPopup
            onClose={popupCloseHandler}
            show={popupVisibility}
            tableData={filterData}
          />
          <div className={styles.rightContainer}>
              <div className={styles.siteHeader}>
                  <div className = { globalStyles.filterContainerTwo}>
                      {hasWriteAccess && 
                              (editClicked ?
                                  <div className={conStyles.actionBtnContainer}>
                                      <button
                                        className={conStyles.btnCancel}
                                        title={ENWORDS.cancel}
                                        onClick={() => onCancelPenaltyConfigEditedData()}
                                      >
                                          {ENWORDS.cancel}
                                      </button>

                                      <button className={conStyles.btnDone} title={ENWORDS.save} onClick={() =>
                                        onClickSavePenaltyConfigEditedData()
                                      }>{ENWORDS.save}</button>
                                  </div>
                                :   

                                  <div className={conStyles.actionBtnContainer}>
                                      <button className={conStyles.btnDone} title={ENWORDS.addPenaltyConfiguration} onClick={addData}>
                                          <img src={plusIcon} alt={ENWORDS.plusIcon}/>
                                      </button>
                                      <button className={conStyles.btnDone} title={ENWORDS.editPenaltyConfiguration} onClick={editData}>
                                          <img src={editPenIconLight} alt ={ENWORDS.editPenIcon}/>
                                      </button>
                                  </div>
                  
                              )
                      }
                  </div>
              </div>
              <ConfigTable
                tableData={filterData}
                headerData={editClicked ? CONFIGURATIONHEADERS : CONFIGURATIONSCREENHEADERS}
                editClicked = {editClicked}
                saveClicked = {saveEditedData}
                actionIcon = {onDeleteClick}
              />
          </div>
      </div>
  );
};

export default ConfigurationScreen;
