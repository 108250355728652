import React,{ useEffect, useState } from 'react'
import styles from '../../styles/popUp.module.css';
import PropTypes from 'prop-types';
import cancelIcon from '../../assets/icons/cancelIcon.svg'
import { downloadCSV, updateInvoiceSummary } from '../../api/services/invoiceListingService';
import { useDispatch } from 'react-redux';
import { setLoadingFlag } from '../../redux/actions/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants/appRoute';
import InvoiceDropdown from './InvoiceDropdown';
import { getModuleDetailsToDisplay } from '../../api/services/moduleConfigurationService';
import { format } from 'date-fns';
import CustomDatePicker from './CustomDatePicker';
import { globalYearMonthDateFormat } from '../constants/appConstants';

const ENWORDS  = require('../local/en.json');

const InvoiceFileDownloadPopUp = (props) => {
  const dispatch = useDispatch();
  const navigate  = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const [ show, setShow ] = useState(true);
  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };
  const submitInvoiceSummary = async () => {
    dispatch(setLoadingFlag(true));
    const res  = await updateInvoiceSummary(props.data);
    if (res){
      dispatch(setLoadingFlag(false))
      closeHandler()
      
      navigate(ROUTES.INVOICELISTING)
    }
    
  };
  useEffect(() => {
    setShow(props.show);
  }, [ props.show ]);

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={styles.overlay}
      >
          <div className={styles.popup}>
              <div className={styles.topSection}>
                  <p> {ENWORDS.generateInvoice}</p>
                  <button className={styles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt= {ENWORDS.cancelIcon} />
                  </button>
              </div>
              {

                  <div>
                      <div className={styles.middleSection}>
                          <p>{ENWORDS.generateMessage}</p>
                      </div>

                      <div className={styles.btnSection}>
                          <button className={styles.btnNo} onClick={closeHandler}>{ENWORDS.cancel}</button>
                          <button className={styles.btnYes} onClick={submitInvoiceSummary} >{ENWORDS.yes}</button>
                      </div>
                  </div>
              }
          </div>
      </div>
  )
}
InvoiceFileDownloadPopUp.propTypes = {

  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
export default InvoiceFileDownloadPopUp;
