import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import globalStyles from '../../../../styles/global.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import { COMPANYTYPE } from '../../baseConfigurationConstant';
import companyStyles from '../../../../styles/baseconfiguration/CompanyDetails.module.css';
import ImageUploadPopUp from '../../../../shared/Components/ImageUploadPopUp';
import cancelIcon from '../../../../assets/icons/cancelIcon.svg';
import CustomInput from '../../../../shared/Components/CustomInput';
import ERRORWORDS from '../../../../shared/local/error.json';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { addCompanyDetails } from '../../../../api/services/companyManagementService';
import { setErrorFlag, setLoadingFlag } from '../../../../redux/actions';
import { ALPHA_NUM_REGEX, NOT_ROLE_RANGE } from '../../../../shared/constants/appConstants';
import CustomDropDown, { MultiSeleteDropDown } from '../../../../shared/Components/CustomDropDown';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import { getModuleDetails } from '../../../../api/services/moduleConfigurationService';
import {  commonScrollFunction } from '../../../../shared/commonFunctions';
import { getCompany } from '../../../../api/services/commonService';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json');
const COMPANYFIELDS = require('../../../../shared/local/en.json');
const AddNewCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ visibility, setVisibility ] = useState(false);
  const [ profileImg, setProfileImg ] = useState('');
  const [ companyName, setCompanyName ] = useState('');
  const [ companyAddress, setCompanyAddress ] = useState('');
  const [ companyDetail, setCompanyDetail ] = useState('');
  const [ companyId, setCompanyId ] = useState('');
  const [ netSuiteId, setNetSuiteId ] = useState('');
  const [ vatNumber, setVatNumber ] = useState('');
  const [ type, setType ] = useState('parent'); 
  const [ logo, setLogo ] = useState('');
  const [ error, setError ] = useState({});
  const [ moduleIDs, setmoduleIDs ] = useState('');
  const [ parentCompanyId, setParentCompanyId ] = useState(0); 
  const [ companyListing, setCompanyListing ] = useState([]);
  const [ isDone, setIsDone ] = useState(false);
  const moduleData = useSelector(state => state.moduleConfiguration.moduleConfigurationData );

  const getCompanyData = async ()=>{
    dispatch(setLoadingFlag(true))
    const companyData = await getCompany(0, true);
    
    if (companyData){
      dispatch(setLoadingFlag(false))
      const data = companyData.res.map(e => ({ id: e.id, value: e.name }));
      setCompanyListing(data)
      if(type !== 'parent'){
        setParentCompanyId(data[ 0 ].id)
      }

    }
  }
  useEffect(() => {
    getCompanyData()

    if(moduleData || moduleData.length === 0)
    {
      dispatch(setLoadingFlag(true))
      dispatch(getModuleDetails());

    }
  }, [])

  useEffect(() => {
    if(type.toLowerCase() !== 'parent'){
      setParentCompanyId(companyListing[ 0 ].id)
    }
  },[ type ])

  const showPopUp = () => {
    setVisibility(true);
  }
  const popupCloseHandler = (e) => {
    setVisibility(e);
  }

  const addImg =  (receivedImg) => {
    setProfileImg(receivedImg);
    const index  = receivedImg.indexOf(',')
    setLogo(receivedImg.slice(index+1))
  }
  const removeImage = () => {
    setProfileImg(null);
  }

  const checkValidation = async() => {
    let errorVariable = error
    if (companyName?.trim() === ''  || ALPHA_NUM_REGEX.test(companyName) === false ) {
      errorVariable ={ ...errorVariable, companyNameError: ERRORWORDS.companyNameError };
    }
    if (companyDetail?.trim() === '' || ALPHA_NUM_REGEX.test(companyDetail) === false  ) {
      errorVariable= { ...errorVariable, companyDetailError: ERRORWORDS.companyDetailError }
    }
    if (companyAddress?.trim() === '' || ALPHA_NUM_REGEX.test(companyAddress) === false ) {
      errorVariable = { ...errorVariable, companyAddressError: ERRORWORDS.companyAddressError }
    }
    if (companyId?.trim() === '') {
      errorVariable = { ...errorVariable, companyIdError: ERRORWORDS.companyIdError }
    }
    if (netSuiteId?.trim() === '') {
      errorVariable = { ...errorVariable, companyNetSuiteIdError: ERRORWORDS.companyNetSuiteId }
    }
    if (vatNumber?.trim() === '' || NOT_ROLE_RANGE.test(vatNumber) === false || vatNumber.length > 25 ) {
      errorVariable = { ...errorVariable, vatNumberError: ERRORWORDS.vatNumber }
    }
    if(profileImg === '' || profileImg === null){
      errorVariable = { ...errorVariable, profileImgError: ERRORWORDS.logoError }
    }
    setError(errorVariable)
    const isEmpty = Object.values(errorVariable).every(value => value === '' ||  value == null)
    
    if(isEmpty){
      dispatch(setLoadingFlag(true))
      const res = await  addCompanyDetails(
        { 
          companyId, 
          companyName, 
          companyAddress, 
          companyDetail, 
          netSuiteId, 
          vatNumber, 
          type, 
          logo, 
          parentCompanyId, 
          moduleIDs })
      if(res){
        dispatch(setLoadingFlag(false))
        navigate(ROUTES.COMPANY);
      }
      else {
        dispatch(setLoadingFlag(false))
        setErrorFlag(true);
      }
    }
    setIsDone(!isDone);
  }
  useEffect(()=>{
    let errorVariable = error
    if ( companyName?.trim() !== '' && ALPHA_NUM_REGEX.test(companyName)) {
      errorVariable = { ...errorVariable, companyNameError: '' }
    }
    if (companyDetail?.trim() !== '' && ALPHA_NUM_REGEX.test(companyDetail)) {
      errorVariable = { ...errorVariable, companyDetailError: '' }
    }
    if (companyAddress?.trim() !== '' && ALPHA_NUM_REGEX.test(companyAddress)) {
      errorVariable = { ...errorVariable, companyAddressError: '' }
    }
    if (companyId?.trim() !== '') {
      errorVariable= { ...errorVariable, companyIdError: '' }
    } 
    if (netSuiteId?.trim() !== '') {
      errorVariable= { ...errorVariable, companyNetSuiteIdError: '' }
    }
    if (vatNumber?.trim() !== '' && NOT_ROLE_RANGE.test(vatNumber)) {
      errorVariable = { ...errorVariable, vatNumberError: '' }
    }
    if(profileImg !== null && profileImg !== ''){
      errorVariable = { ...errorVariable, profileImgError:'' }
    }
    setError(errorVariable)

  },[ companyName, companyDetail, companyAddress, companyId, netSuiteId, vatNumber, profileImg  ])

  useEffect(() => {
    
    if (type === 'parent'){
      
      setParentCompanyId(0);
    }

  },[ type ]);

  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])
  return (
      <div className={styles.mainContainer}>
          <ImageUploadPopUp
            uploadType={ENWORDS.companyScreen}
            show={visibility}
            onClose={popupCloseHandler}
            onAddImg={addImg}
          />
          <div  className={supplierStyles.subContainer}>
              <div className={supplierStyles.headerContainer}>
                  <ListingHeader
                    title = {ENWORDS.addCompany}
                    navigationPath = {-1}
                  />
              </div>
              <div className={supplierStyles.contentContainer}>
                  <div className={supplierStyles.content}>
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{COMPANYFIELDS.companyDetail}</p>
                          </div>
                      </div>
                      <div className={styles.companyForm}>
                          <div className={inputStyles.detailsRow}>
                            
                              <CustomInput 
                                title={COMPANYFIELDS.companyName} 
                                value={companyName ?? ''} 
                                isFocused = {true}
                                placeholder = {PLACEHOLDER.companyName}
                                onChange = {e => setCompanyName((e))} 
                                error ={error.companyNameError}/>

                              <CustomInput 
                                title={COMPANYFIELDS.companyDetail} 
                                value={companyDetail ?? ''} 
                                placeholder = {PLACEHOLDER.companyDetails}
                                onChange = {e => setCompanyDetail((e))} 
                                error = {error.companyDetailError} />

                              <CustomInput 
                                title={COMPANYFIELDS.companyAddress} 
                                
                                placeholder = {PLACEHOLDER.address}
                                value={companyAddress ?? ''} 
                                onChange = {e => setCompanyAddress((e))} 
                                error = {error.companyAddressError}/>

                              <CustomInput 
                                title={COMPANYFIELDS.companyId} 
                                value={companyId ?? '' } 
                                placeholder = {PLACEHOLDER.companyId}
                                onChange = { e => setCompanyId((e))} 
                                error = {error.companyIdError} />

                              <CustomInput 
                                title={COMPANYFIELDS.netSuiteId} 
                                value={netSuiteId ?? ''} 
                                placeholder = {PLACEHOLDER.netSuiteID}
                                onChange = {e => setNetSuiteId((e))} 
                                error = {error.companyNetSuiteIdError} />

                              <CustomInput 
                                title={COMPANYFIELDS.vatNumber} 
                                value={vatNumber ?? ''} 
                                placeholder = {PLACEHOLDER.vatNumber}
                                onChange = {e =>  setVatNumber((e))} 
                                error = {error.vatNumberError} />

                              <CustomDropDown 
                                title = {ENWORDS.companyType} 
                                fieldValues = {COMPANYTYPE}
                                onChange = { (e) => setType(e.target.value) }
                              />                          
                              {type && type.toLocaleLowerCase() !== 'parent' ?
                                  <CustomDropDown 
                                    title = {ENWORDS.parent} 
                                    fieldValues = {companyListing}
                                    renderKeyOne={'value'}
                                    onChange = { (e) => setParentCompanyId(e.target.value) }
                                  />
                                : null}                          
                              <MultiSeleteDropDown 
                                title = {ENWORDS.assignModule} 
                                idKey= "id"
                                fieldValues = {moduleData}
                                renderKeyOne={'moduleName'}
                                selectedPlaceHolder={PLACEHOLDER.selectedPlaceholder}
                                notSelectedPlaceHolder={PLACEHOLDER.notSelectedPlaceholder}
                                onChange = {setmoduleIDs}
                              />
                            
                          </div>
                          <div className={companyStyles.imageContainer}>
                              <div className={companyStyles.logoContainer}>
                                  <p className={companyStyles.logoTitle}>{ENWORDS.companyLogo}</p>
                                  <div className={profileImg !== '' && profileImg !== null ? companyStyles.subImageContainer : companyStyles.subImageContainerHide}>
                                      <div className={companyStyles.crossIcon} onClick={removeImage}><img src={cancelIcon} alt={ENWORDS.crossIcon} /></div>
                                      <div className={companyStyles.logo}><img src={profileImg} alt={ENWORDS.logo} /> </div>
                                  </div>
                                  {<p className={error.profileImgError ? globalStyles.errorMessageShow: globalStyles.errorMessage}>{error.profileImgError}</p> }
                                  <button className={companyStyles.btnAddImage} onClick={showPopUp}>
                                      <p>{profileImg !=='' && profileImg !== null ? ENWORDS.changeImage : ENWORDS.addImg}</p>
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className={styles.btnContainer}>
                          <div className={styles.cancelBtn} onClick={()=> navigate(-1)}>
                              <button>{ENWORDS.cancel}</button>
                          </div>
                          <div className={styles.doneBtn}>
                              <button onClick={checkValidation}>{ENWORDS.done}</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default AddNewCompany