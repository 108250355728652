import { MODULE_CONFIGURATION_DATA, MODULES_PAGE_NUMBER } from '../../shared/constants/actionType';

export const appendModules = (moduleData) => {
  return {
    type: MODULE_CONFIGURATION_DATA,
    payload: moduleData,
  }
}
export const updatModulePageNumber = (data)=>{
  return{
    type: MODULES_PAGE_NUMBER,
    payload: data,
  }
}