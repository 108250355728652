import { makeRequest } from '../makeRequest';
import { API_HEADER, GET, POST,DELETE } from '../../shared/constants/appConstants';
import { MODULEURL } from '../../shared/constants/appUrls'
import { appendModules } from '../../redux/actions/moduleConfiguration';
import reduxStore from '../../redux/store/index.js'
import { setLoadingFlag } from '../../redux/actions';

export function getModuleDetails() {
  return async (dispatch) => {
    await makeRequest({
      method: GET,
      url: MODULEURL.getModules,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(appendModules(response.data.data))
        dispatch(setLoadingFlag(false));
      })
  }
}

export async function addModule({ moduleName,itemName,transactionType, fieldMapper, prefix, companyAssignment, userId, supplierId, apExport,
  arExport, nlExport, exception }){
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url:MODULEURL.addModule,
    data:   {
      id: 0,
      moduleName: moduleName,
      itemName: itemName,
      transactionType: transactionType,
      fieldMapper: fieldMapper,
      prefix: prefix,
      companyAssignment: companyAssignment,
      userId: userId,
      supplierId: supplierId,
      apExport: apExport,
      arExport: arExport,
      nlExport: nlExport,
      exception: exception,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false
      errRes = err.response;
    });
  return { res:res, err:errRes };
}

export async function getModuleDetailsToDisplay(companyName = '', isInvoiceAssignment = false){
  let res = [];
  let errRes = {};
  const reduxState = reduxStore.getState();
  const currentUserId =  reduxState.auth.user?.id
  
  await makeRequest({
    method: GET,
    url: companyName || isInvoiceAssignment?
      MODULEURL.getModulesByCompanyName + companyName:
      MODULEURL.getModulesByUserId + currentUserId,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res  = [];
      errRes = err
    });
  return { res:res, err:errRes };
}

export async function getAllModules(){
  let res = [];
  let errRes = {};
  await makeRequest({
    method: GET,
    url: MODULEURL.getModules,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res  = [];
      errRes = err
    });
  return { res:res, err:errRes };
}

export async function updateModule({  id, moduleName, itemName, transactionType, fieldMapper, prefix, companyAssignment, userId, supplierId, apExport,
  arExport, nlExport ,exception }){
  let res;
  let errRes = {};
  
  const HEADER = API_HEADER;
  Object.assign(HEADER, { 'moduleId': id });
  await makeRequest({
    method: POST,
    url:MODULEURL.editModule,
    data: {
      id: id,
      moduleName: moduleName,
      itemName: itemName,
      transactionType:transactionType,
      fieldMapper: fieldMapper,
      prefix: prefix,
      companyAssignment: companyAssignment,
      userId: userId,
      supplierId: supplierId,
      apExport: apExport,
      arExport: arExport,
      nlExport: nlExport,
      exception: exception,
    },
    headers: HEADER,
  })
    .then(function (response) {
      res = true 
    })
    .catch(function (err) {
      res = false
      errRes = err.response;
    });
  return { res:res, err:errRes };
}

export async function getModuleById(moduleID) {
  
  let res;
  let errRes = {};
  const HEADER = API_HEADER;
  Object.assign(HEADER, { 'moduleId': moduleID });
  
  await makeRequest({
    method: GET,
    url: `${ MODULEURL.getModuleById + moduleID }`,
    data: undefined,
    header: HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      errRes = err.response
    });

  return  { res:res, err:errRes };
}

export async function getRequiredFieldsData() {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: MODULEURL.getRequiredFields,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      errRes = err.response
    });

  return  { res:res, err:errRes };
}

export async function getModuleStationMapping() {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: MODULEURL.getStationMapping,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      errRes =err.response
    });

  return  { res:res, err:errRes };
}

export async function checkModuleAvailability  (moduleId, moduleName) {
  let res;
  await makeRequest({
    method: GET,
    url: `${ MODULEURL.checkModuleName + moduleId + '&moduleName=' + moduleName }`,
    data: undefined,
    showPopUp: false,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (){
      res = false;
    })
  return res;
}
export async function checkPrefixAvailability  (prefix) {
  let res;
  await makeRequest({
    method: GET,
    url: `${ MODULEURL.checkPrefix + prefix }`,
    data: undefined,
    showPopUp: false,
    headers: API_HEADER,
  })
    .then(function (response) {
      
      res = response.data.data
    })
    .catch(function (){
      res = false;
    })
  return res;
}

export function deleteModuleField( moduleId, moduleFieldID ) {
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ MODULEURL.deleteModuleField + moduleId + MODULEURL.moduleFieldID + moduleFieldID }`,
      data: undefined,
      headers: API_HEADER,
    })
  }
}

export function deleteModuleById( moduleId) {
  const HEADER = API_HEADER;
  Object.assign(HEADER, { 'moduleId':  moduleId });
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ MODULEURL.deleteModuleById + moduleId }`,
      data: undefined,
      headers: HEADER,
    })
      .then(function(response){
        dispatch(getModuleDetails())
        dispatch(setLoadingFlag(false))

      })
  }
}