import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import inputStyles from '../../../../styles/inputs.module.css';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import companyStyles from '../../../../styles/baseconfiguration/CompanyDetails.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../shared/constants/appRoute';
import editPenIconLight from '../../../../assets/icons/editPenWhiteIcon.svg';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import bin from '../../../../assets/icons/binIcon.svg';
import globalStyles from '../../../../styles/global.module.css';
import InvoiceVerificationPopUp from '../../../../shared/Components/InvoiceVerificationPopUp';
import { setLoadingFlag } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { checkIfInvoiceExist } from '../../../../api/services/commonService';
import { deleteCompany, getCompanies } from '../../../../api/services/companyManagementService';

const COMPANYFIELDS = require('../../../../shared/local/en.json');
const AboutCompany = () =>{
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const dispatch =  useDispatch ();
  const [ companyDetails , setCompanyDetails ] = useState();
  const [ profileImg, setProfileImg ] = useState();
  const [ toDeleteCompanyId, setToDeleteCompanyId ] = useState(0);
  const [ toDeleteCompanyName, setToDeleteCompanyName ] = useState('');
  const [ visibility, setVisibility ] = useState(false);
  const [ invoiceList, setInvoiceList ] = useState([]);

  const showPopUp = (name,  Cid) =>{
    setVisibility(true);
    setToDeleteCompanyName(name);
    setToDeleteCompanyId(Cid)
  }
  
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  useEffect(()=>{
    if (locationState){
      setCompanyDetails(locationState?.data)
      setProfileImg(ENWORDS.base64Prefix+ locationState?.data.logo)
    }
  },[])

  const onEditCompany  =()=>{
    navigate(ROUTES.EDITCOMPANY, { state:{ data: companyDetails } })
  }

  const invoiceVerficationCall = async (formId, formName) => {

    dispatch(setLoadingFlag(true));
    const verificationCall = await checkIfInvoiceExist(formId, 'companyID');

    if (verificationCall.statusCode === 200) {
      dispatch(setLoadingFlag(false))
      setInvoiceList(verificationCall.res);
      showPopUp(formName, formId);
    }
  };
  const deleteRow = () => {
    dispatch(setLoadingFlag(true));
    dispatch(deleteCompany(toDeleteCompanyId))
    dispatch(getCompanies())
    navigate(ROUTES.COMPANY);
  };
  return (
      <div className={supplierStyles.mainContainer}>
          <InvoiceVerificationPopUp
            onClose={popupCloseHandler}
            show={visibility}
            title={ENWORDS.deleteCompanyTitle}
            text={ENWORDS.commonCheckboxMsg}
            checkboxMsg={ENWORDS.commonCheckboxMsg}
            selectedId={toDeleteCompanyId}
            selectedName={toDeleteCompanyName}
            screenName={ENWORDS.editStation}
            invoiceList={invoiceList ?? []}
            onDelete={deleteRow}
          />
          <div className={supplierStyles.supplierDetailsTopContainer}>
              <ListingHeader
                title = {  companyDetails && companyDetails.type === 'subsidiary' ? ENWORDS.subsidiaryDetails: ENWORDS.companyDetails}
                navigationPath = {-1}
              />    
              {
                locationState.roleAccess && locationState.roleAccess.writeAccess 
                  ? 
                      <div className={styles.buttonContainer}>
                          <button className={styles.btnCancel}  onClick = {()=>invoiceVerficationCall( companyDetails && companyDetails.id ,companyDetails && companyDetails.name)}>
                              <img src={bin} alt = {ENWORDS.deleteIcon}/>
                          </button>
        
                          <button className={styles.btnDone} onClick={onEditCompany}>
                              <img src={editPenIconLight} alt ={ENWORDS.editPenIcon}/>
                          </button>
                      </div>
                  : null
              }

          </div>

          <div className={supplierStyles.contentContainer}>
              <div className={supplierStyles.content}>
                  <div className={styles.siteNavContainer}>
                      <div className={styles.siteNavigation}>
                          <p className={styles.robotoMediam}>{companyDetails && companyDetails.type === 'subsidiary' ? ENWORDS.subsidiaryDetails: ENWORDS.companyDetails}</p>                           
                      </div>
                  </div>
              </div>
              <div className={styles.companyForm}>
                  <div className={inputStyles.detailsRow}>   
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.companyName}</p>
                          <div className={styles.displayBox}> {companyDetails && companyDetails.name}</div>
                      </div> 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.companyDetail}</p>
                          <div className={styles.displayBox}> { companyDetails && companyDetails.details}</div>
                      </div> 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.companyAddress}</p>
                          <div className={styles.displayBox}> {companyDetails && companyDetails.address}</div>
                      </div> 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.companyId}</p>
                          <div className={styles.displayBox}> {companyDetails && companyDetails.companyId}</div>
                      </div> 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.netSuiteId}</p>
                          <div className={styles.displayBox}> {companyDetails && companyDetails.netSuiteId}</div>
                      </div> 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.vatNumber}</p>
                          <div className={styles.displayBox}> {companyDetails && companyDetails.vatNumber}</div>
                      </div> 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.companyType}</p>
                          <div className={styles.displayBox}> {companyDetails && companyDetails.type}</div>
                      </div> 
                      {companyDetails && companyDetails.type !== 'parent' && companyDetails.parentName !== null?
                          <div className={styles.formFieldContainer}>
                              <p>{COMPANYFIELDS.parent}</p>
                              <div className={styles.displayBox}> {companyDetails && companyDetails.parentName}</div>
                          </div>: null} 
                      <div className={styles.formFieldContainer}>
                          <p>{COMPANYFIELDS.module}</p>
                          <div className={globalStyles.selectionBox}>
                              <div className={companyStyles.selectLable}>
                                  {companyDetails && companyDetails.moduleNames?.length > 0 ? 'Selected Modules ' + companyDetails.moduleNames.length : 'No Modules Selected'}  
                              </div>
                              <select>
                                  {
                                    companyDetails && companyDetails.moduleNames?.map(e => <option key={e} disabled>{e}</option>)
                                  }
                              </select>
                          </div>
                      </div> 
                                      
                  </div>
                  <div className={companyStyles.imageContainer}>
                      <div className={companyStyles.logoContainer}>
                          <p className={companyStyles.logoTitle}>{ENWORDS.companyLogo}</p>
                          <div className={profileImg ? companyStyles.subImageContainer : companyStyles.subImageContainerHide}>
                              <div className={companyStyles.logo}>
                                  <img src={profileImg && profileImg} alt={ENWORDS.logo} />
                              </div>
                          </div>
                          <p>{profileImg ? ENWORDS.changeImage : ENWORDS.addImg}</p>
                              
                      </div>
                  </div>
              </div>
          </div>
      </div>              
  )
      
}
export default AboutCompany;