import { SET_OPS_REGIONS_DATA, OPS_REGIONS_FILTER, OPS_REGIONS_PAGE_NUMBER } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  opsRegionsTableData: [],
  opsRegionStatusFilter: '',
  pageNumber: 0,
};

const opsRegionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_OPS_REGIONS_DATA:
    return {
      ...state,
      opsRegionsTableData: action.payload,
    };
  case OPS_REGIONS_FILTER:
    return {
      ...state,
      opsRegionStatusFilter: action.payload,
    };
  case OPS_REGIONS_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.payload,
    };
  default:
    return state;
  }
};

export default opsRegionsReducer;
