const ProgressBar = (props) => {
  const { completed } = props;

  const mainContainer = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent:'center',
    opacity: '10',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    transition: 'opacity 500ms',
    zIndex: '22',

  }
  const containerStyles = {
    backgroundColor: 'white',
    position: 'fixed',
    zIndex: 25,
    height: 20,
    width: '50%',
    borderRadius: 50,
    margin: '0rem 1rem 1rem 1rem',
    
  }
  
  const fillerStyles = {
    height: '100%',
    width: `${ completed }%`,
    backgroundColor: 'red',
    borderRadius: 'inherit',
    textAlign: 'right',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
      <div style={mainContainer}> 
          <div style={containerStyles}>
              <div style={fillerStyles}>
                  <span style={labelStyles}>{`${ completed }%`}</span>
              </div>
              <p>{props.msg}</p>
          </div>
      </div>
  );
};

export default ProgressBar;