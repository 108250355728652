import React, { useState, useEffect } from 'react';
import styles from '../../../styles/Login/Login.module.css';
import LoginScreenBackground from './LoginScreenBackground';
import { useNavigate, useLocation } from 'react-router';
import { AUTHROUTES } from '../../../shared/constants/appRoute';
import { onJwtSignIn, onResendOTP } from '../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFlag, fetchError } from '../../../redux/actions/common';
import { OTP_REGEX } from '../../../shared/constants/appConstants.js';
import LoginCustomInput from '../../../shared/Components/LoginCustomInput';
const ERRORWORDS = require('../../../shared/local/error.json') ;
const ENWORDS = require('../../../shared/local/en.json');

function OTPContent() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serverError = useSelector(value => value.auth.fetchError)
  const[ otp, setotp ] = useState('');
  const[ error, setError ] = useState('');

  const submitOTP = async (e) => {
    e.preventDefault();
    const otpLen = otp.length;
	  if (OTP_REGEX.test(otp) && otpLen === 4) {
      setError('');
      dispatch(setLoadingFlag(true))
      dispatch(onJwtSignIn({ ...state, otp }));      
      setError(serverError)
	  } else {
		  setError(ERRORWORDS.otpError);
	  }
  }

  const resendOTP = async (e) => {
    dispatch(setLoadingFlag(true));
    dispatch(onResendOTP({ ...state }));
    dispatch(fetchError(''))
  }
  const navigateToLogin = () =>{
    dispatch(fetchError(''))
    navigate(AUTHROUTES.LOGINSCREEN)
  }
  
  useEffect(()=>{
    dispatch(fetchError(''))
  },[])

  useEffect(()=>{
    setError(serverError)
  },[ serverError ])

  useEffect(() =>{
    const otpLen = otp.length;
    if(OTP_REGEX.test(otp) && otpLen === 4){
      setError('')
    }
  },[ otp ])
  return(
      <LoginScreenBackground>
          <div>
              <div className={styles.loginLabel}>{ENWORDS.login}</div> 
              <div className={styles.form}>
                  {
                      <form className={styles.formSpace} onSubmit={submitOTP}>
                          <LoginCustomInput 
                            title={ENWORDS.otp} 
                            onChange = {(e) => setotp(e.target.value)} 
                            isFocused = {true} 
                            maxLength = {4}
                            error={error}/>
                          <div className={`${ styles.checkboxContainer } ${ styles.resendOTP }`}>
                              <div className={styles.forgotPass} onClick={() => resendOTP()}>
                                  <label>{ENWORDS.resendOtp}</label>
                              </div>
                          </div>
                          <div className={styles.emptyDiv }></div>
                          <div className={styles.inputContainer}>
                              <button className={styles.otpButton} type= {ENWORDS.typeSubmit}>{ENWORDS.verify}</button>
                              <button className={styles.transparentButton} onClick={navigateToLogin}>{ENWORDS.cancel}</button>
                          </div>
                         
                      </form>
                  }
              </div>
              <div>
                  <div className={ error ? `${ styles.emptyDiv } ${ styles.errorMessage }` : styles.hideError}>{error}</div>
              </div>
          </div>
      </LoginScreenBackground>
  );
}

export default OTPContent;