import { UPDATE_INVOICE_DATA, INVOICE_FILTER, INVOICE_PAGE_NUMBER } from '../../shared/constants/actionType';

const INIT_STATE  ={
  invoiceData:[],
  invoiceFilter: {
    selectedSite: '',
    selectedInvoiceStatus: '',
    fromDate: '',
    toDate: '',
    selectedModuleId: '',
    selectedModuleName: '',
  },
  pageNumber: 0,
}

const invoiceListingReducer = (state = INIT_STATE, action) =>{
  switch(action.type){
  case UPDATE_INVOICE_DATA:
    return{
      ...state,
      invoiceData: action.payload,
    }
  case INVOICE_FILTER:
    return{
      ...state,
      invoiceFilter: action.payload,
    }
  case INVOICE_PAGE_NUMBER:
    return{
      ...state,
      pageNumber: action.payload,
    }
  default: return state
  }
}
export default invoiceListingReducer;