export const SUPPLIERHEADERS = {
  supplierName:'Name',
  supplierCode:'Supplier Account Id',
  termsDate: 'Term Days',
  supplierCommision:'Commision',
  supplierAction:'Action',
}

export const COMPANYHEADERS = {
  name:'Name',
  netSuiteId:'NetSuite ID',
  vatNumber:'VAT Number',
  type:'Type',
  companyAction:'Action',
}

export const VATHEADER = {
  vatName:'VAT Name',
  vatCode : 'VAT Code',
  vatRate: 'VAT Rate',
  effectiveFrom: 'Effective from',
  effectiveTo: 'Effective to',
}

export const ROLEFIELDS = {
  roleName:'Role Name',
  roleAction:'Action',
}

export const COMPANYFIELDS = {
  companyName :'Company Name',
  companyDetail:'Company Details',
  companyAddress:'Company Address',
  companyId:'Company ID',
  netSuiteId:'NetSuite ID',
  vatNumber:'VAT Number',
  COMPANYTYPE:'Company Type',
}

export const SITESTATUS = {
  All : 'All',
  Active:'Active',
  Inactive:'Inactive',
  ComingSoon:'Coming Soon',
  Closed: 'Closed',
};

export const STATUSSTATION = {
  Active:'Active',
  Inactive:'Inactive',
  'Coming Soon':'Coming Soon',
  Closed: 'Closed',
}

export const TERMDATE = {
  '10':'10 days',
  '15':'15 days',
  '20':'20 days',
  '30':'30 days',
  '60':'60 days',
};
export const COMPANYTYPE = {
  'parent':'Parent',
  'subsidiary':'Subsidiary',
}
export const STATUSTYPE = {
  'activated' : 'Activated',
  'deactivated' :'Deactivated',
}

export const STATIONSHEDULE = {
  openHrs:'Open 24Hrs',
  Scheduler : 'Schedule', 
};

export const GLOBALSTATUS = {
  1:'Active',
  0:'Inactive',
}

export const GLOBALSEARCHSTATUS = {
  'All' : 'All',
  '1':'Active',
  '0':'Inactive',
};
