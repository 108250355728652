import { createAction } from '@reduxjs/toolkit';
import { SET_COMPANY_TABLE, COMPANY_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setCompanyTable = createAction(SET_COMPANY_TABLE);

export const updateComponyPageNumber = (data)=>{
  return{
    type: COMPANY_PAGE_NUMBER,
    payload: data,
  }
}