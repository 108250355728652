import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import globalStyles from '../../../../styles/global.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import companyStyles from '../../../../styles/baseconfiguration/CompanyDetails.module.css';
import ImageUploadPopUp from '../../../../shared/Components/ImageUploadPopUp';
import cancelIcon from '../../../../assets/icons/cancelIcon.svg';
import CustomInput from '../../../../shared/Components/CustomInput';
import ERRORWORDS from '../../../../shared/local/error.json';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateCompanyDetails } from '../../../../api/services/companyManagementService';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { setErrorFlag, setLoadingFlag } from '../../../../redux/actions';
import { ALPHA_NUM_REGEX, NOT_ROLE_RANGE } from '../../../../shared/constants/appConstants';
import { COMPANYTYPE } from '../../baseConfigurationConstant';
import CustomDropDown, { MultiSeleteDropDown } from '../../../../shared/Components/CustomDropDown';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import { getModuleDetails } from '../../../../api/services/moduleConfigurationService';
import {  commonScrollFunction } from '../../../../shared/commonFunctions';
import { getCompany } from '../../../../api/services/commonService';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json');
const COMPANYFIELDS = require('../../../../shared/local/en.json');

const EditCompanyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = location.state;
  const [ companyDetails, setCompanyDetails ] = useState();
  const [ visibility, setVisibility ] = useState(false);
  const [ profileImg, setProfileImg ] = useState(null);
  const [ companyName, setCompanyName ] = useState('');
  const [ companyAddress, setCompanyAddress ] = useState('');
  const [ companyDetail, setCompanyDetail ] = useState('');
  const [ companyId, setCompanyId ] = useState('');
  const [ netSuiteId, setNetSuiteId ] = useState('');
  const [ vatNumber, setVatNumber ] = useState('');
  const [ companyType, setCompanyType ] = useState('');
  const [ logo, setLogo ] = useState(null);
  const [ error, setError ] = useState({});
  const [ id, setId ] = useState('');
  const [ moduleIDs, setmoduleIDs ] = useState([]);
  const [ parentCompanyId, setParentCompanyId ] = useState(0); 
  const [ companyListing, setCompanyListing ] = useState([]);
  const [ isDone, setIsDone ] = useState(false);
  const moduleData = useSelector(state => state.moduleConfiguration.moduleConfigurationData );

  const getCompanyData = async (cID)=>{
    const companyData = await getCompany(0, true);
    if (companyData){
      const filteredCompanyData = companyData.res.filter((e)=> e.id !== cID);
      const data = filteredCompanyData.map(e => ({ id: e.id, value: e.name }));
      setCompanyListing(data)

    }
  }
  useEffect(() => {

    if(moduleData || moduleData.length === 0)
    {
      dispatch(setLoadingFlag(true))
      dispatch(getModuleDetails());

    }
  }, [])

  const showPopUp = () => {
    setVisibility(true);
  }
  const popupCloseHandler = (e) => {
    setVisibility(e);
  }
  const addImg = (receivedImg) => {
    setProfileImg(receivedImg);
    const index  = receivedImg.indexOf(',')
    setLogo(receivedImg.slice(index+1))
  }
  const removeImage = () => {
    setProfileImg(null)
  }
  const companyDetailBack = () =>{
    navigate(ROUTES.COMPANY)
  }

  const checkValidation = async() => {
    let errorVariable = error
    if (companyName?.trim() === '' || ALPHA_NUM_REGEX.test(companyName) === false ) {
      errorVariable ={ ...errorVariable, companyNameError: ERRORWORDS.companyNameError };
    }
    if (companyDetail?.trim() === '' || ALPHA_NUM_REGEX.test(companyDetail) === false )  {
      errorVariable= { ...errorVariable, companyDetailError: ERRORWORDS.companyDetailError }
    }
    if (companyAddress?.trim() === '' || ALPHA_NUM_REGEX.test(companyAddress) === false )  {
      errorVariable = { ...errorVariable, companyAddressError: ERRORWORDS.companyAddressError }
    }
    if (companyId?.trim() === '' ) {
      errorVariable = { ...errorVariable, companyIdError: ERRORWORDS.companyIdError }
    }
    if (netSuiteId?.trim() === ''  ) {
      errorVariable = { ...errorVariable, companyNetSuiteIdError: ERRORWORDS.companyNetSuiteId }
    }
    if (vatNumber?.trim() === '' || NOT_ROLE_RANGE.test(vatNumber) === false || vatNumber.length > 25 ) {
      errorVariable = { ...errorVariable, vatNumberError: ERRORWORDS.vatNumber }
    }
    if(profileImg === null || profileImg === ''){
      errorVariable = { ...errorVariable, profileImgError : ERRORWORDS.logoError }
    }
    setError(errorVariable)
    const isEmpty = Object.values(errorVariable).every(x => x === '' ||  x == null)
    if(isEmpty){
      dispatch(setLoadingFlag(true))
      const res = await updateCompanyDetails(
        { companyId ,
          companyName,
          companyDetail, 
          companyAddress, 
          netSuiteId, 
          vatNumber,
          companyType, 
          logo, 
          id,
          parentCompanyId,
          moduleIDs,
        })
      
      if(res){
        dispatch(setLoadingFlag(false))
        navigate(ROUTES.COMPANY);
      }else{
        dispatch(setLoadingFlag(false))
        dispatch(setErrorFlag(false))
      }
    }
    setIsDone(!isDone);
  }

  useEffect(()=>{
    if (locationState){
      setCompanyDetails(locationState?.data)
    }
  },[])

  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])

  useEffect(() =>{
    if(companyDetails){
      
      setCompanyName( companyDetails.name);
      setCompanyId( companyDetails.companyId);
      setCompanyAddress( companyDetails.address);
      setCompanyDetail( companyDetails.details);
      setVatNumber( companyDetails.vatNumber);
      setParentCompanyId(companyDetails.parentId);
      setmoduleIDs(companyDetails.moduleIds);
      setNetSuiteId(companyDetails.netSuiteId);
      setCompanyType( companyDetails.type?.toLowerCase());
      setLogo(  companyDetails.logo );
      setProfileImg( ENWORDS.base64Prefix+ companyDetails.logo)
      setId(companyDetails.id);
      getCompanyData(companyDetails.id);
    }
  },[ companyDetails ])
  useEffect(() => {
    
    if (companyType === 'parent'){
      
      setParentCompanyId(0);
    }
    if(companyType !== 'parent' && companyListing.length > 0 && parentCompanyId === 0){
      setParentCompanyId(companyListing[ 0 ].id)
    }

  },[ companyType ]);
  useEffect(()=>{
    let errorVariable = error
    if ( companyName?.trim() !== '' && ALPHA_NUM_REGEX.test(companyName)) {
      errorVariable = { ...errorVariable, companyNameError: '' }
    }
    if (companyDetail?.trim() !== '' && ALPHA_NUM_REGEX.test(companyDetail) ) {
      errorVariable = { ...errorVariable, companyDetailError: '' }
    }
    if (companyAddress?.trim() !== '' && ALPHA_NUM_REGEX.test(companyAddress)) {
      errorVariable = { ...errorVariable, companyAddressError: '' }
    }
    if (companyId?.trim() !== '' ) {
      errorVariable= { ...errorVariable, companyIdError: '' }
    } 
    if (netSuiteId?.trim() !== '' ) {
      errorVariable= { ...errorVariable, companyNetSuiteIdError: '' }
    }
    if (vatNumber?.trim() !== '' && NOT_ROLE_RANGE.test(vatNumber) ) {
      errorVariable = { ...errorVariable, vatNumberError: '' }
    }
    if(profileImg !== null && profileImg !== ''){
      errorVariable = { ...errorVariable, profileImgError : '' }
    }
    setError(errorVariable)
  },[ companyName, companyDetail, companyAddress, companyId, netSuiteId, vatNumber, profileImg ])

  return (
      <div className={styles.mainContainer}>
          <ImageUploadPopUp
            uploadType={ENWORDS.companyScreen}
            show={visibility}
            onClose={popupCloseHandler}
            onAddImg={addImg}
          />
          <div className={supplierStyles.subContainer}>
              <div className={supplierStyles.headerContainer}>
                  <ListingHeader
                    title={ ENWORDS.editCompany}
                    navigationPath = {-1}
                  />
              </div>
              <div className={supplierStyles.contentContainer}>
                  <div className={supplierStyles.content}>
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{COMPANYFIELDS.companyDetail}</p>
                          </div>
                      </div>
                      <div className={styles.companyForm}>
                          <div className={inputStyles.detailsRow}>
                            
                              <CustomInput 
                                title={COMPANYFIELDS.companyName} 
                                value={companyName ?? ''} 
                                placeholder = {PLACEHOLDER.companyName}
                                onChange = {e => setCompanyName((e))} 
                                error ={error.companyNameError}/>

                              <CustomInput 
                                title={COMPANYFIELDS.companyDetail} 
                                value={companyDetail ?? ''} 
                                placeholder = {PLACEHOLDER.companyDetails}
                                onChange = {e => setCompanyDetail((e))} 
                                error = {error.companyDetailError} />

                              <CustomInput 
                                title={COMPANYFIELDS.companyAddress} 
                                value={companyAddress ?? ''} 
                                placeholder = {PLACEHOLDER.address}
                                onChange = {e => setCompanyAddress((e))} 
                                error = {error.companyAddressError}/>

                              <CustomInput 
                                title={COMPANYFIELDS.companyId} 
                                value={companyId ?? '' } 
                                placeholder = {PLACEHOLDER.companyId}
                                onChange = { e => setCompanyId((e))} 
                                error = {error.companyIdError} />

                              <CustomInput 
                                title={COMPANYFIELDS.netSuiteId} 
                                value={netSuiteId ?? ''} 
                                placeholder = {PLACEHOLDER.netSuiteID}
                                onChange = {e => setNetSuiteId((e))} 
                                error = {error.companyNetSuiteIdError} />

                              <CustomInput 
                                title={COMPANYFIELDS.vatNumber} 
                                value={vatNumber ?? ''} 
                                placeholder = {PLACEHOLDER.vatNumber}
                                onChange = {e =>  setVatNumber((e))} 
                                error = {error.vatNumberError} />

                              <CustomDropDown 
                                title = {ENWORDS.companyType} 
                                selectedValue = {companyType}
                                name = {'companyType'}
                                fieldValues = {COMPANYTYPE}
                                onChange = { (e) => setCompanyType(e.target.value) }
                              />  
                              {companyType && companyType.toLocaleLowerCase() !== 'parent' ?
                                  <CustomDropDown 
                                    title = {ENWORDS.parent} 
                                    selectedValue={parentCompanyId}
                                    fieldValues = {companyListing}
                                    renderKeyOne={'value'}
                                    onChange = { (e) => setParentCompanyId(e.target.value) }
                                  />
                                : null}                          
                              <MultiSeleteDropDown 
                                title = {ENWORDS.assignModule} 
                                idKey= "id"
                                fieldValues = {moduleData}
                                selectedValue ={moduleIDs ?? []}
                                renderKeyOne={'moduleName'}
                                selectedPlaceHolder={PLACEHOLDER.selectedPlaceholder}
                                notSelectedPlaceHolder={PLACEHOLDER.notSelectedPlaceholder}
                                onChange = {setmoduleIDs}
                              />
                          </div>
                          <div className={companyStyles.imageContainer}>
                              <div className={companyStyles.logoContainer}>
                                  <p className={companyStyles.logoTitle}>{ENWORDS.companyLogo}</p>
                                  <div className={profileImg !== null && profileImg !== ''  ? companyStyles.subImageContainer : companyStyles.subImageContainerHide}>
                                      <div className={companyStyles.crossIcon} onClick={removeImage}>
                                          <img src={cancelIcon} alt={ENWORDS.crossIcon} />
                                      </div>
                                      <div className={companyStyles.logo}>
                                          <img src={profileImg}  alt={ENWORDS.logo} />
                                      </div>
                                  </div>
                                  {<span className={error.profileImgError ? globalStyles.errorMessageShow: globalStyles.errorMessage}>{error.profileImgError}</span> }
                                  <button className={companyStyles.btnAddImage} onClick={showPopUp}>
                                      <p>{profileImg !== null && profileImg !== '' ? ENWORDS.changeImage : ENWORDS.addImg}</p>
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className={styles.btnContainer}>
                          <div className={styles.cancelBtn}>
                              <button onClick={companyDetailBack}>{ENWORDS.cancel}</button>
                          </div>
                          <div className={styles.doneBtn}>
                              <button onClick={checkValidation}>{ENWORDS.done}</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}
export default EditCompanyDetails