import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../shared/Components/Loader';
import { authContext } from './AuthProvoider';

const ProtectedRouter =  ({ children, isValid,...props }) => {
  const auth = useContext(authContext);
  const location = useLocation();
  if (auth.user){
    if (location.key === 'default'){
      const data = auth.user.roleAccess.find((obj) => obj.readAccess === true).routeIdentity
      return <Navigate  to={'/'+data.routeIdentity} state={{ from: location }} replace />;
    }
    
    return children  
      
  } else if(auth.loading){
    
    return <div >
        <Loader/>
    </div>
  }
  return <Navigate  to="/" state={{ from: location }} replace />;
}

export default ProtectedRouter;