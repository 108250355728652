import React from 'react'
import ViewSettingScreen from './ViewSetting/ViewSettingScreen.js'

const ViewSetting = () => {
  return (
      <ViewSettingScreen/>
  )
}

export default ViewSetting
