import React, { useState, useEffect,useCallback } from 'react';
import { TABLEHEADERS } from '../EmailLogReportConstant';
import  CustomTable  from '../../../shared/Components/CustomTable';
import CustomDatePicker from '../../../shared/Components/CustomDatePicker';
import { useSelector, useDispatch } from 'react-redux';
import { getEmailLogReport } from '../../../api/services/emailLogReportService';
import { GLOBAL_DATE_FORMAT_DB , globalYearMonthDateFormat } from '../../../shared/constants/appConstants';
import { format } from 'date-fns';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import styles from '../../../styles/emailLogReport/EmailLogScreen.module.css';
import { setLoadingFlag } from '../../../redux/actions';
import { getStationDetailsToDisplay } from '../../../api/services/stationManagementService';
import { EMAILTYPE } from '../EmailLogReportConstant';
import CustomSearchBox from '../../../shared/Components/CustomSearchBox';

const ENWORDS  =  require('../../../shared/local/en.json');
const EmailLogListingScreen = ()=>{
  const tabledata = useSelector(state =>{
    return state.emailLogReportReducer.emailLogReportTable;
  });
  const [ logsDate, setLogsDate ] = useState(new Date());
  const [ filteredData, setFilterData ] = useState(tabledata);
  const dispatch = useDispatch();
  const [ selectedType, setSelectedType ] = useState('All');
  const [ selectedSites, setSelectedSites ] = useState('All');
  const [ searchText, setSearchText ] = useState('');
  const [ sites, setSites ] = useState();

  const populateInitialData = async () =>{
    dispatch(setLoadingFlag(true));
    const { res }  = await getStationDetailsToDisplay();
    if (res){
      dispatch(setLoadingFlag(false))
      setSites([ { 'siteId' : 'All' },...res ])
    }
  }

  useEffect(() => {
    if (logsDate !== '' && selectedType!=='' && selectedSites!=='') {
      dispatch(getEmailLogReport(format(logsDate,GLOBAL_DATE_FORMAT_DB),selectedSites,selectedType));
    }
  },[ logsDate ,selectedType,selectedSites ] )
    
  useEffect(() => {
    populateInitialData();
  }, []);

  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
  }
  
  const filteration = useCallback(
    () =>{
      let userFilterData = tabledata;
  
      if (searchText.length  >0){
        userFilterData = userFilterData.filter((item)=>{
          const searchTerm = Object.values(item)?.join().toLocaleLowerCase();
          return searchTerm?.includes(searchText?.toLowerCase());
        })
      }
      return userFilterData
    },[ searchText, tabledata ]);

  useEffect(() => {
    setFilterData(filteration());
  }, [ searchText, tabledata ]);

  return (
      <div className={styles.mainContainer}>
          <div className={styles.mainFilterContainer}>
              <CustomSearchBox
                placeholder = {ENWORDS.emailLogSearchPlaceholder} 
                title = {ENWORDS.search}
                value = {searchText}
                onChange = {e => onChangeSearch(e)}
              />
              <CustomDropDown
                title={ENWORDS.site}
                name={ENWORDS.site.toLowerCase()}
                defaultValues={{ selectedSites }}
                type={ENWORDS.custom}
                onChange={e => setSelectedSites(e.target.value)}
                fieldValues = {sites ?? [] }
                renderKey='siteId'
                renderKeyOne='siteId'
              />
              <CustomDropDown
                title={ENWORDS.emailType}
                name={ENWORDS.emailType.toLowerCase()}
                fieldValues={{ 'All': 'All',...EMAILTYPE }}
                type={ENWORDS.custom}
                onChange={e => setSelectedType(e.target.value)}
              />
              <CustomDatePicker
                title={ENWORDS.month}
                startDate={logsDate}
                endDate = {new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)}
                dateFormat={globalYearMonthDateFormat}
                showMonthYearPicker={true}
                onChange={date => setLogsDate(date)}
                wrapperClass='stationDate'
                isHidden= {true}
              />
          </div>
          <div className={styles.tableContainer}>
              <CustomTable 
                data = {filteredData}
                headerData = {TABLEHEADERS} 
                sortOrder = {ENWORDS.descending}           
              />
          </div>
      </div>
     
  )

}
export default EmailLogListingScreen;