import { makeRequest } from '../makeRequest';
import { API_HEADER, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { USERURL } from '../../shared/constants/appUrls'
import { appendUsers } from '../../redux/actions/userManagement';
import { setLoadingFlag } from '../../redux/actions';

export function getUserDetails() {
  return async (dispatch) => {
    await makeRequest({
      method: GET,
      url: USERURL.getUser,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(appendUsers(response.data.data))
        dispatch(setLoadingFlag(false))
        
      })
  }
}

export function deleteUser( id , azureID) {
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url:USERURL.deleteUser+id+'&azureID='+azureID ,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function(res){
        dispatch(setLoadingFlag(true))
        dispatch(getUserDetails())
        dispatch(setLoadingFlag(false))
      }).catch(function (err) {
        console.log(err)
      });
  }
}

export async function addUserDetails({ email,azureID,
  firstname, lastname,profilePicture,  role, assignedModules,assignedCompanies }){
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url:USERURL.addUser,
    data: {
      azureID : azureID,
      email: email,
      firstName: firstname,
      profilePicture: profilePicture,
      lastName: lastname,
      role: role,
      assignedModules: assignedModules,
      companyAssignment:assignedCompanies,
    },
    headers: API_HEADER,
  })
    .then(function (err) {
      res = true
    }

    )
    .catch(function (err) {
      res = false
      errRes = err.response
    });
  return { res:res, err:errRes };
}

export async function editUserDetails({ id, azureID, firstname, lastname, profilePicture, role,  assignedModules, companyAssignment }){
  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url:`${ USERURL.editUser }${ id }`,
    data: {
      azureID:azureID,
      firstName: firstname,
      lastName: lastname,
      profilePicture : profilePicture,
      role:role ,
      assignedModules: assignedModules,
      companyAssignment:companyAssignment,
    },
    headers: API_HEADER,
  })
    .then(function (err) {
      res = true
    }
    )
    .catch(function (err) {
      res = false
      errRes = err.response
    });
  return { res:res, err:errRes };
}

export async function getUserById(userID) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ USERURL.getUserById + userID }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });
  return { res:res, err:errRes };
}
