import { GET, POST, PUT, DELETE, API_HEADER } from '../../shared/constants/appConstants';
import { COMPANYURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
import { setCompanyTable } from '../../redux/actions/companyManagement';
import { setLoadingFlag } from '../../redux/actions';

export  async function getCompanyById(companyId){
  let res ;
  await makeRequest({
    method:GET,
    url:COMPANYURL.getCompanyById + companyId,
    data:undefined,
    headers : API_HEADER,
  })
    .then(function(response){
      res = response.data;
    })
  return res;
  
}
export function deleteCompany( id ) {
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ COMPANYURL.deleteCompany + id }`,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function(){
        dispatch(getCompanies())
        dispatch(setLoadingFlag(false))
      })
  }
}
export function getCompanies() {
  return async (dispatch) => {
    await makeRequest({
      method: GET,
      url: COMPANYURL.getCompanies,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setCompanyTable(response.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function updateCompanyDetails({ companyId ,
  companyName,
  companyDetail, 
  companyAddress, 
  netSuiteId, 
  vatNumber,
  companyType, 
  logo, 
  id,
  parentCompanyId,
  moduleIDs,
}){
  let res;
  await makeRequest({
    method:PUT,
    url:COMPANYURL.updateCompanyById,
    data :{
      name: companyName,
      details:companyDetail,
      address : companyAddress,
      netSuiteId:netSuiteId,
      vatNumber : vatNumber,
      companyId:companyId,
      type:companyType,
      logo:logo,
      id:id,
      parentId: parentCompanyId,
      moduleIds: moduleIDs === '' ? [] : moduleIDs,
    },
    headers:API_HEADER,
  })
    .then(
      function (response) {
        res = true
      }
    )
    .catch( 
      function (err) {
        res = false
      }
    )
  return res ;
}

export async function addCompanyDetails (props){
  const { companyId, companyName, companyDetail, companyAddress, netSuiteId, vatNumber, type, logo, parentCompanyId ,moduleIDs } = props;
  let res;
  await makeRequest({
    method:POST,
    url:COMPANYURL.addCompanyDetails,
    data:{
      companyId:companyId,
      name: companyName,
      details:companyDetail,
      address:companyAddress,
      netSuiteId: netSuiteId,
      vatNumber: vatNumber,
      type:type,
      logo:logo,
      parentId: parentCompanyId,
      moduleIds: moduleIDs === '' ? [] : moduleIDs,
    },
    headers:API_HEADER,
  })
    .then(
      function (response) {
        res = true
      }
    )
    .catch( 
      function (err) {
        res = false
      }
    );
  return res
}