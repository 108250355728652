import React , { useState, useEffect, useRef }from 'react';
import styles from '../../styles/inputs.module.css'
import arrowDown from '../../assets/icons/arrowDownFilled.svg'
import arrowUp from '../../assets/icons/arrowUpFilled.svg'
import globalStyles from '../../styles/global.module.css';

const MultiSelectDropdown = ({ title, value, onChange, fieldValues, error  }) => {
  
  const [ show, setShow ] = useState(false);
  const [ companyArr, setCompanyArr ] = useState(value ?? []);
  const [ selectedCompanyName, setSelectedCompanyName ] = useState([]);
  const containerRef = useRef(null);

  const changeDropDown = () =>{
    onChange(companyArr);
  }

  const changeCheckBox = (obj, isChecked, companyName = '') =>{
    const currentIndex = companyArr?.findIndex((e)=> e.companyID === obj.companyID )
    if (isChecked){
      setSelectedCompanyName([ ...selectedCompanyName, companyName ]);
      if (currentIndex < 0){
        setCompanyArr([ ...companyArr, obj ]);
      }
      else{
        setCompanyArr([ obj ]);
      }
    }
    else{
      companyArr.splice(currentIndex,1);
      setCompanyArr([ ...companyArr ]);
      selectedCompanyName.splice(currentIndex,1);
      setSelectedCompanyName([ ...selectedCompanyName ]);
    }
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    // Attach the click event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
    // Clean up the listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    changeDropDown();
  }, [ companyArr ]);

  useEffect(() =>{
    if (value){
      setCompanyArr(value)
    }else{
      setCompanyArr([])
    }

  },[ value ])

  return (
      <div className={globalStyles.roleContainer}>
          <p className={globalStyles.filterText}>{title}</p>
          <div 
            className={  error ? styles.dropDownBoxError : styles.dropdownBox} ref={containerRef}
            onClick={()=>setShow(!show)}>
              <p>{companyArr && companyArr.length > 0 ? companyArr.length + ' companies selected' : 'Select a company'}</p>
              <img src = { show ? arrowUp:  arrowDown} alt="Arrow"/>
          </div>
          {
            show ?
                <div className={error ? styles.dropDownContainerError:styles.dropdownContainer} >
                    {

                      fieldValues&& fieldValues.map((item)=>{
                        return (
                            <label key  ={item.id}>
                                <input 
                                  value = {item.name}
                                  type="checkbox" 
                                  checked = {companyArr.some(e=>item.id === e.companyID)}
                                  onChange={(e)=>
                                    changeCheckBox({  companyID: item.id }, e.target.checked, item?.name)}/>
                                {item.name}</label>
                        )
                      })
                   
                    }

                </div>
              :null
          }
          {<p className={error ? globalStyles.errorMessageShow : globalStyles.errorMessage}>{error}</p>}
      </div>
  )
}
export default MultiSelectDropdown