import { API_HEADER, GET, POST } from '../../shared/constants/appConstants';
import { ADTMONITORINGURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';

export async function getLogReportsData(fromDate, toDate, SiteID, status) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: ADTMONITORINGURL.getReportsList+'?fromDate='+fromDate+'&toDate='+toDate+'&SiteID='+SiteID+'&status='+status,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      res = []
      errRes = err.response
    });

  return { res: res, err: errRes };
}

export async function sendReportsLogOnMail(startDate, endDate, SiteID, status, data) {
  let res;
  let errRes = {};
 
  await makeRequest({
    method: POST,
    url: ADTMONITORINGURL.sendLogReports+'?startDate='+startDate+'&endDate='+endDate+'&SiteID='+SiteID+'&status='+status,
    data: data,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = true;
    })
    .catch(function (err) {
      res = false;
      errRes = err;
    });
  return { res: res, err: errRes };
}
