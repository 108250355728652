import { setLoadingFlag } from '../../redux/actions';
import { setOpsRegionsTable } from '../../redux/actions/opsRegionsListing';
import { API_HEADER, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { OPSREGIONSURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
const ENWORDS = require('../../shared/local/en.json');

export function getOpsRegionsData() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: OPSREGIONSURL.getOpsRegion,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setOpsRegionsTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function getOpsRegionById(opsRegionId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ OPSREGIONSURL.getOpsRegionById + opsRegionId }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function addOrEditOpsRegion(data,type = ENWORDS.addMode) {
  const { id, opsRegionName, opsRegionManagers, opsRegionStatus, opsRegionId } = data
  let res;
  let errRes = {};
  await makeRequest({
    method: type === ENWORDS.editMode? PUT : POST,
    url: type === ENWORDS.editMode ? OPSREGIONSURL.updateOpsRegion +'?id='+ id : OPSREGIONSURL.addOpsRegion ,
    data: {
      opsRegionName:opsRegionName,
      opsRegionManagers:opsRegionManagers,
      opsRegionStatus:opsRegionStatus,
      opsRegionId : opsRegionId,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export function deleteOpsRegionById( id ) { 
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ OPSREGIONSURL.deleteOpsRegion + id }`,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function(res){
        dispatch(setLoadingFlag(true))
        dispatch(getOpsRegionsData())
        dispatch(setLoadingFlag(false))
      }).catch(function (err) {
        console.log(err)
      });
  }
}