export const TABLEHEADERS = {
  'select' : 'Select',
  'invoiceNo': 'Invoice number',
  'siteID':'Station ID',
  'siteName':'Station Name',
  'cmName':'CM Name',
  'documentDate': 'Document Date',
  'billingPeriod':'Billing Period',
  'grossAmount':'Gross Amount',
  'status':'Status',
  'actions': 'Actions',
}

export const INVOICEHEADERS ={
  'productCode':'Product Code',
  'description':'Description',
  'periodFrom':'Period From',
  'periodTo':'Period To',
  'quantity':'Units',
  'unitPrice':'Unit Price',
  'supplierCommision': 'Commission',
  'netAmount':'Net Amount',
  'vat': 'Vat',
  'vatAmount': 'Vat Amount',

}

export const INVOICESTATUS = {
  'all':'All',
  'Completed':'Completed',
  'Generated':'Generated',
  'Regenerated':'Regenerated',
  'Emailed': 'Emailed',
}

export const INVOICE_EXPORT_HEADERS = {
  invoiceNo: 'Inovice No',
  siteID: 'Station ID',
  siteName: 'Station Name',
  billingPeriod: 'Billing Period',
  documentDate: 'Invoice Date',
  moduleName: 'Module',
  vatAmount: 'VAT amount',
  netAmount: 'Net amount',
  grossAmount: 'Gross amount',
  cmName: 'CM name',
  startDate: 'Trading start date',
  endDate: 'Trading end date',
  ladgerAccountNo: 'Ladger Account No',
};

export const INVOICE_EXPORT_FILE_PREFIX = 'Invoice Data '

export const INVOICES_EDITABLE= 
[ 
  { colName : 'status', inputType : 'dropdown', options : [ 'Generated','Completed' ] }
]