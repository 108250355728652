import baseStyles from '../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
export const STATIONHEADER = {
  siteId:'Station ID',
  siteName:'Station Name',
  siteAddress:'Station Address',
  siteStatus:'Status',
  actions: 'Actions',
};
export const STATIONREGIONSHEADER = {
  regionName: 'Name',
  regionalManagerName: 'Regional Manager',
  regionalManagerEmail: 'Manager Email',
  regionStatus: 'Status',
  opsRegionName: 'Ops Region Name',
  actions: 'Actions',
};

export const STATIONOPSREGIONSHEADER = {
  opsRegionName: 'Name',
  opsRegionalManagerName: 'Ops Regional Manager',
  opsRegionalManagerEmail: 'Manager Email',
  opsRegionStatus: 'Status',
  actions: 'Actions',
};

export const STATIONAREASHEADER = {
  areaName: 'Name',
  areaManagerName: 'Area Manager',
  areaManagerEmail: 'Manager Email',
  regionName: 'Region',
  areaStatus: 'Status',
  actions: 'Actions',
};

export const DAYS = [ 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri','Sat','Sun' ]
export const OPENING_TIME = 'openingTime';
export const CLOSING_TIME = 'closingTime';
export const OPEN_24_HRS = 'open24Hrs';
export const SCHEDULED = 'scheduled';
export const DEFAULT_OPENING_TIME = new Date('2014-08-18T06:00:00');
export const DEFAULT_CLOSING_TIME = new Date('2014-08-18T23:00:00');
export const BASE_TIME = new Date('2020-01-01T00:00:00');
export const STATIONSHEDULE = {
  open24Hrs:'Open 24 Hrs',
  scheduled : 'Scheduled', 
};

export const AREANAME = 'areaName';
export const REGIONNAME = 'regionName';
export const OPSREGIONNAME = 'opsRegionName';

export const AREASTATUSSTYLE ={
  1 : baseStyles.userStatusActive,
  0 : baseStyles.userStatusInactive,
}
export const REGIONSTATUSSTYLE ={
  1 : baseStyles.userStatusActive,
  0 : baseStyles.userStatusInactive,
}

export const OPSREGIONSTATUSSTYLE ={
  1 : baseStyles.userStatusActive,
  0 : baseStyles.userStatusInactive,
}

export const STATIONSTATUSSTLE ={
  Active : baseStyles.userStatusActive,
  Inactive : baseStyles.userStatusInactive,
  'Coming Soon':baseStyles.userStatusComingSoon,
  
}

export const STATION_EXPORT_HEADERS = {        
  siteId : 'Station Iascasd' ,
  siteName :'Station Name',
  siteAddress : 'Station Address' ,
  siteStatus : 'Station Status',
  cmName : 'CM Name' ,
  cmEmail : 'CM Email' ,
  cmAddress : 'CM Address',
  ladgerAccNo: 'Ladger Account No',
  startDate : 'Trading Start Date',
}

export const REGION_EXPORT_HEADERS = {
  regionName: 'Name',
  regionalManagerName: 'Regional Manager',
  regionalManagerEmail: 'Manager Email',
  regionStatus: 'Status',
};

export const OPSREGION_EXPORT_HEADERS = {
  opsRegionName: 'Name',
  opsRegionalManagerName: 'Ops Regional Manager',
  opsRegionalManagerEmail: 'Manager Email',
  opsRegionStatus: 'Status',
};

export const AREA_EXPORT_HEADERS = {
  areaName: 'Name',
  areaManagerName: 'Area Manager',
  areaManagerEmail: 'Manager Email',
  regionName: 'Region',
  areaStatus: 'Status',
};

export const STATION_EXPORT_FILE_PREFIX = 'Station Data ';
export const REGION_EXPORT_FILE_PREFIX = 'Region Data '
export const OPSREGION_EXPORT_FILE_PREFIX = 'Ops Region Data '
export const AREA_EXPORT_FILE_PREFIX = 'Area Data '