import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import BaseConfigurationTable from '../BaseConfigurationTable';
import { COMPANYHEADERS } from '../baseConfigurationConstant';
import CompanyHeader from './CompanyDetailsHome/CompanyHeader';
import { setLoadingFlag } from '../../../redux/actions';
import { getCompanies } from '../../../api/services/companyManagementService';
import  ENWORDS  from '../../../shared/local/en.json';
import { TABS } from '../../../shared/constants/appConstants';
import SideNavigation from '../../../shared/Components/SideNavigation';

const CompanyManagementScreen = () =>{
  const dispatch = useDispatch();
  const companyData = useSelector(state => state.companyManagement.companyTable);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ filterData , setFilterData ] = useState(companyData);
  const [ roleAccess, setRoleAccess ] = useState();
  useEffect(() => {
    setFilterData(companyData)
  }, [ companyData ])

  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === 'Company Details'){
        if (!item.writeAccess){
          delete COMPANYHEADERS[ 'companyAction' ];
        }
        setRoleAccess(item)
      }
    })

  }
  
  useEffect(() =>{
    checkRoleAccess()
  },[ roleLevelAccess ])
  
  useEffect(() =>{
    
    dispatch(setLoadingFlag(true))
    dispatch(getCompanies());
  },[])
  
  return(
      <div className={styles.mainContainer}>
          <div className = {styles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   />
              {/* <SideNavigation parentName={TABS.baseConfiguration}   /> */}
          </div>
          
          <div className={styles.rightContainer}>
            
              <div className = {styles.siteHeader}>
                  <CompanyHeader setFilterData={setFilterData} 
                    roleAccess = {roleAccess}/>
              </div>
              <BaseConfigurationTable 
                tableData = {filterData} 
                headerData = {COMPANYHEADERS} 
                tableName ={ENWORDS.companyTable}
                roleAccess = {roleAccess}
                popUpTitle = {ENWORDS.deleteCompanyTitle}
                popUpText = {'The company'+ENWORDS.deleteCMText}
                shouldVerify = {true}
                isFromPage='compony'
              />
              
          </div> 
      </div>
  )
}

export default CompanyManagementScreen