import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import Select from 'react-select';
import styles from '../../../styles/inputs.module.css'
import { MODULEASSIGNMENT } from '../userManagementConstants'
import ImageUploadPopUp from '../../../shared/Components/ImageUploadPopUp';
import cancelIcon from '../../../assets/icons/cancelIcon.svg';
import { ROUTES } from '../../../shared/constants/appRoute';
import CustomInput from '../../../shared/Components/CustomInput';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import { setLoadingFlag } from '../../../redux/actions';
import { addUserDetails, getUserDetails } from '../../../api/services/userManagementService'
import ListingHeader from '../../../shared/Components/ListingHeader';
import MultiSelectDropdown from '../../../shared/Components/MultiSelectDropdown';
import InvoiceAssignmentTable from '../../../shared/Components/InvoiceAssignmentTable';
import { getCompanyModuleMapping } from '../../../api/services/commonService';
import { commonScrollFunction } from '../../../shared/commonFunctions';
import CustomSearchUser from '../../../shared/Components/CustomSearchUser';
import { useDebounce } from 'use-debounce';
const ENWORDS  =  require('../../../shared/local/en.json')
const ERRORWORDS  =  require('../../../shared/local/error.json');
const PLACEHOLDER  =  require('../../../shared/local/placeholder.json');

const AddUserScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location  = useLocation();
  const locationState = location.state;
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ roleIndex, setRoleIndex ] = useState();
  const [ visibility, setVisibility ] = useState(false);
  const [ profileImg, setProfileImg ] = useState(null)
  const [ isValidated, setIsValidated ] = useState(false)
  const [ access, setAccess ]  = useState([ ]);
  const [ error, setError ] = useState({});
  const [ rolesArr, setRolesArr ] = useState([]);
  const [ base64Profile, setBase64Profile ] = useState('');
  const [ companyArr, setCompanyArr ] = useState([]);
  const [ companyData, setCompanyData ] = useState([]);
  const [ moduleTable, setModuleTable ] = useState([]);
  const [ isDone, setIsDone ] = useState(false);
  const [ azureUserList , setAzureUserList ] = useState([])
  const [ azureFilterList , setAzureFilterList ] = useState([]);
  const [ searchText, setSearchText ] = useState();
  const [ searchFilterData, setSearchFilterData ] = useState([]);
  const companyModule = useSelector((state)=> state.common.companyModuleAccess)
  const userData = useSelector(state=> state.userManagement.userManagementList);
  const [ azureID, setAzureId ] = useState()
  const [ changeSearchText ] = useDebounce(searchText, 100);

  useEffect(() => {
    if(userData && azureUserList){
      var filterList = azureUserList.filter((val) => userData.every(e => e.email !== val.mail))
      setAzureFilterList(filterList);
    }
  },[ userData, azureUserList  ])

  const accessState = (receivedAccessValues) =>{
    setAccess(receivedAccessValues)
  } 
  const showPopUp = () =>{
    setVisibility(true);
  }

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const addImg = (receivedImg) =>{
    setProfileImg(receivedImg);
    const index  = receivedImg.indexOf(',')
    setBase64Profile(receivedImg.slice(index+1))
  }

  const checkValidation = () => {
    let errorCopy = error
    if(email === ''){
      errorCopy = { ...errorCopy, email : ERRORWORDS.emailSearchError }
    }
    if(companyData && companyData.length === 0){
      errorCopy = { ...errorCopy, companyError : ERRORWORDS.companyError }
    }

    const isErrorEmpty = Object.keys(errorCopy).filter((item)=>{
      return errorCopy[ item ] !== ''
    })
    
    if (isErrorEmpty.length === 0 ){
      setError({})
      setIsValidated(true)
      
    }

    setError(errorCopy);
    setIsDone(!isDone);
  }
  
  const goBack = () => {
    navigate(ROUTES.USERMANAGEMENTSCREEN, { state: locationState })
  }

  const validated = async () =>{
    const requestBody =    {
      azureID : azureID,
      email:email,  
      firstname:firstName, 
      lastname: lastName, 
      profilePicture : base64Profile,
      role: Number(roleIndex),
      assignedModules: access,
      assignedCompanies:companyData,
    }

    dispatch(setLoadingFlag(true));
    
    const res = await addUserDetails( requestBody);

    const successRes = res.res
    if (successRes){
      dispatch(setLoadingFlag(false))
      dispatch(getUserDetails());
      navigate(ROUTES.USERMANAGEMENTSCREEN, { state: {
        rolesData: locationState.rolesData,
        companyData: locationState.companyData,
        azureUsers : locationState.azureUsers,
      } })
    }
    setIsValidated(false);
  }

  const removeProfileImg = ()=>{
    setProfileImg(null)
  }

  const onDoneClicked =  ()=>{
    checkValidation();
  
  }
  const updateRoleIndex = (e) =>{
    setRoleIndex(e.target.value)
  }
  
  useEffect(() => {
    
    if (isValidated)
    {
      validated();
    }
  }, [ isValidated ])  
  
  useEffect(()=>{
    let errorCopy = error

    if (companyData?.length > 0){
      errorCopy = { ...errorCopy, companyError: '' }
    }
    if(email !== ''){
      errorCopy = { ...errorCopy, email:'' }
    }
    setError(errorCopy)
  }, [  companyData, email ])

  useEffect(()=>{
    if (locationState){
      setRolesArr(locationState?.rolesData);
      setRoleIndex(locationState?.rolesData[ 0 ].id)
      setCompanyArr(locationState?.companyData);
      setAzureUserList(locationState?.azureUsers);
    } 
    dispatch(getCompanyModuleMapping());
  },[])

  const moduleFilteration = () =>{
    const filteredCompanyData = companyModule.filter(e => e.cd.some(k=> companyData.some(j=> j.companyID === k.id)))
    setModuleTable(filteredCompanyData)
  }
  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])
  useEffect(()=>{
    if (companyData.length === 0 ){
      setModuleTable([])
    }else{
      moduleFilteration()
    }
  },[ companyData ])

  useEffect(() => {
    azureFilterList.some(val =>{
      if(val.id === azureID){
        setFirstName(val.givenName ? val.givenName : '-')
        setLastName(val.surname ? val.surname : '-')
        setEmail(val.mail ? val.mail :'Email not found')
      }
        
    })
  },[ azureID ])
  useEffect(() => {
    if(searchText != ''){

      const tempList = azureFilterList.filter((item) =>{
        const searchData = (item[ 'mail' ] + item[ 'givenName' ] + item[ 'surname' ]).toLowerCase()
        return searchData?.includes(searchText.toLowerCase())
      })
      setSearchFilterData(tempList)
    }else{
      setSearchFilterData([])
    }
  },[ changeSearchText ])
  const onValueSelect = (e) => {
    setSearchText('')
    setAzureId(e)
  }
  const onSearchFilter = (e) => {
    setSearchText(e)
  }
  return (
      <div className={styles.mainContainer}>
          <ImageUploadPopUp
            uploadType={ENWORDS.userScreen}
            show={visibility}
            onClose={popupCloseHandler}
            onAddImg= {addImg}
          />
          <div className={styles.subContainer}>
              <ListingHeader
                title = {ENWORDS.addNewUser}
                navigationPath = {ROUTES.USERMANAGEMENTSCREEN}
                state={locationState}
              />
              
              <CustomSearchUser 
                title={ENWORDS.searchUserText}
                fieldValues = {searchFilterData}
                value = {searchText}
                onSelect = {e => onValueSelect(e)}
                onChange = {(e) => onSearchFilter(e)}
              />
              <div className={styles.mainDetailsContainer}>
                  <p>{ENWORDS.userDetails}</p>
                  <div className={styles.detailsContainer}>
                      <div className={styles.detailsRow}>

                          <CustomInput
                            title = {ENWORDS.firstName} 
                            value = {firstName} 
                            error= {error.firstName}
                            isFocused = {true}
                            placeholder = {PLACEHOLDER.firstname}
                            onChange={e=> setFirstName(e)}/>
                                
                          <CustomInput
                            title = {ENWORDS.lastName} 
                            value = {lastName} 
                            error= {error.lastName}
                            placeholder = {PLACEHOLDER.lastname}
                            onChange={e=> setLastName(e)}/>
                                
                          <CustomInput
                            title = {ENWORDS.email} 
                            value = {email} 
                            error= {error.email}
                            placeholder = {PLACEHOLDER.mfgEmail}
                            maintainDefaultCase = {true}
                            disabled = {true}
                            readOnly = {true}
                          />
                          
                          {
                            rolesArr?
                                <CustomDropDown
                                  title={ENWORDS.role}
                                  name = 'role'
                                  fieldValues={ rolesArr }
                                  selectedValue = { roleIndex }
                                  onChange={e=> updateRoleIndex(e)}
                                />:
                              null
                          }
                          <MultiSelectDropdown
                            title={ENWORDS.companies}
                            fieldValues = {companyArr}
                            error = {error.companyError}
                            onChange = {e => setCompanyData(e)} />

                      </div>
                      <div className={styles.imageContainer}>
                          <p>{ENWORDS.profileImg}</p>

                          {profileImg === null ? (                          
                              <button className={styles.btnAddImage} onClick={showPopUp}>
                                  <p>{ENWORDS.addImg}</p>
                              </button>
                          )  : (
                              <div className = {styles.profileImageContainer}>
                                  <div className={styles.cancelContainer} onClick={removeProfileImg}>
                                      <img src={cancelIcon} alt ={ENWORDS.cancelIcon} />
                                  </div>
                                  <img src={profileImg} alt = {ENWORDS.userProfileImg} className={styles.profileImg} />

                              </div>
                          )}
                      </div>
                  </div>
                  <div className={styles.divider}>
                  </div>
                  <div className={styles.mainModuleContainer}>
                      <p>{ENWORDS.invoiceAssignment}</p>
                      {
                        companyData.length === 0 ?
                            <h1 className={styles.invoiceMessage}>{ENWORDS.invoiceMessage}</h1>
                          :null
                      }
                      <InvoiceAssignmentTable 
                        headerData={MODULEASSIGNMENT}  
                        data={moduleTable} 
                        onGetAccessDetails={accessState}  
                        canEdit = {true}  
                        isValidatedAccess={isValidated}/>

                      <div className={styles.buttonContainer}>
                          <button className={styles.btnCancel} onClick={goBack}>{ENWORDS.cancel}</button>
                          <button className={styles.btnDone} onClick={onDoneClicked}>{ENWORDS.done}</button>
                      </div>

                  </div>

              </div>
          </div>
        
      </div>
  )
}

export default AddUserScreen