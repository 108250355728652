import { SET_PENALTY_DATA } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  penaltyTable: [],
};

const penaltyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_PENALTY_DATA:
    return {
      ...state,
      penaltyTable: action.payload,
    };

  default:
    return state;
  }
};

export default penaltyReducer;
