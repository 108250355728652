import React, { useEffect, useState } from 'react';
import baseStyles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import styles from '../../../../styles/baseconfiguration/RoleManagement.module.css';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import CustomSearchBox from '../../../../shared/Components/CustomSearchBox';
import ENWORDS from '../../../../shared/local/en.json';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { updateRolePageNumber } from '../../../../redux/actions/roleManagement'

const RoleHeader = ({ setFilterData, roleAccess } ) =>{
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const roleData = useSelector ( state => state.roleManagement.roleTable)
  const [ filteredData, setFilteredData ] = useState(roleData);
  const [ searchText , setSearchText ] = useState('');
  
  useEffect(()=>{
    setFilterData(filteredData)
  },[ filteredData ])

  const toAddRole = () =>{
    navigate(ROUTES.ADDROLE)
  }
  
  const filteration = () =>{
    let roleFilterData = roleData;
    if(searchText.length > 0){
      roleFilterData = roleFilterData.filter((item) =>{
        const roleName = (item[ 'roleName' ]?.toLowerCase());
        return roleName?.includes((searchText.trim())?.toLowerCase()) 
      })
    }
    return roleFilterData
  }

  useEffect(()=>{
    setFilteredData(filteration())
  },[ searchText ])
  
  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateRolePageNumber(0))
  }
  return (
      <div className={baseStyles.siteHeaderContainer}>
          <div className={baseStyles.filterContainer}>
              <div className={styles.leftFilterContainer}>
                  <CustomSearchBox 
                    placeholder = {ENWORDS.searchPlaceholder} 
                    title = {ENWORDS.search}  
                    onChange = {e => onChangeSearch(e)}/>  
              </div>
              { 
                roleAccess && roleAccess.writeAccess ? 
                    <div className={styles.rightFilterContainer}>
                        <div className={baseStyles.btnContainer}>
                            <div className={styles.addContainer}>
                                <button className={styles.addBtn} onClick = {toAddRole}>
                                    <img src={plusIcon} alt={ENWORDS.plusIcon}  />
                                </button>
                            </div>
                        </div>
                    </div>
                  :null }
          </div>
      </div>
  )
}
export default RoleHeader;