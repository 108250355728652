export const AUTHURL = {
  requestOtp:'api/authenticate/requestLoginOtp',
  login:'api/authenticate/login',
  forgotPassword:'api/authenticate/forgotPassword',
  verifyForgotPassword:'api/authenticate/verifyForgotPassword',
  resetFirstTimePassword: 'api/authenticate/resetPassword',
  getCurrentUser:'api/authenticate/getLoggedInUser',
  refreshToken:'api/authenticate/refreshToken',
};

export const DASHBOARDURL = {
  getDashboardList: 'api/dashboard/getDashboardInvoiceList',
}
export const USERURL = {
  getUser :'api/authenticate/allUsers',
  addUser: 'api/authenticate/addAdminUser',
  editUser: 'api/authenticate/updateAdminUser/',
  getUserById:'api/authenticate/getAdminUser/',
  deleteUser: 'api/authenticate/deleteUser?id=',
  getAzureADUser:'api/authenticate/getADUsers',
}

export const ROLE_URL = {
  getRoles : 'api/roles/getRoles',
  getRoleById:'api/roles/getRole/',
  deleteRole : 'api/roles/deleteRole?id=',
  updateRole:'api/roles/updateRole',
  getScreens : 'api/roles/getScreens',
  addRole: 'api/roles/addRole',
  checkRole : 'api/roles/checkIfRoleExist?roleId=',
}

export const COMMONURL = {
  getRoles: 'api/authenticate/allRoles/',
  getCompanyModuleMapping: 'api/configurations/getCompanyModuleMapping',
}

export const MODULEURL = {
  getModulesByUserId: 'api/configurations/getModules?userId=',
  getModulesByCompanyName: 'api/configurations/getModules?companyNames=',
  getModules: 'api/configurations/getModules',
  addModule: 'api/configurations/addModule',
  getModuleById:'api/configurations/getModuleDetails/',
  getRequiredFields:'api/configurations/getRequriedFieds',
  getStationMapping:'api/configurations/getModulesStationMapping',
  editModule:'api/configurations/updateModule',
  checkModuleName: 'api/configurations/checkModuleName?moduleId=',
  checkPrefix: 'api/configurations/checkPrefix?prefix=',
  deleteModuleField : 'api/configurations/deleteField?moduleID=',
  deleteModuleById : 'api/configurations/deleteModuleById?moduleID=',
  moduleFieldID:'&fieldID=',
  
};

export const STATIONURL = {
  getStation : 'api/stations/getAllStation',
  getStationById:'api/stations/getSiteDetails/',
  addStation:'api/stations/addSiteDetails',
  updateStation:'api/stations/updateSites',
  deleteStation:'api/stations/deleteSite?id=',
  deleteCmById:'api/stations/deleteCMById?id=',
  syncAllStation:'api/stations/getStation',
  checkSupplierSiteExist:'api/stations/checkModuleSupplierAvail?moduleId=',
  checkStationInvoiceExist:'api/stations/checkIfSiteOrInvoiceExist?id=',
}

export const REGIONSURL = {
  getRegion : 'api/regions/getRegions',
  getRegionById:'api/regions/getRegion/',
  addRegion:'api/regions/addRegion',
  updateRegion:'api/regions/updateRegion',
  deleteRegion:'api/regions/deleteRegion/',
  deleteManagerById:'api/regions/deleteManagerById?id=',
}

export const AREAURL = {
  getAreas : 'api/areas/getAreas',
  getAreaById:'api/areas/getArea/',
  updateArea:'api/areas/updateArea',
  addArea:'api/areas/addArea',
  deleteManagerById:'api/regions/deleteManagerById?id=',
  deleteArea:'api/areas/deleteArea/',
}

export const COMPANYURL = {
  getCompanyById : 'api/companyDetails/getCompanyDetails/',
  updateCompanyById: 'api/companyDetails/updateCompany',
  addCompanyDetails :'api/companyDetails/addCompanyDetails',
  deleteCompany:'api/companyDetails/deleteCompany?id=',
  getCompanies:'api/companyDetails/getCompanies',
}

export const SUPPLIERURLS = {
  getSupplier:'api/companyDetails/getSuppliers',
  addSupplier:'api/companyDetails/addSuppliers',
  getSupplierByName: 'api/companyDetails/getSuppliersDetails/',
  editSupplier: 'api/companyDetails/updateSuppliers',
  deleteSupplier: 'api/companyDetails/deleteSupplierById',

}
export const INVOICEURLS = {
  getInvoiceByDateRange:'api/invoices/getAllInvoices',
  canUploadExcel: 'canUploadExcel?moduleId=',
  billingValue: '&billingperiod=',
  uploadExcel: 'uploadExcel',
  generateInvoice: 'generateInvoice',
  invoiceSummary: 'api/invoices/invoiceSummaries',
  downloadCSV: 'api/invoices/downlaodCSV',
  updateInvoice: 'api/invoices/updateInvoiceSummaries',
  sendInvoice: 'api/invoices/sendInvoices',
  checkIfInvoiceExist: 'api/invoices/checkInvoiceExist?id=',
  checkModuleSupplierMapping: 'api/invoices/checkModuleSupplierMapping?supplierId=',
  addInvoiceDetails: 'api/invoices/addInvoiceDetails',
  updateInvoiceStatus: 'api/invoices/updateInvoiceStatus',
}

export const SETTINGSURL  ={
  getAllSettings: 'api/settings/getSettings',
  editSettings: 'api/settings/editSetting',
  getVatDetails:'api/settings/getVatDetails',
}

export const ADTMONITORINGURL  ={
  addConfigUrl: '',
  uploadExcel : 'api/ADTlog/uploadExcel',
  getDailyLogs:'api/ADTlog/getDailyLogs?date=',
  updateDailyLog : 'api/ADTlog/updateDailyLog',
  getDetailedLog :'api/ADTlog/getDetailedLog?logUniqueId=',
  updateDetailedLog : 'api/ADTlog/updateDetailedLog',
  getPenalties : 'api/penaltyLogs/getPenaltyLogs',
  updatePenaltyStatus : 'api/penaltyLogs/updatePenaltyLogs/',
  getPenaltyDocument : 'api/penaltyLogs/getPenaltyDocument/',
  getYearsForLogs : 'api/ADTlog/getYearsForLogs',
  getCounters: 'api/ADTlog/getCounters?year=',
  commitLogs:'api/ADTlog/commitLogs?dailyLogId=',
  commitPenalties:'',
  getAdtEmail : 'api/ADTlog/getAdtEmail',
  addAdtEmail:'api/ADTlog/addAdtEmail',
  deleteAdtEmail:'api/ADTlog/deleteAdtEmail/',
  sendPenaltyEmail:  'api/penaltyLogs/sendPenaltyEmail',
  generateLogs:  'api/ADTlog/generateLogs',
  getReportsList:'api/adtReportsLogs/getLogsReports',
  sendLogsEmails:'api/ADTlog/sendDailyLogsEmail?logUniqueId=',
  revertCommit:'api/ADTlog/revertCommit?logUniqueId=',
  sendCountersLogs:'api/ADTlog/sendCountersLogsOnEmail?year=',
  sendLogReports:'api/adtReportsLogs/sendLogReportsOnEmail',
  uploadExcludedContractNumbersExcel: 'api/ADTlog/uploadExcludedContractNumbersExcel',
  getExcludedContractNumbers: 'api/ADTlog/getExcludedContractNumbers',
}

export const CONFIGURATIONURL = {
  getPenaltyConfiguration : 'api/penalty/getPenalties',
  addPenaltyConfiguration: 'api/penalty/addPenalty',
}

export const OPSREGIONSURL = {
  getOpsRegion : 'api/opsRegions/getOpsRegions',
  getOpsRegionById:'api/opsRegions/getOpsRegion/',
  addOpsRegion:'api/opsRegions/addOpsRegion',
  updateOpsRegion:'api/opsRegions/updateOpsRegion',
  deleteOpsRegion:'api/opsRegions/deleteOpsRegion/',
  deleteManagerById:'api/opsRegions/deleteManagerById?id=',
}

export const EMAILLOGREPORTURL ={
  getEmailLogReport:'api/emailLogReport/getEmailLogs',
}
