import React,{ useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import styles from  '../../../styles/userManagement/UserManagementScreen.module.css'
import globalStyle from  '../../../styles/global.module.css'
import { TABLEHEADERS } from '../userManagementConstants';
import plus from '../../../assets/icons/plusIcon.svg';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import CustomSearchBox from '../../../shared/Components/CustomSearchBox';
import { ROUTES } from '../../../shared/constants/appRoute'
import { getUserDetails } from '../../../api/services/userManagementService'
import UserTable from './UserTable';
import { setLoadingFlag } from '../../../redux/actions';
import { getAzureADUsers, getCompany, getUserRoles } from '../../../api/services/commonService';
import { updateUserRoleFilter, updateUserPageNumber } from '../../../redux/actions/userManagement'
import SideNavigation from '../../../shared/Components/SideNavigation';
import { TABS } from '../../../shared/constants/appConstants';
import baseStyles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import style from '../../../styles/stationListing/StationListingScreen.module.css';
const ENWORDS  = require('../../../shared/local/en.json');
const SCREENS  = require('../../../shared/local/screens.json');

const UserListingScreen = () => {
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(state=> state.userManagement.userManagementList);
  const userRoleFilter = useSelector(state=> state.userManagement.userManagementRoleFilter);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ filteredData, setfilteredData ] = useState([]);
  const [ searchText, setSearchText ] = useState('');
  const [ selectedRole, setSelectedRole ]  = useState(userRoleFilter ? userRoleFilter : 0); 
  const [ roleArr, setRoleArr ] = useState([]);
  const [ roleAccess, setRoleAccess ] = useState(false);

  // Api call to get user roles
  const addUser  =  async ()  => {
    dispatch(setLoadingFlag(true))
    const res  = await getUserRoles()
    const company = await getCompany();
    const azureUser = await getAzureADUsers();

    const rolesRes = res.res
    const companyRes = company.res
    const azureUserRes = azureUser.res
    if (rolesRes && companyRes && azureUser){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.ADDUSERSCREEN, { state:{ rolesData: rolesRes, companyData:companyRes, azureUsers : azureUserRes } })
    }
  }
  const filteration = () => {
    let userFilterData = userData;

    if (searchText.length  > 0 ){
      userFilterData = userFilterData.filter((item)=>{
        const fullName  = (item[ 'firstName' ] + ' '+  item[ 'lastName' ])?.toLowerCase()
        const email = (item[ 'email' ]).toLowerCase()
        return fullName?.includes(searchText.toLowerCase()) || email.includes(searchText.toLowerCase())
      })
    }

    if (selectedRole !== 0){
      userFilterData = userFilterData.filter((item)=>{
        const selectedRoleText = item.roleId
        
        return selectedRoleText === selectedRole
      })
    }
    return userFilterData

  }
  // Api Call to get list of user
  const fetchUserData  = async () =>{
    dispatch(setLoadingFlag(true))
    await dispatch(getUserDetails())
  }
  
  useEffect(() => {
    setfilteredData(filteration())
  }, [ searchText, selectedRole, userData ])

  useEffect(() => {
    dispatch(updateUserRoleFilter(selectedRole))
  }, [ selectedRole ])

  const fetchRoles = async () =>{
    // dispatch(setLoadingFlag(true))
    const res  = await getUserRoles();
    // if(res)
    // {
    // dispatch(setLoadingFlag(false))
    // }
    const arr = res.res;
    // Todo update
    
    arr.splice(0, 0, { id:0, roleName:'All' });
    setRoleArr(arr)
  }
  
  const checkRoleAccess = () =>{
    roleLevelAccess && roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.userManagement){
        if (!item.writeAccess){
          delete TABLEHEADERS[ 'action' ];
        }
        setRoleAccess(item)
      }
    })
  }

  useEffect(()=>{
    fetchUserData()
    fetchRoles()
  },[])

  useEffect(()=>{
    checkRoleAccess()
  },[ roleLevelAccess ])
  
  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateUserPageNumber(0))
  }

  const onChangeRole = (e) =>{
    setSelectedRole(Number(e.target.value));
    dispatch(updateUserPageNumber(0))
  }

  return (
      <div className={baseStyles.mainContainer}>

          <div className = {baseStyles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   />
          </div>
          <div className={baseStyles.rightContainer}>
              <div className = {baseStyles.siteHeader}>
                  <div className={style.stationHeaderContainer}>
                      <div className={style.filterContainer}>
                          <div className={style.leftFilterContainer}>

                              <div className={style.searchBoxContainer}>
                                  <CustomSearchBox
                                    placeholder = {ENWORDS.searchPlaceholder}
                                    title = {ENWORDS.search}
                                    value = {searchText}
                                    onChange = {e => onChangeSearch(e)}
                                  />
                              </div>
                              <div className={baseStyles.addContainer} style={{ paddingRight: '20rem' }}>
                                  <CustomDropDown
                                    title={ENWORDS.role}
                                    name = {ENWORDS.role.toLowerCase()}
                                    fieldValues={  roleArr }
                                    type = {ENWORDS.custom}
                                    selectedValue={selectedRole}
                                    onChange={e=> onChangeRole(e)}
                                  />
                              </div>
                          </div>

                          { 
                            roleAccess && roleAccess.writeAccess ? 
                                <div className={baseStyles.addContainer}>
                                    <button onClick={addUser}>
                                        <img src={plus} alt={ENWORDS.plusIcon} />
                                    </button>
                                </div>
                              :null
                          }
                      </div>
                  </div>
              </div>
              <UserTable data = {filteredData} headerData = {TABLEHEADERS} roleWriteAccess  = {roleAccess}/>
          </div>
         
      </div>
    
  )
}

export default UserListingScreen