//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

// COMMON LOADING FLAG
export const SET_LOADING_FLAG = 'set_loading_flag';
export const SET_PROGRESSBAR_FLAG = 'set_progressBar_flag';
export const SET_ERROR_FLAG = 'set_error_flag';
export const SET_LOAD_ERROR = 'set_load_error';
export const SET_IS_LOGGED_OUT = 'set_is_logged_out';
export const SET_COMPANY_MODULE_MAPPING = 'set_company_module_mapping';

//AUTH
export const UPDATE_AUTH_USER = 'update_auth_user';
export const USER_LOADED = 'user_loaded';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//SUPPLIER MANAGEMENT
export const SET_SUPPLIER_TABLE = 'set_supplier_table';

//ROLE MANAGEMENT
export const SET_ROLE_TABLE = 'set_role_table';
export const SET_USER_LEVEL_ACCESS = 'set_user_level_access';

//COMPNAY MANAGEMENT
export const SET_COMPANY_TABLE = 'set_company_table';

// DASHBOARD
export const SET_DASHBOARD_TABLE = 'set_dashboard_table';

//MODDULE CONFIGURATION
export const MODULE_CONFIGURATION_DATA = 'module_configuration_table';

// USER MANAGEMENT
export const APPEND_USERS = 'append_users'

// INVOICE LISTING
export const UPDATE_INVOICE_DATA = 'update_invoice_data';

//STATION LISTING
export const SET_STATION_TABLE = 'set_station_table';

//SETTINGS 
export const SET_SETTING_DATA = 'set_setting_table'

//ADT DAILY LOGS TABLE
export const SET_DAILY_LOGS_DATA = 'set_daily_logs_table';

//ADT DAILY LOGS DETAILS TABLE
export const SET_DAILY_LOGS_DETAILS_DATA = 'set_daily_logs_details_table';

//ADT REGIONS LOGS TABLE
export const SET_REGIONS_DATA = 'set_regions_table';

//ADT OPS REGIONS LOGS TABLE
export const SET_OPS_REGIONS_DATA = 'set_ops_regions_table';

//AREA TABLE
export const SET_AREA_DATA = 'set_area_table';

//CONFIGURATION TABLE
export const SET_CONFIGURATION_DATA = 'set_configuration_table';

//REPORTS TABLE
export const SET_REPORTS_DATA = 'set_reports_table';

//COUNTERS TABLE
export const SET_COUNTERS_DATA = 'set_counters_table';

//PENALTY TABLE
export const SET_PENALTY_DATA = 'set_penalty_table';

//ADT DAILY LOGS MAIL POPUP
export const SET_ADT_POPUP_DATA = 'set_adt_popup_data';

//INVOICE FILTER
export const INVOICE_FILTER = 'invoice_filter'

//USERS MANAGEMENT FILTER
export const USERS_FILTER = 'user_filter'

//STATION FILTER
export const STATION_FILTER = 'station_filter'

//AREA FILTER
export const AREA_FILTER = 'area_filter'

//REGIONS FILTER
export const REGIONS_FILTER = 'region_filter'

//OPS REGIONS FILTER
export const OPS_REGIONS_FILTER = 'ops_region_filter'

//DAILY LOGS FILTER
export const DAILY_LOGS_FILTER = 'daily_logs_filter'

//INVOICE PAGE NUMBER
export const INVOICE_PAGE_NUMBER = 'invoice_page_number'

//USERS MANAGEMENT PAGE NUMBER
export const USERS_PAGE_NUMBER = 'user_page_number'

//STATION PAGE NUMBER
export const STATION_PAGE_NUMBER = 'station_page_number'

//AREA PAGE NUMBER
export const AREA_PAGE_NUMBER = 'area_page_number'

//REGIONS PAGE NUMBER
export const REGIONS_PAGE_NUMBER = 'region_page_number'

//OPS REGIONS PAGE NUMBER
export const OPS_REGIONS_PAGE_NUMBER = 'ops_region_page_number'

//DAILY LOGS PAGE NUMBER
export const DAILY_LOGS_PAGE_NUMBER = 'daily_logs_page_number'

//MODULES PAGE NUMBER
export const MODULES_PAGE_NUMBER = 'module_page_number'

//COMPANY PAGE NUMBER
export const COMPANY_PAGE_NUMBER = 'compony_page_number'

//SUPPLIER PAGE NUMBER
export const SUPPLIER_PAGE_NUMBER = 'supplier_page_number'

//ROLE PAGE NUMBER
export const ROLE_PAGE_NUMBER = 'role_page_number'

//SET EXCLUDED CONTRACT NUMBERS
export const SET_EXCLUDED_CONTRACT_NUMBERS = 'set_excluded_contract_numbers'

//EMAIL LOG REPORT
export const SET_EMAIL_LOGS_REPORT_DATA = 'set_email_logs_report_table'
