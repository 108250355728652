import React, { useState, useEffect } from 'react'
import baseStyles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import styles from '../../../../styles/stationListing/StationListingScreen.module.css'
import ENWORDS from '../../../../shared/local/en.json';
import { ROUTES }  from '../../../../shared/constants/appRoute';
import { useNavigate } from 'react-router-dom';
import { STATIONHEADER, STATIONSTATUSSTLE, STATION_EXPORT_FILE_PREFIX, STATION_EXPORT_HEADERS } from '../../stationListingConstant';
import { useSelector, useDispatch } from 'react-redux';
import CustomSearchBox from '../../../../shared/Components/CustomSearchBox';
import { deleteStation, getStationById, getStationData } from '../../../../api/services/stationManagementService';
import { setLoadingFlag } from '../../../../redux/actions';
import { getModuleDetailsToDisplay } from '../../../../api/services/moduleConfigurationService';
import { getCompany, getSupplierDetailsToDisplay } from '../../../../api/services/commonService';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import { SITESTATUS } from '../../../BaseConfiguration/baseConfigurationConstant';
import DownloadCSVBtn from '../../../../shared/Components/DownloadCSVBtn';
import SideNavigation from '../../../../shared/Components/SideNavigation';
import { TABS } from '../../../../shared/constants/appConstants';
import CustomTable from '../../../../shared/Components/CustomTable';
import editPenIcon from '../../../../assets/icons/editPenIcon.svg';
import deleteIcon from '../../../../assets/icons/binIcon.svg';
import CustomPopup from '../../../../shared/Components/CustomPopup';
import { updateStationStatusFilter, updateStationPageNumber } from '../../../../redux/actions/stationListing'

const SCREENS  = require('../../../../shared/local/screens.json');

const StationListingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stationData = useSelector(state => state.stationListing.stationTable);
  const stationStatusFilter = useSelector(state => state.stationListing.stationStatusFilter);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ searchText, setSearchText ] = useState('');
  const [ roleAccess, setRoleAccess ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState(stationStatusFilter ? stationStatusFilter : SITESTATUS.All);
  const [ toDeleteId, setToDeleteId ] = useState(0)
  const [ deletePopupVisible, setDeletePopupVisible ] = useState(false)

  useEffect(() => {
    dispatch(getStationData());
  }, [])

  const filteration = () => {
    let stationFilterData = stationData
    if (searchText.length > 0) {
      stationFilterData = stationFilterData.filter((item) => {
        const siteId = (item[ 'siteId' ]?.toLowerCase())
        const siteName = (item[ 'siteName' ]?.toLowerCase())
        const siteAddress = (item[ 'siteAddress' ]?.toLowerCase())
        return siteId?.includes(searchText.toLowerCase()) || siteName?.includes(searchText.toLowerCase()) || siteAddress?.includes(searchText.toLowerCase())
      });
    }
    if(selectedStatus.toLowerCase() !== 'all'){
      
      stationFilterData = stationFilterData.filter((item) =>{

        return item.siteStatus === selectedStatus
      })
    }
    
    return stationFilterData
  }
  useEffect(() => {
    setFilteredData(filteration())

  }, [ searchText, selectedStatus, stationData ])

  useEffect(() => {
    dispatch(updateStationStatusFilter(selectedStatus))
    dispatch(updateStationPageNumber(0))
  }, [ selectedStatus ])
  
  const addStation = async (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))

    const company = await getCompany();

    if (company){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.ADDSTATION, { state:{ companyRes: company } })
    }

  }
  
  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.stationModule){
        if (!item.writeAccess){
          delete STATIONHEADER[ 'actions' ];
        }
        setRoleAccess(item)
      }
    })

  }

  const onRowClick = async(event, siteId) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    
    const stationDetailsData = await getStationById(siteId);
    const moduleData = await getModuleDetailsToDisplay();
    const stationVal = await getSupplierDetailsToDisplay();
    if (stationDetailsData) {
      dispatch(setLoadingFlag(false));
      navigate(ROUTES.STATIONDETAILS, {
        state: {
          data: stationDetailsData?.res,
          moduleValue: moduleData?.res,
          stationValue: stationVal?.res,
          roleAccess: roleAccess,
        },
      });
    }
  }

  const onDeleteClick = (event, areaId) => {
    event.stopPropagation();
    setToDeleteId(areaId)
    setDeletePopupVisible(true)
  }

  const onStationDelete = (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    dispatch(deleteStation(toDeleteId))
    setDeletePopupVisible(false)
    dispatch(getStationData())
  }
  
  const editStation = async (event, siteId) => {
    dispatch(setLoadingFlag(true));
    event.stopPropagation();
    const stationDetailsData = await getStationById(siteId);
    const companies = await getCompany();

    if (stationDetailsData  && companies) {
      dispatch(setLoadingFlag(false));
      navigate(ROUTES.EDITSTATION, {
        state: {
          data: stationDetailsData.res,
          companyValue: companies,
        },
      });
    }
  };

  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateStationPageNumber(0))
  }

  return (
      <div className={baseStyles.mainContainer}>
          <CustomPopup
            showPopUp={deletePopupVisible}
            onConfirm={onStationDelete}
            title={ENWORDS.removeStationTitle}
            onReject={()=>{setDeletePopupVisible(false)}}
          >
              <p>{ENWORDS.removeStationText}</p>
          </CustomPopup>   
          <div className = {baseStyles.leftContainer}>
              <SideNavigation parentName={TABS.setup}
              />
          </div>

          <div className={baseStyles.rightContainer}> 
              <div className = {baseStyles.siteHeader}>
                  <div className={styles.stationHeaderContainer}>
                      <div className={styles.filterContainer}>
                          <div className={styles.leftFilterContainer}>

                              <div className={styles.searchBoxContainer}>
                                  <CustomSearchBox
                                    placeholder = {ENWORDS.stationPlaceholder}
                                    title={ENWORDS.search}
                                    onChange={e => onChangeSearch(e)} />
                              </div>
                              <CustomDropDown
                                title={ENWORDS.status}
                                name = {ENWORDS.status.toLowerCase()}
                                fieldValues={SITESTATUS}
                                type = {ENWORDS.custom}
                                selectedValue={selectedStatus === 'Coming Soon' ? 'ComingSoon' : selectedStatus}
                                onChange={e=> setSelectedStatus(SITESTATUS[ e.target.value ])}
                              />
                          </div>
                          <div className={baseStyles.btnContainer}>
                              <div className={baseStyles.addContainer}>
                                  <DownloadCSVBtn 
                                    popUpText={ENWORDS.downloadStationCSVText}
                                    popUpTitle = {ENWORDS.downloadStationCSVTitle}
                                    screenName = {ENWORDS.stationCSV}
                                    toolTitle = {ENWORDS.downloadStationTooltip}
                                    tableData={filteredData}
                                    headers={STATION_EXPORT_HEADERS}
                                    fileNamePrefix={STATION_EXPORT_FILE_PREFIX}
                                  />
                              </div>
                              {roleAccess && roleAccess.writeAccess ?  
                                  <div className={baseStyles.addContainer} onClick={addStation }>
                                      <button>
                                          <img src={plusIcon} alt={ENWORDS.addBtn} />
                                      </button>
                                  </div>
                                :null
                              }
                
                          </div>             
                      </div>
                  </div> 
              </div>
              <CustomTable 
                data = {filteredData} 
                headerData = {STATIONHEADER} 
                tableName = {ENWORDS.stationTable}
                onRowClick = {onRowClick}
                onClickKey='siteId'
                actions={
                  [ 
                    { name : ENWORDS.deleteIcon, actionToolTipTxt : ENWORDS.deleteDataTxt, src : deleteIcon,columnName :  ENWORDS.actions, onClick : onDeleteClick },
                    { name : ENWORDS.editPenIcon, actionToolTipTxt : ENWORDS.editDataTxt, src : editPenIcon,columnName :  ENWORDS.actions, onClick : editStation } 
                  ]}
                statusStyle={STATIONSTATUSSTLE}
                isFromPage='station'
              />
          </div> 
      </div>
  )
}

export default StationListingScreen