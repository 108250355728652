import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { loadJWTUser, setJWTToken } from '../../redux/actions/auth';
import { fetchStart, fetchSuccess } from '../../redux/actions/common';
import { UPDATE_AUTH_USER, USER_LOADED } from '../../shared/constants/actionType';
import  Cookies  from 'universal-cookie';
import { getCompanyModuleMapping, getCurrentUser } from '../../api/services/commonService';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

export const useProvideAuth = () => {
  const dispatch = useDispatch()
  const { auth } = useSelector((authReducer) => authReducer);
  const { instance } = useMsal();
  const [ loading, setLoading ] = useState(true);

  const  login = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        const cookies = new Cookies();
        cookies.set('token', res.accessToken);
        dispatch(loadJWTUser(res.accessToken));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateAuth = async () => {
    dispatch(fetchStart());
    try{
      const currentUser = await instance.getAllAccounts()[ 0 ];
      const request = {
        scopes: loginRequest.scopes,
        login_hint: currentUser.username,
      };
      instance.ssoSilent(request).then(async (res) => {
        dispatch(setJWTToken(res.accessToken));
        const cookies = new Cookies();
        cookies.set('token', res.accessToken);
        const currentUserRes = await getCurrentUser()
        const userRes = currentUserRes.res;
        dispatch(getCompanyModuleMapping());
        if (userRes){
          const fullName = userRes.firstName + ' ' + userRes.lastName;
          dispatch({
            type: UPDATE_AUTH_USER,
            payload: {
              firstName: userRes.firstName,
              lastName: userRes.lastName,
              displayName: fullName,
              email: userRes.email,
              role: userRes.roleName,
              profilePicture: userRes.profilePicture,
              id: userRes.id,
              roleId: userRes.roleId,
              token: res.accessToken,
              roleAccess: userRes.roleAccess,
              isExtraPrivileged: userRes.isExtraPrivileged,
            },
          });
          setLoading(false);
        }

      })
        .catch(err=>{
          login();
          console.log(err);
          setLoading(false);
          dispatch(fetchSuccess());
          dispatch({ type: USER_LOADED });
        });

    }catch (err) {
      login();
      console.log(err);
      setLoading(false);
      dispatch(fetchSuccess());
      dispatch({ type: USER_LOADED });
    }
  };

  useEffect(() => {
    //create api call here to check jwt set user using setUser
    validateAuth();
  }, []);

  // Return the user object and auth methods
  return {
    user: auth.user,
    loading,
  };
}
