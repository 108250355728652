import React, { useEffect, useState } from 'react';
import styles from '../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TABS } from '../constants/appConstants';
import arrowDown from '../../assets/icons/arrowDownFilled.svg';
import arrowUp from '../../assets/icons/arrowUpFilled.svg';
import globalStyles from '../../styles/global.module.css';

const SubMenu = ({ item, stationListingScreen }) => {
  const [ subnav, setSubnav ] = useState(true);
  const showSubnav = () => {
    setSubnav(!subnav);
  };
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current item is active based on the pathname
  const isActive = stationListingScreen.some(
    (obj) => location.pathname === `/${ obj.routeIdentity }`
  );

  useEffect(() => {
    setSubnav(isActive);
  }, [ isActive ]);

  return (
      <div>
          <div
            className={styles.subMenuContainer}
            onClick={() => {
              showSubnav();
            }}
          >
              <div className={styles.subMenuSpan}>
                  <div className={styles.listSpaceActive}></div>
                  {item.screenName}
              </div>
              <div className={globalStyles.selectionBox}>
                  <img src = { subnav ? arrowUp:  arrowDown} alt="Arrow"/>
              </div>
          </div>
          {subnav && (
          <div className={styles.baseNavContainer}>
              <ul>
                  {stationListingScreen.map((obj) => (
                      <li
                        key={obj.screenName}
                        className={
                          location.pathname === `/${ obj.routeIdentity }`
                            ? styles.navHeading
                            : null
                        }
                        onClick={() => navigate(`/${ obj.routeIdentity }`)}
                      >
                          <div className={styles.listSpaceActive}></div>
                          {obj.screenName}
                      </li>
                  ))}
              </ul>
          </div>
          )}
      </div>
  );
};

const SideNavigation = ({ parentName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const notIncludedScreens = [ 'Stations', 'Areas', 'Regions', 'Ops Regions' ]
  const stationListingScreen = roleLevelAccess.filter((obj)=>notIncludedScreens.includes(obj.screenName))
  return (
      <div className={styles.baseNavContainer}>
          <ul>
              {
                roleLevelAccess.map((item) => {
                  if(item.parent === parentName){
                    if(item.readAccess && !notIncludedScreens.includes(item.screenName) ) {
                      return(<>{item.screenName === TABS.stationListing ? 
                          <li key = {item.screenName}>
                              <SubMenu item={item} stationListingScreen = {stationListingScreen} />
                          </li>
                        : 
                          <li key = {item.screenName}
                            className={ location.pathname === `/${ item.routeIdentity }` ?
                              styles.navHeading : null}
                            onClick={() => navigate(`/${ item.routeIdentity }`)} >
                              <div className={styles.listSpaceActive}></div>
                              {item.screenName}
                          </li>
                      }</>)
                    }
                  }
                })

              }

          </ul>
      </div>
  )
}
export default SideNavigation