export const  ROUTES = {
  DASHBOARD: '/dashboard',
  USERMANAGENT: '/usermangement',
  MODULECONFIGURATION: '/moduleconfigurations',
  ADDMODULECONFIGURATION: '/addmoduleconfigurations',
  EDITMODULECONFIGURATION: '/editmoduleconfigurations',
  VIEWMODULECONFIGURATION: '/viewmoduleconfigurations',
  BASECONFIGURATION: '/baseconfiguration',
  SETUP: '/setup',
  INVOICELISTING: '/invoicelisting',
  STATIONLISTING:'/stationListing',
  ADDSTATION:'/addstation',
  EDITSTATION:'/editstation',
  STATIONDETAILS:'/stationdetails',
  USERMANAGEMENTSCREEN: '/usermanagement',
  ADDUSERSCREEN: '/addUser',
  EDITUSERSCREEN: '/editUser',
  USERDETAILSSCREEN: '/userdetails',
  INVOICEDETAILS: '/invoiceDetails',
  SUPPLIERMANAGEMENT : '/suppliermanagement',
  ADDNEWSUPPLIER : '/addnewsupplier',
  EDITSUPPLIER:'/editsupplier',
  SUPPLIERDETAILS:'/supplierDetails',
  ROLEMANAGEMENT:'/rolemanagement',
  ADDROLE :'/addrole',
  EDITROLE:'/editrole',
  ROLEDETAILS:'/roledetails',
  COMPANY:'/company',
  EDITCOMPANY:'/editcompany',
  ADDCOMPANY : '/addcompany',
  COMPANYDETAILS:'/companydetails',
  BASESETTINGS:'/settings',
  EDITSETTINGS:'/editSettings',
  ADTDAILYLOGS:'/adtDailyLogs',
  ADTDAILYLOGDETAILS:'/adtLogDetails',
  ADTREPORTS:'/adtReports',
  ADTCOUNTERS:'/adtCounters',
  ADTPENALTIES:'/adtPenalties',
  ADTPENALTYCONFIGURATION:'/adtPenaltyConfiguration',
  STATIONAREAS:'/areas',
  STATIONREGIONS:'/regions',
  EDITREGIONS:'/editregions',
  REGIONDETAILS:'/regiondetails',
  ADDREGION:'/addregion',
  ADDAREA:'/addarea',
  AREADETAILS:'/areadetails',
  EDITAREA:'/editarea',
  CONFIGURATION:'/configuration',
  STATIONOPSREGIONS:'/opsRegions',
  EDITOPSREGIONS:'/editOpsRegions',
  OPSREGIONDETAILS:'/opsRegiondetails',
  ADDOPSREGION:'/addOpsRegion',
  EMAILLOGREPORT:'/emailAuditLog',
}
export const  AUTHROUTES = {
  LOGINSCREEN: '/login',
  OTPSCREEN: '/otpscreen',
  CHANGEPASSWORDSCREEN:'/changepassword',
  FORGOTPASSWORDSCREEN:'/forgotpassword',
}
