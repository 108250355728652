import React ,{ useState, useEffect } from 'react';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ENWORDS from '../../../../shared/local/en.json';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import CustomInput from '../../../../shared/Components/CustomInput';
import settingsStyles from '../../../../styles/settings/settings.module.css';
import { ROUTES } from '../../../../shared/constants/appRoute';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import arrowDown from '../../../../assets/icons/arrowDownDark.svg'
import arrowUp from '../../../../assets/icons/arrowUp.svg';
import binIcon from '../../../../assets/icons/binIcon.svg'
import plusIcon from '../../../../assets/icons/plusIcon.svg'
import { displayError, setLoadingFlag } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { clamp, commonScrollFunction, isoDateToMillisecond, milliSecondToIsoString } from '../../../../shared/commonFunctions';
import { getAllSettings, updateSetting } from '../../../../api/services/settingsService';
import { ALPHA_NUM_REGEX, datePlus1day, datePlus30days, globalDateFormat } from '../../../../shared/constants/appConstants';
import CustomDatePicker from '../../../../shared/Components/CustomDatePicker';
import { useDebounce } from 'use-debounce';

const PLACEHOLDER  = require('../../../../shared/local/placeholder.json');
const ERRORWORDS  = require('../../../../shared/local/error.json');

const EditSettingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch();
  
  const [ requestData, setRequestData ] = useState({});
  const [ showVatContainer, setShowVatContainer ] = useState(true);
  const [ error, setError ] = useState({});
  const [ vatError, setVatError ] = useState([]);
  const [ isDone, setIsDone ] = useState(false);
  const [ vatList, setVatList ] = useState(locationState.setting??[]);
  const [ value ] = useDebounce(vatList, 500);

  const isValidated = async () =>{
    dispatch(setLoadingFlag(true));
    const editSettingRes = await updateSetting(requestData);
    if (editSettingRes){
      dispatch(setLoadingFlag(false))
      dispatch(getAllSettings())
      navigate(ROUTES.BASESETTINGS)
    }
  }
  const isBetween = (fromDate,toCheck,toDate) =>{
    const dateToCheck  = isoDateToMillisecond(toCheck);
    const minDate  = isoDateToMillisecond(fromDate);
    const maxDate  = isoDateToMillisecond(toDate);
    return dateToCheck >= minDate && dateToCheck <= maxDate;
  }
  const checkDateIntersection = () => {
    const vatErrorCopy = [ ...vatError ];
    if (vatList.length > 1){
      let isError = false;
      for (let i = 0; i < vatList.length-1; i++) {
        for (let j = i+1; j < vatList.length; j++) {
          const iEle = vatList[ i ];
          const jEle = vatList[ j ];
          if (!isError){

            if ((iEle.vatName === jEle.vatName || iEle.vatCode === jEle.vatCode) && 
            ((isBetween(iEle.effectiveFrom, jEle.effectiveFrom, iEle.effectiveTo ) || 
            isBetween(iEle.effectiveFrom, jEle.effectiveTo, iEle.effectiveTo )) ||
            (isBetween(jEle.effectiveFrom, iEle.effectiveFrom, jEle.effectiveTo ) || 
            isBetween(jEle.effectiveFrom, iEle.effectiveTo, jEle.effectiveTo )))){
              
              vatErrorCopy[ j ].vatFromError = ERRORWORDS.duplicateVatDates;
              vatErrorCopy[ j ].vatToError = ERRORWORDS.duplicateVatDates;
              isError = true;
            }
            else if (isoDateToMillisecond(jEle.effectiveFrom) >= isoDateToMillisecond(jEle.effectiveTo)){
              vatErrorCopy[ j ].vatToError = ERRORWORDS.endDateError
              vatErrorCopy[ j ].vatFromError = ERRORWORDS.startDateError;
              isError = true;
            }
            else if (isoDateToMillisecond(iEle.effectiveFrom) >= isoDateToMillisecond(iEle.effectiveTo)){
              vatErrorCopy[ i ].vatToError = ERRORWORDS.endDateError
              vatErrorCopy[ i ].vatFromError = ERRORWORDS.startDateError;
              isError = true;
            }
            else{
              vatErrorCopy[ j ].vatFromError = '';
              vatErrorCopy[ j ].vatToError = '';
              vatErrorCopy[ i ].vatFromError = '';
              vatErrorCopy[ i ].vatToError = '';
            }
          }

        }
        
      }
    }
    setVatError(vatErrorCopy)
  }

  const checkValidation = () =>{    
    let errorVariable = error;
    const vatErrorCopy = vatError;
    vatList.forEach((vatRow, id) => {
      const currentIndex = vatErrorCopy.findIndex((e)=> e.errorId === vatRow.vatID);

      if (currentIndex > -1){
        if (!ALPHA_NUM_REGEX.test(vatRow.vatName)){
          vatErrorCopy[ currentIndex ].vatNameError = ERRORWORDS.vatNameError
        }
        if (!ALPHA_NUM_REGEX.test(vatRow.vatCode)){
          vatErrorCopy[ currentIndex ].vatCodeError = ERRORWORDS.vatCodeError
        }
        if (vatRow.vatRate === ''){
          vatErrorCopy[ currentIndex ].vatPercentError = ERRORWORDS.vatPercentError
        }
      }      
    })
    setVatError(vatErrorCopy);
    setError(errorVariable);
    setIsDone(!isDone);
    
    const isErrorEmpty = Object.keys(errorVariable).filter((item)=>{
      return errorVariable[ item ] !== ''
    })
    
    const isVatErrorEmpty = vatErrorCopy.filter((item)=>{
      return item.vatNameError !== '' || item.vatCodeError !== '' || item.vatPercentError ||  item.vatFromError !== '' || item.vatToError !== '';
    })

    if (isErrorEmpty.length === 0 && isVatErrorEmpty.length === 0){
      isValidated();    }
  }

  const addBlock = () => {
    let vatListCopy = vatList;
    let vatErrorCopy = vatError;
    let blockId = 0;
    let initialStartDate;
    let initialEndDate;
    let lastEleOfErrorList = 0;
    if (vatError.length !== 0){
      lastEleOfErrorList  = vatError[ vatError.length - 1 ];
      blockId = lastEleOfErrorList.errorId + 1
    }

    let vatListStructure = {
      vatID: blockId,
      vatName:'', 
      vatCode:'',
      vatRate:0,
      effectiveFrom:'',
      effectiveTo:'',
    };

    if (vatList.length === 0) {
      initialStartDate = Date.now() -  datePlus30days;
      initialEndDate = Date.now();
      vatListStructure = {
        vatID: blockId,
        vatName:'', 
        vatCode:'',
        vatRate:0,
        effectiveFrom: milliSecondToIsoString(initialStartDate),
        effectiveTo: milliSecondToIsoString(initialEndDate),
      }
    } else {

      const effectiveFromData = isoDateToMillisecond(vatListCopy[ vatListCopy.length - 1 ].effectiveTo) + datePlus1day
      vatListStructure = {
        vatID: blockId,
        vatName:'', 
        vatCode:'',
        vatRate:0,
        effectiveFrom: milliSecondToIsoString(effectiveFromData),
        effectiveTo: milliSecondToIsoString(effectiveFromData + datePlus30days),
      }
    }
    const vatErrorStructure= { 
      errorId:blockId, 
      vatNameError: '' ,
      vatCodeError:  '',
      vatPercentError:  '',
      vatFromError:'',
      vatToError:'',
    }

    vatErrorCopy = [ ...vatErrorCopy , vatErrorStructure ]
    vatListCopy = [ ...vatListCopy, vatListStructure  ]

    setVatList(vatListCopy)
    setVatError(vatErrorCopy);
  }
      
  const deleteFieldByid = (formVatID) =>{
    const varErrorCopy = vatError;
    const vatListCopy = vatList;
    const index  = vatListCopy.findIndex((item)=>item.vatID ===  formVatID);
    if (index >-1){
      
      vatListCopy.splice(index,1)  
      varErrorCopy.splice(index,1)
          
      setVatList([ ...vatListCopy ])
      setVatError([ ...varErrorCopy ]);

    }
  }
  useEffect(() =>{
    if(vatList.length === 0){
      addBlock()
    } else {
      const temp = vatList
      temp.sort((a, b) => a[ 'vatID' ] - b[ 'vatID' ])
      setVatList(temp)
    }
  },[])

  const  onVatDetailsUpdate  = (eventValue, fieldID, fieldName,objKey) =>{
    const vatErrorCopy = vatError;
    const tempVatList = vatList.map((i,index) => {
      if(i.vatID === fieldID){
        if(objKey === 'Date')
        {
          return{ ...i , [ fieldName ]: milliSecondToIsoString(eventValue) }
        }
        else if (objKey === 'vatRate'){
          if(eventValue >= 0){
            vatErrorCopy[ index ][ 'vatPercentError' ] = '';
          }
          return { ...i, [ fieldName ]: eventValue }
        }
        else{
          if (ALPHA_NUM_REGEX.test(eventValue)){
            vatErrorCopy[ index ][ fieldName + 'Error' ] = '';
          }
          return { ...i, [ fieldName ]: eventValue }
        }
      }
      return i;
    })
    setVatList([ ...tempVatList ])
    setVatError( [ ...vatErrorCopy ])
  }

  useEffect(()=>{
    if(vatList.length>0 && vatError.length > 0){
      checkDateIntersection();
    }
  }, [ value ])

  const populateInitialVatError = () =>{
    let vatErrorCopy = vatError;

    vatList.forEach((item)=>{
      const vatErrorStructure= { 
        errorId:item.vatID, 
        vatNameError: '' ,
        vatCodeError:  '',
        vatPercentError:  '',
        vatFromError:'',
        vatToError:'',
      }
      vatErrorCopy = [ ...vatErrorCopy , vatErrorStructure ]
    })
    setVatError([ ...vatErrorCopy ])

  }

  useEffect(()=>{
    if (vatError.length === 0){
      populateInitialVatError()
    }
  }, [ vatError ])

  useEffect(()=>{
    const inputError =commonScrollFunction();
    if (!inputError){
      commonScrollFunction('p','errorMessageDate');
    }
  }, [ isDone ])

  useEffect(()=>{
    setRequestData(vatList);
  }, [ vatList ])

  return(
      <div className={styles.mainContainer}>
          <div className={settingsStyles.subContainer}>
              <ListingHeader
                title = {ENWORDS.editSettings}
                navigationPath = {-1}
              />             
              <div className={supplierStyles.contentContainer}>
                  {
                    Object.keys(requestData).length ?
                        <div className={supplierStyles.content}>

                            <div className={settingsStyles.vatContainer}>
                                <div className = {settingsStyles.headerContainer}>
                                    <p>{ENWORDS.fieldDetails}</p>
                                    <button onClick={()=>setShowVatContainer(!showVatContainer)}>
                                        <img src = {showVatContainer ? arrowUp: arrowDown} 
                                          alt = {ENWORDS.arrow} 
                                          className= {settingsStyles.downArrow}/>
                                    </button>
                                </div>

                                <div className={styles.buttonContainer} >
                                    <button className={styles.btnDone} onClick= {addBlock}>
                                        <img src={plusIcon} alt ={ENWORDS.editPenIcon}/>
                                    </button>
                                </div>

                                {
                                  showVatContainer &&  vatList && vatList.map((vatItem,id)=>{
                                    return(
                                        <div className={settingsStyles.vatBlock}>
                                            {
                                              vatList.length === 1 ?
                                                null
                                                :<div className={settingsStyles.deleteContainer} >
                                                    <button className={styles.btnCancel} onClick = {()=> deleteFieldByid(vatItem.vatID)} >
                                                        <img src={binIcon} alt = {ENWORDS.deleteIcon}/>
                                                    </button>
                                                </div>
                                            }
                                          
                                            <div className={settingsStyles.fieldBlock}>
                                                <CustomInput
                                                  title = {ENWORDS.vatName} 
                                                  error = { displayError(vatError, vatItem.vatID, ENWORDS.vatNameError) }
                                                  value = {vatItem.vatName}
                                                  placeholder = {PLACEHOLDER.vatName}
                                                  onChange={(e)=> onVatDetailsUpdate(e,vatItem.vatID, 'vatName')}
                                                />
                                                <CustomInput
                                                  title = {ENWORDS.vatCode} 
                                                  error = { displayError(vatError, vatItem.vatID, ENWORDS.vatCodeError) }
                                                  placeholder = {PLACEHOLDER.vatCode}
                                                  value = {vatItem.vatCode}
                                                  onChange={(e)=> onVatDetailsUpdate(e,vatItem.vatID, 'vatCode')}
                                                />
                                                <CustomInput
                                                  title = {ENWORDS.vatRate} 
                                                  error = { displayError(vatError, vatItem.vatID, ENWORDS.vatPercentError) }
                                                  placeholder = {PLACEHOLDER.vatRate}
                                                  value = {vatItem.vatRate}
                                                  maxLength = {4}
                                                  onChange={(e)=> onVatDetailsUpdate((Number(e) >= 0 ?
                                                    clamp(e,0,100) : ''),vatItem.vatID, 'vatRate', 'vatRate')}
                                                />
                                                <CustomDatePicker
                                                  title = {ENWORDS.effectiveFrom}
                                                  startDate = {isoDateToMillisecond(vatItem.effectiveFrom)}
                                                  dateFormat = {globalDateFormat}
                                                  errorMessage = {vatError[ id ]?.vatFromError}
                                                  onChange={(e) => onVatDetailsUpdate(e.getTime(), vatItem.vatID, 'effectiveFrom','Date')}
                                                />
                                                <CustomDatePicker
                                                  title = {ENWORDS.effectiveTo}
                                                  startDate = {isoDateToMillisecond(vatItem.effectiveTo)}
                                                  errorMessage = {vatError[ id ]?.vatToError}
                                                  dateFormat = {globalDateFormat}
                                                  onChange={(e) => onVatDetailsUpdate(e.getTime(), vatItem.vatID,'effectiveTo', 'Date')}
                                                />
                                            </div>
   
                                        </div>
                                    )

                                  })
                                }

                            </div>

                            <div className={styles.btnContainer}>
                                <div className={styles.cancelBtn} onClick={()=> navigate(-1)}>
                                    <button>{ENWORDS.cancel}</button>
                                </div>
                                <div className={styles.doneBtn}>
                                    <button onClick={checkValidation}>{ENWORDS.done}</button>
                                </div>
                            </div>
                        </div>
                      :null
                  }
                 
              </div>
          </div>
      </div>
  )
}

export default EditSettingScreen;