import React from 'react'
import styles from '../../styles/inputs.module.css'
import globalStyles from '../../styles/global.module.css';

const CompanyDropdown = ({ title, name, fieldValues }) => {
  
  return (
      <div className={globalStyles.roleContainer}>
          <p className={globalStyles.filterText}>{title}</p>
          <div className={styles.selectionBox}>
              <select name= {name} id= {name}>
                  <option 
                    className={styles.defaultPlaceholder} 
                    selected 
                    key = {fieldValues?.length}>
                      {'Companies: ' +  fieldValues?.length + ' selected'}
                  </option>
                  {
                    fieldValues?.map((item)=>{
                      return (
                          <option 
                            key={item.companyID} 
                            value={item.companyName}  
                            disabled= {true}>
                              {item.companyName}  
                          </option>
                      )
                    })
                  }
              </select>
          </div>
      </div> 

  )
}

export default CompanyDropdown