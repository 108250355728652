import {  SET_COMPANY_TABLE, COMPANY_PAGE_NUMBER } from '../../shared/constants/actionType';

const INIT_STATE = {
  companyTable:[],
  pageNumber: 0,
}

const companyManagementReducer = (state = INIT_STATE, action) =>{
  switch(action.type){
  case SET_COMPANY_TABLE:
    return{
      ...state,
      companyTable :  action.payload ,
    }
  case COMPANY_PAGE_NUMBER:
    return{
      ...state,
      pageNumber :  action.payload ,
    }
  default: return state
  }
}
export default companyManagementReducer
