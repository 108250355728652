import React, { useState, useEffect } from 'react';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import RoleHeader from './RoleManagementHome/RoleHeader.js';
import RoleTable from './RoleManagementHome/RoleTable.js';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleData } from '../../../api/services/roleManagementService';
import { setLoadingFlag } from '../../../redux/actions';
import { ROLEFIELDS } from '../baseConfigurationConstant';
import SideNavigation from '../../../shared/Components/SideNavigation';
import { TABS } from '../../../shared/constants/appConstants';

const RoleManagementScreen = () =>{
  const roleData = useSelector (state => state.roleManagement.roleTable)
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const[ filterData, setFilterData ] = useState(roleData);
  const [ roleAccess, setRoleAccess ] = useState();

  const dispatch = useDispatch();
  
  const fetchRole = async () =>{
    dispatch(setLoadingFlag(true))
    dispatch(getRoleData());
  }
  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === 'Role Management'){
        if (!item.writeAccess){
          delete ROLEFIELDS[ 'roleAction' ];
          
        }
        setRoleAccess(item)
      }
    })

  }

  useEffect(() =>{
    checkRoleAccess()
  },[ roleLevelAccess ])

  useEffect(() => {
    fetchRole()
  }, [])
  useEffect(() =>{
    setFilterData(roleData)
  },[ roleData ])
  return(
      <div className={styles.mainContainer}>

          <div className = {styles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   />
              {/* <SideNavigation parentName={TABS.baseConfiguration}   /> */}
          </div>
          <div className={styles.rightContainer}>
              <div className = {styles.siteHeader}>
                  <RoleHeader 
                    setFilterData= {setFilterData} 
                    roleAccess = {roleAccess}/>
              </div>
              <RoleTable 
                headerData = {ROLEFIELDS} 
                roleAccess = {roleAccess}
                filterData={ filterData}
              />
          </div>

      </div>
  )
}
export default RoleManagementScreen