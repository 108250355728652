import { API_HEADER, DELETE, GET, POST, PUT } from '../../shared/constants/appConstants';
import { STATIONURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';
import { setLoadingFlag } from '../../redux/actions';
import { setStationTable } from '../../redux/actions/stationListing';

export function getStationData() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: STATIONURL.getStation,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setStationTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export function syncAllStations() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: STATIONURL.syncAllStation,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(getStationData())
        dispatch(setLoadingFlag(false))
      })
  }
}

export function deleteStation( id ) { 
  return async (dispatch) => {
    await makeRequest({
      method: DELETE ,
      url: `${ STATIONURL.deleteStation + id }`,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function(res){
        dispatch(setLoadingFlag(true))
        dispatch(getStationData())
        dispatch(setLoadingFlag(false))
      }).catch(function (err) {
        console.log(err)
      });

  }
}

export async function deleteCmById( id, siteId ) { 
  let isCmDeleted = false;
  await makeRequest({
    method: DELETE ,
    url: `${ STATIONURL.deleteCmById + id + '&siteID=' + siteId }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function(){
      isCmDeleted=  true;
    })
    .catch(function(){
      isCmDeleted = false;
    })
  return isCmDeleted;

}

export async function getStationDetailsToDisplay(){
  let res = [];
  let errRes = {};
  await makeRequest({
    method: GET,
    url: STATIONURL.getStation,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      res  = [];
      errRes = err
    });
  return { res:res, err:errRes };
}

export async function checkStationId(stationId) {
  let res;
  await makeRequest({
    method: POST,
    url: '',
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.status;
    })
    .catch(function(){
      res = false
    })
  return res
}

export async function getStationById(siteId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ STATIONURL.getStationById + siteId }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function addStationDetails({ siteId, siteName, netSuitId, siteEmailAddress, siteAddress,  cmDetails,
  siteModuleSupplier,companyAssignment , effectiveFromDate, effectiveToDate,
  siteStatus,moduleSupplierAssign, scheduleType, siteSchedules, areaId }) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: STATIONURL.addStation,
    data: {
      siteId:siteId,
      siteName:siteName,
      netSuitId:netSuitId,
      siteEmailAddress:siteEmailAddress,
      siteAddress:siteAddress,
      cmDetails:cmDetails,
      siteModuleSupplier: siteModuleSupplier,
      companyAssignment:companyAssignment,
      siteStatus:siteStatus,
      moduleSupplierAssign:moduleSupplierAssign,
      scheduleType: scheduleType,
      siteSchedules :siteSchedules,
      areaId : areaId,
      effectiveFromDate: effectiveFromDate,
      effectiveToDate: effectiveToDate,
      prevStatus: 'newly added',
    },
    headers: API_HEADER,
  })
    .then(function (response) {
      res = true; 
    })
    .catch(function (err) {
      res = false;
      errRes = err
      
    });
  return { res:res, err:errRes }
}

export async function editStationDetails({ id, siteId, siteName, netSuitId, siteEmailAddress, siteAddress,
  cmDetails, effectiveFromDate, effectiveToDate, prevStatus,
  siteModuleSupplier,companyAssignment,moduleSupplierAssign,
  siteStatus , scheduleType, siteSchedules, areaId }) {
  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url: STATIONURL.updateStation,
    data: {
      id: id,
      siteId:siteId,
      siteName:siteName,
      netSuitId:netSuitId,
      siteEmailAddress:siteEmailAddress,
      siteAddress:siteAddress,
      cmDetails:cmDetails,
      siteModuleSupplier: siteModuleSupplier,
      companyAssignment:companyAssignment,
      siteStatus:siteStatus,
      moduleSupplierAssign:moduleSupplierAssign,
      scheduleType: scheduleType,
      siteSchedules :siteSchedules,
      areaId : areaId,
      effectiveFromDate: effectiveFromDate,
      effectiveToDate: effectiveToDate,
      prevStatus: prevStatus,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function checkSupplierSiteExist(moduleId, supplierSiteID, siteId = 0) {
  let res;
  await makeRequest({
    method: GET,
    url: `${ STATIONURL.checkSupplierSiteExist + moduleId + '&siteSupplierID=' + supplierSiteID+'&siteId='+siteId }`,
    data: undefined,
    showPopUp: false,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (){
      res = false;
    })
  return res;
}
export async function checkStationInvoiceExist(id = 0, siteId) {
  let res;
  await makeRequest({
    method: GET,
    url: `${ STATIONURL.checkStationInvoiceExist + id + '&siteId=' + siteId }`,
    data: undefined,
    showPopUp: false,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (){
      res = false;
    })
  return res;
}
