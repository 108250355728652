import React, { useEffect, useState } from 'react';
import styles from '../../styles/errorPopUp.module.css';
import errorIcon from '../../assets/icons/errorIcon.svg';
import ENWORD from '../local/en.json';
import { useDispatch, useSelector } from 'react-redux';
import crossIcon from '../../assets/icons/plusIcon.svg';
import { setErrorFlag } from '../../redux/actions';

const ErrorPopUp = () => {
  const dispatch = useDispatch();
  const loadError = useSelector(state => state.common.loadError);
  const [ error, setError ] = useState()
  const [ showError, setShowError ] = useState(false);
  const [ displayModal, setDisplayModal ] = useState(false);
  const [ animationStyles, setAnimationStyles ] = useState(false);

  const slideAnimation = (id) => { 
    const filteredData = error.filter((e) => Object.values(e)[ 0 ] !== id);
    setDisplayModal(true)
    dispatch(setErrorFlag(false))
    setError(filteredData);

  }

  const slideAnimationOld = () => {
    setAnimationStyles(false);
    setError('');
    setTimeout(() => {
      setDisplayModal(true)
      dispatch(setErrorFlag(false))
      setShowError(false)
      setShowError(false)
    }, 500);
  }
  
  useEffect(() => {
    
    setError(loadError.errorMessage)
    setShowError(true);
    setDisplayModal(loadError.errorStatus)
    setAnimationStyles(loadError.errorStatus)
    setTimeout(() => {
      setDisplayModal(true)
      setShowError(false)
      dispatch(setErrorFlag(false))
    }, 5000);
  }, [ loadError ])
  
  return (
      <div className={showError ? styles.mainContainer : styles.mainContainerHidden}>

          {
            Array.isArray(error) ?
              error && error.map((item, key) => {
                
                return (
                    <div
                      style={{
                        visibility: displayModal ? 'visible' : 'hidden',
                        opacity: displayModal ? '1' : '0',
                      }}
                      key={Object.values(item)[ 0 ]}
                      className={styles.errorPopUp}>

                        <div className={animationStyles ? styles.errorContainer : styles.errorMsgHide}>
                            <div className={styles.errorMsgContainer}>
                                <img className={styles.errorIcon} src={errorIcon} alt={ENWORD.errorLogo} />
                                <div className={styles.helpText}>
                                    {error ? Object.values(item)[ 1 ] : ENWORD.errorText}
                                </div>
                            </div>
                            <div className={styles.close} onClick={() => slideAnimation(Object.values(item)[ 0 ])}>
                                <img className={styles.crossIcon} src={crossIcon} alt={ENWORD.crossIcon} />
                            </div>
                        </div>

                    </div>
                )

              })
              :
              <div
                style={{
                  visibility: displayModal ? 'visible' : 'hidden',
                  opacity: displayModal ? '1' : '0',
                }}
                className={styles.errorPopUp}>

                  <div className={animationStyles ? styles.errorContainer : styles.errorMsgHide}>
                      <div className={styles.errorMsgContainer}>
                          <img className={styles.errorIcon} src={errorIcon} alt={ENWORD.errorLogo} />
                          <div className={styles.helpText}>
                              {error ? (error.message? error.message :error ): ENWORD.errorText}
                          </div>
                      </div>
                      <div className={styles.close} onClick={slideAnimationOld}>
                          <img className={styles.crossIcon} src={crossIcon} alt={ENWORD.crossIcon} />
                      </div>
                  </div>

              </div>
          }

      </div>

  );
}

export default ErrorPopUp
