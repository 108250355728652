import {
  FETCH_ERROR,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  USER_LOADED
} from '../../shared/constants/actionType';
  
const INIT_STATE = {
  loading: true,
  user: null,
  token: null,
  fetchError:'',
};
  
const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case UPDATE_AUTH_USER: {
    
    return {
      ...state,
      loading: false,
      user: action.payload,
    };
  }
  case SIGNOUT_AUTH_SUCCESS: {
    return {
      ...state,
      user: null,
    };
  }
  case USER_LOADED: {
    return { ...state, loading: false };
  }
  case SET_AUTH_TOKEN: {
    return {
      ...state,
      token: action.payload,
    }
  }
  case FETCH_ERROR:{
    return{
      ...state,
      fetchError:action.payload,
    }
  }
  default:
    return state;
  }
};
export default authReducer;