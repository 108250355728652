import moment from 'moment';
import ERRORWORDS from '../shared/local/error.json';
const ENWORDS = require('../shared/local/en.json');

export const userManagementAccessChecker = (access) =>{
  return access.some( e => e.readAction || e.writeAction)
}
// Common date functions

// Pass date in milliseconds to the function
// It will return the date in DD/MM/YYYY format
export const milliSecondToDate = (dateInMilliseconds) =>{
  // dateInMilliseconds = 1649140373151
  // date = Tue Apr 05 2022 12:03:49 GMT+0530 (India Standard Time)
  // dateInFormat = ['05/04/2022', ' 12:05:09']

  if ((typeof dateInMilliseconds ) !== 'undefined'){
    const date = new Date(dateInMilliseconds);
    const dateInFormat = date?.toLocaleString('en-gb').split(',')
    return dateInFormat[ 0 ]
  }
}

// Pass date in milliseconds to the function
// It will return the date inYYYY-MM-DDTHH:mm:ss.sssZ format
export const milliSecondToIsoString = (dateInMilliseconds) =>{
  if ((typeof dateInMilliseconds ) !== 'undefined'){
    const date = new Date(dateInMilliseconds)
    return date?.toISOString()
  }
}

export const isoDateToMillisecond = (dateInIsoFormat) =>{
  const myDate = new Date(dateInIsoFormat);
  const offset = myDate.getTimezoneOffset() * 60 * 1000;
  const withOffset = myDate.getTime();
  const withoutOffset = withOffset - offset;

  return withoutOffset

}

export const isoStringToDate = (isoString) =>{
  const date = new Date (isoString)
  return date.toLocaleDateString('en-GB')
}

// Sorting functions

export const sortByForDropdownData = (toSortData) => {
  let sortedData = toSortData

  sortedData = sortedData.sort((a,b) => 
    String(a[ Object.keys(a)[ 0 ] ]).localeCompare(String(b[ Object.keys(b)[ 0 ] ]))
  )
  return sortedData
}

export const defaultSortBy = (headerData, toSortData, tableName) => {
  let initialKey;
  if(tableName === ENWORDS.companyTable){
    initialKey = 'type'
  }else{
    initialKey = Object.keys(headerData)[ 0 ] 
  }
  let newFilteredData = toSortData
  newFilteredData = newFilteredData?.sort((a,b) => String(a[ initialKey ])?.localeCompare(String(b[ initialKey ])))
  return [ newFilteredData, initialKey ]
}

export const globalSortBy = (toSortData, keyValue, sortState) =>{  
  let newFilteredData = toSortData;
  let currentSortState = sortState;

  if( sortState === ENWORDS.ascending){
    currentSortState = ENWORDS.descending
    if(keyValue === 'billingPeriod'){
      newFilteredData = toSortData.sort((a,b) =>
        isoDateToMillisecond(b[ keyValue ]) - isoDateToMillisecond(a[ keyValue ])
      )
    } else if (keyValue === 'grossAmount' || keyValue === 'supplierCommision') {
      newFilteredData = toSortData.sort((a,b) => 
        parseFloat(b[ keyValue ]) - parseFloat(a[ keyValue ]))
    }
    else if (keyValue === 'termsDate') {
      newFilteredData = toSortData.sort((a,b) => 
        parseInt(b[ keyValue ]) - parseInt(a[ keyValue ]))
    }
    else{
      newFilteredData = toSortData.sort((a,b) =>
        String(b[ keyValue ]).localeCompare(String(a[ keyValue ]))
      )
    }
  }
  else{

    currentSortState = ENWORDS.ascending
    if(keyValue === 'billingPeriod'){
      newFilteredData = toSortData.sort((a,b) =>
        isoDateToMillisecond(a[ keyValue ]) - isoDateToMillisecond(b[ keyValue ])
      )
    }else if (keyValue === 'grossAmount' || keyValue === 'supplierCommision') {
      newFilteredData = toSortData.sort((a,b) => 
        parseFloat(a[ keyValue ]) - parseFloat(b[ keyValue ]))
        
    }
    else if (keyValue === 'termsDate') {
      newFilteredData = toSortData.sort((a,b) => 
        parseInt(a[ keyValue ]) - parseInt(b[ keyValue ]))
    }else{

      newFilteredData = toSortData.sort((a,b) => 
        String(a[ keyValue ]).localeCompare(String(b[ keyValue ]))
      )
    }
  }
  return [ newFilteredData, currentSortState ] 
}

export const sortByDate = (arr, key) => {
  const sorter = (a, b) => {
    return new Date(b[ key ]).getTime() - new Date(a[ key ]).getTime();
  }
  return arr.sort(sorter);
};
export const clamp = (value,min, max) => {
  if(value< max){
    return value;
  }
  return Math.min(Math.max(value, min), max);
};
function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[ i ]) {
      if (line !== '') line += ',';
      const value = ((array[ i ][ index ])?.toString())?.includes(',')? `"${ array[ i ][ index ] }"` :  array[ i ][ index ];
      line += value
    }
    str += line + '\r\n';
  }
  return str;
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    const cHeadersKeys =  Object.keys(headers)
    const isInArray = items.find(function(el){ return el[ cHeadersKeys[ 0 ] ] === headers[ cHeadersKeys[ 0 ] ] }) !== undefined
    if (!isInArray) {
      items.unshift(headers);
    }
  }
  else{
    const cHeaders ={}
    if(items.length >  0) {
      Object.keys(items[ 0 ]).forEach(e => {cHeaders[ e ]=e})
    }
    const cHeadersKeys =  Object.keys(cHeaders)
    const isInArray = items.find(function(el){ return el[ cHeadersKeys[ 0 ] ] === cHeaders[ cHeadersKeys[ 0 ] ] }) !== undefined
    if (!isInArray) {
      items.unshift(cHeaders);
    }
  }

  //Replacer function to replace all the undefined values with empty string
  const replacer = (key, value) => value === undefined || value  === null ? '' : value;

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items, replacer);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  const blob = new Blob([ csv ], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const getFirst = (array = [], func) => array.filter(func).at(0);

export const commonScrollFunction = (tagName = 'p', errorClassName = 'errorMessageShow') =>{
  const all = document.querySelectorAll(tagName+'[class*="'+errorClassName+'"]');
  all[ 0 ]?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
  });

  return all[ 0 ];
}

export const checkManagerDateIntersection = (id, managerDetailsList, managerDetailsError) => {
  const formErrorVariable = { ...managerDetailsError };
  const sortedValue =  [ ...managerDetailsList ];
  
  for (let i = 0; i < sortedValue.length; i++) {
    let isAlreadyTaken = false;
    let isStartDateError = false;

    const startDate =new Date( sortedValue[ i ].startDate).setHours(1,0,0,0);
    const endDate =new Date(sortedValue[ i ].endDate).setHours(1,0,0,0)

    if (!formErrorVariable[ id ]) {
      formErrorVariable[ id ] = {};
    }

    //Check if start date is greater than end date
    if(startDate > endDate){
      isStartDateError = true
    }

    for(let j = 0; j < sortedValue.length; j++){
      if(i === j){
        continue
      }
      const currentStartDate =new Date( sortedValue[ j ].startDate).setHours(1,0,0,0);
      const currentEndDate =new Date(sortedValue[ j ].endDate).setHours(1,0,0,0)

      isAlreadyTaken =
        (currentStartDate >= startDate && currentStartDate <= endDate) ||
        (currentEndDate >= startDate && currentEndDate <= endDate);
      if(isAlreadyTaken){
        break
      }

    }
    //Current element which is changed
    if(id === sortedValue[ i ].id){
      if(isStartDateError && i !== 0){
        formErrorVariable[ id ].formEndDateError = ERRORWORDS.endDateError
        formErrorVariable[ id ].formStartDateError = ERRORWORDS.startDateError;
      }
      else if(isAlreadyTaken){
        formErrorVariable[ id ].formStartDateError = ERRORWORDS.dateAlreadyTaken;
        formErrorVariable[ id ].formEndDateError = ERRORWORDS.dateAlreadyTaken;
      }
    }
    if(!isAlreadyTaken && !isStartDateError && formErrorVariable[ sortedValue[ i ].id ]){
      delete formErrorVariable[ sortedValue[ i ].id ].formEndDateError
      delete formErrorVariable[ sortedValue[ i ].id ].formStartDateError
    }
  }
  return { formErrorVariable, isError : (formErrorVariable[ id ]?.formEndDateError || formErrorVariable[ id ]?.formStartDateError)  }
}

export const convertTimetoMinutes = (timeValue) => {
  const openingTimeVal =  moment(timeValue).hours() * 60 + moment(timeValue).minutes()
  return openingTimeVal;
}
