import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import conStyles from '../../../../styles/moduleConfiguration/addconfig.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import arrowUp from '../../../../assets/icons/arrowUp.svg';
import arrowDown from '../../../../assets/icons/arrowDownDark.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { useDispatch, useSelector } from 'react-redux';
import editPenIconLight from '../../../../assets/icons/editPenWhiteIcon.svg';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import { deleteStation, getStationById, getStationData } from '../../../../api/services/stationManagementService';
import { setLoadingFlag } from '../../../../redux/actions';
import { isoStringToDate } from '../../../../shared/commonFunctions';
import { getCompany } from '../../../../api/services/commonService';
import CompanyDropdown from '../../../../shared/Components/CompanyDropdown';
import binIcon from '../../../../assets/icons/binIcon.svg';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import { getModuleDetails } from '../../../../api/services/moduleConfigurationService';
import SchedularTimeComponent from '../../../../shared/Components/SchedularTimeComponent';
import { BASE_TIME, DAYS, OPEN_24_HRS, SCHEDULED, STATIONSHEDULE } from '../../stationListingConstant';
import moment from 'moment/moment';
import { getAreas } from '../../../../api/services/areaManagementService';
import CustomPopup from '../../../../shared/Components/CustomPopup';

const StationDetailsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location  = useLocation()
  const locationState = location.state;
  const moduleData = useSelector(state => state.moduleConfiguration.moduleConfigurationData);
  const [ stationPopupVisible, setStationPopupVisible ] = useState(false);
  const [ hiddenStation, setHiddenStation ] = useState(false);
  const [ hiddenCM, setHiddenCM ] = useState(false);
  const [ visibleSection, setVisibleSection ] = useState('stationDetails');
  const [ stationData, setStationData ] = useState();
  const [ toDeleteId, setToDeleteId ] = useState('');
  const [ cmDetails, setCmDetails ]= useState();
  const [ companyData, setCompanyData ] = useState([]);
  const [ moduleFormList, setModuleFormList ] = useState([]);
  const [ modules, setAllModule ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState(STATIONSHEDULE.open24Hrs);
  const [ schedularTimeDetailsList, setSchedularTimeDetailsList ] = useState([]);
  const [ hiddenSchedular, setHiddenSchedular ] = useState(false);
  const areas = useSelector(state => state.areaReducer.areasTableData);

  const stationRef = useRef(null)
  const cmRef = useRef(null)
  const mainRef = useRef(null)
  const supplierRef = useRef(null)
  const scheduleRef = useRef(null);
  const sectionRefs = [
    { section: ENWORDS.stationRefSection, ref: stationRef },
    { section: ENWORDS.scheduleRefSection, ref: scheduleRef },
    { section: ENWORDS.cmRefSection, ref: cmRef },
    { section: ENWORDS.supplierRefSection, ref:supplierRef }
  ];
    
  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;
    
    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };
    
  const scrollTo = (ele) => {
    window.scrollTo({
      top:ele.offsetTop - mainRef.current.offsetTop,
      left : 0,
      behavior : 'smooth',
    })
  };
      
  const toggleSiteClass = () => {
    scrollTo(stationRef.current)
  };
  const toggleCmClass = () => {
    scrollTo(cmRef.current)
  };
  const toggleSupplierClass = () =>{
    scrollTo(supplierRef.current)
  };
  const toggleSchedulerClass = () => {
    scrollTo(scheduleRef.current);
  };
    
  const getStationDetailsById = async (siteId) =>{
    const res = await getStationById(siteId)
    const stationRes = res.res;
    if (stationRes){
      return stationRes
    }
  }
  
  const editStation = async () =>{
    dispatch(setLoadingFlag(true))
    const stationDetailsData = await getStationDetailsById(stationData?.siteId);
    const companyValue = await getCompany();
  
    if (stationDetailsData  &&stationData){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITSTATION, { state:{ 
        data: stationDetailsData,
        companyValue:companyValue,
      } })
    }

  }

  const onStationDelete = (event) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    dispatch(deleteStation(toDeleteId))
    setStationPopupVisible(false)
    dispatch(getStationData())
    navigate(ROUTES.STATIONLISTING, { state: {
      data: locationState.data,
      moduleValue: locationState.moduleValue,
      stationValue: locationState.stationValue,
      roleAccess: locationState.roleAccess,
    } })
  }

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(stationRef.current);
      const scrollPosition = window.scrollY + headerHeight;
    
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };
    
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ visibleSection ]);
  
  useEffect(()=>{
    dispatch(getModuleDetails())
    dispatch(getAreas());
    if (locationState){
      setStationData(locationState.data);
      setCmDetails(locationState.data?.cmDetails)
      setCompanyData(locationState.data?.companyAssignment)
      setModuleFormList(locationState.data.moduleSupplierAssign)
      if(locationState.data.siteSchedules?.length > 0){
        setSchedularTimeDetailsList(locationState.data.siteSchedules)
      }
      setSelectedStatus(locationState.data.scheduleType === 1? SCHEDULED : OPEN_24_HRS)
    }
  },[])
  useEffect(() =>{
    setAllModule(moduleData)
  },[ moduleData ])

  return (
      <div>
          <div className={styles.mainContainer} ref = { mainRef }>
              <CustomPopup
                showPopUp={stationPopupVisible}
                onConfirm={onStationDelete}
                title={ENWORDS.removeStationTitle}
                onReject={()=>{setStationPopupVisible(false); setToDeleteId(0)}}
              >
                  <p>{ENWORDS.removeStationText}</p>
              </CustomPopup>
              <div className={conStyles.leftContainerStation}>
                  <div className={conStyles.innerSideContainer}>
                      <p
                        className={
                          visibleSection === ENWORDS.stationRefSection
                            ? conStyles.activeText
                            : conStyles.inactiveText
                        }
                        onClick={toggleSiteClass}
                      >
                          {ENWORDS.stationDetails}
                      </p>

                      <p
                        className={
                          visibleSection === ENWORDS.scheduleRefSection
                            ? conStyles.activeText
                            : conStyles.inactiveText
                        }
                        onClick={toggleSchedulerClass}
                      >
                          {ENWORDS.scheduler}
                      </p>
                      <p
                        className={
                          visibleSection === ENWORDS.supplierRefSection
                            ? conStyles.activeText
                            : conStyles.inactiveText
                        }
                        onClick={toggleSupplierClass}
                      >
                          {ENWORDS.assignSupplier}
                      </p>
                      <p
                        className={
                          visibleSection === ENWORDS.cmRefSection
                            ? conStyles.activeText
                            : conStyles.inactiveText
                        }
                        onClick={toggleCmClass}
                      >
                          {ENWORDS.cmDetails}
                      </p>
                  </div>
              </div>
              <div className={conStyles.rightContainer}>
                  <div className={styles.content}>
                      <div id={ENWORDS.stationRefSection} ref={stationRef}>
                          <div className={conStyles.moduleDetailsTopContainer}>
                              <ListingHeader
                                title={ENWORDS.stationDetails}
                                navigationPath={ROUTES.STATIONLISTING}
                                state={locationState}
                              />
                              {
                                locationState.roleAccess && locationState.roleAccess.writeAccess 

                                  ? 
                       
                                      <div className={conStyles.buttonContainer}>
                                          <button className={conStyles.btnCancel} onClick={()=>{setStationPopupVisible(true); setToDeleteId(stationData.siteId)}}>
                                              <img src={binIcon} alt = {ENWORDS.deleteIcon}/>
                                          </button>
                                          <button className={conStyles.btnDone} onClick={() => editStation()}>
                                              <img src={editPenIconLight} alt ={ENWORDS.editPenIcon}/>
                                          </button>
                                      </div>:null}
                          </div>
                          <div className={styles.siteNavContainer}>
                              <div className={styles.siteNavigation}>
                                  <p className={styles.robotoMediam}>{ENWORDS.stationDetails}</p>
                                  <button onClick={() => setHiddenStation(!hiddenStation)}>
                                      <img src={!hiddenStation ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>
                          </div>
                      
                          {!hiddenStation ? 
                              <div className={styles.siteForm}>
                                  {stationData ? <div className = {inputStyles.detailsRow}>
                                      
                                      <div className={styles.formFieldContainer}>
                                          <p>{ENWORDS.stationId}</p>
                                          <div className={styles.displayBox}>{stationData.siteId}</div>
                                      </div>

                                      <div className={styles.formFieldContainer} title = {stationData.siteName}>
                                          <p>{ENWORDS.stationName}</p>
                                          <div className={styles.displayBox}>{(stationData.siteName)?.slice(0, 15) + '...'}</div>
                                      </div>
                                      
                                      <div className={styles.formFieldContainer} title = {stationData.siteAddress}>
                                          <p>{ENWORDS.stationAddress}</p>
                                          <div className={styles.displayBox}>{(stationData.siteAddress)?.slice(0, 15) + '...'}</div>
                                      </div>
                                      
                                      <div className={styles.formFieldContainer}>
                                          <p>{ENWORDS.stationEmail}</p>
                                          <div className={styles.displayBox}>
                                              {stationData.siteEmailAddress}
                                          </div>
                                      </div>
                                      <div className={styles.formFieldContainer}>
                                          <p>{ENWORDS.netSuiteStationId}</p>
                                          <div className={styles.displayBox}>{stationData.netSuitId}</div>
                                      </div>
                                      <div className={styles.formFieldContainer}>
                                          <p>{ENWORDS.status}</p>
                                          <div className={styles.displayBox}>{stationData.siteStatus === 'ComingSoon' ? 'Coming Soon' : stationData.siteStatus}</div>
                                      </div>
                                      
                                      <div className={styles.formFieldContainer}>
                                          <p>{ENWORDS.area}</p>
                                          <div className={styles.displayBox}>{areas?.find(area=>{return area.id === stationData.areaId})?.areaName}</div>
                                      </div>
                                      <CompanyDropdown 
                                        title = {ENWORDS.companies} 
                                        name = {ENWORDS.companies}
                                        fieldValues = {companyData}
                                      />
                                      {stationData.siteStatus === 'Inactive' ? 
                                          <div style={{ display: 'flex' }}>
                                              <div className={styles.formFieldContainer}>
                                                  <p>{ENWORDS.InactiveFromDate}</p>
                                                  <div className={styles.displayBox}>{stationData.effectiveFromDate ? isoStringToDate(stationData.effectiveFromDate) : '-'}</div>
                                              </div>
                                              <div className={styles.formFieldContainer}>
                                                  <p>{ENWORDS.InactiveToDate}</p>
                                                  <div className={styles.displayBox}>{stationData.effectiveToDate ? isoStringToDate(stationData.effectiveToDate) : '-'}</div>
                                              </div>
                                          </div>
                                        : null} 
                                  </div> : null
                                  }
                              </div> 
                            : <div></div>
                          }
                      </div>
                      <hr />
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.scheduler}</p>
                              <button onClick={() => setHiddenSchedular(!hiddenSchedular)}>
                                  <img src={!hiddenSchedular ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                              </button>
                          </div>
                      </div>
                      { !hiddenSchedular &&

                      <div>
                          <div
                            className={styles.cmMainForm}
                            id={ENWORDS.scheduleRefSection}
                            ref={scheduleRef}
                          >
                              <div>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    className={styles.cmForm}
                                  >
                                      <div>
                                          <CustomDropDown
                                            name={ENWORDS.status.toLowerCase()}
                                            selectedValue={selectedStatus}
                                            fieldValues={STATIONSHEDULE}
                                            type={ENWORDS.custom}
                                            onClick={toggleSchedulerClass}
                                            onChange={e => setSelectedStatus(e.target.value)}
                                            isDisabled={true}
                                          />
                                      </div>
                                      {  selectedStatus === SCHEDULED  ?
                                          <div>
                                              <div className={inputStyles.detailsRow}>
                                                  { schedularTimeDetailsList.map((item ) =>{
                                                    return(
                                                        <SchedularTimeComponent 
                                                          key={item.id}
                                                          day = {DAYS[ item.day - 1 ]}
                                                          openingTimeTitle = {ENWORDS.openingTime} 
                                                          closingTimeTitle={ENWORDS.closingTime}
                                                          openingTime={moment(BASE_TIME).add(item.openingTime, 'minutes').toDate()}
                                                          closingTime={moment(BASE_TIME).add(item.closingTime, 'minutes').toDate()}
                                                          isDisabled={true} 
                                                        />
                                                    )
                                                  })
                                                  }
                                              </div>
                                          </div>
                                        : null }
                                  </div>
                              </div>
                          </div>

                          <hr />
                      </div>
                      }

                      <div className={styles.cmNavContainer}>
                          <div className={styles.cmNavigation}>
                              <p>{ENWORDS.assignSupplier}</p>
                          </div>
                      </div>
                      <div className={styles.cmMainForm}  id={ENWORDS.supplierRefSection} ref={ supplierRef }>
                          <div> 
                              {
                                moduleFormList && moduleFormList.map((formData, formId) =>{
                                  return(
                                
                                      <div className={styles.cmForm} key = {formData.id}>

                                          <div className={inputStyles.detailsRow}>
                                      
                                              <CustomDropDown 
                                                title = {ENWORDS.modulePlaceholder} 
                                                fieldValues = {(modules.filter((el) => {
                                                  return moduleFormList.every((f) => {
                                                    return f.moduleID != el.id || f.moduleID == formData.moduleID
                                                  });
                                                }))}
                                                // fieldValues = {modules }
                                                selectedValue = {formData.moduleID}
                                                name = {'moduleName'}
                                                renderKey = {'id'}
                                                renderKeyOne= {'moduleName'}
                                                isDisabled = {true}
                                                 
                                              />
                                              
                                              <div className={styles.formFieldContainer}>
                                                  <p>{ENWORDS.Supplier_Site_ID}</p>
                                                  <div className={styles.displayBox}>{formData.supplierSiteID}</div>
                                              </div>
                                              
                                          </div>

                                      </div>
                                  )
                                })
                              }
                                 
                          </div>
                      </div>
                      
                      <hr />
                      <div id={ENWORDS.cmRefSection} ref={cmRef}>
                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.activeCMDetails}</p>
                              </div>
                          </div>
                          <div className={styles.cmMainForm} >
                              <div> 
                                  {
                                    cmDetails && cmDetails.map((formData, formId) =>{
                                      return(
                                        formData.id === cmDetails[ 0 ].id ? 
                                            <div className={styles.cmForm} key = {formData.id}>

                                                <div className={inputStyles.detailsRow}>

                                                    <div className={styles.formFieldContainer}>
                                                        <p>{ENWORDS.cmName}</p>
                                                        <div className={styles.displayBox}>{formData.name}</div>
                                                    </div>
                                                    <div className={styles.formFieldContainer} title = {formData.address}>
                                                        <p>{ENWORDS.cmAddress}</p>
                                                        <div className={styles.displayBox}>{(formData.address)?.slice(0,15) + '...'}</div>
                                                    </div>
                                                     
                                                    <div className={styles.formFieldContainer}>
                                                        <p>{ENWORDS.cmEmail}</p>
                                                        <div className={styles.displayBox}>{formData.email}</div>
                                                    </div>
                                                    <div className={styles.formFieldContainer}>
                                                        <p>{ENWORDS.customerLedgerAccountNo}</p>
                                                        <div className={styles.displayBox}>{formData.ledgerAccount}</div>
                                                    </div>
                                                    <div className={styles.formFieldContainer}>
                                                        <p>{ENWORDS.termsDate}</p>
                                                        <div className={styles.displayBox}>{formData.termDays}</div>
                                                    </div>
                                                    <div className={styles.formFieldContainer}>
                                                        <p>{ENWORDS.tradingStartDate}</p>
                                                        <div className={styles.displayBox}>{isoStringToDate(formData.startDate)}</div>
                                                    </div>
                                                    <div className={styles.formFieldContainer}>
                                                        <p>{ENWORDS.tempTradingEndDate}</p>
                                                        <div className={styles.displayBox}>{formData.endDate ? isoStringToDate(formData.endDate) : '-'}</div>
                                                    </div>
                                                </div>

                                            </div>
                                          : null  )
                                    })
                                  }
                                 
                              </div>
                          </div>
                          <hr />
                      
                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.previousCM}</p>
                                  <button onClick={() => setHiddenCM(!hiddenCM)}>
                                      <img src={!hiddenCM ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>
                          </div>
                          <div className={styles.cmMainForm} >
                              {!hiddenCM ? 
                                  <div> 
                                      {
                                        cmDetails && cmDetails.map((formData, formId) =>{
                                          return(
                                            formData.id !== cmDetails[ 0 ].id ? 
                                                <div className={styles.cmForm} key = {formData.id}>

                                                    <div className={inputStyles.detailsRow}>

                                                        <div className={styles.formFieldContainer}>
                                                            <p>{ENWORDS.cmName}</p>
                                                            <div className={styles.displayBox}>{formData.name}</div>
                                                        </div>
                                                        <div className={styles.formFieldContainer} title = {formData.address}>
                                                            <p>{ENWORDS.cmAddress}</p>
                                                            <div className={styles.displayBox}>{(formData.address)?.slice(0,15) + '...'}</div>
                                                        </div>
                                                     
                                                        <div className={styles.formFieldContainer}>
                                                            <p>{ENWORDS.cmEmail}</p>
                                                            <div className={styles.displayBox}>{formData.email}</div>
                                                        </div>
                                                        <div className={styles.formFieldContainer}>
                                                            <p>{ENWORDS.customerLedgerAccountNo}</p>
                                                            <div className={styles.displayBox}>{formData.ledgerAccount}</div>
                                                        </div>
                                                        <div className={styles.formFieldContainer}>
                                                            <p>{ENWORDS.termsDate}</p>
                                                            <div className={styles.displayBox}>{formData.termDays}</div>
                                                        </div>
                                                        <div className={styles.formFieldContainer}>
                                                            <p>{ENWORDS.tradingStartDate}</p>
                                                            <div className={styles.displayBox}>{isoStringToDate(formData.startDate)}</div>
                                                        </div>
                                                        <div className={styles.formFieldContainer}>
                                                            <p>{ENWORDS.tradingEndDate}</p>
                                                            <div className={styles.displayBox}>{isoStringToDate(formData.endDate)}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                              : null   )
                                        })
                                      }
                                 
                                  </div>
                            
                                : <div></div>}
                          </div>
                      </div>
                      
                  </div>
              </div>
          </div>
      </div>
  )
}
    
export default StationDetailsScreen
