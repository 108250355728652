import React from 'react'
import styles from '../../styles/inputs.module.css'
import backArrow from '../../assets/icons/arrowBack.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../shared/constants/appRoute';
const ENWORDS = require('../../shared/local/en.json')

const ListingHeader = ({ title, navigationPath, state }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(navigationPath, { state: state })
  }
  return (
      <div style={navigationPath === ROUTES.ADTDAILYLOGS ? { marginLeft: '40px' }: null } className={styles.navigateContainer}>
          <button onClick={goBack}>
              <img src={backArrow} alt = {ENWORDS.backArrow}  className={styles.backArrow}/>
              <p>{title}</p>
          </button>
      </div>
  )
}

export default ListingHeader