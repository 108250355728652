import React, { useEffect, useState } from 'react'
import styles from '../../styles/popUp.module.css';
import cancelIcon from '../../assets/icons/cancelIcon.svg'

const ENWORDS = require('../../shared/local/en.json')

const CustomPopup = (
  { title,
    onConfirm =() =>{},
    onReject =() =>{},
    children ,
    showPopUp = false,
    cancelText = ENWORDS.no,
    confirmText = ENWORDS.yes,
    style ={},
    disableConfirm = false,
    isNotShowButton = false,
  }) => {
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    setShow(showPopUp);
  }, [ showPopUp ]);

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={styles.overlay}
      >
          <div style={style} className={styles.popup}>
              <div className={styles.topSection}>
                  <p> {title}</p>
                  <button className={styles.btnCancel} onClick={onReject}>
                      <img src={cancelIcon} alt={ENWORDS.cancelIcon} />
                  </button>
              </div>
              <div className={styles.middleSection}>
                  {children}
              </div>

              <div className={styles.btnSection}>
                  <button hidden = {isNotShowButton} className={styles.btnNo} onClick={(event)=>onReject(event)}>{cancelText}</button>
                  <button hidden = {isNotShowButton} className={disableConfirm ? styles.btnYesDisabled: confirmText === ENWORDS.upload ? [ styles.btnYes, styles.green ].join(' ') : styles.btnYes} disabled={disableConfirm} onClick={(event)=>onConfirm(event)} >{confirmText}</button>
              </div>
          </div>
      </div>
  )
}

export default CustomPopup