import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import configurationStyles from '../../../../styles/moduleConfiguration/addconfig.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import globalStyles from '../../../../styles/global.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import arrowUp from '../../../../assets/icons/arrowUp.svg';
import arrowDown from '../../../../assets/icons/arrowDownDark.svg';
import binIcon from '../../../../assets/icons/binIcon.svg';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from '../../../../shared/Components/CustomInput';
import { ROUTES } from '../../../../shared/constants/appRoute';
import ERRORWORDS from '../../../../shared/local/error.json';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../styles/invoiceListing/inputDateCalendar.css';
import {  displayError, setLoadingFlag } from '../../../../redux/actions';
import { checkManagerDateIntersection, commonScrollFunction, isoDateToMillisecond,  milliSecondToIsoString, sortByDate } from '../../../../shared/commonFunctions';
import { checkStationInvoiceExist, checkSupplierSiteExist, deleteCmById, editStationDetails } from '../../../../api/services/stationManagementService';
import {  globalDateFormat, NUMBER_REGEX, ALPHA_NUMBER_REGEX, GLOBAL_DATE_FORMAT_DB } from '../../../../shared/constants/appConstants';
import { ALPHA_NUM_REGEX , ALL_EMAIL_RG } from '../../../../shared/constants/appConstants';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import MultiSelectDropdown from '../../../../shared/Components/MultiSelectDropdown';
import { STATUSSTATION } from '../../../BaseConfiguration/baseConfigurationConstant';
import CustomDatePicker from '../../../../shared/Components/CustomDatePicker';
import { checkIfInvoiceExist } from '../../../../api/services/commonService';
import InvoiceVerificationPopUp from '../../../../shared/Components/InvoiceVerificationPopUp';
import { useDebounce } from 'use-debounce';
import { getModuleDetails } from '../../../../api/services/moduleConfigurationService';
import moment from 'moment';
import { format } from 'date-fns';
import SchedularTimeComponent from '../../../../shared/Components/SchedularTimeComponent';
import { AREANAME, BASE_TIME, CLOSING_TIME, DAYS, DEFAULT_CLOSING_TIME, DEFAULT_OPENING_TIME, OPEN_24_HRS, OPENING_TIME, SCHEDULED, STATIONSHEDULE } from '../../stationListingConstant';
import { getAreas } from '../../../../api/services/areaManagementService';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json');

const onTimeChangeCalculate = (timeValue) => {
  const openingTimeVal =  moment(timeValue).hours() * 60 + moment(timeValue).minutes()
  return openingTimeVal;
}

const EditStationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location  = useLocation();
  const locationState = location.state;
  const moduleData = useSelector(state => state.moduleConfiguration.moduleConfigurationData);
  const [ mainData, setMainData ] = useState({});
  const [ hiddenStation, setHiddenStation ] = useState(false);
  const [ hiddenCM, setHiddenCM ] = useState(false);
  const [ hiddenSupplier, setHiddenSupplier ] = useState(false);
  const [ hiddenSchedular, setHiddenSchedular ] = useState(false);
  const [ stationId, setStationId ] = useState('');
  const [ stationName, setStationName ] = useState('');
  const [ stationAddress, setStationAddress ] = useState('');
  const [ netSuiteStationId, setNetSuiteId ] = useState('');
  const [ stationEmail, setStationEmail ] = useState('');
  const [ formError, setFormError ] = useState({});
  const [ cmDetailsList, setCmDetailsList ] = useState([]);
  const [ cmDetailsError, setCmDetailsError ] = useState([]);
  const [ modules, setAllModule ] = useState();
  const [ visibleSection, setVisibleSection ] = useState('stationDetails');
  const [ companyArr, setCompanyArr ] = useState([]);
  const [ stationStatus,setStationStatus ] = useState('');
  const [ companyData, setCompanyData ] = useState([]);
  const [ displayData, setDisplayData ] = useState([]);
  const [ invoiceList, setInvoiceList ] = useState([]);
  const [ activeCMForm, setActiveCMForm ] = useState(0); 
  const [ popUpVisibility, setPopupVisibility ] = useState(false);
  const [ toDeleteCmId, setToDeleteCmId ] =  useState(0);
  const [ toDeleteCmName, setToDeleteCmName ] = useState('');
  const [ isDone, setIsDone ] = useState(false);
  const [ fromId, setFormId ] = useState('');
  const [ moduleFormList , setModuleFormList ] = useState([]);
  const [ moduleError , setModuleError ] = useState([]);
  const [ formValue ] = useDebounce(cmDetailsList, 500);
  const [ moduleValueUpdate ] = useDebounce(moduleFormList, 1000);
  const [ siteIdValue ] = useDebounce(stationId, 1000);
  const [ selectedStatus, setSelectedStatus ] = useState(OPEN_24_HRS);
  const [ selectedAreaId, setSelectedAreaId ] = useState();
  const areas = useSelector(state => state.areaReducer.areasTableData);
  const [ newCMIndex, setNewCMIndex ] = useState(0);
  const [ effectiveFromDate, setEffectiveFromDate ] = useState()
  const [ effectiveToDate, setEffectiveToDate ] = useState()
  const [ schedularTimeDetailsList, setSchedularTimeDetailsList ] = useState(    Array(7)
    .fill({
      openingTime: onTimeChangeCalculate(DEFAULT_OPENING_TIME),
      closingTime: onTimeChangeCalculate(DEFAULT_CLOSING_TIME),
    })
    .map((day, index) => {
      return { ...day, day: index + 1 };
    }));

  useEffect(() =>{
    setAllModule(moduleData);
    if(moduleData && locationState ){
      setModuleFormList( locationState.data.moduleSupplierAssign.map((item, i) => {
        return { ...item, id : i }
      }))
      setModuleError(locationState.data.moduleSupplierAssign.map((item, i) => {
        return {
          errorId: i,
          supplierError: '',
        }
      }))
    }
  },[ moduleData ])

  useEffect(()=> {
    if (stationStatus !== 'Inactive') {
      setEffectiveFromDate()
      setEffectiveToDate()
    }
  }, [ stationStatus ])

  const stationRef = useRef(null)
  const cmRef = useRef(null)
  const mainRef = useRef(null)
  const supplierRef = useRef(null);
  const scheduleRef = useRef(null);
  const sectionRefs = [
    { section: ENWORDS.stationRefSection, ref: stationRef },
    { section: ENWORDS.scheduleRefSection, ref: scheduleRef },
    { section: ENWORDS.cmRefSection, ref: cmRef },
    { section: ENWORDS.supplierRefSection, ref: supplierRef }
  ];

  useEffect(() => {
    const cmListCopy = cmDetailsList.map(cm=>{
      if(cm.id !== activeCMForm){
        return { ...cm, endDate : cm.endDate ? cm.endDate : new Date() }
      }
      return cm
    })
    setCmDetailsList(cmListCopy)

  }, [ activeCMForm ])
  
  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const scrollTo = (ele) => {
    window.scrollTo({
      top:ele.offsetTop - mainRef.current.offsetTop,
      left : 0,
      behavior : 'smooth',
    })
  };

  const addCMBlock = () => {
    let cmDetailsCopy = [ ...cmDetailsList ];
    var lenOfCmDetails = cmDetailsList.length
    let initialStartDate ;
    let initialEndDate;
    let afterInitialStart;
    let afterInitialEnd;
    let struct;
    const cmId = (newCMIndex + 1)*(-1)
    if(lenOfCmDetails === 0){
      initialStartDate = Date.now()
      const momentEndDate = moment(initialStartDate);
      initialEndDate = (momentEndDate).valueOf();
      struct = {
        id: cmId,
        siteId: '',
        name: '',
        address: '',
        email: '',
        ledgerAccount: '',
        termDays:'',
        startDate: initialStartDate,
        endDate: initialEndDate ,
      }
    }else{
      cmDetailsCopy.forEach((value) =>{
        if(value.id === cmDetailsCopy[ 0 ].id ){
          const endDate = value.endDate === null ? new Date() : value.endDate;
          afterInitialStart = moment(endDate).add(1,'d').valueOf() ;
          afterInitialEnd = moment(afterInitialStart).valueOf()
        }
      })
      struct = {
        id:cmId,
        siteId:'',
        name:'',
        address:'',
        ledgerAccount:'',
        termDays:'',
        startDate:afterInitialStart,
        endDate:afterInitialEnd,
      }
    }
    cmDetailsCopy = [ ...cmDetailsCopy, struct ];
    setActiveCMForm(struct.id);
    setCmDetailsList(sortByDate(cmDetailsCopy,'startDate'));
    setNewCMIndex(prevId=>prevId+1)
  }
  const addModuleBlock = () =>{
    let moduleFormCopy = moduleFormList;
    var lenOfFormList = moduleFormCopy.length
    let moduleErrorCopy = moduleError;
    let moduleFormId = 0;
    if (moduleFormCopy.length !== 0) {
      const nThLst = moduleFormCopy.at(lenOfFormList - 1)
      moduleFormId = nThLst.id + 1
    }
    
    // const defaultModuleID = Object.keys
    const defaultModuleID = (modules.filter((el) => {
      return moduleFormCopy.every((f) => {
        return f.moduleID != el.id ;
      });
    }))[ 0 ].id;
    
    let struct;
    if(defaultModuleID){

      struct = {
        id: moduleFormId,
        moduleID: parseInt(defaultModuleID),
        supplierSiteID: '',
      }
    }
    moduleFormCopy = [ ...moduleFormCopy, struct ]
    setModuleFormList(moduleFormCopy)

    const errorStruct = {
      errorId: moduleFormId,
      supplierError:'',
    }
    moduleErrorCopy = [ ...moduleErrorCopy, errorStruct ];
    setModuleError(moduleErrorCopy);
  }

  const setStationData = () => {
    
    if(mainData){

      setStationId(mainData.siteId)
      setStationName(mainData.siteName)
      setStationAddress(mainData.siteAddress)
      setNetSuiteId(mainData.netSuitId)
      setStationEmail(mainData.siteEmailAddress)
      mainData.cmDetails?.forEach((item)=>{
        item.startDate = item.startDate ? isoDateToMillisecond(item.startDate) : null
        item.endDate = item.endDate ? isoDateToMillisecond(item.endDate) : null
      })
      setCmDetailsList(sortByDate(mainData?.cmDetails, 'startDate'))
      setStationStatus(mainData.siteStatus);
      setSelectedAreaId(mainData.areaId)
      setCompanyData(mainData.companyAssignment)
      setEffectiveFromDate(mainData.effectiveFromDate ? isoDateToMillisecond(mainData.effectiveFromDate) : null)
      setEffectiveToDate(mainData.effectiveToDate ? isoDateToMillisecond(mainData.effectiveToDate) : null)
      if(mainData.siteSchedules?.length > 0){
        setSchedularTimeDetailsList(mainData.siteSchedules)
      }
      setSelectedStatus(mainData.scheduleType === 1? SCHEDULED : OPEN_24_HRS)
    }
  }
  const showPopUp = ( fieldId, cmName) =>{
    setPopupVisibility(true);
    setToDeleteCmName(cmName);
    setToDeleteCmId(fieldId)
  }

  const popupCloseHandler = (e) => {
    setPopupVisibility(e);
  };

  const validated = async () => {
    dispatch(setLoadingFlag(true))

    const res = await editStationDetails({
      id: mainData.id,
      siteId: stationId,
      siteName: stationName,
      netSuitId: netSuiteStationId,
      siteEmailAddress: stationEmail,
      siteAddress: stationAddress,
      cmDetails: cmDetailsList.map((item, index) => {
        const toDate = item.startDate ? milliSecondToIsoString(item.startDate) : null
        const fromDate = item.endDate ? milliSecondToIsoString(item.endDate) : null
        const isActive = index === 0 ? 1: 0;
        return { ...item, startDate: toDate, endDate: fromDate, siteId: stationId , isActiveCM: isActive }
      }),
      companyAssignment:companyData,
      siteStatus : stationStatus,
      moduleSupplierAssign:moduleFormList,
      scheduleType: selectedStatus === OPEN_24_HRS? 0 : 1,
      siteSchedules : selectedStatus === OPEN_24_HRS? [] : schedularTimeDetailsList,
      areaId : selectedAreaId,
      effectiveFromDate: effectiveFromDate ? format(effectiveFromDate,GLOBAL_DATE_FORMAT_DB) : null,
      effectiveToDate: effectiveToDate ? format(effectiveToDate,GLOBAL_DATE_FORMAT_DB) : null,
      prevStatus: mainData.siteStatus,
    })
    if (res.res) {  
      dispatch(setLoadingFlag(false)) 
      navigate(ROUTES.STATIONLISTING, { state: {
        data: locationState.data,
        companyValue: locationState.companyValue,
      } })
    }
  }
  const checkValidation = () => {
    let formErrorVariable = formError;
    const cmDetailsErrorCopy = cmDetailsError;
    const moduleErrorCopy = moduleError;
    if (stationId?.trim() === '' || ALPHA_NUM_REGEX.test(stationId) === false) {
      formErrorVariable = { ...formErrorVariable, stationIdError: ERRORWORDS.stationIdError }
    }
    if (stationName?.trim() === '' || ALPHA_NUM_REGEX.test(stationName) === false) {
      formErrorVariable = { ...formErrorVariable, stationNameError: ERRORWORDS.stationNameError }
    }
    if (stationAddress?.trim() === '' || ALPHA_NUM_REGEX.test(stationAddress) === false) {
      formErrorVariable = { ...formErrorVariable, stationAddressError: ERRORWORDS.stationAddressError }
    }
    if (netSuiteStationId?.trim() === '') {
      formErrorVariable = { ...formErrorVariable, netSuiteStationIdError: ERRORWORDS.netSuiteStationIdError }
    }

    if(cmDetailsList.length === 0){
      formErrorVariable = { ...formErrorVariable, cmDetailsListError : ERRORWORDS.cmDetailsListError } 
    }
    if (ALL_EMAIL_RG.test((stationEmail)?.trim()) === false) {
      formErrorVariable = { ...formErrorVariable, stationEmailError: ERRORWORDS.stationInvalidEmail }
    }
    if (companyData && companyData.length === 0) {
      formErrorVariable = { ...formErrorVariable, companyError: ERRORWORDS.companyError }
    }
    if (areas && areas.length === 0) {
      formErrorVariable = { ...formErrorVariable, areaError: ERRORWORDS.areaError }
    }
    if (stationStatus === 'Inactive') {
      if (!effectiveFromDate) {
        formErrorVariable = { ...formErrorVariable, effectiveFromDateError: ERRORWORDS.effectiveFromDateError }
      }
      if (!effectiveToDate || effectiveToDate <= effectiveFromDate) {
        formErrorVariable = { ...formErrorVariable, effectiveToDateError: ERRORWORDS.effectiveToDateError }
      }
    }

    moduleFormList.forEach((moduleRow, id) => {
      const currentIndex = moduleError.findIndex((e)=> e.errorId === moduleRow.id);
      if (currentIndex > -1){
        if ( !ALPHA_NUMBER_REGEX.test(moduleRow.supplierSiteID) || moduleRow.supplierSiteID === ''){
          moduleErrorCopy[ currentIndex ].supplierError = ERRORWORDS.invalidSupplierStation;
        }
        if ( ALPHA_NUMBER_REGEX.test(moduleRow.supplierSiteID) && moduleRow.supplierSiteID !== ''){
          moduleErrorCopy[ currentIndex ].supplierError = '';
        }

      }      
    })
   
    cmDetailsList.forEach((cmErrorRow) => {
      if (!cmDetailsErrorCopy[ cmErrorRow.id ]) {
        cmDetailsErrorCopy[ cmErrorRow.id ]={};
      }
      if (cmErrorRow.name?.trimStart() === '' || !ALPHA_NUM_REGEX.test(cmErrorRow.name)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMNameError = ERRORWORDS.cmNameError;
      }
      if (cmErrorRow.name?.trimStart() !== '' && ALPHA_NUM_REGEX.test(cmErrorRow.name)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMNameError = '';
      }
      if (cmErrorRow.address?.trimStart() === '' || !ALPHA_NUM_REGEX.test(cmErrorRow.address)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMAddressError = ERRORWORDS.cmAddressError;
      }
      if (cmErrorRow.address?.trimStart() !== '' && ALPHA_NUM_REGEX.test(cmErrorRow.address)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMAddressError = '';
      }
      if (cmErrorRow.termDays === '' || isNaN(cmErrorRow.termDays) || !NUMBER_REGEX.test(cmErrorRow.termDays) ) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formTermDaysError = ERRORWORDS.termDateError;
      }
      if (cmErrorRow.termDays !== '' && !isNaN(cmErrorRow.termDays) && NUMBER_REGEX.test(cmErrorRow.termDays) ) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formTermDaysError = '';
      }
      if (!ALL_EMAIL_RG.test((cmErrorRow.email)?.trim())) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMEmailError = ERRORWORDS.cmEmailError;
      }
      if (cmErrorRow.ledgerAccount === '' || !ALPHA_NUM_REGEX.test(cmErrorRow.ledgerAccount)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formLedgerAccountError = ERRORWORDS.ledgerAccountError;
      }
      
    })
    
    setModuleError( moduleErrorCopy )
    setFormError(formErrorVariable);
    setCmDetailsError({ ...cmDetailsErrorCopy })
    const isFormErroEmpty = Object.values(formErrorVariable).every((value) => {
      if (value === null || value === '') {
        return true
      } else {
        return false
      }
    });
    const isCMErrorEmpty = Object.keys(cmDetailsErrorCopy).filter((e) => {
      return cmDetailsErrorCopy[ e ]?.formCMNameError || cmDetailsErrorCopy[ e ]?.formCMEmailError || cmDetailsErrorCopy[ e ]?.formEndDateError || cmDetailsErrorCopy[ e ]?.formCMAddressError || cmDetailsErrorCopy[ e ]?.formLedgerAccountError || cmDetailsErrorCopy[ e ]?.formStartDateError|| cmDetailsErrorCopy[ e ]?.formTermDaysError
    });

    const isModuleErrorEmpty = moduleErrorCopy.filter((e) => {
      return   e.supplierError !== ''
    })
    
    if (isFormErroEmpty && isCMErrorEmpty.length === 0  
    && isModuleErrorEmpty.length === 0
    ) {
      validated()
    }
    setIsDone(!isDone);
  }

  const deleteModuleForm = (id) =>{
    const moduleFormCopy = moduleFormList;
    const moduleErrorCopy = moduleError;
    moduleFormCopy.forEach((item, idForm ) =>{
      if(item.id === id){
        moduleFormCopy.splice(idForm,1)
        moduleErrorCopy.splice(idForm,1);
        setModuleError([ ...moduleErrorCopy ])
        setModuleFormList([ ...moduleFormCopy ]);
      }
    })
  }

  const deleteCmDetailsById = async (formIndex) => {
    const cmDetailsListCopy = cmDetailsList;
    const isCmDeleted = await deleteCmById(formIndex, mainData?.id);
    if (isCmDeleted){
      dispatch(setLoadingFlag(false))
      cmDetailsListCopy.forEach((item, idForm) => {
        if (item.id === formIndex) {
          cmDetailsListCopy.splice(idForm, 1)
          if(cmDetailsError[ item.id ])
          {
            cmDetailsError[ item.id ] = undefined;
          }
          const sortedCM = sortByDate(cmDetailsListCopy,'startDate')
          if (sortedCM[ 0 ]?.id){
            setCmDetailsList([ ...sortedCM ]);
            setActiveCMForm(sortedCM[ 0 ].id);
          }
        }
      })
      popupCloseHandler();
    }
  }

  const onCmDetailsUpdate = (value, formID, nameField, objKey ) => {
    const cmTempList = cmDetailsList.map((i, index)=>{
      if(i.id === formID){
        if(objKey === 'endDate'){
          setFormId(formID)
          return { ...i, [ nameField ] : value.getTime() }
        }
        else if(objKey === 'startDate'){
          setFormId(formID)
          return index === 0? { ...i, [ nameField ] : value.getTime(), [ 'endDate' ] : new Date(moment(value.getTime()).add(1, 'day')) }: { ...i, [ nameField ] : value.getTime() }
        }
        else{

          return { ...i,  [ nameField ]  : value }
        }
      }
      return i
    })
    /**date  interseaction not working  need to modify */
    setCmDetailsList(cmTempList)
  }

  useEffect(() =>{
    if(fromId !== ''){
      const { formErrorVariable, isError } = checkManagerDateIntersection(fromId, cmDetailsList, cmDetailsError)
      setCmDetailsError(formErrorVariable)
      if(!isError){
        setActiveCMForm(sortByDate(cmDetailsList,'startDate')[ 0 ]?.id)
        setCmDetailsList(sortByDate(cmDetailsList,'startDate'));
      }
    }
  },[ formValue ])

  const onCancelClicked = () => {
    navigate(ROUTES.STATIONLISTING, { state: {
      data: locationState.data,
      companyValue: locationState.companyValue,
    } })
  }
  const toggleSiteClass = () => {
    scrollTo(stationRef.current)
  };
  const toggleSchedulerClass = () => {
    scrollTo(scheduleRef.current)
  }
  const toggleSupplierClass = () => {
    scrollTo(supplierRef.current)
  };
  const toggleCmClass = () => {
    scrollTo(cmRef.current)
  };

  const onDoneClicked = () => {
    checkValidation();
  }

  const updateModuleSupplier = ( value, formId , nameField, objKey ) =>{
    const moduleErrorCopy = moduleError;
    const tempModuleFormList = moduleFormList.map((i, index) =>{
      if(i.id === formId){
        if(value){
          moduleErrorCopy[ index ][ 'supplierError' ] = ''
          return { ...i,  [ nameField ]  : value }
        }
      }
      return i
    })
    setModuleFormList( tempModuleFormList )

  }

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(stationRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ visibleSection ]);

  useEffect(() => {
    let errorVariable = formError;

    if (stationId?.trim() !== '' && ALPHA_NUM_REGEX.test(stationId) !== false) {
      errorVariable = { ...errorVariable, stationIdError: '' }
    }
    if (stationName?.trim() !== '' && ALPHA_NUM_REGEX.test(stationName) !== false) {
      errorVariable = { ...errorVariable, stationNameError: '' }
    }
    if (stationAddress?.trim() !== '' && ALPHA_NUM_REGEX.test(stationAddress) !== false) {
      errorVariable = { ...errorVariable, stationAddressError: '' }
    }
    if (netSuiteStationId?.trim() !== '') {
      errorVariable = { ...errorVariable, netSuiteStationIdError: '' }
    }
    if (ALL_EMAIL_RG.test((stationEmail).trim())) {
      errorVariable = { ...errorVariable, stationEmailError: '' }
    }
    if (companyData?.length > 0){
      errorVariable = { ...errorVariable, companyError: '' }
    }
    if (effectiveFromDate){
      errorVariable = { ...errorVariable, effectiveFromDateError: '' }
    }
    if (effectiveToDate && effectiveToDate > effectiveFromDate){
      errorVariable = { ...errorVariable, effectiveToDateError: '' }
    }

    if(cmDetailsList.length !== 0){
      errorVariable = { ...errorVariable, cmDetailsListError : '' } 
    }
    setFormError(errorVariable)
  }, [ stationId, stationName, stationAddress, netSuiteStationId, stationEmail, cmDetailsList , companyData, effectiveFromDate, effectiveToDate ]) 

  useEffect(() => {
    const formErrorVariable = { ...cmDetailsError };
    cmDetailsList.forEach((formRow) => {
      if (formErrorVariable[ formRow.id ]  !== undefined) {
        if (formRow.name?.trim() !== '') {
          formErrorVariable[ formRow.id ].formCMNameError = '';
        }
        if (formRow.address?.trim() !== '') {
          formErrorVariable[ formRow.id ].formCMAddressError = '';
        }
        if(formRow.termDays !== '' && !isNaN(formRow.termDays) && NUMBER_REGEX.test(formRow.termDays)){
          formErrorVariable[ formRow.id ].formTermDaysError = ''
        }
        if (formRow.ledgerAccount !== '' && ALPHA_NUM_REGEX.test(formRow.ledgerAccount)) {
          formErrorVariable[ formRow.id ].formLedgerAccountError = '';
        }
        if (ALL_EMAIL_RG.test((formRow.email)?.trim())) {
          formErrorVariable[ formRow.id ].formCMEmailError = '';
        }
      }
    })
    setCmDetailsError(formErrorVariable)
  }, [ cmDetailsList ])

  const updateCompanyData = (e) =>{
    e.forEach((item) =>{
      delete item[ 'companyName' ]
      delete item[ 'siteID' ]
    })
    setCompanyData(e)
  }
  useEffect(()=>{
    dispatch(getModuleDetails())
    dispatch(getAreas());

    if (locationState){
      setMainData(locationState?.data);
      setDisplayData(locationState?.data.companyAssignment)
      setCompanyArr(locationState?.companyValue.res)
    }
  },[])

  const checkSupplierSiteAvail = async (moduleID, supplierSiteID) =>{
    const checkRes = await checkSupplierSiteExist(moduleID, supplierSiteID, mainData.id)
    return checkRes;
    // return await checkSupplierSiteExist(moduleID, supplierSiteID)
    
  }
  useEffect(() =>{
    async function setError(){
      const copyModuleError = moduleError;
      moduleFormList.forEach((i, index) =>{
        if(i.moduleID > 0 && i.supplierSiteID > 0){
          moduleError.forEach(async(item,ind) =>{
            if(i.id === item.errorId ){
              const res = await checkSupplierSiteAvail(i.moduleID, i.supplierSiteID)
              if(res){
                copyModuleError[ ind ].supplierError = ERRORWORDS.duplicateSiteRef
              }
              else{
                copyModuleError[ ind ].supplierError = ''
              }
            }
          })
          setModuleError(copyModuleError);
          
        }
      })
    }
    setError();
    
  },[ moduleValueUpdate ])

  const onTimeChange = (e, item, type) => {
    const time = onTimeChangeCalculate(e);
    const schedulerListCopy = schedularTimeDetailsList.map(schedule=>{
      if(schedule.day=== item.day){
        return { ...schedule, [ type ]: time }
      }
      return schedule
    })
    setSchedularTimeDetailsList(schedulerListCopy);
  }

  useEffect(() =>{
    if (mainData.cmDetails){
      if((mainData.cmDetails).length !== 0){

        setActiveCMForm(mainData.cmDetails[ 0 ].id);
      }
    }
    if( Object.keys(mainData).length !== 0){
      setStationData();
    }    
  },[ mainData ])
  
  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])

  const invoiceVerficationCall = async (formId, formName) =>{
    dispatch(setLoadingFlag(true))
    const verificationCall = await checkIfInvoiceExist(formId, 'cmId');

    if (verificationCall.statusCode === 200){
      dispatch(setLoadingFlag(false))
      setInvoiceList(verificationCall.res);
      showPopUp(formId, formName);
    }
  }

  const validateStationId = async () =>{
    let errorVariable = formError
    const stationIdValidation = await checkStationInvoiceExist(mainData.id, stationId);
    if(stationIdValidation.siteCount > 0){
      errorVariable = { ...errorVariable, stationIdError
      : ERRORWORDS.stationAlreadyExist }
    }
    else if(stationIdValidation.invoiceCount > 0 && mainData.siteId != stationId ){
      errorVariable = { ...errorVariable, stationIdError
      : ERRORWORDS.invoiceExistForStation + mainData.siteId }
    }
    else{
      errorVariable = { ...errorVariable, stationIdError
      : '' }

    }
    setFormError(errorVariable)
  }

  useEffect(() =>{
    if(siteIdValue !== ''){
      validateStationId();
    }
  },[ siteIdValue ])

  const onStationStatusChanged = (e) => {
    setStationStatus(e.target.value)
    if (e.target.value === 'Inactive') {
      setEffectiveFromDate(new Date())
      const tempCM = cmDetailsList.map((item, index) => {
        const endDate = index === 0 ? new Date() : item.endDate
        return { ...item, endDate: endDate }
      })
      setCmDetailsList(tempCM)
    } else {
      setEffectiveFromDate()
      setEffectiveToDate()
    }
  }
  return (
      <div>
          <InvoiceVerificationPopUp
            onClose={popupCloseHandler}
            show={popUpVisibility}
            title={ENWORDS.deleteCM}
            text={invoiceList.length > 0 ? 'The CM'+ENWORDS.deleteCMText : ENWORDS.cmCheckboxMsg }
            checkboxMsg = {ENWORDS.cmCheckboxMsg}
            selectedName = { toDeleteCmName }
            selectedId = { toDeleteCmId }
            screenName = {ENWORDS.editStation}
            invoiceList = {invoiceList??[]}
            onDelete = { deleteCmDetailsById }
          />
          <div className={styles.mainContainer} ref = { mainRef }>
              <div className={configurationStyles.leftContainerStation}>
                  <div className={configurationStyles.innerSideContainer}>
                      <p className={
                        visibleSection === ENWORDS.stationRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} 
                      onClick={toggleSiteClass} >{ENWORDS.stationDetails}</p>
                      <p className={
                        visibleSection === ENWORDS.scheduleRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} 
                      onClick={toggleSchedulerClass}>{ENWORDS.scheduler}</p>
                      <p className={
                        visibleSection === ENWORDS.supplierRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} 
                      onClick={toggleSupplierClass}>{ENWORDS.assignSupplier}</p>
                      <p className={
                        visibleSection === ENWORDS.cmRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} 
                      onClick={toggleCmClass}>{ENWORDS.cmDetails}</p>
                  </div>
              </div>
              <div className={configurationStyles.rightContainer}>
                  <div className={styles.content}>
                      <ListingHeader
                        title = {ENWORDS.editStation}
                        navigationPath = {ROUTES.STATIONLISTING} 
                        state={locationState}/>
                      <div id={ENWORDS.stationRefSection} ref={stationRef}>
                          <div className={styles.siteNavContainer}>
                              <div className={styles.siteNavigation}>
                                  <p className={styles.robotoMediam}>{ENWORDS.stationDetails}</p>
                                  <button onClick={() => setHiddenStation(!hiddenStation)}>
                                      <img src={!hiddenStation ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>
                              
                          </div>
                          {!hiddenStation ?
                          
                              <div className={styles.siteForm} >
                                  <div className={inputStyles.detailsRow}>
                                      <CustomInput
                                        title={ENWORDS.stationId}
                                        placeholder = {PLACEHOLDER.stationId}
                                        value={stationId}
                                        error={formError.stationIdError}
                                        onChange={e => setStationId(e)} />
                                    
                                      <CustomInput
                                        title={ENWORDS.stationName}
                                        value={stationName}
                                        placeholder = {PLACEHOLDER.stationName}
                                        error={formError.stationNameError}
                                        onChange={e => setStationName(e)} /> 
                                 
                                      <CustomInput
                                        title={ENWORDS.stationAddress}
                                        value={stationAddress}
                                        placeholder = {PLACEHOLDER.address}
                                        error={formError.stationAddressError}
                                        onChange={e => setStationAddress(e)} />

                                      <CustomInput
                                        title={ENWORDS.netSuiteStationId}
                                        value={netSuiteStationId}
                                        placeholder = {PLACEHOLDER.netSuiteID}
                                        error={formError.netSuiteStationIdError}
                                        maintainDefaultCase = {true}
                                        onChange={e => setNetSuiteId((e).trim())} />

                                      <CustomInput
                                        title={ENWORDS.stationEmail}
                                        value={stationEmail}
                                        placeholder = {PLACEHOLDER.commonEmail}
                                        error={formError.stationEmailError}
                                        maintainDefaultCase = {true}
                                        onChange={e => setStationEmail((e).trim())} />
                                        
                                      <CustomDropDown 
                                        title = {ENWORDS.siteStatus} 
                                        selectedValue = {stationStatus}
                                        fieldValues = {STATUSSTATION}
                                        onChange = {e => onStationStatusChanged(e)}
                                      />
                                      <CustomDropDown
                                        title={ENWORDS.area}
                                        name={ENWORDS.area}
                                        fieldValues={areas}
                                        renderKey = {'id'}
                                        renderKeyOne = {AREANAME}
                                        error = {formError.areaError}
                                        isDisabled = {false}
                                        selectedValue={selectedAreaId}
                                        onChange={(e)=> setSelectedAreaId(Number(e.target.value))
                                        }
                                      /> 
                                      <MultiSelectDropdown 
                                        title={ENWORDS.companies}
                                        fieldValues = { companyArr }
                                        value = {displayData}
                                        error = {formError.companyError}
                                        onChange = {e => updateCompanyData(e)}
                                      /> 
                                      { stationStatus === 'Inactive' ? <CustomDatePicker
                                        title = {ENWORDS.InactiveFromDate}
                                        startDate = {effectiveFromDate}
                                        endDate = {effectiveToDate}
                                        dateFormat = {globalDateFormat}
                                        disabled = {true}
                                        onChange={(e) => setEffectiveFromDate(e)}
                                        wrapperClass = 'stationDate'
                                        errorMessage = { formError.effectiveFromDateError }
                                      /> : null}
                                      { stationStatus === 'Inactive' ? <CustomDatePicker
                                        title = {ENWORDS.InactiveToDate}
                                        startDate = {effectiveToDate}
                                        minDate = {effectiveFromDate}
                                        dateFormat = {globalDateFormat}
                                        onChange={(e) => setEffectiveToDate(e)}
                                        wrapperClass = 'stationDate'
                                        errorMessage = { formError.effectiveToDateError }
                                      /> : null} 
                                  </div>
                              </div> : null}
                      </div>
                      <hr />

                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.scheduler}</p>
                              <button onClick={() => setHiddenSchedular(!hiddenSchedular)}>
                                  <img src={!hiddenSchedular ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                              </button>
                          </div>
                      </div>
                      { !hiddenSchedular &&
                      <div>
                          <div className={styles.cmMainForm} id = {ENWORDS.scheduleRefSection} ref={ scheduleRef }>
                              <div>                                
                                  <div style={{ display:'flex', flexDirection:'column' }} className={styles.cmForm}>
                                      <div>
                                          <CustomDropDown
                                            selectedValue={selectedStatus}
                                            name = {ENWORDS.status.toLowerCase()}
                                            fieldValues={STATIONSHEDULE}
                                            type = {ENWORDS.custom}
                                            onClick={toggleSchedulerClass}               
                                            onChange={e=> setSelectedStatus(e.target.value)}
                                          />
                                      </div>
                                      { selectedStatus === SCHEDULED ?
                                          <div>
                                              <div className={inputStyles.detailsRow}>
                                                  { schedularTimeDetailsList.map((item ) =>{
                                                    return(
                                                        <SchedularTimeComponent 
                                                          key={item.id}
                                                          day = {DAYS[ item.day - 1 ]}
                                                          onStartTimeChange={e => onTimeChange(e, item,OPENING_TIME )}
                                                          onEndTimeChange={e => onTimeChange(e, item,CLOSING_TIME)}
                                                          openingTimeTitle = {ENWORDS.openingTime} 
                                                          closingTimeTitle={ENWORDS.closingTime}
                                                          openingTime={moment(BASE_TIME).add(item.openingTime, 'minutes').toDate()}
                                                          closingTime={moment(BASE_TIME).add(item.closingTime, 'minutes').toDate()}
                                                          isDisabled={false} 
                                                        />
                                                    )
                                                  })
                                                  }
                                              </div>
                                          </div>
                                        : null }
                                  </div>
                              </div>
                          </div>
                      
                          <hr />
                      </div>
                      }

                      <div id = {ENWORDS.supplierRefSection} ref={supplierRef}>
                          
                          <div className={styles.siteNavContainer}>
                              <div className={styles.siteNavigation}>
                                  <p className={styles.robotoMediam}>{ENWORDS.assignSupplier}</p>
                                  <button onClick={() => setHiddenSupplier(!hiddenSupplier)}>
                                      <img src={!hiddenSupplier ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>

                          </div>
                          <div className={styles.cmBtnContainer}>
                              <div className={styles.addBtn}>
                                  <button onClick={addModuleBlock}><img src={plusIcon} alt={ENWORDS.addBtn} /></button>
                              </div>
                          </div> 

                          {!hiddenSupplier ?

                            moduleFormList.map((item ) =>{
                          
                              return(
                                  <div className={styles.cmForm} >
                                      <div className={styles.assignModuleContainer}>
                                          <div className={styles.assignModule} >
                                              <div className={styles.moduleDropdownContainer} >
                                                  <CustomDropDown 
                                                    title = {ENWORDS.modulePlaceholder} 
                                                    fieldValues = {(modules.filter((el) => {
                                                      return moduleFormList.every((f) => {
                                                        return f.moduleID != el.id || f.moduleID == item.moduleID
                                                      });
                                                    }))}
                                                    // fieldValues = {modules }
                                                    selectedValue = {item.moduleID}
                                                    onChange = {e => updateModuleSupplier(e.target.value, item.id, 'moduleID')}
                                                    name = {'moduleName'}
                                                    renderKey = {'id'}
                                                    renderKeyOne= {'moduleName'}
                                                 
                                                  />
                                              </div>
                                              <div className={styles.supplierDropDownContainer}>
                                                  <CustomInput
                                                    title={ENWORDS.Supplier_Site_ID}
                                                    value={item.supplierSiteID}
                                                    placeholder = {PLACEHOLDER.netSuiteID}
                                                    error = { displayError(moduleError, item.id, 'supplierError')}
                                                    onChange={e => updateModuleSupplier(e, item.id, 'supplierSiteID')} />
                                              </div>
                                          </div>
                                          <div className={styles.deleteBtnModule}>
                                              <div className={styles.deleteModuleAssign}>
                                                  <button className={styles.deleteBtnCM} onClick = {() => deleteModuleForm(item.id)} >
                                                      <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              )
                            })
                            : <div></div>} 
                      </div>
                      <hr />
                      <div id = {ENWORDS.cmRefSection} ref={cmRef}>
                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.activeCMDetails}</p>
                              </div>

                          </div>
                          <div className={styles.cmMainForm} >
                              {<p className={formError.cmDetailsListError ? globalStyles.errorMessageShow: globalStyles.errorMessage}>{formError.cmDetailsListError}</p> }
                              <div className={styles.cmBtnContainer}>
                                  <div className={styles.addBtn}>
                                      <button onClick={addCMBlock}><img src={plusIcon} alt={ENWORDS.addBtn} /></button>
                                  </div>
                              </div>
                              {
                                cmDetailsList.length > 0 ?
                                  cmDetailsList && cmDetailsList.map((formData, formId) => {
                                    return (
                                      formData.id === activeCMForm ?
                                          <div className={styles.cmForm} id={ENWORDS.cmRefSection} key={formData.id}>
                                              {
                                                  <div className={styles.deleteBtnCMContainer}>
        
                                                      <button className={ styles.deleteBtnCM } 
                                                        onClick={() => invoiceVerficationCall(formData.id, formData.name)}>
                                                          <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                                      </button>
                                                  </div>
                                              }
                                              <div className={inputStyles.detailsRow}>
                                                  <CustomInput
                                                    title={ENWORDS.cmName}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMNameError}
                                                    placeholder = {PLACEHOLDER.fullName}
                                                    value = {formData.name}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'name', 'cmName')} />
                                                    
                                                  <CustomInput
                                                    title={ENWORDS.cmAddress}
                                                    placeholder = {PLACEHOLDER.address}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMAddressError}
                                                    value = {formData.address}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'address', 'cmAddress')} />
                                                                                                    
                                                  <CustomInput
                                                    title={ENWORDS.cmEmail}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMEmailError}
                                                    value = {formData.email}
                                                    placeholder = {PLACEHOLDER.commonEmail}
                                                    maintainDefaultCase = {true}
                                                    onChange={e => onCmDetailsUpdate((e).trim(), formData.id, 'email','cmEmail')} />
                                                  
                                                  <CustomInput
                                                    title={ENWORDS.customerLedgerAccountNo}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formLedgerAccountError}
                                                    value = {formData.ledgerAccount}
                                                    placeholder = {PLACEHOLDER.ledgerAccNo}
                                                    maintainDefaultCase = {true}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'ledgerAccount', 'ledgerAccountNo')} />

                                                  <CustomInput
                                                    title={ ENWORDS.termsDate }
                                                    value={formData.termDays}
                                                    placeholder = '15'
                                                    maxLength={ 4 }
                                                    type='number'
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formTermDaysError}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'termDays','termDays')} 
                                                  />
  
                                                  <CustomDatePicker
                                                    title = {ENWORDS.tradingStartDate}
                                                    startDate = {formData.startDate}
                                                    dateFormat = {globalDateFormat}
                                                    onChange={(e) => onCmDetailsUpdate(e, formData.id, 'startDate','startDate')}
                                                    wrapperClass = 'stationDate'
                                                    errorMessage= {
                                                      cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formStartDateError
                                                    }/>
                                                  { stationStatus === 'Inactive' ? <CustomDatePicker
                                                    title = {ENWORDS.tempTradingEndDate}
                                                    startDate = {formData.endDate}
                                                    dateFormat = {globalDateFormat}
                                                    onChange={(e) => onCmDetailsUpdate(e, formData.id, 'endDate', 'endDate')}
                                                    wrapperClass = 'stationDate'
                                                    errorMessage= {
                                                      cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formEndDateError
                                                    }
                                                  /> : null}
                                              </div> 
                                          </div> : null
                                    )
                                  } ) : null}
                          </div>
                          <hr />
                     
                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.previousCM}</p>
                                  <button onClick={() => setHiddenCM(!hiddenCM)}>
                                      <img src={!hiddenCM ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>

                          </div>
                          <div className={styles.cmMainForm} >
                              {!hiddenCM ? <div>
                                  {cmDetailsList && cmDetailsList.map((formData, formId) => {
                                    return (
                                      formData.id !== activeCMForm ?
                                          <div className={styles.cmForm} id={ENWORDS.cmRefSection} key={formData.id}>
                                              {
                                             
                                                  <div className={styles.deleteBtnCMContainer}>
      
                                                      <button className={ styles.deleteBtnCM } 
                                                        onClick={() => invoiceVerficationCall(formData.id, formData.name)}>
                                                          <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                                      </button>
                                                  </div>
                                              }
                                              <div className={inputStyles.detailsRow}>
                                                  <CustomInput
                                                    title={ENWORDS.cmName}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMNameError}
                                                    placeholder = {PLACEHOLDER.fullName}
                                                    value = {formData.name}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'name', 'cmName' )} />
                                                    
                                                  <CustomInput
                                                    title={ENWORDS.cmAddress}
                                                    
                                                    placeholder = {PLACEHOLDER.address}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMAddressError}
                                                    value = {formData.address}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'address','cmAddess')} />
                                                                                                    
                                                  <CustomInput
                                                    title={ENWORDS.cmEmail}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMEmailError}
                                                    value = {formData.email}
                                                    placeholder = {PLACEHOLDER.commonEmail}
                                                    maintainDefaultCase = {true}
                                                    onChange={e => onCmDetailsUpdate((e).trim(), formData.id, 'email','cmEmail')} />
                                                  
                                                  <CustomInput
                                                    title={ENWORDS.customerLedgerAccountNo}
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formLedgerAccountError}
                                                    value = {formData.ledgerAccount}
                                                    placeholder = {PLACEHOLDER.ledgerAccNo}
                                                    maintainDefaultCase = {true}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'ledgerAccount','ledgerAccountNo')} />

                                                  <CustomInput
                                                    title={ ENWORDS.termsDate }
                                                    placeholder = '15'
                                                    value={formData.termDays}
                                                    maxLength = {4}
                                                    type='number'
                                                    error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formTermDaysError}
                                                    onChange={e => onCmDetailsUpdate(e, formData.id, 'termDays','termDays')} 
                                                  />
  
                                                  <CustomDatePicker
                                                    title = {ENWORDS.tradingStartDate}
                                                    startDate = {formData.startDate}
                                                    dateFormat = {globalDateFormat}
                                                    onChange={(e) => onCmDetailsUpdate(e, formData.id, 'startDate', 'startDate')}
                                                    wrapperClass = 'stationDate'
                                                    errorMessage= {
                                                      cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formStartDateError
                                                    }/>
                                                  <CustomDatePicker
                                                    title = {ENWORDS.tradingEndDate}
                                                    startDate = {formData.endDate}
                                                    dateFormat = {globalDateFormat}
                                                    onChange={(e) => onCmDetailsUpdate(e, formData.id, 'endDate', 'endDate')}
                                                    wrapperClass = 'stationDate'
                                                    errorMessage= {
                                                      cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formEndDateError
                                                    }/>

                                              </div> 
                                          </div> : null
                                    )
                                  })}
                              </div> : <div></div>}
                              <div className={styles.btnContainer}>
                                  <div className={styles.cancelBtn}>
                                      <button onClick={onCancelClicked}>{ENWORDS.cancel}</button>
                                  </div>
                                  <div className={styles.doneBtn}>
                                      <button onClick={onDoneClicked}>{ENWORDS.done}</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default EditStationScreen