import React from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/invoiceListing/inputDateCalendar.css';
import  dashboardStyle from '../../styles/dashboard/AdminDashboardScreen.module.css';
import stationStyle from '../../styles/baseconfiguration/AddNewSite.module.css';
import invoiceStyle from '../../styles/invoiceListing/InvoiceListingScreen.module.css';
import timePickerStyles from '../../styles/stationListing/TimePicker.module.css';
import { useState } from 'react';

const CustomTimePicker = ({ 
  title, onChange, defaultTime,
  wrapperClass = '', errorMessage = 'please select correct time',isDisabled }) => {
  const [ selectTime, setSelectTime ] = useState(defaultTime);
  const [ errorMsgForTime, setErrorMsgForTime ] = useState(errorMessage);
 
  const onFromDateChange = (time) =>{
    setSelectTime(time);

    if(onChange){
      onChange(time);
      setErrorMsgForTime(null);
    }
  }

  return (
      <div className={ 
        wrapperClass === 'dashboardFilter' ?
          dashboardStyle.fromDate 
          : wrapperClass === 'invoiceDatePicker'? 
            invoiceStyle.datePickerStyle : wrapperClass === 'adtLogsDatePicker'? timePickerStyles.editTimePickerContainer
              :stationStyle.formDataContainer} style={{
        margin: '0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
          {title && 
          <p className={
            wrapperClass === 'dashboardFilter' ?
              dashboardStyle.filterText:
              stationStyle.dateTitle}>{title}</p>
          }
          <DatePicker
            selected={selectTime}
            onChange={(date) => onFromDateChange(date)} 
            shouldCloseOnSelect={true}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            wrapperClassName= {wrapperClass}
            dateFormat="HH:mm"
            disabled={isDisabled}
            errorMessage= { errorMsgForTime }
          />
          { selectTime === undefined || errorMessage === ''?
              <p className={ errorMessage !== '' ? 
                stationStyle.errorMessageDate :
                stationStyle.errorMessage}> {errorMessage}</p> : null
          } 
      </div>
   
  )
}

export default CustomTimePicker