import { SET_DASHBOARD_TABLE } from '../../shared/constants/actionType.js'

const INIT_STATE = {
  dashboardTable: [],
}

const dashboardReducer = (state = INIT_STATE, action) => {
  switch(action.type){

  case SET_DASHBOARD_TABLE:

    return {
      ...state,
      dashboardTable: action.payload,
    }
    
  default: return state;
  }
}

export default dashboardReducer;