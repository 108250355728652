import React, { useState, useEffect } from 'react'
import { isoStringToDate } from '../../shared/commonFunctions'
import { NUMBER_REGEXX } from '../../shared/constants/appConstants';
import invoiceStyle from '../../styles/invoiceListing/invoiceTable.module.css';
import PropTypes from 'prop-types';
import editPen from '../../assets/icons/editPenIcon.svg';
const InvoiceDetailsTable = (props) => {

  const [ selectedCellId, setSelectedCellId ] = useState([]);
  const [ toEditCellValue, setToEditCellValue ] = useState(0);
  const [ tableData, setTableData ] = useState([]);
  const [ originalData, setOriginalData ] = useState([]);
  const [ data, setData ] =  useState([]);
  const editableColumns = [ 'quantity', 'unitPrice' ];
  const onTableDataClicked = (event, cellData, columnValue, rowValue, key ) =>{

    if (key === 'quantity' || key === 'unitPrice') {
      event.stopPropagation();
      setSelectedCellId({ col: columnValue, row: rowValue });
      setToEditCellValue(cellData);
    }

  }

  const onValueChange = (cellData) => {
    if (NUMBER_REGEXX.test(cellData)) {
      setToEditCellValue(cellData);
    }
  }

  const onInputBlur = (columnValue, dataValue, key) => {

    let dataCopy = data;
    if (toEditCellValue !== props.data[ columnValue ][ key ]) {
      const dataStruc = {
        id: dataValue.id,
        quantity: dataValue.quantity,
        unitPrice: dataValue.unitPrice,
        invoiceNo: dataValue.invoiceNo,
      }
      if (key === 'quantity') {
        dataStruc.quantity = toEditCellValue * 1
      } else {
        dataStruc.unitPrice = toEditCellValue * 1
      }

      tableData.forEach((item, id) => {
        if (id === columnValue) {
          item[ key ] = toEditCellValue * 1
        }
      })
      const index = dataCopy.findIndex((val) => val.id === dataValue.id);
      if (index > -1) {
        if (key === 'quantity') {
          dataCopy[ index ].quantity = toEditCellValue * 1
        }
        else {
          dataCopy[ index ].unitPrice = toEditCellValue * 1
        }
      } else {
        dataCopy = [ ...dataCopy, dataStruc ]
        setData(dataCopy)
      }
    }
    setSelectedCellId([])
    props.onDataValueChange(tableData, dataCopy);

  }

  useEffect(() => {
    setTableData(JSON.parse(JSON.stringify(props.data)))
    setOriginalData(JSON.parse(JSON.stringify(props.data)))
  }, [ props.data ])

  return (
      <div className={invoiceStyle.invoiceTableContainer} >
          <table>
              <thead >
                  <tr className={invoiceStyle.tblRow}>
                      {
                        Object.keys(props.headerData).map(key =>
                            <th className={key === 'description' ? invoiceStyle.invoiceHeaderDesc : invoiceStyle.invoiceHeader} key={key}>
                                {props.headerData[ key ]}
                            </th>
                        )}
                  </tr>
              </thead>
              <tbody>
                  {tableData && tableData.map((item, colummId) => {
                    return (
                        <tr
                          className={invoiceStyle.tblRow}
                          key={item[ Object.keys(item)[ 0 ] ] + item[ Object.keys(item)[ 1 ] ]}
                          onBlur={() => setSelectedCellId([])}
                        >
                            {Object.keys(props.headerData).map((key, rowId) =>
                              selectedCellId.col === colummId && selectedCellId.row === rowId ?
                                  <td className={invoiceStyle.dataCell}
                                    key={rowId + '-' + item[ key ]}>
                                      <input type="text" value={toEditCellValue}
                                        onChange={e => onValueChange(e.target.value)}
                                        autoFocus={true}
                                        onBlur={() => onInputBlur(colummId, item, key)}
                                        onKeyUp={(e) => e.key === 'Enter' ? onInputBlur(colummId, item, key) : null}
                                      />
                                  </td>

                                :
                                  <td
                                    className={
                                      item[ key ] !== originalData[ colummId ][ key ]
                                        ? invoiceStyle.dataCellHighLighted :
                                        invoiceStyle.dataCell
                                    } 
                                    key = { rowId  + '-' +item[ key ]}>                                      <div>
                                        {  key === 'periodFrom' || key  === 'periodTo' ? item[ key ] === null ? '-' : isoStringToDate(item[ key ]) :item[ key ]}
                                        {editableColumns.includes(key) && props.invoiceStatus !== 'Completed'?<img onClick={event => onTableDataClicked(event, item[ key ],colummId,rowId, key)} src={editPen} alt='edit'/> : null}
                                    </div>
                                  </td>
                            )

                            }
                        </tr>
                    )
                  })}

              </tbody>
          </table>
          <div>
          </div>
      </div>

  )
}

InvoiceDetailsTable.propTypes = {
  onDataValueChange: PropTypes.func.isRequired,
};
export default InvoiceDetailsTable