export const LOG_STATUS_NEW = 'New';
export const LOG_STATUS_COMMITTED = 'Committed';
export const LOG_STATUS_OPEN = 'Open';
export const LOG_STATUS_SENT = 'Sent';
export const LOG_STATUS_CLOSED = 'Closed';
export const STATUS = 'status';
export const LOG_UNIQUE_ID = 'logUniqueId';
export const DETAILED_LOG_ID ='detailsLogId';
export const PENALTY_ID = 'penaltyId';
export const PENALTY_STATUS = 'penaltyStatus';
export const PENALTY_TYPE = 'type';
export const PENALTY_TYPE_NOTICE = 'Notice';
export const PENALTY_TYPE_WARNING = 'Warning';
export const PENALTY_STATUS_NEW = 'New';
export const PENALTY_STATUS_PENDING = 'Pending';
export const PENALTY_STATUS_OPEN = 'Open';
export const PENALTY_STATUS_CLOSED = 'Closed';
export const PENALTY_STATUS_EMAILED = 'Emailed';
export const PENALTY_FILE_PREFIX = 'Penalty Data ';
export const PENALTY_DOCUMENT_ACTIONS = 'documentActions';
export const COUNTER_FILE_PREFIX = 'Counters Data ';
export const REPORTS_FILE_PREFIX = 'Log Reports Data ';

export const PENALTY_CONFIG_TYPES = {
  Warning : 'Warning',
  Notice : 'Notice',
}

export const DAILYLOGHEADERS = {
  logUniqueId : 'ID',
  date: 'Date',
  status: 'Status',
  sitesCount: 'Sites',
  penalties: 'Penalties',
  actions: 'Actions',
};

export const CONFIGURATIONHEADERS = {
  dragIcon:'',
  type: 'Type',
  applicableFine: 'Applicable Fine',
  vatCode:'vatCode',
  configActions: 'Actions',
};

export const CONFIGURATIONSCREENHEADERS = {
  dragIcon:'',
  type: 'Type',
  applicableFine: 'Applicable Fine',
  vatCode:'vatCode',
  configActions: 'Actions',
};

export const REPORTSHEADERS = {
  siteID: 'siteID',
  siteName: 'Site Name',
  contractNumber: 'Contract Number',
  date: 'Log Date',
  openingDate: 'Opening Date',
  configuredOpeningTime: 'Expected Opening Time',
  actualOpeningTime: 'Actual Opening Time',
  closingDate: 'Closing Date',
  configuredClosingTime: 'Expected Closing Time',
  actualClosingTime: 'Actual Closing Time',
  status: 'Status',
  remark: 'Remarks',
};

export const COUNTERSHEADERS = {
  siteId: 'Site',
  siteName: 'Site Name',
  contractNumber: 'Contract Number',
  total: 'Total',
  status: 'Status',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'Aug',
  sep: 'Sept',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
};

export const PENALTYSHEADERS = {
  select : 'Select',
  siteId: 'Site',
  siteName: 'Site Name',
  contractNumber: 'Contract Number',
  date: 'Date',
  status: 'Status',
  applicableFine: 'Amount',
  siteEmail: 'Site Email',
  areaManagerEmail: 'Area Manager Email',
  regionManagerEmail: 'Region Manager Email',
  penaltyStatus: 'Penalty Status',
  documentActions: 'Documents',
  actions: 'Actions',
};

export const DAILYLOGDETAILSHEADERS = {
  siteId: 'Site ID',
  siteName: 'Site Name',
  contractNumber: 'Contract Number',
  openingDate : 'Opening Date',
  actualOpeningTime: 'Opening Time',
  closingDate : 'Closing Date',
  actualClosingTime: 'Closing Time',
  status: 'Status',
  remark: 'Remark',
  actions: 'Actions',
};

export const COUNTERSSERACH = {
  areaName: 'area',
  contractNumber: 'contractNumber',
  counter: 'counter',
  siteId: 'siteId',
  regionName: 'regionName',
  siteName: 'siteName',
  status: 'status',
};

export const PENALTIESSTATUS = {
  'Late Opening':'Late Opening',
  'Early Closing':'Early Closing',
  'Alarm not Set':'Alarm not Set',
  'Alarm on Test':'Alarm on Test',
};

export const PENALTYSTATUSES = {
  'Late Opening': 'Late Opening',
  'Early Closing': 'Early Closing',
  'Alarm not Set': 'Alarm not Set',
};

export const PENALTYSTATUS = {
  // New : 'New',
  // 'Pending':'Pending',
  'Open':'Open',
  'Closed':'Closed',
  'Emailed':'Emailed',
};

export const DETAILED_LOGS_EDITABLE= 
[ 
  { colName : 'remark', inputType : 'text' } ,
  { colName : 'status', inputType : 'dropdown', options : {  '' : '-', ...PENALTIESSTATUS } },
  { colName : 'actualOpeningTime', inputType : 'time' },
  { colName : 'actualClosingTime', inputType : 'time' },
  { colName : 'openingDate', inputType : 'date' },
  { colName : 'closingDate', inputType : 'date' }
]

export const PENALTIES_EDITABLE= 
[ 
  { colName : 'penaltyStatus', inputType : 'dropdown', options : [ 'Open', 'Closed' ] }
]

export const PENALTIES_EXPORT_HEADERS = {
  siteId: 'Site',
  siteName: 'Site Name',
  contractNumber: 'Contract Number',
  date: 'Date',
  status: 'Status',
  applicableFine: 'Amount',
  siteEmail: 'Site Email',
  areaManagerEmail: 'Area Manager Email',
  regionManagerEmail: 'Region Manager Email',
  penaltyStatus: 'Penalty Status',
}

export const CONTRACT_NUMBER_EXPORT_HEADERS = {
  contractNumbers: 'contractNumbers',
};

export const CONTRACT_NUMBER_EXPORT_FILE_PREFIX = 'Contract Number Data '
