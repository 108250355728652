import React,{ useEffect, useState } from 'react';
import styles from '../../styles/invoiceListing/InvoiceListingScreen.module.css'
import downloadIcon from '../../assets/icons/downloadIcon.svg'
import ENWORDS from '../../shared/local/en.json';

import { exportCSVFile } from '../commonFunctions';
import CustomPopup from './CustomPopup';
import { format } from 'date-fns';
import { globalDateFormat } from '../../shared/constants/appConstants';

const DownloadCSVBtn = ({ popUpTitle, popUpText, screenName,toolTitle,
  tableData, fileNamePrefix ='Data', headers = { } , disabled = false , setSelectedRows = '' }) => {
  const [ visibility, setVisibility ] = useState(false);
  const [ filteredData, setFilteredData ] = useState(tableData);

  useEffect(() => {
    setFilteredData(tableData.map(obj => {
      const object = {};
      Object.keys(headers).forEach(key=>{
        if (key.toLowerCase().includes('date')) {
          object[ headers[ key ] ] = obj[ key ] !== null && !isNaN(Date.parse(obj[ key ])) ? format(new Date(obj[ key ]), globalDateFormat) : obj[ key ]
        } else {
          object[ headers[ key ] ] = obj[ key ]
        }
      })
      return object;
    }))
  },[ tableData ])

  const downloadCSV = () => {
    const today = new Date(Date.now())
    exportCSVFile(null, filteredData, fileNamePrefix + today.toLocaleDateString())
    setVisibility(false)
    if (setSelectedRows !== '') {
      setSelectedRows([])
    }
  }
  return (
      <div>
          <CustomPopup
            showPopUp={visibility}
            title={popUpTitle}
            onConfirm={downloadCSV}
            onReject={()=>{setVisibility(false)}}
          >
              <p>{popUpText}</p> 
          </CustomPopup>
          <div className={styles.downloadCSVDiv}>
              <button className= {disabled ? styles.featureButtonDisable : styles.featureButton} disabled={disabled} onClick = {()=>{ setVisibility(true)}} title={toolTitle}>
                  <div >
                      <img src={downloadIcon} alt = {ENWORDS.downloadIcon} />
                  </div>
              </button>
          </div>
      </div>
  )
}

export default DownloadCSVBtn