import { combineReducers } from 'redux';
import authReducer from './authReducer';
import supplierManagementReducer from './supplierManagementReducer';
import roleManagementReducer from './roleManagementReducer';
import dashboardReducer from './dashboardReducer';
import moduleConfigurationReducer from './moduleConfigurationReducer';
import userManagementReducer from './userManagementReducer';
import invoiceListingReducer from './invoiceListingReducer';
import companyManagementReducer from './companyManagementReducer';
import commonReducer from './commonReducer';
import stationListingReducer from './stationListingReducer';
import settingReducer from './settingReducer';
import areaReducer from './areaReducer';
import regionsReducer from './regionsReducer';
import opsRegionsReducer from './opsRegionsReducer';
import configurationReducer from './configurationReducer';
import reportsReducer from './reportsReducer';
import countersReducer from './countersReducer';
import penaltyReducer from './penaltyReducer';
import dailyLogsReducer from './dailyLogsReducer';
import emailLogReportReducer from './emailLogReportReducer';

const reducers = combineReducers({
  auth: authReducer,
  companyManagement:companyManagementReducer,
  supplierManagement: supplierManagementReducer,
  roleManagement:roleManagementReducer,
  stationListing:stationListingReducer,
  dashboard: dashboardReducer,
  moduleConfiguration: moduleConfigurationReducer,
  userManagement: userManagementReducer,
  invoiceListing: invoiceListingReducer,
  common:commonReducer,
  setting:settingReducer,
  areaReducer:areaReducer,
  regionsReducer: regionsReducer,
  configurationReducer:configurationReducer,
  reportsReducer:reportsReducer,
  countersReducer:countersReducer,
  penaltyReducer:penaltyReducer,
  dailyLogsReducer:dailyLogsReducer,
  opsRegionsReducer:opsRegionsReducer,
  emailLogReportReducer: emailLogReportReducer,
});
export default reducers;