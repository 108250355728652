import React, { useEffect } from 'react'
import CustomPopup from '../../../shared/Components/CustomPopup';
import CustomDatePicker from '../../../shared/Components/CustomDatePicker';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import { FILETYPE, GLOBAL_DATE_FORMAT_DB, globalDateFormat, TABS } from '../../../shared/constants/appConstants';
import styles from '../../../styles/fileUploadPopUp.module.css'
import fileIcon from '../../../assets/icons/filesIcon.svg';
import { useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyModuleMapping } from '../../../api/services/commonService';
import { generateLogs, uploadDailyLogs } from '../../../api/services/adtDailyLogService';
import { format } from 'date-fns';
import { setError, setLoadingFlag } from '../../../redux/actions';
import warning from '../../../assets/icons/warningSign.svg'
import arrowDownFilled from '../../../assets/icons/arrowDownFilled.svg'
import arrowUpFilled from '../../../assets/icons/arrowUpFilled.svg';
const ENWORDS = require('../../../shared/local/en.json');

function DailyLogUploadPopup({ showPopUp, closePopup }) {

  const [ selectedFile, setSelectedFile ] = useState();
  const [ selectedDate, setSelectedDate ] = useState(new Date());
  const [ companies, setCompanies ] = useState([]);
  const [ company, setCompany ] = useState(0);
  const [ uploadedUuid, setUploadedUuid ] = useState();
  const [ willReplaceFile, setWillReplaceFile ] = useState(false)
  const [ serverErrorArr, setServerErrorArr ] = useState([]);
  const [ serverError, setServerError ] = useState('');
  const [ erroMessage, setErrorMessage ] = useState();
  const [ isHiddenValidation, setIsHiddenValidation ] = useState(false)
  const [ isErrorStatus, setIsErrorStatus ] = useState(false)
  const [ isServerStatus, setIsServerStatus ] = useState(false);
  
  const companyModule = useSelector((state)=> state.common.companyModuleAccess);
  const fileInputRef = useRef();
  const dispatch = useDispatch();
    
  const selectFile =()=>{
    fileInputRef.current.click()
  }

  const filePickHandler = async (e) =>{
    setIsErrorStatus(false)
    setWillReplaceFile(false)
    setIsServerStatus(false)
    setServerError([])
    e.preventDefault();

    setSelectedFile(e.target.files[ 0 ]);
  }

  const validateLogs = async() =>{
    const formdata = new FormData();
    formdata.append('date', format(selectedDate, GLOBAL_DATE_FORMAT_DB));
    formdata.append('companyID', company);
    formdata.append('', selectedFile);
    dispatch(setLoadingFlag(true))
    const res = await uploadDailyLogs(formdata)
    dispatch(setLoadingFlag(false))
    if (res.res.status === 200){
      if( res.res?.data?.message && res.res?.data?.statusCode === 409){
        setWillReplaceFile(true)
        setIsErrorStatus(true)
        setErrorMessage( res.res?.data?.message)
      } 
      setUploadedUuid(res.res?.data.data)
      
    }
    else{
      setWillReplaceFile(false)
      setIsErrorStatus(false)
      if (Array.isArray(res.res)){
        setServerErrorArr(res.res)
      }
      else{
        setIsServerStatus(true);
        setServerError(res.res.message)
      }
    }
    dispatch(setLoadingFlag(false))
  }
  const uploadLogs = async() =>{
    const formdata = new FormData();
    formdata.append('date',  format(selectedDate, GLOBAL_DATE_FORMAT_DB));
    formdata.append('companyID', company);
    formdata.append('uuid', uploadedUuid)
    formdata.append('deleteIfExists', true);
    dispatch(setLoadingFlag(true))
    const res = await generateLogs(formdata)

    if(res.data.statusCode !== 200){
      dispatch(setError({ statusCode: res.data.status, errorStatus: true, errorMessage:res.data.message }))
    }
    if(res){
      dispatch(setLoadingFlag(false))
      setUploadedUuid(undefined)
      setSelectedFile(undefined)
      closePopup()
    }
  }

  useEffect(() => {
    setUploadedUuid(undefined)
    setServerErrorArr([])
  }, [ selectedFile ])

  useEffect(() => {
    let companyList = []
    const filteredCompanyData = companyModule.filter(companyModuleData=> TABS.adtAlarmMonitoring === companyModuleData.moduleName);
    if (filteredCompanyData.length > 0){
      companyList = filteredCompanyData[ 0 ].cd;
    }

    const companyListCopy = []
    companyList.forEach(_company => {
      if(!companyListCopy.some(comp=>{ return comp.id === _company.id})){
        companyListCopy.push(_company)
      }
    });
    setCompanies(companyListCopy)

  }, [ companyModule ])
  
  useEffect(() => {
    setCompany(companies[ 0 ]?.id)
  }, [ companies ])

  useEffect(() => {
    dispatch(getCompanyModuleMapping());
  }, [])

  const serverErrorArrBlock = () =>{

    return (
        <div className={styles.statusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <img src={warning} alt={ENWORDS.warningIcon}  className = {styles.statusIcon} />
                    <p className={styles.invalidCount}>
                        {serverErrorArr.length}
                    </p>
                    <p className={styles.invalidText}>
                        {ENWORDS.invalidEntries}
                    </p>
                </div>
                <div className={styles.hideValidationContainer}>
                    <button onClick={ ()=> setIsHiddenValidation(!isHiddenValidation)} >
                  
                        <img src={isHiddenValidation ?  arrowUpFilled: arrowDownFilled} alt= {ENWORDS.arrow} />
                    </button>
                </div>
            </div>
            <div className={styles.bottomErrorContainer}>
                {
                  isHiddenValidation  ?
                      <div >

                          <div className={styles.errorHeader}>
                              <div className={styles.erroCol1}>
                                  <p>{ENWORDS.fieldName}</p>
                              </div>
                              <div className={styles.erroCol2}>
                                  <p>{ENWORDS.errormsg}</p>
                              </div>
                          </div>
            
                          {
                            serverErrorArr &&  serverErrorArr.map((item)=>{
                              return (

                                  <div className={styles.errorHeader}>
                                      <div className={styles.errorBodyCol1}>
                                          <p>
                                              {item.fieldName}
                                          </p>
                                      </div>
                                      <div className={styles.errorBodyCol2}>
                                          <p>
                                              {item.issue}
                                          </p>
                                      </div>
                                  </div>
                              )
                    
                            })
                          }
                      </div>

                    : <div></div>
                }

            </div>
        </div>
    )

  }
  const errorBlock = () =>{
    return (

        <div className={styles.invoiceStatusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <img src={warning} alt={ENWORDS.warningIcon}  className = {styles.statusIcon} />
                    <p className={styles.invalidText}>
                        {erroMessage}
                    </p>
                </div>
            </div>
            <div className={styles.checkContainer}>
                <input type="checkbox" checked= {!willReplaceFile} onChange={(e)=> setWillReplaceFile(!willReplaceFile)} />
                <p className={styles.invalidText}>
                    {ENWORDS.invoiceCheckboxMsg}
                </p>
            </div>
        </div>
    )
  }

  const serverErrorBlock = () =>{

    return (
        <div className={styles.statusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <p className={styles.invalidText}>
                        {serverError}
                    </p>
                </div>
            </div>
               
        </div>
    )

  }
  
  return (
      <CustomPopup
        showPopUp={showPopUp}
        onConfirm={uploadedUuid? uploadLogs : validateLogs}
        title={ENWORDS.bulkUpload}
        onReject={closePopup}
        confirmText={uploadedUuid? ENWORDS.upload : ENWORDS.Validate}
        cancelText ={ENWORDS.cancel}
        style={{ width : '40%' }}
        disableConfirm={! (selectedFile && company) || serverErrorArr.length> 0 || willReplaceFile || isServerStatus}
      >
          <div className = {styles.invoiceDetailsContainer}>
              <CustomDropDown 
                title={ENWORDS.companyName}
                fieldValues={companies}
                selectedValue={company}
                renderKey='id'
                renderKeyOne='name'
                onChange={e => setCompany(e.target.value)}
              
              />
                         
              <CustomDatePicker
                title = {ENWORDS.documentDateTitle}
                startDate = {selectedDate}
                endDate={new Date(Date.now())}
                dateFormat = {globalDateFormat}
                onChange={(e) => setSelectedDate(e)}
                wrapperClass = 'stationDate'
              />

          </div> 
          <div className={styles.fileDetailsBox}>
              <div className={styles.iconContainer}>
                  <img src={fileIcon} alt= {ENWORDS.fileIcon} />
              </div>
              <div className={styles.fileInfoContainer}>
                  <div className={styles.bottomBox}>
                      <p>{selectedFile? selectedFile.name : ENWORDS.noFile}</p>
                      <button onClick={selectFile}>{selectedFile? ENWORDS.replace: ENWORDS.select}</button>
                  </div>
              </div>
          </div>
          {
            serverErrorArr.length > 0 && 
            serverErrorArrBlock()
          }
          {
            isErrorStatus && errorBlock()
          }
          {
            isServerStatus?
              serverErrorBlock():
              null
          }
          <input
            type="file"
            accept={FILETYPE}
            onChange={filePickHandler}
            onClick={(event)=> { 
              event.target.value = null
            }}
            ref={fileInputRef}
            hidden
          />
      </CustomPopup>
  )
}

export default DailyLogUploadPopup
