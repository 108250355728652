import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import ENWORDS from '../../../shared/local/en.json';
import { PENALTYSTATUSES, PENALTIES_EDITABLE, PENALTYSHEADERS, PENALTYSTATUS, PENALTY_STATUS, PENALTY_STATUS_OPEN, PENALTIES_EXPORT_HEADERS, PENALTY_FILE_PREFIX, PENALTY_DOCUMENT_ACTIONS, PENALTY_TYPE, PENALTY_TYPE_NOTICE, PENALTY_TYPE_WARNING, PENALTY_STATUS_CLOSED } from '../adtMonitoringConstant';
import uploadBtnStyles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import style from '../../../styles/stationListing/StationListingScreen.module.css';
import { TABS } from '../../../shared/constants/appConstants';
import SideNavigation from '../../../shared/Components/SideNavigation';
import CustomTable from '../../../shared/Components/CustomTable';
import mailFilledIcon from '../../../assets/icons/mailFilledIcon.svg'
import documentIcon from '../../../assets/icons/document.svg'
import { createInvoiceForPenalty } from '../../../api/services/invoiceListingService';
import DownloadCSVBtn from '../../../shared/Components/DownloadCSVBtn';
import { setError, setErrorFlag, setLoadingFlag } from '../../../redux/actions';
import { getPenalties, getPenaltyDocument, sendPenaltyEmail, updatePenaltyStatus } from '../../../api/services/adtPenaltyService';
import SuccessPopUp, { DisplayPopUp } from '../../../shared/Components/SuccessPopUp';
import CustomPopup from '../../../shared/Components/CustomPopup';
import useAccess from '../../../utility/appHooks/useAccess';
import mailIcon from '../../../assets/icons/mailFilledIconWhite.svg'
const SCREENS  = require('../../../shared/local/screens.json');

const PenaltyScreen = () => {
  const penaltyData = useSelector(state => state.penaltyReducer.penaltyTable);
  const [ filterData, setFilterData ] = useState(penaltyData);
  const [ selectedStatus, setSelectedStatus ] = useState('All');
  const [ selectedPenaltyStatus, setSelectedPenaltyStatus ] = useState('Open');
  const [ selectedRows, setSelectedRows ] = useState([]);
  const [ fileData, setFileData ] = useState()
  const [ showPdf, setShowPdf ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ penaltyId, setPenaltyId ] = useState()
  const dispatch = useDispatch();
  const hasWriteAccess = useAccess(SCREENS.adtPenalties)
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const userData = useSelector(state => state.auth.user)

  const filteration = () => {
    let penaltyFilterData = penaltyData;

    if (selectedStatus?.toLowerCase() !== 'all') {
      penaltyFilterData = penaltyFilterData.filter(item => {
        return item.status === selectedStatus;
      });
    }
    if(selectedPenaltyStatus?.toLocaleLowerCase() !== 'all'){
      penaltyFilterData = penaltyFilterData.filter(item => {
        return item.penaltyStatus === selectedPenaltyStatus;
      });
    }

    return penaltyFilterData;
  };

  const updateStatus =async (row)=>{
    dispatch(setLoadingFlag(true))
    const res = await updatePenaltyStatus(row?.penaltyStatus, row?.id)
    if(res?.res === true){
      dispatch(getPenalties())
    }else{
      dispatch(setError(res.err))
      dispatch(setLoadingFlag(false))
    }
  }

  useEffect(() => {
    dispatch(setLoadingFlag(true));
    dispatch(getPenalties());
  }, [])

  useEffect(() => {
    setFilterData(filteration());
    setSelectedRows([])
  }, [ selectedStatus, selectedPenaltyStatus, penaltyData ]);

  const getDocument = async ( event, id, documentType) => {
    event.stopPropagation();
    dispatch(setLoadingFlag(true))
    const res =  await getPenaltyDocument(id, documentType)
    dispatch(setLoadingFlag(false))
    if(res.res){
      setFileData(res.res)
      setShowPdf(true)
    }

  };
  const downloadPDF = () => {
    const linkSource = `data:application/pdf;base64,${ fileData?.file }`;
    const downloadLink = document.createElement('a');
    const fileName = fileData.fileName;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setSelectedRows([]);
  }

  const sendEmail = async() =>{
    const ids = selectedRows.length > 0 ? selectedRows : [ penaltyId ]
    setPenaltyId(undefined)
    dispatch(setLoadingFlag(true))
    const res = await sendPenaltyEmail(ids)
    dispatch(setLoadingFlag(false))
    const filteredItems = filterData?.filter(item => ids.includes(item.id) && item.type === ENWORDS.notice);
    if(res.res){
      setShowSuccess(true)
      setSelectedRows([]);
      if(filteredItems.length > 0) {
        const response = await createInvoiceForPenalty(filteredItems);
        if (response?.res === false) {
          dispatch(setError(response.err))
          dispatch(setLoadingFlag(false))
        }
      }
    }else{
      dispatch(setErrorFlag(true))
    }
  }

  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.adtPenalties){
        if (!item.writeAccess){
          delete PENALTYSHEADERS[ 'actions' ];
        }
      }
    })
  }

  useEffect(()=>{
    checkRoleAccess()
  }, [ roleLevelAccess ])

  return (
      <div className={styles.mainContainer}>
          <DisplayPopUp
            title = {ENWORDS.mailSuccess}
            show = {showPdf}
            onClose={()=> setShowPdf(false)}
            component={
                <iframe src={`data:application/pdf;base64,${ fileData?.file }#toolbar=0`} title = "some" style={{ height: '90vh', width:'90vw' }} download = {fileData?.fileName}/>
            }
            onDownloadPDF = {downloadPDF}
          />
          <SuccessPopUp
            title = {ENWORDS.commonMailSuccess}
            show = {showSuccess}
            onClose={()=>{
              setShowSuccess(false);      
              dispatch(setLoadingFlag(true));
              dispatch(getPenalties());}}
          />
          <CustomPopup
            showPopUp={penaltyId}
            onConfirm={sendEmail}
            title={ENWORDS.sendEmailTitle}
            onReject={()=>{ setPenaltyId(undefined)}}
          >
              <p>{ENWORDS.sendEmailText}</p>
          </CustomPopup>
          <div className={styles.leftContainer}>
              <SideNavigation parentName={TABS.adtAlarmMonitoring} />
          </div>

          <div className={styles.rightContainer}>
              <div className={styles.siteHeader}>
                  <div className={style.stationHeaderContainer}> 
                      <div className={style.filterContainer}> 
                          <div className={style.leftFilterContainer}>
                              <CustomDropDown
                                title={ENWORDS.status}
                                name={ENWORDS.status.toLowerCase()}
                                fieldValues={{ 'All' :'All', ...PENALTYSTATUSES }}
                                type={ENWORDS.custom}
                                onChange={e => setSelectedStatus(e.target.value)}
                              />
                              <CustomDropDown
                                title={ENWORDS.penaltyStatus}
                                name={ENWORDS.penaltyStatus.toLowerCase()}
                                fieldValues={{ 'All' :'All', ...PENALTYSTATUS }}
                                selectedValue={selectedPenaltyStatus}
                                type={ENWORDS.custom}
                                onChange={e => setSelectedPenaltyStatus(e.target.value)}
                              />
                          </div>
                          <div className={styles.btnContainer}>
                              <div
                                className={uploadBtnStyles.mainBtnContainer}
                                title={ENWORDS.exportPenaltyTooltip}
                              >
                                  <DownloadCSVBtn 
                                    tableData={filterData?.filter(item=>  selectedRows?.includes(item.id))}
                                    popUpText={ENWORDS.downloadPenaltyCSVText}
                                    popUpTitle = {ENWORDS.downloadDashboardCSVTitle}
                                    screenName = {ENWORDS.invoiceListingCSV}
                                    toolTitle = {ENWORDS.downloadPenaltyCSVTile}
                                    headers={PENALTIES_EXPORT_HEADERS}
                                    fileNamePrefix={PENALTY_FILE_PREFIX}
                                    disabled={selectedRows.length === 0}
                                  />
                              </div>
                              { hasWriteAccess && selectedPenaltyStatus === PENALTY_STATUS_OPEN ?
                                  <div className={styles.mainBtnContainer}>
                                      <button
                                        disabled = {selectedRows.length ===0} 
                                        className={selectedRows.length ===0 ? styles.featureButtonDisable : styles.featureButton}  
                                        onClick = {() => setPenaltyId(selectedRows[ 0 ])}>
                                          <div className={styles.btnContainer}>
                                              <img src={mailIcon} alt = {ENWORDS.mail} />
                                          </div>
                                      </button>
                                  </div>
                                : null }
                          </div>
                      </div>
                  </div>
              </div>
              <CustomTable 
                headerData={PENALTYSHEADERS}
                data={filterData}
                onClickKey={'id'}
                editableColumns={ PENALTIES_EDITABLE }
                onSaveClick={updateStatus}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                writeAccess={hasWriteAccess}
                actions={
                  [ 
                    { name : ENWORDS.mail, 
                      src : mailFilledIcon, 
                      actionToolTipTxt : ENWORDS.sendMailTxt,
                      columnName : ENWORDS.actions,
                      onClick : (event, id, row)=>{ event.stopPropagation(); setPenaltyId(id); } , 
                      condition : { columnName: PENALTY_STATUS, values : [ PENALTY_STATUS_OPEN ], restrictAccess : !hasWriteAccess },
                    },
                    // { name : ENWORDS.invoice, 
                    //   src : invoiceIcon, 
                    //   columnName :  PENALTY_DOCUMENT_ACTIONS,
                    //   actionToolTipTxt : ENWORDS.penaltyDocTxt,
                    //   onClick : (event, id)=> getDocument(event, id , ENWORDS.invoice), 
                    //   condition : { columnName: PENALTY_TYPE, values : [ PENALTY_TYPE_NOTICE ] },
                    // },  
                    { name : ENWORDS.notice, 
                      src : documentIcon, 
                      columnName : PENALTY_DOCUMENT_ACTIONS,
                      actionToolTipTxt : ENWORDS.penaltyNoticeDocTxt,
                      onClick :(event, id)=> getDocument(event, id , ENWORDS.notice), 
                      condition : { columnName: PENALTY_TYPE, values : [ PENALTY_TYPE_NOTICE ] },
                    },
                    { name : ENWORDS.warning, 
                      src : documentIcon, 
                      actionToolTipTxt : ENWORDS.penaltyWarningDocTxt,
                      columnName : PENALTY_DOCUMENT_ACTIONS,
                      onClick : (event, id)=> getDocument(event, id , ENWORDS.warning) , 
                      condition : { columnName: PENALTY_TYPE, values : [ PENALTY_TYPE_WARNING ] },
                    }
                  ]}
                editRowCondition={ { 
                  columnName: PENALTY_STATUS, 
                  values : [ 
                    { value: PENALTY_STATUS_OPEN, condition: true },
                    { value: PENALTY_STATUS_CLOSED, condition: userData.isExtraPrivileged === 1 } 
                  ],
                } }
              />
          </div>
      </div>
  );
};

export default PenaltyScreen;
