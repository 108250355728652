import React, { useState, useEffect, useRef } from 'react'
import globalPopUpStyles from '../../styles/popUp.module.css'
import styles from '../../styles/fileUploadPopUp.module.css'
import cancelIcon from '../../assets/icons/cancelIcon.svg';
import fileIcon from '../../assets/icons/filesIcon.svg'
import warning from '../../assets/icons/warningSign.svg'
import PropTypes from 'prop-types';
import { FILETYPE, globalDateFormat, globalYearMonthDateFormat, TABS } from '../constants/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import {  setError, setLoadingFlag, setProgressbarFlag } from '../../redux/actions';
import { getModuleDetailsToDisplay } from '../../api/services/moduleConfigurationService';
import InvoiceDropdown from './InvoiceDropdown';
import '../../styles/invoiceListing/inputDateCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import { generateInvoice, uploadExcel } from '../../api/services/formService';
import arrowDownFilled from '../../assets/icons/arrowDownFilled.svg'
import arrowUpFilled from '../../assets/icons/arrowUpFilled.svg';
import successIcon from '../../assets/icons/successIcon.png';
import { getCompanyModuleMapping } from '../../api/services/commonService';
import CustomDatePicker from './CustomDatePicker';
import { getAllSettings } from '../../api/services/settingsService';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants/appRoute';
const ENWORDS  = require('../../shared/local/en.json')
const ERRWORDS  = require('../../shared/local/error.json')

const InvoiceFileUploadPopUp = (props) => {
  const fileInputRef = useRef();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ show, setShow ] = useState(false);
  const companyModule = useSelector((state)=> state.common.companyModuleAccess);
  const settingData = useSelector(state => state.setting.settingsData);
  const [ fileName, setFileName ] = useState('');
  const [ isErrorStatus, setIsErrorStatus ] = useState(false);
  const [ isServerArrStatus, setIsArrServerStatus ] = useState(false);
  const [ isServerStatus, setIsServerStatus ] = useState(false);
  const [ selectedFile, setSelectedFile ] = useState();
  const [ isFilePicked, setIsFilePicked ] = useState(false);
  const [ moduleIndex, setModuleIndex ] = useState(0);
  const [ companyIndex, setCompanyIndex ] = useState(0);
  const [ moduleName, setModuleName ] = useState('');
  const [ moduleArr, setModuleArr ] = useState([]);
  const [ companyArr, setCompanyArr ] = useState([]);
  const [ isHiddenValidation, setIsHiddenValidation ] = useState(false)
  const [ documentDate, setDocumentDate ] = useState(new Date(), 'yyyy-MM-dd')
  const [ erroMessage, setErrorMessage ] = useState('');
  const [ willReplaceFile, setWillReplaceFile ] = useState(false);
  const [ serverErrorArr, setServerErrorArr ] = useState([]);
  const [ serverError, setServerError ] = useState('');
  const [ isFileUploaded, setIsFileUploaded ] = useState(false);
  const [ progressValueEnabled, setProgressValueEnabled ] = useState(false);
  const [ progressValue, setProgressValue ] = useState(10);
  const [ validate, setValidate ] = useState(false);
  const [ timerValues, setTimerValues ] = useState({
    incrementValue:1,
    timeout: 1000,
  })
  const [ standardRate, setStandardRate ] = useState(0);
  const [ uuid, setUuid ] = useState('');
  const [ moduleException, setModuleException ] =  useState('');

  const setDefault = () =>{
    setIsFilePicked(false)
    setIsErrorStatus(false)
    setIsArrServerStatus(false)
    setIsServerStatus(false)
    setIsFileUploaded(false);
    setSelectedFile()
  }
  const closeHandler = (e) => {
    setValidate(false)
    setShow(false);
    setDefault()
    props.onClose(false);
  };

  useEffect(()=>{
    if (progressValueEnabled){
      dispatch(setProgressbarFlag({
        progressFlag:true,
        progressPercentValue:progressValue,
        progressBarMsg :  validate ? ENWORDS.uploadedProgressBarText : ENWORDS.generateProgressBarText,
      }))

      const timer = setInterval(() => {
        setProgressValue(
          (beforeValue) => (beforeValue >= 90 ? 90
            : beforeValue + timerValues.incrementValue));
      }, timerValues.timeout);
      return () => {
        clearInterval(timer);
      };

    }
  },[ progressValueEnabled, timerValues, validate ])
  useEffect(()=>{
    if (Math.floor((progressValue+1)/10)>0){
      setTimerValues({
        incrementValue:Math.floor(progressValue/10)+1,
        timeout: (progressValue+1)*1000,
      })

    }
  }, [ progressValue ])

  const createInvoice =  async () =>{
    setProgressValueEnabled(true);
    const formdata = new FormData();
    formdata.append('date', format(new Date(documentDate.getFullYear(), documentDate.getMonth(), 1), 'yyyy-MM-dd'));
    formdata.append('moduleID', moduleIndex);
    formdata.append('companyID', companyIndex);
    formdata.append('uuid', uuid)
    formdata.append('deleteIfExists', !willReplaceFile);
    formdata.append('documentDate',  format(documentDate, 'yyyy-MM-dd'));
    const res = await generateInvoice(formdata)
    dispatch(setProgressbarFlag({
      progressFlag:false,
      progressPercentValue:0,
    }))
    setProgressValueEnabled(false);
    if(res?.status === 200){
      setDefault()
      setIsFileUploaded(true)
      setValidate(false)
      if(props.onUploaded){
        props.onUploaded();
      }
    }else{
      setValidate(false)
      navigate(ROUTES.INVOICELISTING);
    }

  }
  const uploadFile =  async (file) =>{
    setProgressValueEnabled(true);
    const formdata = new FormData();
    formdata.append('date',  format(new Date(documentDate.getFullYear(), documentDate.getMonth(), 1), 'yyyy-MM-dd'));
    formdata.append('moduleID', moduleIndex);
    formdata.append('companyID', companyIndex);
    formdata.append('documentDate',  format(documentDate, 'yyyy-MM-dd'));
    formdata.append('standardRate', standardRate)
    formdata.append('deleteIfExists', !willReplaceFile);
    formdata.append('', file);
    const res = await uploadExcel(formdata)
    dispatch(setProgressbarFlag({
      progressFlag:false,
      progressPercentValue:0,
    }))
    setProgressValueEnabled(false);
    setProgressValue(10);
    if (res.res.status === 200){
      if( res.res?.data?.message && res.res?.data?.statusCode === 409){
        setIsErrorStatus(true)
        setWillReplaceFile(true)
        setErrorMessage( res.res?.data?.message)
        setValidate(true);
      } 
      setValidate(true)
      setUuid(res.res?.data.data)
      setIsFilePicked(true)
      
    }
    else{
      setIsErrorStatus(false);
      setWillReplaceFile(false)
      setValidate(false)
      if (Array.isArray(res.res)){
        setIsArrServerStatus(true)
        setServerErrorArr(res.res)
      }
      else{
        setIsServerStatus(true);
        setServerError(res.res)
      }
    }
  }

  const filePickHandler = async (e) =>{
    e.preventDefault();
    if(moduleException != null && moduleException.toLowerCase() === 'exception 2'){
      if(e.target.files[ 0 ].name.split('.').pop() === 'xlsx' || e.target.files[ 0 ].name.split('.').pop() === 'xls'){
        setSelectedFile(e.target.files[ 0 ]);
        setIsFilePicked(true)
        // uploadFile( e.target.files[ 0 ] );
      }
      else{
        dispatch(setError({  errorStatus: true, errorMessage: 'Please upload a Excel file!' }))
      }
    }else{
      if(e.target.files[ 0 ].type === 'text/csv'){
        setSelectedFile(e.target.files[ 0 ]);
        setIsFilePicked(true)
        // uploadFile( e.target.files[ 0 ] );
      }
      else{
        dispatch(setError({  errorStatus: true, errorMessage: 'Please upload a CSV file!' }))
      }
    }
  
  }
  const onDoneClicked = () =>{
    setIsErrorStatus(false)
    setValidate(false)
    setWillReplaceFile(false);
    setServerErrorArr([])
    setIsArrServerStatus(false)
    setIsServerStatus(false)
    fileInputRef.current.click()
  }

  const onChangeModule = (e) =>{
    const selectedIndex = e.target.options.selectedIndex;
    const moduleKey = e.target.options[ selectedIndex ].getAttribute('datakey');
    setModuleIndex(moduleKey)
    setDefault()
  }

  const onChangeCompany = (e) =>{
    const selectedIndex = e.target.options.selectedIndex;
    const companyKey = e.target.options[ selectedIndex ].getAttribute('datakey');
    setCompanyIndex(Number(companyKey))
    setDefault()
  }

  const fetchModule = async ()=>{
    dispatch(setLoadingFlag(true));
    const moduleResponse = await getModuleDetailsToDisplay();
    const moduleSuccess = moduleResponse.res
    const filteredModule = moduleSuccess?.filter((item) => item.moduleName !== TABS.adtAlarmMonitoring);
    if (moduleSuccess){
      dispatch(setLoadingFlag(false))
      setModuleIndex(moduleSuccess[ 0 ].id);
      setModuleArr(filteredModule);
    }

  }
  useEffect(() => {
    moduleArr.forEach((val) => {
      if(val.id === Number(moduleIndex)){
        setModuleName(val.moduleName )
        setModuleException(val.exception);
      }
    })
  },[ moduleIndex ])

  useEffect(() => {
    if (isFilePicked){
      setFileName(selectedFile.name)
    }
  }, [ isFilePicked, selectedFile ])

  useEffect(() => {
    setShow(props.show);
  }, [ props.show ]);

  useEffect(()=>{
    const filteredCompanyData = companyModule.filter(companyModuleData=> Number(moduleIndex) === companyModuleData.moduleId);
    if (filteredCompanyData.length){
      setCompanyIndex(Number(filteredCompanyData[ 0 ].cd[ 0 ].id))
      setCompanyArr(filteredCompanyData[ 0 ].cd);
    }
    else{
      setCompanyArr([]);
      setCompanyIndex(0);
    }

  },[ companyModule, moduleIndex ])
  
  useEffect(() => {
    fetchModule();
    dispatch(getCompanyModuleMapping());
    dispatch(getAllSettings());
  }, [])

  useEffect(() =>{
    if(settingData){
      setStandardRate(0);
      settingData.forEach(element => {
        const newEffectiveDate = new Date(element.effectiveFrom);
        const newEffectiveToDate = new Date(element.effectiveTo);
        const newDocumentDate = new Date(documentDate);
        if(newEffectiveDate <= newDocumentDate && newEffectiveToDate >= newDocumentDate && (element.vatName)?.toLowerCase() === 'standard rate' ){
          setStandardRate(element.vatRate)
          
        }
      });
    }
  },[ settingData, documentDate ])

  // Render components
  const fileDetailsBlock = () =>{
    return (
        <div className={styles.fileDetailsBox}>
            <div className={styles.iconContainer}>
                <img src={fileIcon} alt= {ENWORDS.fileIcon} />
            </div>
            <div className={styles.fileInfoContainer}>
                <div className={styles.topBox}>
                    <p>{ENWORDS.file}</p>
                </div>
                <div className={styles.bottomBox}>
                    <p>{fileName}</p>
                    <button onClick={onDoneClicked}>{ENWORDS.change}</button>
                </div>
            </div>
        </div> 
    )
  }

  const errorBlock = () =>{
    return (

        <div className={styles.invoiceStatusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <img src={warning} alt={ENWORDS.warningIcon}  className = {styles.statusIcon} />
                    <p className={styles.invalidText}>
                        {erroMessage}
                    </p>
                </div>
            </div>
            <div className={styles.checkContainer}>
                <input type="checkbox" checked= {!willReplaceFile} onChange={(e)=> setWillReplaceFile(!willReplaceFile)} />
                <p className={styles.invalidText}>
                    {ENWORDS.invoiceCheckboxMsg}
                </p>
            </div>
        </div>
    )
  }

  const serverErrorArrBlock = () =>{

    return (
        <div className={styles.statusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <img src={warning} alt={ENWORDS.warningIcon}  className = {styles.statusIcon} />
                    <p className={styles.invalidCount}>
                        {serverErrorArr.length}
                    </p>
                    <p className={styles.invalidText}>
                        {ENWORDS.invalidEntries}
                    </p>
                </div>
                <div className={styles.hideValidationContainer}>
                    <button onClick={ ()=> setIsHiddenValidation(!isHiddenValidation)} >
                  
                        <img src={isHiddenValidation ?  arrowUpFilled: arrowDownFilled} alt= {ENWORDS.arrow} />
                    </button>
                </div>
            </div>
            <div className={styles.bottomErrorContainer}>
                {
                  isHiddenValidation  ?
                      <div >

                          <div className={styles.errorHeader}>
                              <div className={styles.erroCol1}>
                                  <p>{ENWORDS.fieldName}</p>
                              </div>
                              <div className={styles.erroCol2}>
                                  <p>{ENWORDS.errormsg}</p>
                              </div>
                          </div>
            
                          {
                            serverErrorArr &&  serverErrorArr.map((item)=>{
                              return (

                                  <div className={styles.errorHeader}>
                                      <div className={styles.errorBodyCol1}>
                                          <p>
                                              {item.fieldName}
                                          </p>
                                      </div>
                                      <div className={styles.errorBodyCol2}>
                                          <p>
                                              {item.issue}
                                          </p>
                                      </div>
                                  </div>
                              )
                    
                            })
                          }
                      </div>

                    : <div></div>
                }

            </div>
        </div>
    )

  }

  const serverErrorBlock = () =>{

    return (
        <div className={styles.statusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <p className={styles.invalidText}>
                        {serverError}
                    </p>
                </div>
            </div>
               
        </div>
    )

  }
  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={globalPopUpStyles.overlay}
      >
          <div className={styles.popUpContainer}>   

              <div className={globalPopUpStyles.topSection}>
                  <p>{ENWORDS.bulkUpload}</p>
                  <button className={globalPopUpStyles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt={ENWORDS.cancelIcon}/>
                  </button>
              </div>
              {
                isFileUploaded ?
              
                    <div className={styles.successStatusValueContainer}>
                        <div>
                            <img src={successIcon} alt = {ENWORDS.done} />
                        </div>
                        <p className={styles.invalidText}>
                            {ENWORDS.fileUploaded}
                        </p>
                    </div>
                  :
                    <div>
                        <div className = {styles.invoiceDetailsContainer}>
                            <InvoiceDropdown
                              title={ENWORDS.moduleName}
                              name = 'module'
                              fieldValues={ moduleArr }
                              idKey = 'id'
                              renderKey = 'moduleName'
                              onChange={e=> onChangeModule(e)}
                            />
                            <InvoiceDropdown
                              title={ENWORDS.companyDetails}
                              name = 'company'
                              fieldValues={ companyArr ?? [] }
                              idKey = 'id'
                              renderKey = 'name'
                              onChange={e=> onChangeCompany(e)}
                            />
                               
                            <CustomDatePicker
                              title = {ENWORDS.documentDateTitle}
                              startDate = {documentDate}
                              dateFormat = {globalDateFormat}
                              onChange={(e) => setDocumentDate(e)}
                              wrapperClass = 'stationDate'
                              errorMessage= {standardRate ? '*If the VAT Rate & VAT Amount is not available in the upload file or not mapped in the module set up then the Standard Rate will be used by default for VAT Calculation'
                                : ERRWORDS.standardRateError}
                            />
                       
                        </div>  
                        {
                          isFilePicked ? 
                            fileDetailsBlock()
                            :null
                        }
                        {
                          isErrorStatus ?
                            errorBlock()
                            : null
                        }
                        {
                          isServerArrStatus ?
                            serverErrorArrBlock()
                            :null
                        }
                        {
                          isServerStatus?
                            serverErrorBlock():
                            null
                        }

                        <input
                          type="file"
                          accept={FILETYPE}
                          onChange={filePickHandler}
                          onClick={(event)=> { 
                            event.target.value = null
                          }}
                          ref={fileInputRef}
                          hidden
                        />
                        {
                          standardRate ? 
                              <div className={globalPopUpStyles.btnSection}>
                                  <button className={globalPopUpStyles.btnNo} onClick={closeHandler}>{ENWORDS.cancel}</button>
                                  {
                                    isFilePicked?(
                                        <button 
                                          className={ isServerStatus || isServerArrStatus? globalPopUpStyles.btnYesDisabled: validate ? [ globalPopUpStyles.btnYes, globalPopUpStyles.green ].join(' ') : globalPopUpStyles.btnYes } 
                                          onClick = {()=>{validate ? createInvoice() : uploadFile(selectedFile)}} disabled = { isServerStatus || isServerArrStatus}>{validate ? ENWORDS.Generate : ENWORDS.Validate}</button>
                                    ):(
                                        <button disabled={ moduleArr.length === 0 || companyArr.length === 0 } onClick={onDoneClicked}
                                          className={ moduleArr.length === 0 || companyArr.length === 0 ? globalPopUpStyles.btnYesDisabled : globalPopUpStyles.btnYes} >
                                            { ENWORDS.upload } 
                                        </button>
                                    )
                                  }

                              </div> : null
                        }
                    </div>
              }
              
          </div>

      </div>
  )
}

InvoiceFileUploadPopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default InvoiceFileUploadPopUp