import { setLoadingFlag } from '../../redux/actions';
import { setConfigurationTable } from '../../redux/actions/penaltyConfiguration';
import { API_HEADER, GET, POST } from '../../shared/constants/appConstants';
import { CONFIGURATIONURL, SETTINGSURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';

export function getPenaltyConfiguration() {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: CONFIGURATIONURL.getPenaltyConfiguration,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setConfigurationTable(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function getPenaltyConfigurationById(penaltyConfigId) {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ CONFIGURATIONURL.getPenaltyConfigurationById + penaltyConfigId }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data;
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function addPenaltyConfiguration(data) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: CONFIGURATIONURL.addPenaltyConfiguration ,
    data: data ,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function getVatCodesToDisplay(){
  let res = [];
  let errRes = {};
  await makeRequest({
    method: GET,
    url: SETTINGSURL.getVatDetails,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      res  = [];
      errRes = err
    });
  return { res:res, err:errRes };
}
