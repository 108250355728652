import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import ENWORDS from '../../../shared/local/en.json';
import { COUNTERSHEADERS, COUNTERSSERACH, COUNTER_FILE_PREFIX } from '../adtMonitoringConstant';
import uploadBtnStyles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import CustomSearchBox from '../../../shared/Components/CustomSearchBox';
import style from '../../../styles/adtConfiguration/ConfigurationScreen.module.css';
import SideNavigation from '../../../shared/Components/SideNavigation';
import { TABS } from '../../../shared/constants/appConstants';
import CustomTable from '../../../shared/Components/CustomTable';
import { getDailyLogEmail, getDistinctYears } from '../../../api/services/adtDailyLogService';
import { setErrorFlag, setLoadingFlag } from '../../../redux/actions';
import { getCountersData, sendCountersLogOnMail } from '../../../api/services/adtCounterService';
import DownloadCSVBtn from '../../../shared/Components/DownloadCSVBtn';
import mailIcon from '../../../assets/icons/mailFilledIconWhite.svg'
import CustomLogMailPopup from '../../../shared/Components/CustomLogMailPopup';
import SuccessPopUp from '../../../shared/Components/SuccessPopUp';
const SCREENS  = require('../../../shared/local/screens.json');

const CountersScreen = () => {
  const countersData = useSelector(
    state => state.countersReducer.countersTable
  );
  const roleLevelAccess = useSelector(
    state => state.roleManagement.roleLevelAccess
  );
  const [ filterData, setFilterData ] = useState([]);
  const [ roleAccess, setRoleAccess ] = useState();
  const [ years, setYears ] = useState([]);
  const [ selectedYear, setSelectedYear ] = useState();
  const [ searchText, setSearchText ] = useState('');
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showEmailPopup, setShowEmailPopup ] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    setFilterData(countersData ?? []);
  }, [ countersData ]);

  const checkRoleAccess = () => {
    roleLevelAccess.forEach(item => {
      if (item.screenName === SCREENS.adtCounters) {
        if (!item.writeAccess) {
          delete COUNTERSHEADERS[ 'counterActions' ];
        }
        setRoleAccess(item);
      }
    });
  };

  const filteration = () => {
    let countersFilterData = countersData;
    if (searchText.length > 0) {
      countersFilterData = countersFilterData.filter(item => {
        const site = item[ COUNTERSSERACH.siteId ]?.toLowerCase();
        const siteName = item[ COUNTERSSERACH.siteName ]?.toLowerCase();
        const contractNumber =
          item[ COUNTERSSERACH.contractNumber ]?.toLowerCase();
        const area = item[ COUNTERSSERACH.areaName ]?.toLowerCase();
        const region = item[ COUNTERSSERACH.regionName ]?.toLowerCase();
        const status = item[ COUNTERSSERACH.status ]?.toLowerCase();
        return (
          site?.includes(searchText.toLowerCase()) ||
          siteName?.includes(searchText.toLowerCase()) ||
          contractNumber?.includes(searchText.toLowerCase()) ||
          area?.includes(searchText.toLowerCase()) ||
          region?.includes(searchText.toLowerCase()) ||
          status?.includes(searchText.toLowerCase())
        );
      });
    }

    return countersFilterData;
  };

  const sendEmail = () => {
    setShowEmailPopup(true);
  }

  const onSendMailClick = async (data) => {
    const res = await sendCountersLogOnMail(selectedYear, data);
    dispatch(setLoadingFlag(false));

    if (res.res) {
      setShowSuccess(true);
    } else {
      dispatch(setErrorFlag(true));
    }
  };

  useEffect(() => {
    setFilterData(filteration());
  }, [ searchText ]);

  useEffect(() => {
    checkRoleAccess();
  }, [ roleLevelAccess ]);

  useEffect(() => {
    getDistinctYears().then(res=>{
      setYears(res.res)
      setSelectedYear(res.res[ 0 ])
    })
  }, []);

  useEffect(() => {
    //Fetch Counter Data
    if(selectedYear){
      dispatch(setLoadingFlag(true))
      dispatch(getCountersData(selectedYear))
    }
  }, [ selectedYear ]);

  return (
      <div className={styles.mainContainer}>
          <div className={styles.leftContainer}>
              <SideNavigation parentName={TABS.adtAlarmMonitoring}/>
          </div>

          <div className={styles.rightContainer}> 
              <div className={styles.siteHeader}>
                  <div className={style.stationHeaderContainer}> 
                      <div className={style.filterContainer}>
                          <div className={styles.searchBoxContainer}>
                              <CustomSearchBox
                                placeholder={ENWORDS.stationPlaceholder}
                                title={ENWORDS.search}
                                onChange={e => setSearchText(e.target.value.trimStart())}
                              />
                          </div>

                          <CustomDropDown
                            title={ENWORDS.year}
                            name={ENWORDS.year.toLowerCase()}
                            fieldValues={years}
                            selectedValue={selectedYear}
                            type={ENWORDS.custom}
                            onChange={e => setSelectedYear(e.target.value)}
                          />

                      </div>
                      <SuccessPopUp
                        title = {ENWORDS.commonMailSuccess}
                        show = {showSuccess}
                        onClose={()=>{ 
                          setShowSuccess(false)                  
                          dispatch(setLoadingFlag(true));
                          dispatch(getDailyLogEmail());
                        }}
                      />
                      < CustomLogMailPopup
                        title = {ENWORDS.counterMailPopUpTxt}
                        show={showEmailPopup}
                        onClose={setShowEmailPopup}              
                        setShowSuccess = {showSuccess}           
                        onSendClick = {onSendMailClick}
                      />
                      <div className={styles.btnContainer}>
                          <div
                            className={uploadBtnStyles.mainBtnContainer}
                            title={ENWORDS.exportCountersTooltip}
                          >
                              <DownloadCSVBtn 
                                tableData={filterData}
                                popUpText={ENWORDS.downloadCounterCSVText}
                                popUpTitle = {ENWORDS.downloadCounterCSVTitle}
                                toolTitle = {ENWORDS.downloadCounterCSVTitle}
                                headers={COUNTERSHEADERS}
                                fileNamePrefix={COUNTER_FILE_PREFIX}
                              />
                          </div>
                          {roleAccess && roleAccess.writeAccess ?
                              <div className={uploadBtnStyles.mainBtnContainer}>
                                  <button
                                    className={uploadBtnStyles.featureButton}  
                                    title={ENWORDS.sendEmailTitle}
                                    onClick = {() => sendEmail()}
                                  >
                                      <div className={uploadBtnStyles.btnContainer}>
                                          <img src={mailIcon} alt = {ENWORDS.mail} />
                                      </div>
                                  </button>
                              </div>
                            :null
                          }
                      </div>
                  </div>
              </div>
              <CustomTable 
                data = {filterData} 
                headerData = {COUNTERSHEADERS} 
                tableName = {ENWORDS.countersTable}
                statusStyle = {ENWORDS.counterStatus}       
              />
          </div>
      </div>
  );
};

export default CountersScreen;
