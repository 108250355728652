import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import { useSelector } from 'react-redux';
import SettingBlock from '../SettingHome/SettingBlock';
import globalStyle from  '../../../../styles/global.module.css'
import editIcon from '../../../../assets/icons/editPenWhiteIcon.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../shared/constants/appRoute';
import { getAllSettings } from '../../../../api/services/settingsService';
import { setLoadingFlag } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import BaseConfigurationTable from '../../BaseConfigurationTable';
import { VATHEADER } from '../../baseConfigurationConstant';
import { TABS } from '../../../../shared/constants/appConstants';
import SideNavigation from '../../../../shared/Components/SideNavigation';
import useAccess from '../../../../utility/appHooks/useAccess';
const ENWORDS = require('../../../../shared/local/en.json')
const SCREENS  = require('../../../../shared/local/screens.json');

const ViewSettingScreen = () => {

  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const settingData = useSelector(state => state.setting.settingsData);
  const [ setting, setSetting ] = useState(settingData);
  const hasWriteAccess = useAccess(SCREENS.vatSettings)

  const navigate = useNavigate();
  const dispatch  = useDispatch();
  
  const navigateToEdit = () =>{
    navigate(ROUTES.EDITSETTINGS, { state:{ setting: settingData } })
  }

  const getSettingData=  () =>{
    dispatch(setLoadingFlag(true));
    dispatch(getAllSettings());
  }

  useEffect(() => {
    getSettingData();
  }, [])

  useEffect(() =>{
    setSetting(settingData);
  },[ settingData ])
  
  return (
      <div className={styles.mainContainer}>

          <div className = {styles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   />
              {/* <SideNavigation parentName={TABS.baseConfiguration}   /> */}
          </div>
              
          <div className={styles.rightContainer}>
              <div className={styles.siteHeader}>
                  {hasWriteAccess &&
                  <div className = { globalStyle.filterContainerTwo}>
                      <div className={globalStyle.addContainer}>
                          <button onClick={navigateToEdit}>
                              <img src={editIcon} alt={ENWORDS.plusIcon} />
                          </button>
                      </div>
                  </div>
                  }
              </div>
              {
                setting &&   <BaseConfigurationTable 
                  tableData = {setting} 
                  headerData = {VATHEADER}  
                />
              }
          </div>
      </div>
  )
}

export default ViewSettingScreen;