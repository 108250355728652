import React, { useState } from 'react'
import globalStyles from '../../styles/global.module.css';
import styles from '../../styles/inputs.module.css'
import eyeClosed  from '../../assets/icons/passwordEyeClosed.png';
import eyeOpen  from '../../assets/icons/passwordEyeOpen.png';
import { useEffect } from 'react';
const ENWORDS = require('../local/en.json')

const CustomInput = ({ 
  title, value, defValue, 
  onChange,onBlur,
  readOnly, error, 
  type = 'text', fieldType = 'input', 
  placeholder = '', disabled,
  isFocused, maxLength = 100, myRef, isCapital = false, maintainDefaultCase = false , wrapperClass }) => {
  const [ isPasswordVisibile, setPasswordText ]  = useState(true);
  const [ fieldValue, setFieldValue ] = useState(value);
  const togglePassword = () => {
    setPasswordText(!isPasswordVisibile)
  } 

  const changeInputField = (e) =>{
    setFieldValue(e.target.value);
    if(onChange && !maintainDefaultCase){
      onChange((e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)).trimStart());
    }
    else{
      onChange((e.target.value).trim());
    }
  }
  useEffect(() => {
    setFieldValue(value)
  },[ value ])
  
  return (
      <div className={globalStyles.inputContainer} ref = {myRef}>
          {title && 
          <p className={ readOnly ? globalStyles.readOnlyText : globalStyles.filterText}>{title}</p>
          }
          { fieldType === 'password' ? 
              <div className={error ? styles.mandatoryField : styles.inputBox}>
                  <input 
                    type= {isPasswordVisibile? 'password': type}  
                    value={value} onChange={onChange} 
                    onBlur = {onBlur} readOnly = {readOnly}
                    autoComplete="off"
                    placeholder = {placeholder}
                    disabled={disabled}
                  />

                  <button onClick={togglePassword} >
                      <img src={ isPasswordVisibile? eyeOpen: eyeClosed } alt={ENWORDS.passwordIcon} className={styles.passwordIcon} />
                  </button>
              </div>
            :           
              <div className={error ? styles.mandatoryField : wrapperClass === 'customInput' ? '': styles.inputBox}>
                  <input
                    className= {isCapital ? styles.capitalText : wrapperClass === 'customInput' ? styles.editInputField : '' } 
                    id= {readOnly ? styles.readOnlyInput : title} 
                    type= {type}  
                    autoFocus = {isFocused}
                    value={fieldValue}
                    maxLength={maxLength}
                    onChange={(e)=>changeInputField(e)?.trim()} onBlur = {onBlur} 
                    autoComplete="off"
                    disabled={disabled}
                    placeholder = {placeholder}
                    readOnly = {readOnly}/>
              </div> }
          {<p className={error ? globalStyles.errorMessageShow: globalStyles.errorMessage}>{error}</p> }
          <style>
              {`
              
              ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba(70, 62, 62, 0.267);
                font-size: 0.8rem
                opacity: 1; /* Firefox */
              }
              
              :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba(70, 62, 62, 0.267);
              }
              
              ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba(70, 62, 62, 0.267);
              }
              `
              }
          </style>
      </div>

  )
}

export default CustomInput
