import { setLoadingFlag } from '../../redux/actions';
import { setSettingData } from '../../redux/actions/setting';
import { API_HEADER, GET, PUT } from '../../shared/constants/appConstants';
import { SETTINGSURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';

export  function getAllSettings() {
  return async (dispatch) =>{
    await makeRequest({
      method: GET,
      url: SETTINGSURL.getAllSettings,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setSettingData(response.data.data))
        dispatch(setLoadingFlag(false))
      })
      .catch(function (err) {
        dispatch(setLoadingFlag(false))
      });
  }
}

export async function updateSetting(data){
  let res;
  await makeRequest({
    method:PUT,
    url:SETTINGSURL.editSettings,
    data:data,
    headers:API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function(err){
      res = false
    });
  return res;
}