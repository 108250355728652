import {
  MODULE_CONFIGURATION_DATA, MODULES_PAGE_NUMBER
} from '../../shared/constants/actionType';
    
const INIT_STATE = {
  moduleConfigurationData: [],
  pageNumber: 0,
};
    
const moduleConfigurationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case MODULE_CONFIGURATION_DATA: {
    return {
      ...state,
      moduleConfigurationData: action.payload,
    };
  }
  case MODULES_PAGE_NUMBER:
    return{
      ...state,
      pageNumber: action.payload,
    }
  default:
    return state;
  }
};
export default moduleConfigurationReducer;