import { SET_AREA_DATA, AREA_FILTER, AREA_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setAreasTable = (data)=>{
  return{
    type: SET_AREA_DATA,
    payload: data,
  }
}

export const updateAreaFilter = (data)=>{
  return{
    type: AREA_FILTER,
    payload: data,
  }
}

export const updateAreaPageNumber = (data)=>{
  return{
    type: AREA_PAGE_NUMBER,
    payload: data,
  }
}