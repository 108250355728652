import { SET_ROLE_TABLE, SET_USER_LEVEL_ACCESS, ROLE_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setRoleTable = (data) => {
  
  return {
    type:SET_ROLE_TABLE,
    payload:data,
  }
}

export const setUserLevelAccess = (data) =>{
  
  return {
    type: SET_USER_LEVEL_ACCESS,
    payload: data,
  }
}

export const updateRolePageNumber = (data) => {
  return {
    type:ROLE_PAGE_NUMBER,
    payload:data,
  }
}