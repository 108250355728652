import React, { createContext } from 'react';
import { useProvideAuth } from '../utility/appHooks/useAuth';

export const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export const useAuth = () => {
  return React.useContext(authContext);
}