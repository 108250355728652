import { SET_REGIONS_DATA, REGIONS_FILTER, REGIONS_PAGE_NUMBER } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  regionsTableData: [],
  regionStatusFilter: '',
  pageNumber: 0,
};

const regionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_REGIONS_DATA:
    return {
      ...state,
      regionsTableData: action.payload,
    };
  case REGIONS_FILTER:
    return {
      ...state,
      regionStatusFilter: action.payload,
    };
  case REGIONS_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.payload,
    };
  default:
    return state;
  }
};

export default regionsReducer;
