import React, { useContext } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../modules/auth/Login';
import ProtectedRouter from './ProtectedRouter';
import { AUTHROUTES, ROUTES } from '../shared/constants/appRoute';
import SupplierManagementScreen from '../modules/BaseConfiguration/SupplierManagement/SupplierManagementScreen';
import AddNewSupplier from '../modules/BaseConfiguration/SupplierManagement/AddNewSupplier';
import EditSupplier from '../modules/BaseConfiguration/SupplierManagement/EditSupplier';
import SupplierDetails from '../modules/BaseConfiguration/SupplierManagement/SupplierDetails';
import RoleManagement from '../modules/BaseConfiguration/RoleManagement';
import AddRole from '../modules/BaseConfiguration/RoleManagement/AddNewRole';
import EditRole from '../modules/BaseConfiguration/RoleManagement/EditRole';
import RoleDetails from '../modules/BaseConfiguration/RoleManagement/RoleDetails';
import CompanyDetails from '../modules/BaseConfiguration/CompanyDetails/CompanyDetails';
import EditCompany from '../modules/BaseConfiguration/CompanyDetails/EditDetails';
import OTPScreen from '../modules/auth/Login/OTPScreen';
import ChangePassword from '../modules/auth/ForgotPassword/ChangePassword';
import ForgotPassword from '../modules/auth/ForgotPassword';
import AuthRouter from './AuthRouter';
import ModuleConfiguration from '../modules/ModuleConfiguration';
import AuthHoc from '../modules/AuthHoc';
import UserManagement from '../modules/UserManagement';
import AddUser from '../modules/UserManagement/AddUser';
import EditUser from '../modules/UserManagement/EditUser';
import UserDetails from '../modules/UserManagement/UserDetails';
import AddModuleConfiguration from '../modules/ModuleConfiguration/AddModuleConfiguration';
import EditModuleConfiguration from '../modules/ModuleConfiguration/EditModuleConfiguration';
import ViewModuleConfiguration from '../modules/ModuleConfiguration/ViewModuleConfiguration';
import InvoiceListing from '../modules/InvoiceListing/InvoiceList';
import ViewInvoice from '../modules/InvoiceListing/InvoiceDetails';
import AddCompany from '../modules/BaseConfiguration/CompanyDetails/AddNewCompany';
import Loader from '../shared/Components/Loader';
import ErrorPopUp from '../shared/Components/ErrorPopUp';
import LogoutPopUp from '../shared/Components/LogoutPopUp';
import CompanyManagement from '../modules/BaseConfiguration/CompanyDetails';
import { authContext } from './AuthProvoider';
import ViewSetting from '../modules/BaseConfiguration/Settings';
import EditSetting from '../modules/BaseConfiguration/Settings/EditSettings';
import ProgressBar from '../shared/Components/ProgressBar';
import  ENWORDS  from '../shared/local/en.json';
import AddStation from '../modules/StationListing/Stations/AddStation';
import EditStation from '../modules/StationListing/Stations/EditStation';
import StationDetails from '../modules/StationListing/Stations/StationDetails';
import StationListing from '../modules/StationListing/Stations/StationList';
import AreaListing from '../modules/StationListing/Areas/AreaListing';
import RegionsListing from '../modules/StationListing/Regions/RegionListing';
import RegionDetails from '../modules/StationListing/Regions/RegionDetails';
import OpsRegionsListing from '../modules/StationListing/OpsRegions/OpsRegionListing';
import OpsRegionDetails from '../modules/StationListing/OpsRegions/OpsRegionDetails';
import AreaDetails from '../modules/StationListing/Areas/AreaDetails';
import EmailLogReportScreen from '../modules/EmailLogReport';
import ADTCounters from '../modules/AdtAlarmMonitoring/Counters';
import DailyLogs from '../modules/AdtAlarmMonitoring/DailyLogs';
import SitesReports from '../modules/AdtAlarmMonitoring/SitesReports';
import Penalty from '../modules/AdtAlarmMonitoring/Penalities';
import Configuration from '../modules/AdtAlarmMonitoring/Configuration';
import DailyLogsDetails from '../modules/AdtAlarmMonitoring/DailyLogsDetails';

const WrapWithAuthRoute = ({ children }) => {

  return (
      <AuthRouter>
          {children}
      </AuthRouter>
  )
}
const WrapWithProtectedRoute = ({ children }) => {
  return (
      <ProtectedRouter>
          {children}
      </ProtectedRouter>
  )
}
/* the page which required auth should use ProtectedRouter */

function Routers(props) {
  const loadingFlag = useSelector(state => state.common.loadingFlag)
  const errorStatus = useSelector(state => state.common.loadError.errorStatus )
  const isLoggedOut = useSelector(state => state.common.isLoggedOut )
  const progressBarFlag = useSelector(state => state.common.progressBarFlag );
  const progressPercent = useSelector(state => state.common.progressPercent );
  const progressBarMsg = useSelector(state => state.common.progressBarMsg);
  const auth= useContext(authContext); 
  return (
      <Router {...props} >
          {
            loadingFlag  || !auth ?
                <Loader />
              : null
          }
          {
            progressBarFlag?
                <ProgressBar
                  completed = {progressPercent}
                  bgcolor = {'#ffffff'}
                  msg = {progressBarMsg}
                />
              : null
          }
          { 
            errorStatus ? 
                <ErrorPopUp/>
              :null
          }
          { 
            isLoggedOut ? 
                <LogoutPopUp/>
              :null
          }

          <Routes>
              <Route path='/' exact
                element={<WrapWithAuthRoute children={<Login />} auth = {auth.user}  />}  /> 
              <Route path={AUTHROUTES.OTPSCREEN} 
                element={<WrapWithAuthRoute children={<OTPScreen />} />} />
              <Route path={AUTHROUTES.CHANGEPASSWORDSCREEN} 
                element={<WrapWithAuthRoute children={<ChangePassword />} />} />
              <Route path={AUTHROUTES.FORGOTPASSWORDSCREEN} 
                element={<WrapWithAuthRoute children={<ForgotPassword />} />} />

              <Route path="/" element={ <WrapWithProtectedRoute children={<AuthHoc />}  /> }>
                  {/* Dashboard routes */}

                  <Route
                    path={ROUTES.USERMANAGEMENTSCREEN}
                    element={<WrapWithProtectedRoute children={<UserManagement />} />} />

                  {/* User management routes */}

                  <Route
                    path={ROUTES.ADDUSERSCREEN}
                    element={<WrapWithProtectedRoute children={<AddUser />} />} />
                  <Route
                    path={ROUTES.EDITUSERSCREEN}
                    element={<WrapWithProtectedRoute children={<EditUser />} />} />

                  <Route
                    path={ROUTES.USERDETAILSSCREEN}
                    element={<WrapWithProtectedRoute children={<UserDetails />} />} />

                  {/* Module configuration routes */}

                  <Route
                    path={ROUTES.MODULECONFIGURATION}
                    element={<WrapWithProtectedRoute children={<ModuleConfiguration />} />} />

                  <Route
                    path={ROUTES.ADDMODULECONFIGURATION}
                    element={<WrapWithProtectedRoute children={<AddModuleConfiguration />} />} />

                  <Route
                    path={ROUTES.EDITMODULECONFIGURATION}
                    element={<WrapWithProtectedRoute children={<EditModuleConfiguration />} />} />

                  <Route
                    path={ROUTES.VIEWMODULECONFIGURATION}
                    element={<WrapWithProtectedRoute children={<ViewModuleConfiguration />} />} />

                  {/* Base configuration */}
                  {/* Site management */}

                  <Route
                    path={ROUTES.SUPPLIERMANAGEMENT}
                    element={<WrapWithProtectedRoute children={<SupplierManagementScreen />} />} />

                  <Route
                    path={ROUTES.ADDNEWSUPPLIER}
                    element={<WrapWithProtectedRoute children={<AddNewSupplier />} />} />

                  <Route
                    path={ROUTES.EDITSUPPLIER}
                    element={<WrapWithProtectedRoute children={<EditSupplier />} />} />

                  <Route
                    path={ROUTES.SUPPLIERDETAILS}
                    element={<WrapWithProtectedRoute children={<SupplierDetails />} />} />

                  {/* Role management */}

                  <Route
                    path={ROUTES.ROLEMANAGEMENT}
                    element={<WrapWithProtectedRoute children={<RoleManagement />} />} />

                  <Route
                    path={ROUTES.ADDROLE}
                    element={<WrapWithProtectedRoute children={<AddRole />} />} />

                  <Route
                    path={ROUTES.EDITROLE}
                    element={<WrapWithProtectedRoute children={<EditRole />} />} />

                  <Route
                    path={ROUTES.ROLEDETAILS}
                    element={<WrapWithProtectedRoute children={<RoleDetails />} />} />

                  {/* Company details */}

                  <Route
                    path={ROUTES.COMPANY}
                    element={<WrapWithProtectedRoute children={<CompanyManagement />} />} />
                  <Route
                    path={ROUTES.ADDCOMPANY}
                    element={<WrapWithProtectedRoute children={<AddCompany />} />} />

                  <Route
                    path={ROUTES.EDITCOMPANY}
                    element={<WrapWithProtectedRoute children={<EditCompany />} />} />
                  <Route
                    path = {ROUTES.COMPANYDETAILS}
                    element={<WrapWithProtectedRoute children={<CompanyDetails />} />} />
                  {/* Invoice listing */}

                  <Route
                    path={ROUTES.INVOICELISTING}
                    element={<WrapWithProtectedRoute children={<InvoiceListing />} />} />

                  <Route
                    path={ROUTES.INVOICEDETAILS}
                    element={<WrapWithProtectedRoute children={<ViewInvoice />} />} />
                    
                  {/* Station listing */}

                  <Route
                    path={ROUTES.STATIONLISTING}
                    element={<WrapWithProtectedRoute children={<StationListing />} />} />

                  <Route
                    path={ROUTES.ADDSTATION}
                    element={<WrapWithProtectedRoute children={<AddStation />} />} />

                  <Route
                    path={ROUTES.EDITSTATION}
                    element={<WrapWithProtectedRoute children={<EditStation />} />} />

                  <Route
                    path={ROUTES.STATIONDETAILS}
                    element={<WrapWithProtectedRoute children={<StationDetails />} />} />
                  
                  {/* Settings */}
                  <Route
                    path={ROUTES.BASESETTINGS}
                    element={<WrapWithProtectedRoute children={<ViewSetting />} />} />
                    
                  <Route
                    path={ROUTES.EDITSETTINGS}
                    element={<WrapWithProtectedRoute children={<EditSetting />} />} />

                  {/* ADT ALARM */}

                  <Route
                    path={ROUTES.ADTCOUNTERS}
                    element={
                        <WrapWithProtectedRoute children={<ADTCounters />} />
                    }
                  />

                  <Route
                    path={ROUTES.ADTDAILYLOGS}
                    element={
                        <WrapWithProtectedRoute children={<DailyLogs />} />
                    }
                  />

                  <Route
                    path={ROUTES.ADTDAILYLOGDETAILS}
                    element={
                        <WrapWithProtectedRoute children={<DailyLogsDetails />} />
                    }
                  />

                  <Route
                    path={ROUTES.ADTREPORTS}
                    element={
                        <WrapWithProtectedRoute children={<SitesReports />} />
                    }
                  />
                  <Route
                    path={ROUTES.ADTPENALTIES}
                    element={
                        <WrapWithProtectedRoute children={<Penalty />} />
                    }
                  />
                  <Route
                    path={ROUTES.ADTPENALTYCONFIGURATION}
                    element={
                        <WrapWithProtectedRoute children={<Configuration />} />
                    }
                  />

                  <Route
                    path={ROUTES.STATIONAREAS}
                    element={
                        <WrapWithProtectedRoute children={<AreaListing />} />
                    }
                  />

                  <Route
                    path={ROUTES.STATIONREGIONS}
                    element={
                        <WrapWithProtectedRoute children={<RegionsListing />} />
                    }
                  />              

                  <Route
                    path={ROUTES.STATIONOPSREGIONS}
                    element={
                        <WrapWithProtectedRoute children={<OpsRegionsListing />} />
                    }
                  /> 

                  <Route
                    path={ROUTES.ADDREGION}
                    element={
                        <WrapWithProtectedRoute children={< RegionDetails mode ={ENWORDS.addMode}/>} />
                    }
                  />
                  <Route
                    path={ROUTES.ADDOPSREGION}
                    element={
                        <WrapWithProtectedRoute children={< OpsRegionDetails mode ={ENWORDS.addMode}/>} />
                    }
                  />

                  <Route
                    path={ROUTES.EDITREGIONS}
                    element={
                        <WrapWithProtectedRoute children={< RegionDetails mode ={ENWORDS.editMode}/>} />
                    }
                  />

                  <Route
                    path={ROUTES.EDITOPSREGIONS}
                    element={
                        <WrapWithProtectedRoute children={< OpsRegionDetails mode ={ENWORDS.editMode}/>} />
                    }
                  />

                  <Route
                    path={ROUTES.REGIONDETAILS}
                    element={
                        <WrapWithProtectedRoute children={< RegionDetails mode ={ENWORDS.viewMode}/>} />
                    }
                  />

                  <Route
                    path={ROUTES.OPSREGIONDETAILS}
                    element={
                        <WrapWithProtectedRoute children={< OpsRegionDetails mode ={ENWORDS.viewMode}/>} />
                    }
                  />
                  
                  <Route
                    path={ROUTES.ADDAREA}
                    element={
                        <WrapWithProtectedRoute children={< AreaDetails mode ={ENWORDS.addMode}/>} />
                    }
                  />

                  <Route
                    path={ROUTES.EDITAREA}
                    element={
                        <WrapWithProtectedRoute children={< AreaDetails mode ={ENWORDS.editMode}/>} />
                    }
                  />

                  <Route
                    path={ROUTES.AREADETAILS}
                    element={
                        <WrapWithProtectedRoute children={< AreaDetails mode ={ENWORDS.viewMode}/>} />
                    }
                  />
                  <Route
                    path={ROUTES.EMAILLOGREPORT}
                    element={
                        <WrapWithProtectedRoute children={<EmailLogReportScreen/>} />
                    }
                  />
              </Route>
          </Routes>
      </Router>
  );
}

export default Routers;
