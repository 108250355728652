import React,{ useEffect, useState } from 'react';
import styles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css'
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import { useNavigate } from 'react-router-dom';
import CustomSearchBox from '../../../../shared/Components/CustomSearchBox';
import { ROUTES }  from '../../../../shared/constants/appRoute';
import ENWORDS from '../../../../shared/local/en.json';
import globalStyle from '../../../../styles/global.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateSupplierPageNumber } from '../../../../redux/actions/supplierManagement'

const SupplierHeader = ({ setFilterData, roleAccess }) =>{
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const supplierData = useSelector ( state => state.supplierManagement.supplierTable);
  const [ filteredData, setFilteredData ] = useState(supplierData);
  const [ searchText, setSearchText ] = useState('');

  const addNewSupplier = () =>{
    navigate(ROUTES.ADDNEWSUPPLIER)
  }
  const filteration = () =>{
    let userFilterData = supplierData;

    if (searchText.length  >0){
      userFilterData = userFilterData.filter((item)=>{ 

        const fullName  = (item[ 'supplierName' ])?.toLowerCase()
        return fullName?.includes((searchText.trim()).toLowerCase())
      })
    }

    return userFilterData
  }
  useEffect(()=>{
    setFilterData(filteredData)
  },[ filteredData ])

  useEffect(()=>{
    setFilteredData(filteration())
  },[ searchText ])

  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateSupplierPageNumber(0))
  }
  return (
      <div className={styles.siteHeaderContainer}>
          <div className={styles.filterContainer}>
              <CustomSearchBox 
                placeholder = {ENWORDS.searchPlaceholder} 
                title = {ENWORDS.search}  
                onChange = {e => onChangeSearch(e)}/>  
              <div className = { globalStyle.filterContainerTwo}>
                  { 
                    roleAccess && roleAccess.writeAccess ? 
                        <div className={globalStyle.addContainer}>
                            <button onClick={addNewSupplier}>
                                <img src={plusIcon} alt={ENWORDS.plusIcon} />
                            </button>
                        </div>
                      :null}
              </div>
          </div>
      </div>
  )
}
export default SupplierHeader;