import React, {  useEffect, useState }  from 'react';
import { useDebounce } from 'use-debounce';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import { useNavigate } from 'react-router-dom';
import ENWORDS from '../../../../shared/local/en.json';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import roleStyles from '../../../../styles/baseconfiguration/RoleManagement.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import CustomInput from '../../../../shared/Components/CustomInput';
import  { ROUTES } from '../../../../shared/constants/appRoute';
import ERRORWORDS from '../../../../shared/local/error.json';
import {  NOT_ROLE_RANGE } from '../../../../shared/constants/appConstants';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import ModuleAssignmentTable from '../../../../shared/Components/ModuleAssignmentTable';
import { SCREENASSIGNMENT } from '../../../UserManagement/userManagementConstants';
import { useDispatch } from 'react-redux';
import { setLoadingFlag } from '../../../../redux/actions';
import { addRole, checkRoleName } from '../../../../api/services/roleManagementService';
import { commonScrollFunction } from '../../../../shared/commonFunctions';
import globalStyles from  '../../../../styles/global.module.css';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json')

const AddNewRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ roleName , setRoleName ] = useState('');
  const [ error, setError ] = useState({});
  const [ access, setAccess ] = useState();
  const [ isAssignmentError, setIsAssignmentError ] = useState(false);
  const [ isDone, setIsDone ] = useState(false);
  const [ isExtraPrivileged, setIsExtraPrivileged ] = useState(0)
  const [ value ] = useDebounce(roleName, 1000);
  const accessState = (receivedAccessValues) =>{
    setAccess(receivedAccessValues)
  } 
  const isValidated   = async () =>{
    dispatch(setLoadingFlag(true));
    
    const res = await addRole( roleName, access, isExtraPrivileged)
    
    if(res){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.ROLEMANAGEMENT)
    }
  }
  const checkValidation = () => {
    let errorVariable = error;

    if(NOT_ROLE_RANGE.test(roleName?.trim())){
      errorVariable = { ...errorVariable, roleNameError : ERRORWORDS.roleNameError }
    }
    
    const moduleList = access.filter((item)=>{
      return item.readAccess || item.writeAccess
    });

    setIsAssignmentError(moduleList.length === 0);

    const isErrorEmpty = Object.keys(errorVariable).filter((item)=>{
      return errorVariable[ item ] !== ''
    })

    if (isErrorEmpty.length === 0 && moduleList.length > 0){
      isValidated();
    }
    setError(errorVariable);
    setIsDone(!isDone);
  }

  const addedRole = () =>{
    navigate(ROUTES.ROLEMANAGEMENT)
  }
  
  const validateRoleName = async () =>{
    let errorVariable = error
    const roleNameValidation = await checkRoleName(-1, roleName);

    if(roleNameValidation){
      errorVariable = { ...errorVariable, roleNameError: ERRORWORDS.roleAlreadyTaken }
    }else{
      errorVariable = { ...errorVariable, roleNameError : '' }

    }
    setError(errorVariable)
  }

  useEffect(() =>{
    validateRoleName();
  },[ value ])

  useEffect(()=>{
    const inputError  =commonScrollFunction();
    if(!inputError){
      commonScrollFunction('h1','screenError');

    }
  }, [ isDone ])

  const onChangeIsExtraPrivilegedValue = (e) => {
    const isChecked = e.target.checked ? 1 : 0
    setIsExtraPrivileged(isChecked)
  }
  
  return(
      <div className={styles.mainContainer}>
          <div className={supplierStyles.subContainer}>
              <ListingHeader
                title = {ENWORDS.addNewRole}
                navigationPath = {-1}
              />            
              <div className={supplierStyles.contentContainer}>
                  <div className={supplierStyles.content}>
                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.roleDetails}</p>
                          </div>
                      </div>
                      <div className={roleStyles.siteForm}>
                          <div className={inputStyles.detailsRow}>
                              <CustomInput
                                title = {ENWORDS.roleName}
                                error = {error.roleNameError}
                                placeholder = {PLACEHOLDER.roleName}
                                onChange = {e => setRoleName(e)}/>  
 
                              <div className={styles.inputPreviliageContainer}>
                                  <input className={styles.checkboxInput} 
                                    type="checkbox" 
                                    id="isExtraPrivileged" 
                                    name="isExtraPrivileged" 
                                    value={isExtraPrivileged}
                                    onChange={onChangeIsExtraPrivilegedValue}/>
                                  <p className={globalStyles.readOnlyText}>{ENWORDS.isExtraPrivileged}</p>                          
                              </div>
                          </div>
                          
                      </div> 
                      <div className={inputStyles.mainModuleContainer}>
                          <p>{ENWORDS.screenAssignment}</p>
                          {
                            isAssignmentError ?
                                <h1 className={inputStyles.screenError}>{ERRORWORDS.screenAssignmentError}</h1>
                              : null
                          }

                          <ModuleAssignmentTable 
                            headerData={SCREENASSIGNMENT}  
                            onGetAccessDetails={accessState}  
                            canEdit = {true}  
                            isEmpty = {true}
                            screenType = {ENWORDS.assignScreen}
                          />
                      </div>
                      <div className={styles.btnContainer}>
                          <div className={styles.cancelBtn} onClick = {addedRole}>
                              <button>{ENWORDS.cancel}</button>
                          </div>
                          <div className={styles.doneBtn}>
                              <button onClick={checkValidation}>{ENWORDS.done}</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default AddNewRole;