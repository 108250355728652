import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../styles/invoiceVerificationPopup.module.css';
import PropTypes from 'prop-types';
import cancelIcon from '../../assets/icons/cancelIcon.svg';
import { setLoadingFlag } from '../../redux/actions';

const ENWORDS = require('../../shared/local/en.json');

const InvoiceVerificationPopUp = (props) => {
  const dispatch = useDispatch();
  const [ show, setShow ] = useState(false);

  const [ shouldDelete, setShouldDelete ] = useState(false);

  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [ props.show, props.selectedUserName, props.selectedName ]);

  const onDeleteHandler = () => {
    dispatch(setLoadingFlag(true));
    props.onDelete(props.selectedId);
    setShow(false);
    props.onClose(false);
  };

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={styles.overlay}
      >
          <div className={styles.popup}>
              <div className={styles.topSection}>
                  <p> {props.title}</p>
                  <button className={styles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt={ENWORDS.cancelIcon} />
                  </button>
              </div>
              <div className={styles.middleSection}>
                  <p>{props.text}</p>
              </div>
              {props.invoiceList.length > 0 ? (
                  <div>
                      <div className={styles.listingSection}>
                          {props.invoiceList.map((item, value) => {
                            return <p>{item.invoiceNumber}</p>;
                          })}
                      </div>

                      <div className={styles.checkContainer}>
                          <input
                            type="checkbox"
                            checked={!shouldDelete}
                            onChange={(e) => setShouldDelete(!shouldDelete)}
                          />
                          <p className={styles.invalidText}>{props.checkboxMsg}</p>
                      </div>
                  </div>
              ) : null}

              <div className={styles.btnSection}>
                  <button className={styles.btnNo} onClick={closeHandler}>
                      {ENWORDS.no}
                  </button>
                  <button
                    className={shouldDelete ? styles.btnYesDisabled : styles.btnYes}
                    onClick={onDeleteHandler}
                  >
                      {ENWORDS.yes}
                  </button>
              </div>
          </div>
      </div>
  );
};
InvoiceVerificationPopUp.propTypes = {
  selectedName: PropTypes.string,
  selectedId: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  screenName: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  selectedModuleID: PropTypes.number,
};
export default InvoiceVerificationPopUp;
