import { SET_STATION_TABLE, STATION_FILTER, STATION_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setStationTable = (data)=>{
  return{
    type: SET_STATION_TABLE,
    payload: data,
  }
}

export const updateStationStatusFilter = (data)=>{
  return{
    type: STATION_FILTER,
    payload: data,
  }
}

export const updateStationPageNumber = (data) => {
  return {
    type:STATION_PAGE_NUMBER,
    payload:data,
  }
}
