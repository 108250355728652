import React, { useState, useEffect, useRef } from 'react'
import globalPopUpStyles from '../../styles/popUp.module.css'
import styles from '../../styles/ImagePopUp.module.css'
import cancelIcon from '../../assets/icons/cancelIcon.svg';
import defaultImg from '../../assets/icons/defaultProfileImg.svg';
import PropTypes from 'prop-types';
import { IMAGETYPE } from '../constants/appConstants';
import { useDispatch } from 'react-redux';
import { setError } from '../../redux/actions';

const ENWORDS  = require('../../shared/local/en.json')
const ImageUploadPopUp = (props) => {
  const [ show, setShow ] = useState(false);
  const [ isImgUploaded, setIsImgUploaded ] = useState(false)
  const [ seletectImg, setSelectedImg ] = useState();
  const imageUploader = useRef(null);
  const dispatch = useDispatch();

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
    setIsImgUploaded(false);
  };

  useEffect(() => {
    setShow(props.show);
    imageUploader.current.value = null
  }, [ props.show ]);

  const handleImageUpload = async (e) => {
    if(e.target.files[ 0 ].size < 20000097152){
      const base64 =  await new  Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(e.target.files[ 0 ]);
        reader.onload = (i) => {
          const image = new Image();
          image.src = i.target.result;
          image.onload = () => {
            setSelectedImg(base64);
            setIsImgUploaded(true);

          };
        };
      });
    }else{
      dispatch(setError({  errorStatus: true, errorMessage: 'Image size Must be less than 2 MB' }))
    }
  };

  const sendImg = ()=>{
    props.onAddImg(seletectImg)
    closeHandler();
    setIsImgUploaded(false);
  }

  const removeImg = ()=>{
    setIsImgUploaded(false);
  }

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={globalPopUpStyles.overlay}
      >
          <div className={styles.popUpContainer}>              
              <div className={globalPopUpStyles.topSection}>
                  <p>{props.uploadType === ENWORDS.userScreen ? ENWORDS.profileImg : ENWORDS.companyLogo}</p>
                  <button className={globalPopUpStyles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt= {ENWORDS.cancelIcon} />
                  </button>
              </div>
              <div className={styles.imageContainer}>
                  <input
                    type="file"
                    accept={IMAGETYPE}
                    onChange={handleImageUpload}
                    ref={imageUploader}
                    onClick={ e=> {e.target.files = null}}
                    hidden
                  />
                  <div className={styles.uploadedImageContainer}>
                      <img src={seletectImg} alt= {ENWORDS.userProfileImg} className={ isImgUploaded ? styles.uploadedImage : styles.hideImg}/>
                  </div>
                     
                  <div className={isImgUploaded ? styles.hideImg : styles.defaultImgContainer}>
                      <img src={defaultImg} alt= {ENWORDS.userProfileImg} className={isImgUploaded ? styles.hideImg :  styles.defaultImg }/>
                  </div>   

              </div>

              {
                props.uploadType === 'edit' ? (
                    <div>
                        {
                          isImgUploaded? (
                              <div className={globalPopUpStyles.btnSection}>
                                  <button className={globalPopUpStyles.btnNo} onClick={closeHandler}>{ENWORDS.cancel}</button>
                                  <button className={globalPopUpStyles.btnYes}onClick ={sendImg}>{ENWORDS.done}</button>
                              </div>
                          ):  
                            (
                                <div className={globalPopUpStyles.btnSection}>
                                    <button className={globalPopUpStyles.btnRemove} onClick={removeImg}>{ENWORDS.removePicture}</button>
                                    <button className={globalPopUpStyles.btnAdd}  onClick={() => imageUploader.current.click()}>{ENWORDS.changePicture}</button>
                                </div>
                            )
                        }
                    </div>
                ):
                  (
                      <div>
                          {
                            isImgUploaded? (
                                <div className={globalPopUpStyles.btnSection}>
                                    <button className={globalPopUpStyles.btnNo} onClick={closeHandler}>{ENWORDS.cancel}</button>
                                    <button className={globalPopUpStyles.btnYes}onClick ={sendImg}>{ENWORDS.done}</button>
                                </div>
                            ):  
                              (
                                  <div className={globalPopUpStyles.btnSection}>
                                      <button className={globalPopUpStyles.btnYes} onClick={() => imageUploader.current.click()}>{ENWORDS.addPicture}</button>
                                  </div>
                              )
                          }
                      </div>
                  )
              }

          </div>

      </div>
  )
}

ImageUploadPopUp.propTypes = {
  uploadType: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddImg: PropTypes.func.isRequired,
};
export default ImageUploadPopUp