import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router';
import invoiceStyles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css'
import styles from '../../../styles/inputs.module.css'
import globalStyles from '../../../styles/global.module.css';
import '../../../styles/invoiceListing/inputDateCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ROUTES } from '../../../shared/constants/appRoute';
import { INVOICEHEADERS } from '../invoiceListingConstants';
import InvoiceDetailsTable from '../InvoiceDetailsTable';
import { isoStringToDate } from '../../../shared/commonFunctions';
import arrowDown from '../../../assets/icons/arrowBack.svg'
import InvoiceFileDownloadPopUp from '../../../shared/Components/InvoiceFileDownloadPopUp';
import { useNavigate } from 'react-router-dom'
import { setLoadingFlag } from '../../../redux/actions';
import { sendInvoices } from '../../../api/services/invoiceListingService';
import { useDispatch } from 'react-redux';
import SuccessPopUp, { DisplayPopUp } from '../../../shared/Components/SuccessPopUp';

const ENWORDS  =  require('../../../shared/local/en.json');

const ViewInvoiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch();
  const [ fileVisibility, setFileVisibility ] = useState(false);
  const [ visibility, setVisibility ] = useState(false);
  const [ showPdf, setShowPdf ] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ invoiceData, setInvoiceData ] = useState({});
  const [ transactionDetails, setTransactionsDetails ] = useState([]);
  const [ updatedSummary, setUpdateSummary ] = useState([]);
  const [ resStatus,setResValue ] = useState();
  const [ modifiedData, setModifiedData ] = useState([]);
  const [ pdfFileData, setPdfFileData ] = useState();
  const [ documentDate, setDocumentDate ] = useState();
  const goBack = () => {
    navigate(ROUTES.INVOICELISTING)
  }

  const showFilePopUp = () =>{
    setFileVisibility(true);
  }
  const showPopUp = () =>{
    setVisibility(true);
  }

  const filePopupCloseHandler = (e) => {
    setFileVisibility(e);
  };
    
  const onTableValueChange = (tableValue, structuredData) =>{
    
    setUpdateSummary(structuredData)
    setModifiedData(JSON.parse(JSON.stringify(tableValue)));
  }
  const sendInvoiceEmail = async () =>{
    dispatch(setLoadingFlag(true));

    const res = await sendInvoices([ locationState.invoiceNo ]);

    if (res.res){
      dispatch(setLoadingFlag(false))
      showPopUp();
      location.state.invoiceStatus = 'Completed'
    }
  }

  useEffect(() => {
    if (locationState){
      setInvoiceData(locationState?.invoiceValue);
      setResValue(locationState?.resValue);
      setTransactionsDetails(locationState?.transactionValue);
      setPdfFileData( locationState?.fileData );
      setDocumentDate(locationState?.documentDate)
   
    }
  }, [])
  
  useEffect(()=>{
    if ( modifiedData.length > 0 && transactionDetails.length > 0 && 
         modifiedData !== transactionDetails ){
      for (let index = 0; index < transactionDetails.length; index++){
        if (modifiedData[ index ].quantity !== transactionDetails[ index ].quantity 
          || modifiedData[ index ].unitPrice !== transactionDetails[ index ].unitPrice){
          setIsDisabled(false);
          break;
        }
        else{
          setIsDisabled(true);
        }
      }
    }
  },[ modifiedData ])

  const downloadPDF = () => {
    const linkSource = `data:application/pdf;base64,${ pdfFileData.file }`;
    const downloadLink = document.createElement('a');
    const fileName = pdfFileData.fileName;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
      <div className={invoiceStyles.viewMainConatiner}>
          <InvoiceFileDownloadPopUp
            onClose={filePopupCloseHandler}
            show={fileVisibility}
            data = {updatedSummary}
          />
          <SuccessPopUp
            title = {ENWORDS.mailSuccess}
            show = {visibility}
            onClose={setVisibility}
          />
          <DisplayPopUp
            title = {ENWORDS.mailSuccess}
            show = {showPdf}
            onClose={()=> setShowPdf(false)}
            component={
                <iframe src={`data:application/pdf;base64,${ locationState.fileData.file }#toolbar=0`} title = "some" style={{ height: '90vh', width:'90vw' }} download = {locationState.fileData.fileName}/>
            }
            onDownloadPDF = {downloadPDF}
          />
          <div className={invoiceStyles.subContainer}>
              <div className={styles.userDetailsTopContainer}>
                  <div className={styles.navigateContainer}>
                      <button onClick={goBack}>
                          <img src={arrowDown} alt = {ENWORDS.backArrow}  className={styles.backArrow}/>
                          <p>{ENWORDS.invoiceDetails}</p>
                      </button>
                  </div>
              </div>
              <div className={styles.mainDetailsContainer}>
                  <p />
                  <div className={styles.detailsContainer}>
                      <div className={invoiceStyles.row1}>
                          <div>
                              <p className={globalStyles.filterText}>{ENWORDS.siteId}</p>
                              <p className={invoiceStyles.displayText}>{invoiceData.siteID}</p>
                          </div>
                          <div>
                              <p className={globalStyles.filterText}>{ENWORDS.siteName}</p>
                              <p className={invoiceStyles.displayText}>{invoiceData.siteName}</p>
                          </div>
                          <div>
                              <p className={globalStyles.filterText}>{ENWORDS.moduleName}</p>
                              <p className={invoiceStyles.displayText}>{invoiceData.moduleName}</p>
                          </div>
                          <div>
                              <p className={globalStyles.filterText}>{ENWORDS.billingDate}</p>
                              <p className={invoiceStyles.displayText}>{isoStringToDate(documentDate)}</p>
                          </div>
                          <div>
                              <p className={globalStyles.filterText}>{ENWORDS.acNo}</p>
                              <p className={invoiceStyles.displayText}>{invoiceData.accountNumber}</p>
                          </div>
                      </div>
                  </div>

                  <div className={invoiceStyles.transactionContainer}>

                      <p>{ENWORDS.transactionDetails}</p>
                  </div>

                  <InvoiceDetailsTable 
                    headerData={INVOICEHEADERS} 
                    data = {transactionDetails} 
                    onDataValueChange = {onTableValueChange}
                    accessLevel = {locationState.writeAccess}
                    invoiceStatus = {location?.state.invoiceStatus}
                  />
                  
                  {
                    locationState && locationState.writeAccess 

                      ? 
                          <div className={styles.buttonContainer}>

                              <button className={styles.btnCancel} onClick={() => setShowPdf(true)} >Preview</button>
                              <button 
                                className={ styles.btnCancel}  
                                onClick = {sendInvoiceEmail} >
                                  {ENWORDS.mail }
                              </button>
                              {
                                location?.state.invoiceStatus !== 'Completed' ?
                                    <button 
                                      disabled = {isDisabled} 
                                      className={ isDisabled ? styles.btnDoneDisabled : styles.btnDone}  
                                      onClick = {()=> showFilePopUp()} >
                                        {resStatus ? ENWORDS.reGenerate :ENWORDS.generate }
                                    </button>
                                  : null
                              }
                          </div>
                      :null
                  }
                
              </div>
          </div>
  
      </div>
  )
}

export default ViewInvoiceDetails