import { SET_AREA_DATA, AREA_FILTER, AREA_PAGE_NUMBER } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  areasTableData: [],
  areaStatusFilter: '',
  pageNumber: 0,
};

const areaReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_AREA_DATA:
    return {
      ...state,
      areasTableData: action.payload,
    };
  case AREA_FILTER:
    return {
      ...state,
      areaStatusFilter: action.payload,
    };
  case AREA_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.payload,
    };

  default:
    return state;
  }
};

export default areaReducer;
