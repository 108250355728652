import axios from 'axios';
import { BASE_URL, APITIMEOUT } from '../shared/constants/appConstants';
import Cookies from 'universal-cookie';
import reduxStore from '../redux/store/index.js'
import { setLoading, setError, onJWTAuthSignout, setSessionExpire } from '../redux/actions/auth';

export function makeRequest ({ method, url, showPopUp = true,   headers, data }){
  return new Promise ( function (resolve, reject ){
    const cookies= new Cookies();
    const TOKEN = cookies.get('token');
    const REFRESH_TOKEN = cookies.get('refreshToken');
    axios ({
      method:method,
      timeout:APITIMEOUT,
      data:data,
      url:`${ BASE_URL }${ url }`,
      headers:Object.assign({},headers, {
        Authorization : `Bearer ${ TOKEN }`,
      }),
    })
      .then(function (res){
        resolve(res);
      })
      .catch(function(err){
        const mainError = err.response;
        reduxStore.dispatch(setLoading(false))
        if (showPopUp){
          if (err.response?.status === 401){
            reduxStore.dispatch(setError({ statusCode: mainError.data.status, errorStatus: false, errorMessage: '' }))
            if(reduxStore){
              reduxStore.dispatch(setSessionExpire());
            }
          }
          else if (err.response?.status === 403){
            reduxStore.dispatch(setError({ statusCode: err.response?.status, errorStatus: true, errorMessage: 'Unauthorized access' }))
          }else if (mainError?.status === 409){
            reduxStore.dispatch(setError({ statusCode: mainError?.status, errorStatus: true, errorMessage: mainError.data }))
          }
          else{ 
            if (mainError?.data){
              if (mainError.data.errors){
                reduxStore.dispatch(setError({ statusCode: mainError.data.status, errorStatus: true, errorMessage: mainError.data.errors }))
              }
              else if(mainError.data !== ''){
                reduxStore.dispatch(setError({ statusCode: mainError.data.status, errorStatus: true, errorMessage: mainError.data }))
              }else{
                reduxStore.dispatch(setError({ statusCode: mainError.data.status, errorStatus: true, errorMessage: mainError.data.message }))
              }
            }
            else{
              reduxStore.dispatch(setError({ statusCode: mainError?.status, errorStatus: true, errorMessage: mainError?.statusText }))
            }
          }
        }
        reject(err);
      });
  
  });
}