export const TABLEHEADERS = {
  'date': 'Sent Date',
  'time': 'Time',
  'to':'To',
  'from': 'From',
  'subjectLine':'Subject Line',
  'status':'Status',
  'emailType':'Email Type',
  'cc':'Cc',
  'site': 'Site ID',
  'siteName':'Site Name',
  'siteRef':'Site Reference',
}
export const EMAILTYPE={
  'Invoice':'Invoice',
  'Penalty':'Penalty',
  'Daily Log': 'Daily Log',
  'Log Report': 'Log Report',
  'Counters': 'Counters',
}