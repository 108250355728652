import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ALL_EMAIL_RG } from '../constants/appConstants';
import '../../styles/invoiceListing/inputDateCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import plusIcon from '../../assets/icons/plusIcon.svg';
import iconStyles from '../../styles/table.module.css';
import binIcon from '../../assets/icons/binIcon.svg';
import { setLoadingFlag } from '../../redux/actions';
import { addDailyLogsAdtMail, deleteMailOnPopUpById, getDailyLogEmail } from '../../api/services/adtDailyLogService';
import { useDispatch, useSelector } from 'react-redux';
import popupStyle from '../../styles/adtLogs/DailyLogsScreen.module.css';
import styles from '../../styles/invoiceListing/InvoiceListingScreen.module.css';
import saveIcon  from '../../assets/icons/saveIcon.svg';
import CustomPopup from './CustomPopup';
import CustomInput from './CustomInput';
import SuccessPopUp from './SuccessPopUp';
const PLACEHOLDER = require('../local/placeholder.json');
const ENWORDS  = require('../local/en.json');

const CustomLogMailPopup = ({ onSendClick,onClose,show, title }) => {
  const savedEmailData = useSelector(
    state => state.dailyLogsReducer.adtMailPopupData
  );
  const [ emailData, setEmailData ] = useState([]);
  const [ errorList, setErrorList ] = useState([]);
  const [ uniqueId, setUniqueId ] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedEmailDataCopy = savedEmailData.map(item =>{
      return { ...item, checked : true , isSaved : true }
    })
    const unsavedData = emailData.filter(item=>{
      if(item.isSaved === false) return item
    })
    setEmailData([ ...savedEmailDataCopy, ...unsavedData ])
    
  }, [ savedEmailData ])

  const addEmail = async (item) => {
    dispatch(setLoadingFlag(true));
    const data = {
      emailId: item.emailId,
    };

    const res = await addDailyLogsAdtMail(data);
    dispatch(setLoadingFlag(false));

    if (res.res) {
      const emailDataCopy = emailData.filter(i=>{
        if(i.id !== item.id){
          return i
        }
      })
      setEmailData(emailDataCopy)
      dispatch(getDailyLogEmail())
    }

  };

  const checkValidation = (item) =>{
    let isError = false;
    if(ALL_EMAIL_RG.test((item.emailId)?.trim()) === false) {
      isError = true;
    }
    let errorListCopy = [];
    if(errorList.some(i=>i.id === item.id)){
      errorListCopy = errorList.map(i=>{
        if(i.id === item.id){
          return { ...i, error : isError ? ENWORDS.notValidEmail : undefined }
        }
      })
    }else{
      errorListCopy = [ ...errorListCopy, { id : item.id , error : isError ? ENWORDS.notValidEmail : undefined } ]
    }

    setErrorList(errorListCopy);
    if(!isError){
      addEmail(item);
    }
  };

  const onAddClicked = (item) =>{
    checkValidation(item);
  }

  const onDeleteClicked = async (id) =>{
    dispatch(setLoadingFlag(true));
    dispatch(deleteMailOnPopUpById(id));
  }

  useEffect(() => {
    dispatch(getDailyLogEmail());
  }, [])

  const  onCheckClicked = (id) =>{
    const emailDataCopy = emailData.map(item=>{
      if(item.id === id){
        return { ...item, checked : !item.checked }
      }
      return item
    })
    setEmailData(emailDataCopy)
  }

  const  onEmailChange = (id, value) =>{
    const emailDataCopy = emailData.map(item=>{
      if(item.id === id){
        return { ...item, emailId :value }
      }
      return item
    })
    setEmailData(emailDataCopy)
  }
  
  const addEmailBlock =() =>{
    setEmailData(prevState =>{
      return [ ...prevState, { id : uniqueId -1 , emailId : '', isSaved : false, checked : true } ]
    })
    setUniqueId(prevID => prevID - 1)
  }

  const onSendMailClick = async () => {
    dispatch(setLoadingFlag(true));
    const data = emailData.map(function (m) {
      return m.checked === true ? m.emailId : undefined;
    });

    onSendClick(data);
    onClose()
  };

  const onUnsavedDelete =(id) =>{
    const emailDataCopy = emailData.filter(item=>{
      if(item.id !== id){
        return item
      }
    })
    setEmailData(emailDataCopy)
  }

  const closeHandler = (e) => {
    onClose(false);
  };

  return (
      <CustomPopup
        title={title}
        showPopUp={show}
        style={{ width : '40%' }}
        onReject={closeHandler}
        cancelText = {ENWORDS.cancel}
        confirmText = {ENWORDS.send}
        onConfirm = {onSendMailClick}
      >
          <div>
              <div className={popupStyle.plusIconStyle}>
                  <div className={styles.mainBtnContainer}>
                      <button className= {styles.featureButton} onClick={()=>{addEmailBlock()}}>
                          <div className={styles.btnContainer}>
                              <img src={plusIcon} alt={ENWORDS.addBtn} />
                          </div>
                      </button>
                  </div>
              </div>

              <div className={popupStyle.mailContainer}>
                  
                  {emailData && emailData.map((item) =>  {
                    if(item.isSaved){
                      return(
                          <div className={popupStyle.mailRow}>
                              <div className={popupStyle.rowItems}>
                                  <input type="checkbox" checked= {item.checked} onChange={(e)=> onCheckClicked(item.id)} />
                              </div>
                              <div className={popupStyle.formFieldContainer}><p>{item.emailId}</p></div> 
                        
                              <button className = {iconStyles.btnOne} onClick={(event) => onDeleteClicked(item.id)} >
                                  <img src={binIcon} alt={ENWORDS.deleteIcon} className={popupStyle.iconStyle} />
                              </button>  
                          </div>
                      ) 
                    }
                    
                  })}

                  {emailData && emailData.map((item) =>  {
                    if(!item.isSaved){
                      return(
                          <div className={popupStyle.mailRow} key ={item.id}>
                              <div  className={popupStyle.editIconStyle}>
                                  <div className={popupStyle.customRowInput}>
                                      <input type="checkbox" checked= {item.checked} onChange={(e)=> onCheckClicked(item.id)} />
                                  </div>
                                  <div className={popupStyle.formFieldContainer}> 
                                      <CustomInput            
                                        isFocused={true}
                                        placeholder = {PLACEHOLDER.email}
                                        value={item.emailId} 
                                        maintainDefaultCase = {true}
                                        onChange = {e => onEmailChange(item.id, (e).trim())} 
                                        error={errorList.find(i=>{return i.id === item.id})?.error}
                                      />
                                  </div>
                              </div>

                              <button className = {iconStyles.btnOne} onClick={(event) => onUnsavedDelete(item.id)} >
                                  <img src={binIcon} className={popupStyle.iconStyle} alt={ENWORDS.deleteIcon} />
                              </button>           
                              
                              <div>
                                  <button className = {iconStyles.btnOne} onClick={(event)=> onAddClicked(item)}>
                                      <img src={saveIcon} alt={ENWORDS.saveIcon} className={iconStyles.iconOne} />  
                                  </button>
                              </div> 
                          </div>
                      ) 
                    }
                    
                  })}               
              </div>
          </div>
              
      </CustomPopup>  

  )
}

CustomLogMailPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendClick: PropTypes.func.isRequired,
};

export default CustomLogMailPopup
