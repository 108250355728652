import { EMAILLOGREPORTURL } from '../../shared/constants/appUrls';
import { setEmailLogReportTable } from '../../redux/actions/emailLogReport';
import { setLoadingFlag } from '../../redux/actions';
import { API_HEADER, GET } from '../../shared/constants/appConstants';
import { makeRequest } from '../makeRequest';
import moment from 'moment';
export function getEmailLogReport(actionDate, site, emailType) {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: EMAILLOGREPORTURL.getEmailLogReport+'?actionDate='+actionDate+'&site='+site+'&emailType='+emailType,
      data: undefined,
      headers: API_HEADER,
    }).then(function (response) {
      const res = response.data.data.map((obj) => {
        obj.time = moment(new Date(obj.time + ' UTC').toString()).format('HH:mm');
        return obj
      })
      dispatch(setEmailLogReportTable(res));
      dispatch(setLoadingFlag(false))
    })
  }
}