import { SET_LOADING_FLAG, SET_ERROR_FLAG, SET_LOAD_ERROR, SET_IS_LOGGED_OUT, SET_COMPANY_MODULE_MAPPING, SET_PROGRESSBAR_FLAG } from '../../shared/constants/actionType';

const INIT_STATE = {
  loadingFlag: false,
  progressBarFlag: false,
  progressPercent: 0,
  progressBarMsg:'',
  errorFlag:false,
  isLoggedOut: false,
  loadError: 
    {
      statusCode:'',
      errorStatus:false,
      errorMessage:'',
    },
  companyModuleAccess:[],
  
}

const commonReducer = (state = INIT_STATE, action) =>{

  switch(action.type){
  case SET_LOADING_FLAG:
    return{
      ...state,
      loadingFlag:action.payload,
    }
  case SET_PROGRESSBAR_FLAG:
    return{
      ...state,
      progressBarFlag:action.payload.progressFlag,
      progressPercent:action.payload.progressPercentValue,
      progressBarMsg : action.payload.progressBarMsg,
    }
  case SET_ERROR_FLAG:
    return{
      ...state,
      errorFlag:action.payload,
    }
  case SET_LOAD_ERROR:
    return{
      ...state,
      loadError:action.payload,
    }
  case SET_IS_LOGGED_OUT:
    return{
      ...state,
      isLoggedOut:action.payload,
    }
  case SET_COMPANY_MODULE_MAPPING:
    return{
      ...state,
      companyModuleAccess:action.payload,
    }
  default: return state
  }
}

export default commonReducer
