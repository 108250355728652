import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onJWTAuthSignout } from '../../redux/actions';
import styles from '../../styles/popUp.module.css';
import { AUTHROUTES } from '../constants/appRoute';

const ENWORDS  = require('../../shared/local/en.json');

const LogoutPopUp = () => {
  const [ show, setShow ] = useState(false);
  const loginStatus = useSelector(state=> state.common.isLoggedOut);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const logout = () =>{
    dispatch(onJWTAuthSignout());
  }
  useEffect(() => {
    setShow(loginStatus)
  }, [ loginStatus ])
  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={styles.logOutoverlay}
      >
          <div className={styles.popup}>
              <div className={styles.middleSection}>
                  <p>{ENWORDS.sessionTimeOut}</p>
                
              </div>

              <div className={styles.btnSection}>
                  <button className={styles.btnYes} onClick={logout} >{ENWORDS.logout}</button>
              </div>
          </div>
      </div>
  )
}

export default LogoutPopUp