import React from 'react'
import CustomTimePicker from './CustomTimePicker';
import inputStyles from '../../styles/inputs.module.css';
import timePickerStyles from '../../styles/stationListing/TimePicker.module.css';

const SchedularTimeComponent = ({ day, onStartTimeChange ,onEndTimeChange, openingTimeTitle, closingTimeTitle , isDisabled , openingTime, closingTime }) => {
  return (
      <div>
          <div className={inputStyles.detailsRow}>
              <div className={timePickerStyles.timePickerContainer}>
                  <div className={timePickerStyles.timeFieldContainer}>
                      <div className={timePickerStyles.displayBox}>{day}</div>
                  </div>
                  <div className={timePickerStyles.fieldRowContainer}>
                      <CustomTimePicker
                        title = {openingTimeTitle} 
                        isDisabled = {isDisabled} 
                        onChange={onStartTimeChange}
                        defaultTime={openingTime}
                      />
                      <CustomTimePicker
                        title = {closingTimeTitle}
                        isDisabled = {isDisabled} 
                        onChange={onEndTimeChange}
                        defaultTime={closingTime}
                      />
                  </div>
              </div>
          </div>
      </div>
  )
}

export default SchedularTimeComponent
