import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import editPenIconLight from '../../../../assets/icons/editPenWhiteIcon.svg';
import supplierStyles from '../../../../styles/baseconfiguration/SupplierManagement.module.css';
import { ROUTES }  from '../../../../shared/constants/appRoute';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import binIcon from '../../../../assets/icons/binIcon.svg';
import SupplierVerificationPopUp from '../../../../shared/Components/SupplierVerificationPopUp';
import { checkIfInvoiceExist, checkModuleSupplierMapping, getSupplierDetailsToDisplay } from '../../../../api/services/commonService';
import { deleteSupplierById } from '../../../../api/services/supplierManagementServices';
import { setLoadingFlag } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

const AboutSupplier = () =>{
  const navigate = useNavigate();
  const location  = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch();
  const [ visibility, setVisibility ] = useState(false);
  const [ toDeleteName, setToDeleteName ] =  useState('');
  const [ toDeleteId, setToDeleteId ] = useState(0);
  const [ toDeleteUserName, setToDeleteUserName ] = useState('');
  const [ supplierDetails,setSupplierDetails ] = useState();
  const [ invoiceList, setInvoiceList ] = useState([]);
  const [ assignedModule, setAssignedModule ] = useState([]);
  const [ supplierDetailsData, setSupplierDetailsData ] = useState([]);
  const editSupplier  =()=>{
    navigate(ROUTES.EDITSUPPLIER, { state:{ data: supplierDetails } })
  }
  const showPopUp = (popUpName, popUpUserName) =>{
    setVisibility(true);
    setToDeleteName(popUpName);
    setToDeleteUserName(popUpUserName)
  }
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };
  
  const supplierVerficationCall = async (formId, formName) => {
    dispatch(setLoadingFlag(true));
    const verificationCall = await checkIfInvoiceExist(formId, 'supplierID');
    const moduleSupplierMapping = await checkModuleSupplierMapping(supplierDetails.id);
    const supplierData = await getSupplierDetailsToDisplay();
    // Filtering so that same supplier is not shown in invoice dropdown
    const filterdSupplierData = (supplierData.res).filter((item)=> item.id !== supplierDetails?.id)
  
    if (verificationCall.statusCode === 200) {
      dispatch(setLoadingFlag(false))
      setInvoiceList(verificationCall.res);
      setAssignedModule(moduleSupplierMapping.res);
      setSupplierDetailsData(filterdSupplierData);
      showPopUp(formName, formId);
      setToDeleteId(formId);
    }
  };
  const deleteRow = ( id, moduleSupplierList) => {
    dispatch(deleteSupplierById(toDeleteId, moduleSupplierList))  
    navigate(ROUTES.SUPPLIERMANAGEMENT)
  };

  useEffect(() => {
    if (locationState){
      setSupplierDetails( locationState?.data)
    }
  }, [])

  return(
      <div className={styles.mainContainer}>
          <SupplierVerificationPopUp
            onClose={popupCloseHandler}
            show={visibility}
            title={ENWORDS.deleteSupplierTitle}
            text={ENWORDS.commonCheckboxMsg}
            checkboxMsg={ENWORDS.commonCheckboxMsg}
            selectedName={toDeleteName}
            selectedId={toDeleteUserName}
            screenName={ENWORDS.editStation}
            invoiceList={invoiceList ?? []}
            assignedModuleList ={assignedModule ?? []}
            supplierDataList = {supplierDetailsData ?? []}
            onDelete={deleteRow}
          />
          {
            supplierDetails ? 
                <div className={supplierStyles.subContainer}>
                    <div className={supplierStyles.supplierDetailsTopContainer}>
                        <ListingHeader
                          title = {ENWORDS.supplierDetails}
                          navigationPath = {-1}
                        />    

                        {
                          locationState?.roleAccess && locationState?.roleAccess.writeAccess 
                            ? 
                                <div className={styles.buttonContainer}>
                                    {
                                      locationState?.showDelete ?

                                          <button className={styles.btnCancel}  onClick = {()=>  supplierVerficationCall(supplierDetails.id, supplierDetails.supplier)}>
                                              <img src={binIcon} alt = {ENWORDS.deleteIcon}/>
                                          </button>:null

                                    }

                                    <button className={styles.btnDone} onClick={editSupplier}>
                                        <img src={editPenIconLight} alt ={ENWORDS.editPenIcon}/>
                                    </button>
                                </div>
                            : null }
                       
                    </div>

                    <div className={supplierStyles.contentContainer}>
                        <div className={supplierStyles.content}>
                            <div className={styles.siteNavContainer}>
                                <div className={styles.siteNavigation}>
                                    <p className={styles.robotoMediam}>{ENWORDS.supplierDetails}</p>                           
                                </div>
                            </div>
                            <div className={styles.supplierForm}>
                                <div className ={inputStyles.detailsRow}>
                                    <div className={styles.formFieldContainer}>
                                        <p>{ENWORDS.supplierName}</p>
                                        <div className={styles.displayBox}> {supplierDetails.supplierName}</div>
                                    </div>
                                
                                    <div className={styles.formFieldContainer}>
                                        <p>{ENWORDS.supplierSiteCode}</p>
                                        <div className={styles.displayBox}>{supplierDetails.supplierCode}</div>
                                    </div>

                                    <div className={styles.formFieldContainer}>
                                        <p>{ENWORDS.termsDate}</p>
                                        <div className={styles.displayBox}>{supplierDetails.termsDate}</div> 
                                    </div>

                                    <div className={styles.formFieldContainer}>
                                        <p>{ENWORDS.commissionPercentage}</p>
                                        <div className={styles.displayBox}>
                                            {supplierDetails.supplierCommision}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>              
              :
              null
          }
      </div>
  )
}

export default AboutSupplier