import React, { useState, useEffect } from 'react';
import { Link, useMatch, useResolvedPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import globalStyle from '../../styles/global.module.css';
import styles from '../../styles/hoc/HOC.module.css';
import { useAuth } from '../../routers/AuthProvoider';
import { ROUTES } from '../../shared/constants/appRoute';
import arrowUp from '../../assets/icons/arrowUp.svg'
import arrowDown from '../../assets/icons/arrowDownDark.svg'
import { onJWTAuthSignout } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux';
import dataProcessorLogo from '../../assets/icons/dataProcessorLogo.svg';
import { getRoleById } from '../../api/services/roleManagementService';
import { setUserLevelAccess } from '../../redux/actions/roleManagement';
import { useMsal } from '@azure/msal-react';
import { getModuleDetailsToDisplay } from '../../api/services/moduleConfigurationService';
import { TABS } from '../../shared/constants/appConstants';
const ENWORDS = require('../../shared/local/en.json');
const SCREENS = require('../../shared/local/screens.json')

function TabNavigator(props) {
  const auth = useAuth();
  const userData = useSelector(state => state.auth.user)
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);
  const [ modules, setAllModule ] = useState();
  const dispatch = useDispatch()
  const location = useLocation();
  const [ hideNavBar, sethideNavBar ] = useState(0)
  const [ showToolTip, setShowToolTip ] = useState(false)
  const [ currentUser, setCurrentUser ] = useState('');
  const [ profilePicture, setProfilePicture ] = useState('');
  const navigate = useNavigate(); 
  const { instance } = useMsal();
  
  if(location.pathname === '/'){
    const data = userData.roleAccess.find((obj) => obj.readAccess === true)
    navigate('/'+data.routeIdentity);
  }
  const onLogoutClicked = async () =>{
    await dispatch(onJWTAuthSignout());
    instance.logoutPopup({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/', // redirects the top level app after logout
    });
  }

  const setTabData = async () =>{
    
    if (userData.roleId){
      const userLevelRole = await getRoleById(userData.roleId)
      dispatch(setUserLevelAccess(userLevelRole.roleAccess))
    }
  }

  const getModulesData = async () => {
    const moduleData = await getModuleDetailsToDisplay();
    setAllModule(moduleData.res);
  };

  useEffect(()=>{
    if (!auth.loading){
      setCurrentUser(userData)
      setProfilePicture(ENWORDS.base64Prefix+ userData.profilePicture)
    }
  }, [ auth ])

  useEffect(()=>{
    setTabData();
  },[])

  useEffect(() => {
    getModulesData();
  }, []);
  
  return (
      <div style={{ height: '100%' }} className={globalStyle.flexColumn}>
          <div className={styles.headerContainer}>
              <div className={styles.topUserHeader}>
                  <button className={styles.toggleButton} onClick={() => sethideNavBar(hideNavBar === 0 ? '22.8rem' : 0)}>
                  </button>
                  <img className={styles.mfgLogo} alt={ENWORDS.dataProcessorLogo} src={dataProcessorLogo}/>
                  {
                    currentUser ? 
                        <div>
                            <button className={styles.logOutContainer} onClick={ ()=> setShowToolTip(!showToolTip)}>
                                <div className={styles.userInfoContainer}>
                                    <div className={`${ styles.avatar } ${ styles.avatarLetters }`}>
                                        {
                                          currentUser.profilePicture !== '' ? <img src={profilePicture} alt = {profilePicture}></img> :
                                            currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)}
                                       
                                    </div>
                                    <div className={`${ globalStyle.flexColumn } ${ styles.userInfo }`}>
                                        <div className={styles.userName}>{currentUser.firstName + ' '+ currentUser.lastName}</div>
                                        <div className={styles.userRole}>{currentUser.role}</div>
                                    </div>
                                    <img className={styles.logoutIcon} src={showToolTip ? arrowUp: arrowDown } alt="Logout" />

                                </div>
                            </button>
                            <button className={styles.logOutBtn} onClick={()=>onLogoutClicked()}>
                                <div className={ showToolTip ?  styles.logoutTooltip : styles.logoutTooltipDisabled}>
                                    <p>{ENWORDS.logout}</p>
                                </div>
                            </button>
                        </div>
                      : null
                  }
              </div>
              <div style={{ height: hideNavBar }} className={`${ styles.navbar } ${ styles.navbarExpandLG }`}>
                  <div className={styles.navbarCollapse}>
                      {
                        roleLevelAccess && roleLevelAccess?.reduce((items,item)=>{
                          if(item.parent === null){
                            return [ ...items, item ]
                          }
                          if(!items?.some(i=> i.screenName === item.parent && i.readAccess === true)){
                            return [ ...items, { ...item, screenName : item.parent, parent: null } ]
                          }
                          return items
                    
                        },[]).map((item, index ) =>{

                          const checkModuleAccessibilty = modules?.some(i => {
                            return i.isADT === 1;
                          });

                          const adtAvailable = item.screenName === TABS.adtAlarmMonitoring;
                          if(!adtAvailable ||(adtAvailable && checkModuleAccessibilty )){
                            return(
                              item.parent === null && item.readAccess ?
                                  <div key={item.screenName} onClick = {() => sethideNavBar(hideNavBar === 0 ? '20rem':0)}>
                                      <Tab to={ `/${ item.routeIdentity }` }>{item.screenName}</Tab>
                                  </div>
                                : null
                            )
                          }
                        })
                      }
                  </div>
              </div>
          </div>
          <Outlet/>
      </div>
  );
}

function Tab({ children ,screeName, to, ...props }) {
  const location = useLocation();
  const resolved = useResolvedPath(to);
  let match;
  match = useMatch({ path: resolved.pathname, end: true });
  
  if(children === SCREENS.invoiceListing){
    if(location.pathname === ROUTES.INVOICEDETAILS){
      match = true;
    }
  }
  else if(children === SCREENS.stationListing){
    if(location.pathname === ROUTES.ADDSTATION || location.pathname === ROUTES.EDITSTATION || location.pathname === ROUTES.STATIONDETAILS || location.pathname === ROUTES.STATIONAREAS  || location.pathname === ROUTES.STATIONREGIONS || location.pathname === ROUTES.REGIONDETAILS || location.pathname === ROUTES.EDITREGIONS || location.pathname === ROUTES.ADDREGION || location.pathname === ROUTES.EDITAREA || location.pathname === ROUTES.ADDAREA || location.pathname === ROUTES.AREADETAILS ||
      location.pathname === ROUTES.OPSREGIONDETAILS || location.pathname === ROUTES.ADDOPSREGION || location.pathname === ROUTES.EDITOPSREGIONS || location.pathname === ROUTES.STATIONOPSREGIONS ){
      match = true;
    }
  }
  else if(children === SCREENS.setup) { // SCREENS.baseConfiguration
    if(location.pathname === ROUTES.ADDNEWSUPPLIER || location.pathname === ROUTES.EDITSUPPLIER || location.pathname === ROUTES.SUPPLIERDETAILS ||
        location.pathname === ROUTES.ADDROLE || location.pathname === ROUTES.EDITROLE || location.pathname === ROUTES.ROLEDETAILS ||
        location.pathname === ROUTES.COMPANY || location.pathname === ROUTES.EDITCOMPANY || location.pathname === ROUTES.COMPANYDETAILS ||
        location.pathname === ROUTES.ROLEMANAGEMENT || location.pathname === ROUTES.SUPPLIERMANAGEMENT ||
        location.pathname === ROUTES.ADDNEWSUPPLIER || location.pathname === ROUTES.ADDCOMPANY || location.pathname === ROUTES.BASESETTINGS || 
        location.pathname === ROUTES.EDITSETTINGS || location.pathname === ROUTES.STATIONLISTING  || location.pathname === ROUTES.ADDSTATION || location.pathname === ROUTES.EDITSTATION || location.pathname === ROUTES.STATIONDETAILS || location.pathname === ROUTES.STATIONAREAS  || location.pathname === ROUTES.STATIONREGIONS || location.pathname === ROUTES.REGIONDETAILS || location.pathname === ROUTES.EDITREGIONS || location.pathname === ROUTES.ADDREGION || location.pathname === ROUTES.EDITAREA || location.pathname === ROUTES.ADDAREA || location.pathname === ROUTES.AREADETAILS ||
        location.pathname === ROUTES.OPSREGIONDETAILS || location.pathname === ROUTES.ADDOPSREGION || location.pathname === ROUTES.EDITOPSREGIONS || location.pathname === ROUTES.STATIONOPSREGIONS ||location.pathname === ROUTES.ADDMODULECONFIGURATION || location.pathname === ROUTES.EDITMODULECONFIGURATION ||
        location.pathname === ROUTES.VIEWMODULECONFIGURATION || location.pathname === ROUTES.ADDUSERSCREEN || location.pathname === ROUTES.EDITUSERSCREEN ||
        location.pathname === ROUTES.USERDETAILSSCREEN || location.pathname === ROUTES.USERMANAGEMENTSCREEN){
      match = true;
    }
  }
  else if(children === SCREENS.adtalarmmonitoring){
    if(location.pathname === ROUTES.ADTALARMMONITORING || location.pathname === ROUTES.ADTREPORTS || location.pathname === ROUTES.ADTPENALTIES || location.pathname === ROUTES.ADTCOUNTERS || location.pathname === ROUTES.ADTPENALTYCONFIGURATION || location.pathname === ROUTES.ADTDAILYLOGDETAILS){
      match = true;
    }
  }

  return (
      <div>
          <Link
            className={`${ styles.tab } ${ match ? styles.activeTab : {} }`}
            to={to}
            {...props}>
              {children}
          </Link>
      </div>
  );
}
export default TabNavigator;