import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../../styles/moduleConfiguration/moduleconfig.module.css'
import globalStyle from '../../../styles/global.module.css'
import { TABLEHEADERS } from '../moduleConfigurationConstant';
import plus from '../../../assets/icons/plusIcon.svg';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../shared/constants/appRoute';
import DataTable from '../../../shared/Components/DataTable.js'
import CustomSearchBox from '../../../shared/Components/CustomSearchBox';
import { getModuleDetails, getModuleStationMapping, getRequiredFieldsData } from '../../../api/services/moduleConfigurationService';
import { setLoadingFlag } from '../../../redux/actions';
import { getCompany, getUserRoles } from '../../../api/services/commonService';
import { getSupplierList } from '../../../api/services/supplierManagementServices';
import { updatModulePageNumber } from '../../../redux/actions/moduleConfiguration'
import SideNavigation from '../../../shared/Components/SideNavigation';
import { TABS } from '../../../shared/constants/appConstants';
import baseStyles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import style from '../../../styles/stationListing/StationListingScreen.module.css';
const ENWORDS  = require('../../../shared/local/en.json');
const SCREENS  = require('../../../shared/local/screens.json');

const ModuleListingScreen = () => {
  const moduleData = useSelector(state => state.moduleConfiguration.moduleConfigurationData);
  const roleLevelAccess = useSelector(state => state.roleManagement.roleLevelAccess);

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [ searchText, setSearchText ] = useState('');
  const [ filteredData, setfilteredData ] = useState(moduleData);
  const [ roleAccess, setRoleAccess ] = useState();
  const filteration = () => {
    let moduleFilterData = moduleData;

    if (searchText.length  > 0 ){
      moduleFilterData = moduleFilterData.filter((item)=>{
        const moduleName  = (item.moduleName)?.toLowerCase()
        return moduleName?.includes(searchText.toLowerCase())
      })
    }

    return moduleFilterData

  }
  
  const fetchUserRoles = async () =>{    
    const res  = await getUserRoles()
    const rolesRes = res.res

    if (rolesRes){
      return rolesRes;
    }

  }

  const getStationData = async() => {
    const stationRes = await getModuleStationMapping()
    const stationResData = stationRes.res;
    if (stationResData){
      return stationResData.map((val)=>({
        value:val, label:val,
      }))
    }
  }
  
  const addModule = async () =>{
    dispatch(setLoadingFlag(true))
    const fieldRes = await getRequiredFieldsData()
    // Get list of all the available stations
    const stationData = await getStationData();
    
    // Get list of all the available user roles
    const userRole = await fetchUserRoles();
    
    const companyResponse = await getCompany();
    const supplierData = await getSupplierList();
    
    if (fieldRes && stationData && userRole){
      dispatch(setLoadingFlag(false))
      navigation(ROUTES.ADDMODULECONFIGURATION, { state: 
        { fieldValues: fieldRes.res,
          stationValue:stationData,
          roleValue:userRole,
          companyList: companyResponse.res,
          supplierDataList : supplierData.res,
        } })
    }
  }
  // Get all Modules
  const fetchModule = async () => {
    dispatch(setLoadingFlag(true))
    dispatch(getModuleDetails())
  }

  const checkRoleAccess = () =>{
    roleLevelAccess.forEach((item)=>{
      if (item.screenName === SCREENS.moduleConfiguration){
        if (!item.writeAccess){
          delete TABLEHEADERS[ 'moduleAction' ];
        }
        setRoleAccess(item)
      }
    })

  }

  useEffect(() => {
    setfilteredData(filteration())
  }, [ searchText ])

  useEffect(()=>{
    setfilteredData(moduleData)
  }, [ moduleData ])

  useEffect(() => {
    fetchModule() 
  }, [])

  useEffect(() => {
    checkRoleAccess()
  }, [ roleLevelAccess ])
  
  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updatModulePageNumber(0))
  }

  return (
      <div className={baseStyles.mainContainer}>
          <div className = {baseStyles.leftContainer}>
              <SideNavigation parentName={TABS.setup}   />
          </div>

          <div className={baseStyles.rightContainer}>
              <div className = {baseStyles.siteHeader}>
                  <div className={style.stationHeaderContainer}>
                      <div className={style.filterContainer}>
                          <div className={style.leftFilterContainer}>

                              <div className={style.searchBoxContainer}>
                                  <CustomSearchBox 
                                    placeholder = {ENWORDS.stationPlaceholder}  
                                    title={ENWORDS.search} 
                                    onChange={e => onChangeSearch(e)}                 />
                              </div>
                          </div>
                          <div className={baseStyles.btnContainer}>
                              {roleAccess && roleAccess.writeAccess ? 
                                  <div className={baseStyles.addContainer}>
                                      <button onClick={addModule}>
                                          <img src={plus} alt={ENWORDS.plusIcon} />
                                      </button>
                                  </div>
                                :null
                              }
                          </div>
                      </div>
                  </div> 
              </div>
              <DataTable 
                data = {filteredData} 
                headerData = {TABLEHEADERS} 
                tableName = { ENWORDS.moduleTable}
                roleLevelAccess  = {roleAccess}
                shouldVerify = {true}
                popupTitle = {ENWORDS.deleteModuleTitle}
                popupText = {ENWORDS.deleteModuleText}
              />
          </div>
      </div>
  )
}

export default ModuleListingScreen