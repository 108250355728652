import { makeRequest } from '../makeRequest';
import { GET,  API_HEADER, GET_ROLES } from '../../shared/constants/appConstants';
import { AUTHURL, COMMONURL, COMPANYURL, INVOICEURLS, SUPPLIERURLS, USERURL } from '../../shared/constants/appUrls';
import { setCompanyModuleMapping, setLoadingFlag } from '../../redux/actions';

export async function getUserRoles() {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: GET_ROLES,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });

  return { res:res, err:errRes };

}

export async function getAzureADUsers(){
  let res;
  let errRes = {}
  await makeRequest({
    method: GET,
    url: USERURL.getAzureADUser,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });

  return { res:res, err:errRes };
}

export async function getCompany(id = 0, hideParent = false) {
  let res;
  let errRes = {};
  
  await makeRequest({
    method: GET,
    url: `${ COMPANYURL.getCompanies  }${ hideParent ? '?hideParent='+ hideParent : '' }${ id ? '?id='+ id : '' }`,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      res = false
      errRes = err.response
    });

  return { res:res, err:errRes };
}

export const getCurrentUser = async () =>  {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url:AUTHURL.getCurrentUser,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data.data
    })
    .catch(function (err) {
      errRes  = err
    });

  return  { res:res, err:errRes };
}

export async function  getSupplierDetailsToDisplay() {
  let res;
  let errRes = {};
  
  await makeRequest({
    method: GET,
    url: SUPPLIERURLS.getSupplier,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
    })
    .catch(function (err) {
      errRes = err;
    });

  return { res: res, err: errRes }

}

export async function  checkIfInvoiceExist(id, columnName) {
  let res = [];
  let statusCode = 200;
  let errRes = {};
  
  await makeRequest({
    method: GET,
    url: INVOICEURLS.checkIfInvoiceExist+ id+ '&columnName='+columnName,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
      statusCode = response.status;
    })
    .catch(function (err) {
      errRes = err;
      statusCode = err.response.status;
    });

  return { res: res, err: errRes, statusCode: statusCode }

}
export async function  checkModuleSupplierMapping(supplierId) {
  let res = [];
  let statusCode = 200;
  let errRes = {};
  
  await makeRequest({
    method: GET,
    url: INVOICEURLS.checkModuleSupplierMapping+ supplierId,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function (response) {
      res = response.data
      statusCode = response.status;
    })
    .catch(function (err) {
      errRes = err;
      statusCode = err.response.status;
    });

  return { res: res, err: errRes, statusCode: statusCode }

}
export function getCompanyModuleMapping() {
  return async (dispatch) => {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: COMMONURL.getCompanyModuleMapping,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setCompanyModuleMapping(JSON.parse(response.data.data)))
        dispatch(setLoadingFlag(false))
      }).catch((e)=>{
        
      })
  }
}