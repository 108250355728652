import { setLoadingFlag } from '../../redux/actions';
import { setPenaltiesData } from '../../redux/actions/adtPenalties';
import { API_HEADER, GET, POST, PUT } from '../../shared/constants/appConstants';
import { ADTMONITORINGURL } from '../../shared/constants/appUrls';
import { makeRequest } from '../makeRequest';

export function getPenalties(  ) {
  return async function (dispatch) {
    dispatch(setLoadingFlag(true))
    await makeRequest({
      method: GET,
      url: ADTMONITORINGURL.getPenalties ,
      data: undefined,
      headers: API_HEADER,
    })
      .then(function (response) {
        dispatch(setPenaltiesData(response.data.data));
        dispatch(setLoadingFlag(false))
      })
  }
}

export async function updatePenaltyStatus(status , id ) {
  let res;
  let errRes = {};
  await makeRequest({
    method: PUT,
    url: ADTMONITORINGURL.updatePenaltyStatus + id,
    data: {
      status : status,
    },
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function getPenaltyDocument(id, documentType)  {
  let res;
  let errRes = {};
  await makeRequest({
    method: GET,
    url: `${ ADTMONITORINGURL.getPenaltyDocument + id }?documentType=${ documentType }` ,
    data: undefined,
    headers: API_HEADER,
  })
    .then(function(response){
      res = response.data
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function commitPenaltyById(data, id) {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: ADTMONITORINGURL.commitPenalties + id ,
    data: data ,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}

export async function sendPenaltyEmail(ids)  {
  let res;
  let errRes = {};
  await makeRequest({
    method: POST,
    url: `${ ADTMONITORINGURL.sendPenaltyEmail }` ,
    data: ids,
    headers: API_HEADER,
  })
    .then(function(response){
      res = true
    })
    .catch(function (err) {
      res = false;
      errRes = err
    });
  return { res:res, err:errRes }
}
