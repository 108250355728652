import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ProvideAuth } from "./routers/AuthProvoider";
import Routers from "./routers/Routers";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./utility/appHooks/authConfig";
import { MsalProvider } from "@azure/msal-react";

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
      <div className="App">
          <MsalProvider instance={msalInstance}>
              <Provider store={store}>
                  <ProvideAuth>
                      <Routers />
                  </ProvideAuth>
              </Provider>
          </MsalProvider>
      </div>
  );
}

export default App;
