import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../../../styles/baseconfiguration/AddNewSite.module.css';
import configurationStyles from '../../../../styles/moduleConfiguration/addconfig.module.css';
import inputStyles from '../../../../styles/inputs.module.css';
import globalStyles from '../../../../styles/global.module.css';
import ENWORDS from '../../../../shared/local/en.json';
import arrowUp from '../../../../assets/icons/arrowUp.svg';
import arrowDown from '../../../../assets/icons/arrowDownDark.svg';
import binIcon from '../../../../assets/icons/binIcon.svg';
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from '../../../../shared/Components/CustomInput';
import { ROUTES } from '../../../../shared/constants/appRoute';
import ERRORWORDS from '../../../../shared/local/error.json';
import { getModuleDetails } from '../../../../api/services/moduleConfigurationService';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_EMAIL_RG, ALPHA_NUM_REGEX, NUMBER_REGEX, globalDateFormat, ALPHA_NUMBER_REGEX, GLOBAL_DATE_FORMAT_DB } from '../../../../shared/constants/appConstants';
import '../../../../styles/invoiceListing/inputDateCalendar.css';
import { displayError, setLoadingFlag } from '../../../../redux/actions';
import {  checkManagerDateIntersection, commonScrollFunction, milliSecondToIsoString, sortByDate } from '../../../../shared/commonFunctions';
import { addStationDetails,checkStationInvoiceExist, checkSupplierSiteExist } from '../../../../api/services/stationManagementService';
import ListingHeader from '../../../../shared/Components/ListingHeader';
import MultiSelectDropdown from '../../../../shared/Components/MultiSelectDropdown';
import CustomDropDown from '../../../../shared/Components/CustomDropDown';
import CustomDatePicker from '../../../../shared/Components/CustomDatePicker';
import { useDebounce } from 'use-debounce';
import moment from 'moment/moment';
import SchedularTimeComponent from '../../../../shared/Components/SchedularTimeComponent';
import { CLOSING_TIME, DAYS, BASE_TIME, OPEN_24_HRS, OPENING_TIME, DEFAULT_OPENING_TIME, DEFAULT_CLOSING_TIME, STATIONSHEDULE, AREANAME } from '../../stationListingConstant';
import { getAreas } from '../../../../api/services/areaManagementService';
import { STATUSSTATION } from '../../../BaseConfiguration/baseConfigurationConstant';
import { format } from 'date-fns';
const PLACEHOLDER = require('../../../../shared/local/placeholder.json');

const onTimeChangeCalculate = (timeValue) => {
  const openingTimeVal =  moment(timeValue).hours() * 60 + moment(timeValue).minutes()
  return openingTimeVal;
}

const AddStationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location  = useLocation();
  const userData = useSelector(state => state.auth.user)
  const locationState = location.state;
  const moduleData = useSelector(state => state.moduleConfiguration.moduleConfigurationData);
  const [ hiddenStation, setHiddenStation ] = useState(false);
  const [ hiddenCM, setHiddenCM ] = useState(false);
  const [ hiddenSupplier, setHiddenSupplier ] = useState(false);
  const [ hiddenSchedular, setHiddenSchedular ] = useState(false);
  const [ stationId, setStationId ] = useState('');
  const [ stationName, setStationName ] = useState('');
  const [ stationAddress, setStationAddress ] = useState('');
  const [ netSuiteStationId, setNetSuiteId ] = useState('');
  const [ stationEmail, setStationEmail ] = useState('');
  const [ formError, setFormError ] = useState({});
  const [ cmDetailsList, setCmDetailsList ] = useState([]);
  const [ cmDetailsError, setCmDetailsError ] = useState({});
  const [ modules, setAllModule ] = useState();
  const [ visibleSection, setVisibleSection ] = useState('stationDetails');
  const [ isoFormList, setIsoFormList ] = useState();
  const [ companyArr, setCompanyArr ] = useState([]);
  const [ companyData, setCompanyData ] = useState([]);
  const [ stationStatus, setStationStatus ] = useState('Active');
  const [ activeCMForm, setActiveCMForm ] = useState(0);  
  const [ isDone, setIsDone ] = useState(false);
  const [ fromId, setFormId ] = useState('');
  const [ moduleFormList , setModuleFormList ] = useState([]);
  const [ moduleError , setModuleError ] = useState([]);
  const [ formValue ] = useDebounce(cmDetailsList, 500);
  const [ moduleValueUpdate ] = useDebounce(moduleFormList, 1000);
  const [ siteIdValue ] = useDebounce(stationId, 1000);
  const [ selectedStatus, setSelectedStatus ] = useState(OPEN_24_HRS);
  const [ selectedAreaId, setSelectedAreaId ] = useState()
  const [ effectiveFromDate, setEffectiveFromDate ] = useState()
  const [ effectiveToDate, setEffectiveToDate ] = useState()
  const areas = useSelector(state => state.areaReducer.areasTableData);
  const [ schedularTimeDetailsList, setSchedularTimeDetailsList ] = useState(
    Array(7)
      .fill({
        openingTime: onTimeChangeCalculate(DEFAULT_OPENING_TIME),
        closingTime: onTimeChangeCalculate(DEFAULT_CLOSING_TIME),
      })
      .map((day, index) => {
        return { ...day, day: index + 1 };
      })
  )

  const [ newCMIndex, setNewCMIndex ] = useState(0)

  useEffect(() => {
    dispatch(getAreas());
  }, [])
  
  useEffect(() =>{
    setAllModule(moduleData);
  },[ moduleData ])
  
  useEffect(() =>{
    if(cmDetailsList.length !== 0){
      setActiveCMForm(cmDetailsList[ 0 ].id)
    }
  },[ cmDetailsList ])

  useEffect(() => {
    const cmListCopy = cmDetailsList.map(cm=>{
      if(cm.id !== activeCMForm){
        return { ...cm, endDate : cm.endDate ? cm.endDate : new Date() }
      }
      return cm
    })
    setCmDetailsList(cmListCopy)

  }, [ activeCMForm ])

  const stationRef = useRef(null)
  const cmRef = useRef(null)
  const mainRef = useRef(null);
  const supplierRef = useRef(null);
  const scheduleRef = useRef(null);
  const sectionRefs = [
    { section: ENWORDS.stationRefSection, ref: stationRef },
    { section: ENWORDS.scheduleRefSection, ref: scheduleRef },
    { section: ENWORDS.cmRefSection, ref: cmRef },
    { section: ENWORDS.supplierRefSection, ref: supplierRef }
  ];

  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  // const scrollTo = (ele) => {
  //   ele.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //   });
  // };
  const scrollTo = (ele) => {
    window.scrollTo({
      top:ele.offsetTop - mainRef.current.offsetTop,
      left : 0,
      behavior : 'smooth',
    })
  };
  const addCMBlock = () => {
    let cmDetailsCopy = [ ...cmDetailsList ];;
    const lenOfCmDetails = cmDetailsList.length
    let initialStartDate;
    let initialEndDate;
    let afterInitialStart;
    let afterInitialEnd;
    let struct;
    const cmId = (newCMIndex + 1)
    if (lenOfCmDetails === 0) {
      initialStartDate = Date.now()
      const momentEndDate =moment(initialStartDate);;
      initialEndDate = (momentEndDate).valueOf();
      struct = {
        id: cmId,
        siteId: '',
        name: '',
        address: '',
        email: '',
        ledgerAccount: '',
        termDays:0,
        startDate: initialStartDate,
        endDate: initialEndDate,
      }
    } else {
      cmDetailsCopy.forEach((value) => {
        if (value.id === cmDetailsCopy[ 0 ].id ) {
          const endDate = value.endDate === null ? new Date() : value.endDate;
          afterInitialStart = moment(endDate).add(1,'d').valueOf() ;
          afterInitialEnd = moment(afterInitialStart).valueOf()
         
        }
      })
      struct = {
        id: cmId,
        siteId: '',
        name: '',
        address: '',
        ledgerAccount: '',
        termDays:0,
        startDate: afterInitialStart,
        endDate: afterInitialEnd,
      }
    }
    cmDetailsCopy = [ ...cmDetailsCopy, struct ];
    setCmDetailsList(sortByDate(cmDetailsCopy,'startDate'));
    setNewCMIndex(prevId=>prevId+1)
  }

  const addModuleBlock = () =>{
    let moduleFormCopy = moduleFormList;
    var lenOfFormList = moduleFormCopy.length
    let moduleErrorCopy = moduleError;
    let moduleFormId = 0;
    if (moduleFormCopy.length !== 0) {
      const nThLst = moduleFormCopy.at(lenOfFormList - 1)
      moduleFormId = nThLst.id + 1
    }
    
    // const defaultModuleID = Object.keys
    const defaultModuleID = (modules.filter((el) => {
      return moduleFormCopy.every((f) => {
        return f.moduleID != el.id ;
      });
    }))[ 0 ].id;
    
    let struct;
    if(defaultModuleID){

      struct = {
        id: moduleFormId,
        moduleID: parseInt(defaultModuleID),
        supplierSiteID: '',
      }
    }
    moduleFormCopy = [ ...moduleFormCopy, struct ]
    setModuleFormList(moduleFormCopy)

    const errorStruct = {
      errorId: moduleFormId,
      supplierError:'',
    }
    moduleErrorCopy = [ ...moduleErrorCopy, errorStruct ];
    setModuleError(moduleErrorCopy);
  }

  const checkSupplierSiteAvail = async (moduleID, supplierSiteID) =>{
    const checkRes = await checkSupplierSiteExist(moduleID, supplierSiteID)
    return checkRes;
    // return await checkSupplierSiteExist(moduleID, supplierSiteID)
    
  }
  useEffect(() =>{
    async function setError(){
      moduleFormList.forEach((i, index) =>{
        if(i.moduleID > 0 && i.supplierSiteID > 0){
          const copyModuleError = moduleError;
          moduleError.forEach(async(item,ind) =>{
            if(i.id === item.errorId ){
              const res = await checkSupplierSiteAvail(i.moduleID, i.supplierSiteID)
              if(res){
                copyModuleError[ ind ].supplierError = ERRORWORDS.duplicateSiteRef
              }
              else{
                copyModuleError[ ind ].supplierError = ''
              }
            }
          })
          setModuleError(copyModuleError);
          
        }
      })
    }
    setError();
    
  },[ moduleValueUpdate ])

  const validated = async () => {
    dispatch(setLoadingFlag(true))
    const requestBody = {
      siteId: stationId.trim(),
      siteName: stationName.trim(),
      netSuitId: netSuiteStationId.trim(),
      siteEmailAddress: stationEmail.trim(),
      siteAddress: stationAddress.trim(),
      cmDetails: isoFormList,
      companyAssignment:companyData,
      siteStatus:stationStatus,
      moduleSupplierAssign:moduleFormList,
      scheduleType: selectedStatus === OPEN_24_HRS? 0 : 1,
      siteSchedules : selectedStatus === OPEN_24_HRS?[]: schedularTimeDetailsList,
      areaId : selectedAreaId,
      effectiveFromDate: effectiveFromDate ? format(effectiveFromDate, GLOBAL_DATE_FORMAT_DB) : null,
      effectiveToDate: effectiveToDate ? format(effectiveToDate, GLOBAL_DATE_FORMAT_DB) : null,
    }
    const res = await addStationDetails(requestBody)
    const stationRes = res.res;
    if (stationRes) {
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.STATIONLISTING, { state: {
        companyRes: locationState.companyRes,
      } })
    }
  }
  const checkValidation = () => {
    let formErrorVariable = formError;
    const cmDetailsErrorCopy = cmDetailsError;
    const moduleErrorCopy = moduleError;
    if (stationId?.trim() === '' || ALPHA_NUM_REGEX.test(stationId) === false) {
      formErrorVariable = { ...formErrorVariable, stationIdError: ERRORWORDS.stationIdError }
    }
    if (stationName?.trim() === '' || ALPHA_NUM_REGEX.test(stationName) === false) {
      formErrorVariable = { ...formErrorVariable, stationNameError: ERRORWORDS.stationNameError }
    }
    if (stationAddress?.trim() === '' || ALPHA_NUM_REGEX.test(stationAddress) === false) {
      formErrorVariable = { ...formErrorVariable, stationAddressError: ERRORWORDS.stationAddressError }
    }
    if (netSuiteStationId?.trim() === '') {
      formErrorVariable = { ...formErrorVariable, netSuiteStationIdError: ERRORWORDS.netSuiteStationIdError }
    }
    if (ALL_EMAIL_RG.test((stationEmail).trim()) === false) {
      formErrorVariable = { ...formErrorVariable, stationEmailError: ERRORWORDS.stationInvalidEmail }
    }
    if (companyData && companyData.length === 0) {
      formErrorVariable = { ...formErrorVariable, companyError: ERRORWORDS.companyError }
    }
    if (areas && areas.length === 0) {
      formErrorVariable = { ...formErrorVariable, areaError: ERRORWORDS.areaError }
    }
    if (stationStatus === 'Inactive') {
      if (!effectiveFromDate) {
        formErrorVariable = { ...formErrorVariable, effectiveFromDateError: ERRORWORDS.effectiveFromDateError }
      }
      if (effectiveToDate === undefined || effectiveToDate <= effectiveFromDate) {
        formErrorVariable = { ...formErrorVariable, effectiveToDateError: ERRORWORDS.effectiveToDateError }
      }
    }
    if(cmDetailsList.length === 0){
      formErrorVariable = { ...formErrorVariable, cmDetailsError:ERRORWORDS.cmDetailsListError }
    }

    moduleFormList.forEach((moduleRow, id) => {
      const currentIndex = moduleError.findIndex((e)=> e.errorId === moduleRow.id);
      if (currentIndex > -1){
        if ( moduleRow.supplierSiteID?.trimStart() === '' || !ALPHA_NUMBER_REGEX.test(moduleRow.supplierSiteID)){
          moduleErrorCopy[ currentIndex ].supplierError = ERRORWORDS.invalidSupplierStation;
        }
        if (moduleRow.supplierSiteID?.trimStart() !== '' && ALPHA_NUMBER_REGEX.test(moduleRow.supplierSiteID)){
          moduleErrorCopy[ currentIndex ].supplierError = '';
        }

      }      
    })
    cmDetailsList.forEach((cmErrorRow, index) => {
      if (!cmDetailsErrorCopy[ cmErrorRow.id ]) {
        cmDetailsErrorCopy[ cmErrorRow.id ]={};
      }
      if (cmErrorRow.name?.trimStart() === '' || !ALPHA_NUM_REGEX.test(cmErrorRow.name)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMNameError = ERRORWORDS.cmNameError;
      }
      if (cmErrorRow.name?.trimStart() !== '' && ALPHA_NUM_REGEX.test(cmErrorRow.name)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMNameError = '';
      }
      if (cmErrorRow.address?.trimStart() === '' || !ALPHA_NUM_REGEX.test(cmErrorRow.address)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMAddressError = ERRORWORDS.cmAddressError;
      }
      if (cmErrorRow.address?.trimStart() !== '' && ALPHA_NUM_REGEX.test(cmErrorRow.address)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMAddressError = '';
      }
      if (cmErrorRow.termDays === '' || isNaN(cmErrorRow.termDays) || !NUMBER_REGEX.test(cmErrorRow.termDays) ) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formTermDaysError = ERRORWORDS.termDateError;
      }
      if (cmErrorRow.termDays !== '' && !isNaN(cmErrorRow.termDays) && NUMBER_REGEX.test(cmErrorRow.termDays) ) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formTermDaysError = '';
      }
      if (!ALL_EMAIL_RG.test((cmErrorRow.email)?.trim())) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMEmailError = ERRORWORDS.cmEmailError;
      }
      if (ALL_EMAIL_RG.test((cmErrorRow.email)?.trim())) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formCMEmailError = '';
      }
      if (cmErrorRow.ledgerAccount === '' || !ALPHA_NUM_REGEX.test(cmErrorRow.ledgerAccount)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formLedgerAccountError = ERRORWORDS.ledgerAccountError;
      }
      if (cmErrorRow.ledgerAccount !== '' || ALPHA_NUM_REGEX.test(cmErrorRow.ledgerAccount)) {
        cmDetailsErrorCopy[ cmErrorRow.id ].formLedgerAccountError = '';
      }
    })

    setModuleError( moduleErrorCopy )
    setFormError(formErrorVariable);
    setCmDetailsError({ ...cmDetailsErrorCopy })

    const isFormErrorEmpty = Object.values(formErrorVariable).every((value) => {
      if (value === null || value === '') {
        return true
      } else {
        return false
      }
    })
    const isCMErrorEmpty =  Object.keys(cmDetailsErrorCopy).filter((e) => {
      return cmDetailsErrorCopy[ e ]?.formTermDaysError ||cmDetailsErrorCopy[ e ]?.formCMNameError || cmDetailsErrorCopy[ e ]?.formCMEmailError || cmDetailsErrorCopy[ e ]?.formEndDateError || cmDetailsErrorCopy[ e ]?.formCMAddressError || cmDetailsErrorCopy[ e ]?.formLedgerAccountError || cmDetailsErrorCopy[ e ]?.formStartDateError
    });

    const isModuleErrorEmpty = moduleErrorCopy.filter((e) => {
      return   e.supplierError !== ''
    })

    if (isFormErrorEmpty && isCMErrorEmpty.length === 0
     && isModuleErrorEmpty.length === 0
    ) {
      const copyFormList = cmDetailsList.map((item, index) => {
        const toDate = milliSecondToIsoString(item.startDate)
        const fromDate = milliSecondToIsoString(item.endDate)
        const isActiveCM = index === 0 ? 1 : 0;
        return { ...item, startDate: toDate, endDate: fromDate, siteId: stationId, isActiveCM : isActiveCM }
      })
      setIsoFormList(copyFormList)
    }      
    setIsDone(!isDone);
  }
    
  useEffect(()=>{
    if (isoFormList){
      validated();
    }
  }, [ isoFormList ])
  useEffect(()=>{
    const inputError =commonScrollFunction();
    if (!inputError){
      commonScrollFunction('errorMessageDate');
    }
  }, [ isDone ])
  const deleteModuleForm = (id) =>{
    const moduleFormCopy = moduleFormList;
    const moduleErrorCopy = moduleError;
    moduleFormCopy.forEach((item, idForm ) =>{
      if(item.id === id){
        moduleFormCopy.splice(idForm,1)
        moduleErrorCopy.splice(idForm,1);
        setModuleError([ ...moduleErrorCopy ])
        setModuleFormList([ ...moduleFormCopy ]);
      }
    })
  }
  const deleteCmDetailsById = (formIndex) => {
    const cmDetailsListCopy = cmDetailsList;
    cmDetailsListCopy.forEach((item, idForm) => {
      if (item.id === formIndex) {
        cmDetailsListCopy.splice(idForm, 1)
        if(cmDetailsError[ item.id ])
        {
          cmDetailsError[ item.id ] = undefined;
        }
        setCmDetailsList([ ...cmDetailsListCopy ])
      }
    })
  }

  const onCmDetailsUpdate = (value, formID, nameField,objKey) => {
    const cmTempList = cmDetailsList.map((i,index)=>{
      if(i.id === formID){
        if(objKey === 'endDate'){
          setFormId(formID);
          return { ...i, [ nameField ] : value.getTime() }
        }
        else if(objKey === 'startDate'){
          setFormId(formID)
          return index === 0? { ...i, [ nameField ] : value.getTime(), [ 'endDate' ] : new Date(moment(value.getTime()).add(1, 'day')) }: { ...i, [ nameField ] : value.getTime() }
        }
        else{

          return { ...i,  [ nameField ]  : value }
        }
      }
      return i
    })
    setCmDetailsList(cmTempList)
  }

  const onTimeChange = (e, item, type) => {
    const time = onTimeChangeCalculate(e);
    const schedulerListCopy = schedularTimeDetailsList.map(schedule=>{
      if(schedule.day=== item.day){
        return { ...schedule, [ type ]: time }
      }
      return schedule
    })
    setSchedularTimeDetailsList(schedulerListCopy);
  }

  useEffect(() =>{
    if(fromId != null ){
      const { formErrorVariable, isError } = checkManagerDateIntersection(fromId, cmDetailsList, cmDetailsError)
      setCmDetailsError(formErrorVariable)
      if(!isError){
        setActiveCMForm(sortByDate(cmDetailsList,'startDate')[ 0 ]?.id)
        setCmDetailsList(sortByDate(cmDetailsList,'startDate'));
      }
    }
  },[ formValue ])

  const onCancelClicked = () => {
    navigate(ROUTES.STATIONLISTING, { state: {
      companyRes: locationState.companyRes,
    } })
  }

  const toggleSiteClass = () => {
    scrollTo(stationRef.current)
  };
  const toggleSupplierClass = () => {
    scrollTo(supplierRef.current)
  };
  const toggleSchedulerClass = () => {
    scrollTo(scheduleRef.current)
  };
  const toggleCmClass = () => {
    scrollTo(cmRef.current)
  };
 
  const onDoneClicked = () => {
    checkValidation();
  }

  const updateModuleSupplier = ( value, formId , nameField, objKey ) =>{
    const moduleErrorCopy = moduleError;
    const tempModuleFormList = moduleFormList.map((i, index) =>{
      if(i.id === formId){
        if(value){
          moduleErrorCopy[ index ][ 'supplierError' ] = ''
          return { ...i,  [ nameField ]  : value }
        }
      }
      return i
    })
    setModuleFormList( tempModuleFormList )

  }
  useEffect(() => {
    dispatch(setLoadingFlag(true))
    dispatch(getModuleDetails())
    if (cmDetailsList.length === 0) {
      addCMBlock();
    }
  }, [])

  useEffect(() => {
    let errorVariable = formError;

    if (stationId?.trim() !== '' && ALPHA_NUM_REGEX.test(stationId) !== false) {
      errorVariable = { ...errorVariable, stationIdError: '' }
    }
    if (stationName?.trim() !== '' && ALPHA_NUM_REGEX.test(stationName) !== false) {
      errorVariable = { ...errorVariable, stationNameError: '' }
    }
    if (stationAddress?.trim() !== '' && ALPHA_NUM_REGEX.test(stationAddress) !== false) {
      errorVariable = { ...errorVariable, stationAddressError: '' }
    }
    if (netSuiteStationId?.trim() !== '') {
      errorVariable = { ...errorVariable, netSuiteStationIdError: '' }
    }
    if (ALL_EMAIL_RG.test((stationEmail)?.trim())) {
      errorVariable = { ...errorVariable, stationEmailError: '' }
    }
    if (companyData?.length > 0){
      errorVariable = { ...errorVariable, companyError: '' }
    }
    if (effectiveFromDate){
      errorVariable = { ...errorVariable, effectiveFromDateError: '' }
    }
    if (effectiveToDate !== undefined && effectiveToDate > effectiveFromDate){
      errorVariable = { ...errorVariable, effectiveToDateError: '' }
    }
    if (areas?.length > 0){
      errorVariable = { ...errorVariable, areaError: '' }
    }
    if(cmDetailsList.length !== 0){
      errorVariable = { ...errorVariable, cmDetailsError:'' }
    }
    setFormError(errorVariable)
  }, [ stationId, stationName, stationAddress, netSuiteStationId, stationEmail, cmDetailsList, companyData ,areas, effectiveFromDate, effectiveToDate ]) 

  useEffect(() => {
    const formErrorVariable = { ...cmDetailsError };
    cmDetailsList.forEach((formRow) => {
      if (formErrorVariable[ formRow.id ]  !== undefined) {
        if (formRow.name?.trim() !== '') {
          formErrorVariable[ formRow.id ].formCMNameError = '';
        }
        if (formRow.address?.trim() !== '') {
          formErrorVariable[ formRow.id ].formCMAddressError = '';
        }
        if(formRow.termDays !== '' && ! isNaN(formRow.termDays) ){
          formErrorVariable[ formRow.id ].formTermDaysError = ''
        }
        if (formRow.ledgerAccount !== '' && ALPHA_NUM_REGEX.test(formRow.ledgerAccount)) {
          formErrorVariable[ formRow.id ].formLedgerAccountError = '';
        }
        if (ALL_EMAIL_RG.test((formRow.email)?.trim())) {
          formErrorVariable[ formRow.id ].formCMEmailError = '';
        }
      }
      
    })
    setCmDetailsError(formErrorVariable)
  }, [ cmDetailsList ])

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(stationRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ visibleSection ]);

  useEffect(()=>{
    if (locationState){
      setCompanyArr(locationState?.companyRes.res);
    }
    else{
      const data = userData.roleAccess.find((obj) => obj.readAccess === true)
      navigate('/'+data.routeIdentity)
    }
  },[])
  
  const validateStationId = async () =>{
    let errorVariable = formError
    const stationIdValidation = await checkStationInvoiceExist(0, stationId);
    if(stationIdValidation.siteCount > 0){
      errorVariable = { ...errorVariable, stationIdError
      : ERRORWORDS.stationAlreadyExist }
    }else{
      errorVariable = { ...errorVariable, stationIdError
      : '' }

    }
    setFormError(errorVariable)
  }

  useEffect(() =>{
    if(stationId !== '')
      validateStationId();
  },[ siteIdValue ])

  const onStationStatusChanged = (e) => {
    setStationStatus(e.target.value)
    if (e.target.value === 'Inactive') {
      setEffectiveFromDate(new Date())
      const tempCM = cmDetailsList.map((item, index) => {
        const endDate = index === 0 ? new Date() : item.endDate
        return { ...item, endDate: endDate }
      })
      setCmDetailsList(tempCM)
    } else {
      setEffectiveFromDate()
      setEffectiveToDate()
    }
  }

  return (
      <div>
          <div className={styles.mainContainer} ref = { mainRef }>
              <div className={configurationStyles.leftContainerStation}>
                  <div className={configurationStyles.innerSideContainer}>
                      <p className={visibleSection === ENWORDS.stationRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} onClick={toggleSiteClass} >{ENWORDS.stationDetails}</p>
                      <p className={visibleSection === ENWORDS.scheduleRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} onClick={toggleSchedulerClass}>{ENWORDS.scheduler}</p>
                      <p className={visibleSection === ENWORDS.supplierRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} onClick={toggleSupplierClass}>{ENWORDS.assignSupplier}</p>
                      <p className={visibleSection === ENWORDS.cmRefSection ? configurationStyles.activeText : configurationStyles.inactiveText} onClick={toggleCmClass}>{ENWORDS.cmDetails}</p>
                  </div>
              </div>
              <div className={configurationStyles.rightContainer}>
                  <div className={styles.content}>
                      <div id={ENWORDS.stationRefSection} ref={stationRef}>
                          <ListingHeader
                            title={ENWORDS.addNewStation}
                            navigationPath={ROUTES.STATIONLISTING} 
                            state={locationState}/>
                          <div className={styles.siteNavContainer}>
                              <div className={styles.siteNavigation}>
                                  <p className={styles.robotoMediam}>{ENWORDS.stationDetails}</p>
                                  <button onClick={() => setHiddenStation(!hiddenStation)}>
                                      <img src={!hiddenStation ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>

                          </div>

                          {!hiddenStation ?
                              <div className={styles.siteForm} >
                                  <div className={inputStyles.detailsRow}>

                                      <CustomInput
                                        title={ENWORDS.stationId}
                                        placeholder = {PLACEHOLDER.stationId}
                                        value={stationId}
                                        error={formError.stationIdError}
                                        onChange={e => setStationId((e).trim())} />

                                      <CustomInput
                                        title={ENWORDS.stationName}
                                        placeholder = {PLACEHOLDER.stationName}
                                        value={stationName}
                                        error={formError.stationNameError}
                                        onChange={e => setStationName(e)} />
                                      <CustomInput
                                        title={ENWORDS.stationAddress}
                                        value={stationAddress}
                                        placeholder = {PLACEHOLDER.address}
                                        error={formError.stationAddressError}
                                        onChange={e => setStationAddress(e)} />
                                      <CustomInput
                                        title={ENWORDS.netSuiteStationId}
                                        value={netSuiteStationId}
                                        placeholder = {PLACEHOLDER.netSuiteID}
                                        error={formError.netSuiteStationIdError}
                                        maintainDefaultCase = {true}
                                        onChange={e => setNetSuiteId((e).trim())} />
                                      <CustomInput
                                        title={ENWORDS.stationEmail}
                                        value={stationEmail ?? ''}
                                        placeholder = {PLACEHOLDER.commonEmail}
                                        error={formError.stationEmailError}
                                        maintainDefaultCase = {true}
                                        onChange={e => setStationEmail((e).trim())} />
                                      <CustomDropDown 
                                        title = {ENWORDS.siteStatus} 
                                        fieldValues = {STATUSSTATION}
                                        onChange = {e => onStationStatusChanged(e)}
                                      />
                                      <CustomDropDown
                                        title={ENWORDS.area}
                                        name={ENWORDS.area}
                                        fieldValues={areas}
                                        renderKey = {'id'}
                                        renderKeyOne = {AREANAME}
                                        error = {formError.areaError}
                                        isDisabled = {false}
                                        onChange={(e)=> setSelectedAreaId(Number(e.target.value))
                                        }
                                      /> 
                                      <MultiSelectDropdown 
                                        title={ENWORDS.companies}
                                        fieldValues = { companyArr }
                                        error = {formError.companyError}
                                        onChange = {e => setCompanyData(e)}
                                      />
                                      { stationStatus === 'Inactive' ? <CustomDatePicker
                                        title = {ENWORDS.InactiveFromDate}
                                        startDate = {effectiveFromDate}
                                        endDate = {effectiveToDate}
                                        dateFormat = {globalDateFormat}
                                        onChange={(e) => setEffectiveFromDate(e)}
                                        wrapperClass = 'stationDate'
                                        disabled ={true}
                                        errorMessage = { formError.effectiveFromDateError }
                                      /> : null}
                                      { stationStatus === 'Inactive' ? <CustomDatePicker
                                        title = {ENWORDS.InactiveToDate}
                                        startDate = {effectiveToDate}
                                        minDate = {effectiveFromDate}
                                        dateFormat = {globalDateFormat}
                                        onChange={(e) => setEffectiveToDate(e)}
                                        wrapperClass = 'stationDate'
                                        errorMessage = { formError.effectiveToDateError }
                                      /> : null}
                                  </div>
                              </div>
                            : <div></div>}
                          <hr />
                      </div>

                      <div className={styles.siteNavContainer}>
                          <div className={styles.siteNavigation}>
                              <p className={styles.robotoMediam}>{ENWORDS.scheduler}</p>
                              <button onClick={() => setHiddenSchedular(!hiddenSchedular)}>
                                  <img src={!hiddenSchedular ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                              </button>
                          </div>
                      </div>

                      {!hiddenSchedular &&
                      <div >
                          <div className={styles.cmMainForm} id = {ENWORDS.scheduleRefSection} ref={ scheduleRef }>
                              <div style={{ display:'flex', flexDirection:'column' }} className={styles.cmForm}>
                                  <div>
                                      <CustomDropDown
                                        name = {ENWORDS.status.toLowerCase()}
                                        fieldValues={STATIONSHEDULE}
                                        type = {ENWORDS.custom}
                                        onClick={toggleSchedulerClass}               
                                        onChange={e=> setSelectedStatus(e.target.value)}
                                      />
                                  </div>
                                  { selectedStatus !== OPEN_24_HRS ?
                                      <div>
                                          <div className={inputStyles.detailsRow}>
                                              { schedularTimeDetailsList.map((item ) =>{
                                                return(
                                                    <SchedularTimeComponent 
                                                      key={item.id}
                                                      day = {DAYS[ item.day - 1 ]}
                                                      onStartTimeChange={e => onTimeChange(e, item,OPENING_TIME )}
                                                      onEndTimeChange={e => onTimeChange(e, item,CLOSING_TIME)}
                                                      openingTimeTitle = {ENWORDS.openingTime} 
                                                      closingTimeTitle={ENWORDS.closingTime}
                                                      isDisabled={false} 
                                                      openingTime={moment(BASE_TIME).add(item.openingTime, 'minutes').toDate()}
                                                      closingTime={moment(BASE_TIME).add(item.closingTime, 'minutes').toDate()}
                                                    />
                                                )
                                              })
                                              }
                                          </div>
                                      </div>
                                    : null }
                              </div>
                             
                          </div>
                          <hr />
                      </div>
                      }
    
                      <div id = {ENWORDS.supplierRefSection} ref = {supplierRef}>
                          
                          <div className={styles.siteNavContainer}>
                              <div className={styles.siteNavigation}>
                                  <p className={styles.robotoMediam}>{ENWORDS.assignSupplier}</p>
                                  <button onClick={() => setHiddenSupplier(!hiddenSupplier)}>
                                      <img src={!hiddenSupplier ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>

                          </div>
                          <div className={styles.cmBtnContainer}>
                              <div className={styles.addBtn}>
                                  <button onClick={addModuleBlock}><img src={plusIcon} alt={ENWORDS.addBtn} /></button>
                              </div>
                          </div> 

                          {!hiddenSupplier ?

                            moduleFormList.map((item ) =>{
                          
                              return(
                                  <div className={styles.cmForm} >
                                      <div className={styles.assignModuleContainer}>
                                          <div className={styles.assignModule} >
                                              <div className={styles.moduleDropdownContainer} >
                                                  <CustomDropDown 
                                                    title = {ENWORDS.modulePlaceholder} 
                                                    fieldValues = {(modules.filter((el) => {
                                                      return moduleFormList.every((f) => {
                                                        return f.moduleID != el.id || f.moduleID == item.moduleID
                                                      });
                                                    }))}
                                                    selectedValue = {item.moduleID}
                                                    onChange = {e => updateModuleSupplier(e.target.value, item.id, 'moduleID')}
                                                    name = {'moduleName'}
                                                    renderKey = {'id'}
                                                    renderKeyOne= {'moduleName'}
                                                  />
                                              </div>
                                              <div className={styles.supplierDropDownContainer}>
                                                  <CustomInput
                                                    title={ENWORDS.Supplier_Site_ID}
                                                    value={item.supplierSiteID}
                                                    placeholder = {PLACEHOLDER.netSuiteID}
                                                    error = {displayError(moduleError, item.id, 'supplierError')}
                                                    onChange={e => updateModuleSupplier(e, item.id, 'supplierSiteID')} />
                                              </div>
                                          </div>
                                          <div className={styles.deleteBtnModule}>
                                              <div className={styles.deleteModuleAssign}>
                                                  <button className={styles.deleteBtnCM} onClick = {() => deleteModuleForm(item.id)} >
                                                      <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              )
                            })
                            : <div></div>} 
                      </div>
                      <hr />

                      <div id={ENWORDS.cmRefSection} ref={cmRef}>
                          <div className={styles.cmNavContainer}>
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.activeCMDetails}</p>
                              </div>
                          </div>
                          <div className={styles.cmMainForm} >
                            
                              {<p className={formError.cmDetailsError ? globalStyles.errorMessageShow: globalStyles.errorMessage}>{formError.cmDetailsError}</p> }
                              <div className={styles.cmBtnContainer}>
                                  <div className={styles.addBtn}>
                                      <button onClick={addCMBlock}><img src={plusIcon} alt={ENWORDS.addBtn} /></button>
                                  </div>
                              </div> 

                              {cmDetailsList && cmDetailsList.map((formData) => {
                                return (
                                    <div>{  formData.id === activeCMForm ? 
                                        <div className={`${ styles.cmForm }`} key={formData.id} >
                                            <div className={styles.deleteBtnCMContainer}>
                                                <button className={ styles.deleteBtnCM } onClick={() => deleteCmDetailsById(formData.id)}>
                                                    <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                                </button></div>
                                            <div className={inputStyles.detailsRow}>

                                                <CustomInput
                                                  title={ENWORDS.cmName}
                                                  value = {formData.name}
                                                  placeholder = {PLACEHOLDER.fullName}
                                                  error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMNameError}
                                                  onChange={e => onCmDetailsUpdate(e, formData.id, 'name','cmName')} />

                                                <CustomInput
                                                  title={ENWORDS.cmAddress}
                                                  value = {formData.address}
                                                  placeholder = {PLACEHOLDER.address}
                                                  error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMAddressError}
                                                  onChange={e => onCmDetailsUpdate(e, formData.id, 'address', 'cmAddress')} />
                                                      
                                                <CustomInput
                                                  title={ENWORDS.cmEmail}
                                                  value = {formData.email}
                                                  placeholder = {PLACEHOLDER.commonEmail}
                                                  error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMEmailError}
                                                  maintainDefaultCase = {true}
                                                  onChange={e => onCmDetailsUpdate((e).trim(), formData.id, 'email', 'cmEmail')} />

                                                <CustomInput
                                                  title={ENWORDS.customerLedgerAccountNo}
                                                  value = {formData.ledgerAccount}
                                                  placeholder = {PLACEHOLDER.ledgerAccNo}
                                                  error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formLedgerAccountError}
                                                  maintainDefaultCase = {true}
                                                  onChange={e => onCmDetailsUpdate((e).trim(), formData.id, 'ledgerAccount','cmledgerAccount')} />

                                                <CustomInput
                                                  title={ ENWORDS.termsDate }
                                                  placeholder = '15'
                                                  value={formData.termDays}
                                                  type='number'
                                                  error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formTermDaysError}
                                                  onChange={e => onCmDetailsUpdate(e , formData.id, 'termDays','termDays')} 
                                                />

                                                <CustomDatePicker
                                                  title = {ENWORDS.tradingStartDate}
                                                  startDate = {formData.startDate}
                                                  dateFormat = {globalDateFormat}
                                                  onChange={(e) => onCmDetailsUpdate(e, formData.id, 'startDate','startDate')}
                                                  wrapperClass = 'stationDate'
                                                  errorMessage= {
                                                    cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formStartDateError
                                                  }
                                                />
                                          
                                                { stationStatus === 'Inactive' ? <CustomDatePicker
                                                  title = {ENWORDS.tempTradingEndDate}
                                                  startDate = {formData.endDate}
                                                  dateFormat = {globalDateFormat}
                                                  onChange={(e) => onCmDetailsUpdate(e, formData.id, 'endDate', 'endDate')}
                                                  wrapperClass = 'stationDate'
                                                  errorMessage= {
                                                    cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formEndDateError
                                                  }
                                                /> : null}
                                            </div>
                                        </div> : null }
                                    </div>
                                
                                )
                              })}
                          </div>
                          <hr />
                      
                          <div className={styles.cmNavContainer} >
                              <div className={styles.cmNavigation}>
                                  <p>{ENWORDS.previousCM}</p>
                                  <button onClick={() => setHiddenCM(!hiddenCM)}>
                                      <img src={!hiddenCM ? arrowUp : arrowDown} alt={ENWORDS.sortingArrow} className={styles.upArrow} />
                                  </button>
                              </div>
                          </div>
                          <div className={styles.cmMainForm} >
                            
                              {!hiddenCM ? <div>

                                  {cmDetailsList && cmDetailsList.map((formData) => {
                                    return (<div>
                                        {

                                          formData.id !== activeCMForm ? 
                                              <div className={`${ styles.cmForm }`} key={formData.id} >
                                                  <div className={styles.deleteBtnCMContainer}>
                                                      <button className={ styles.deleteBtnCM } onClick={() => deleteCmDetailsById(formData.id)}>
                                                          <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                                      </button></div>
                                                  <div className={inputStyles.detailsRow}>

                                                      <CustomInput
                                                        title={ENWORDS.cmName}
                                                        value = {formData.name}
                                                        placeholder = {PLACEHOLDER.fullName}
                                                        error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMNameError}
                                                        onChange={e => onCmDetailsUpdate(e, formData.id, 'name', 'cmName')} />

                                                      <CustomInput
                                                        title={ENWORDS.cmAddress}
                                                        
                                                        value = {formData.address}
                                                        placeholder = {PLACEHOLDER.address}
                                                        error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMAddressError}
                                                        onChange={e => onCmDetailsUpdate(e, formData.id, 'address','cmAddress')} />
                                                      
                                                      <CustomInput
                                                        title={ENWORDS.cmEmail}
                                                        value = {formData.email}
                                                        placeholder = {PLACEHOLDER.commonEmail}
                                                        error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formCMEmailError}
                                                        maintainDefaultCase = {true}
                                                        onChange={e => onCmDetailsUpdate((e).trim(), formData.id, 'email','cmEmail')} />

                                                      <CustomInput
                                                        title={ENWORDS.customerLedgerAccountNo}
                                                        value = {formData.ledgerAccount}
                                                        placeholder = {PLACEHOLDER.ledgerAccNo}
                                                        error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formLedgerAccountError}
                                                        onChange={e => onCmDetailsUpdate(e, formData.id, 'ledgerAccount','cmLedgerAccount')} />

                                                      <CustomInput
                                                        title={ ENWORDS.termsDate }
                                                        placeholder = '15'
                                                        value={formData.termDays}
                                                        type = 'number'
                                                        error={cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formTermDaysError}
                                                        onChange={e => onCmDetailsUpdate((e), formData.id, 'termDays','temDays')} 
                                                      />
                                                        
                                                      <CustomDatePicker
                                                        title = {ENWORDS.tradingStartDate}
                                                        startDate = {formData.startDate}
                                                        dateFormat = {globalDateFormat}
                                                        onChange={(e) => onCmDetailsUpdate(e, formData.id, 'startDate','startDate')}
                                                        wrapperClass = 'stationDate'
                                                        errorMessage= {
                                                          cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formStartDateError
                                                        }
                                                      />
                                                      <CustomDatePicker
                                                        title = {ENWORDS.tradingEndDate}
                                                        startDate = {formData.endDate}
                                                        dateFormat = {globalDateFormat}
                                                        onChange={(e) => onCmDetailsUpdate(e, formData.id, 'endDate', 'endDate')}
                                                        wrapperClass = 'stationDate'
                                                        errorMessage= {
                                                          cmDetailsError[ formData.id ] && cmDetailsError[ formData.id ]?.formEndDateError
                                                        }
                                                      />

                                                  </div>
                                              </div> : null }
                                    </div>
                                    )
                                  }
                                  )}
                              </div> : <div></div>}
                              <div className={styles.btnContainer}>
                                  <div className={styles.cancelBtn}>
                                      <button onClick={onCancelClicked}>{ENWORDS.cancel}</button>
                                  </div>
                                  <div className={styles.doneBtn}>
                                      <button onClick={onDoneClicked}>{ENWORDS.done}</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default AddStationScreen