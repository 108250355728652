import { SET_CONFIGURATION_DATA } from '../../shared/constants/actionType.js';

const INIT_STATE = {
  configurationTable: [],
};

const configurationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case SET_CONFIGURATION_DATA:
    return {
      ...state,
      configurationTable: action.payload,
    };

  default:
    return state;
  }
};

export default configurationReducer;
