import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import tableStyles from '../../styles/table.module.css';
import styles from '../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import editPenIcon from '../../assets/icons/editPenIcon.svg';
import arrowDownLight from '../../assets/icons/arrowDownLight.svg';
import arrowUpLight from '../../assets/icons/arrowLightUp.svg';
import ENWORDS from '../../shared/local/en.json';
import PopUp from '../../shared/Components/PopUp';
import supplierTable from '../../styles/baseconfiguration/SupplierManagement.module.css';
import ReactPaginate from 'react-paginate';
import { ROUTES } from '../../shared/constants/appRoute';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setLoadingFlag } from '../../redux/actions';
import { defaultSortBy, globalSortBy, isoStringToDate } from '../../shared/commonFunctions';
import { deleteSupplierById, getSupplierByName } from '../../api/services/supplierManagementServices';
import { deleteCompany,  getCompanyById } from '../../api/services/companyManagementService';
import binIcon from '../../assets/icons/binIcon.svg';
import { deleteRole, getRoleById } from '../../api/services/roleManagementService';
import { checkIfInvoiceExist, checkModuleSupplierMapping, getSupplierDetailsToDisplay } from '../../api/services/commonService';
import InvoiceVerificationPopUp from '../../shared/Components/InvoiceVerificationPopUp';
import SupplierVerificationPopUp from '../../shared/Components/SupplierVerificationPopUp';
import Paginator from '../../shared/Components/Paginator';

const BaseConfigurationTable = ({ tableData, headerData, tableName, roleAccess, popUpTitle, popUpText, shouldVerify = false, isFromPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user);
  const [ visibility, setVisibility ] = useState(false);
  const [ toDeleteName, setToDeleteName ] = useState('');
  const [ toDeleteId, setToDeleteId ] = useState(0);
  const [ sortState, setSortState ] = useState(ENWORDS.ascending);
  const [ filteredData, setfilteredData ] = useState(tableData);
  const [ selectedSort, setSelectedSort ] = useState('');
  const [ invoiceList, setInvoiceList ] = useState([]);
  const [ invoicePopUpVisibility, setInvoicePopupVisibility ] = useState(false);
  const [ supplierPopUpVisibility, setSupplierPopupVisibility ] = useState(false);
  const [ assignedModule, setAssignedModule ] = useState([]);
  const [ supplierDetailsData, setSupplierDetailsData ] = useState([]);
  const [ slicedData, setSlicedData ] = useState(tableData);

  const editRole  = async (event, roleId)=>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    const res = await getRoleById(roleId);
    if(res){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITROLE, { state:{ rolesData: res } })
    }
    
  }

  const onEditCompany = async (event,  id) =>{
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    const data = await getCompanyById(id)
    if(data){
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITCOMPANY, { state:{ data: data } })
    }
  }

  const showPopUp = (event, popUpName, popupId) =>{
    event.stopPropagation();
    setVisibility(true);
    setToDeleteName(popUpName);
    setToDeleteId(popupId)
  }
  const getSupplierDetails = async (supplierName) => {
    dispatch(setLoadingFlag(true))
    const res = await getSupplierByName(supplierName)
    const successRes = res.res
    if (successRes) {
      dispatch(setLoadingFlag(false))
      return successRes
    }
  }

  const popupCloseHandler = (e) =>{
    switch (tableName) {
    case ENWORDS.roleTable:
      setVisibility(e);
      navigate(ROUTES.ROLEMANAGEMENT);
      break;
    case ENWORDS.companyTable :
      setVisibility(e)
      navigate(ROUTES.COMPANY)
      break;
    case ENWORDS.supplierTable :
      setVisibility(e)
      navigate(ROUTES.SUPPLIERMANAGEMENT)
      break;

    default:
      break;
    }
  }
  
  const onEditSupplier = async (event, supplierName) => {
    event.stopPropagation();

    const data = await getSupplierDetails(supplierName);
    if (data) {
      navigate(ROUTES.EDITSUPPLIER, { state: { data: data } })
    }
  }

  const tblRowClicked = async(tblName, isCurrentUser = false) =>{
    switch (tableName) {
    case ENWORDS.roleTable:{
      dispatch(setLoadingFlag(true));
      const data = await getRoleById(tblName);
      if(data){
        dispatch(setLoadingFlag(false))
        navigate(ROUTES.ROLEDETAILS, { state:{ data: data , roleAccess: roleAccess , isCurrentUser: isCurrentUser } })
      }
      break;
    }
    case ENWORDS.companyTable :{
      dispatch(setLoadingFlag(true))
      const data = await getCompanyById(tblName)
      if(data){
        dispatch(setLoadingFlag(false))
        navigate(ROUTES.COMPANYDETAILS, { state : { data: data, roleAccess: roleAccess  } });
      }
      break;
    }
    case ENWORDS.supplierTable :{
      dispatch(setLoadingFlag(true))
      const data = await getSupplierDetails(tblName);
      if (data) {
        dispatch(setLoadingFlag(false))
        navigate(ROUTES.SUPPLIERDETAILS, { state: { data: data, roleAccess: roleAccess, showDelete: tableData.length > 1   } })
      }
      break
    }
    default:
      navigate(ROUTES.BASESETTINGS)
      break;
    }
  }

  const onGlobalSortBy = (keyValue) => {
    const x = globalSortBy(tableData, keyValue, sortState)
    setfilteredData([ ...x[ 0 ] ]);
    setSelectedSort(keyValue);
    setSortState(x[ 1 ]);
  }

  const onDefaultSort = () => {
    setSortState(ENWORDS.ascending)
    const response = defaultSortBy(headerData, tableData, tableName);
    setfilteredData(response[ 0 ])
    setSelectedSort(response[ 1 ])
  }

  useEffect(() => {
    onDefaultSort();
    setfilteredData(tableData);
  }, [ tableData ])

  const invoicePopupCloseHandler = (e) => {
    setInvoicePopupVisibility(e);
  };
  const supplierPopupCloseHandler = (e) => {
    setSupplierPopupVisibility(e);
  };

  const showVerificationPopUp = (event, moduleName, id) => {
    event.stopPropagation();
    setInvoicePopupVisibility(true);
    setToDeleteName(moduleName);
    setToDeleteId(id);
  };
  
  const invoiceVerficationCall = async (event,formId, formName, columnName ) => {
    event.stopPropagation();
    dispatch(setLoadingFlag(true));
    const verificationCall = await checkIfInvoiceExist(formId, columnName);

    if (verificationCall.statusCode === 200) {
      dispatch(setLoadingFlag(false))
      setInvoiceList(verificationCall.res);
      showVerificationPopUp(event, formName, formId);

    }
  };

  const supplierVerficationCall = async (event, supplierId, supplierName) => {
    event.stopPropagation();
    
    dispatch(setLoadingFlag(true));
    setToDeleteId(supplierId);
    const verificationCall = await checkIfInvoiceExist(supplierId, 'supplierID');
    const moduleSupplierMapping = await checkModuleSupplierMapping(supplierId);
    const supplierData = await getSupplierDetailsToDisplay();
    // Filtering so that same supplier is not shown in invoice dropdown
    const filterdSupplierData = (supplierData.res).filter((item)=> item.id !== supplierId)
  
    setSupplierPopupVisibility(true);
    if (verificationCall.statusCode === 200) {
      dispatch(setLoadingFlag(false))
      setInvoiceList(verificationCall.res);
      setAssignedModule(moduleSupplierMapping.res);
      setSupplierDetailsData(filterdSupplierData);
      showPopUp(event, supplierName, supplierId);
    }
  };

  const deleteRow = async (id = 0, moduleSupplierList = []) => {
    dispatch(setLoadingFlag(true));

    if (tableName === ENWORDS.companyTable){
      dispatch(deleteCompany(toDeleteId))
      navigate(ROUTES.COMPANY);
    }
    else{
      dispatch(deleteRole(toDeleteId))
      navigate(ROUTES.ROLEMANAGEMENT)
    }
  };

  const deleteSupplier = ( moduleSupplierList) => {
    dispatch(deleteSupplierById(toDeleteId, moduleSupplierList))  
    navigate(ROUTES.SUPPLIERMANAGEMENT)
  };

  return (
      < div className={tableStyles.dataTable}>
          <div className={tableStyles.siteTableContainer} >
              {
                tableName === ENWORDS.supplierTable && shouldVerify? 

                    <SupplierVerificationPopUp
                      onClose={supplierPopupCloseHandler}
                      show={supplierPopUpVisibility}
                      title={ENWORDS.deleteSupplierTitle}
                      text={ENWORDS.commonCheckboxMsg}
                      checkboxMsg={ENWORDS.commonCheckboxMsg}
                      selectedName={toDeleteName}
                      invoiceList={invoiceList ?? []}
                      assignedModuleList ={assignedModule ?? []}
                      supplierDataList = {supplierDetailsData}
                      onDelete={deleteSupplier}
                    />:
                  shouldVerify ? (
                      <InvoiceVerificationPopUp
                        onClose={invoicePopupCloseHandler}
                        show={invoicePopUpVisibility}
                        title={popUpTitle?popUpTitle:''}
                        text={invoiceList.length> 0 ? tableName === ENWORDS.companyTable 
                          ? 'The company'+ENWORDS.deleteCMText  :  'The supplier'+ENWORDS.deleteCMText:  ENWORDS.commonCheckboxMsg }
                        checkboxMsg={ENWORDS.commonCheckboxMsg}
                        selectedName={toDeleteName}
                        selectedId={toDeleteId}
                        screenName={tableName === ENWORDS.companyTable ? ENWORDS.deleteCompanyScreen  :  ENWORDS.supplierDetails}
                        invoiceList={invoiceList ?? []}
                        onDelete={deleteRow}
                      />
                  ) :
                      <PopUp
                        onClose={popupCloseHandler}
                        show={visibility}
                        title={popUpTitle?popUpTitle:''}
                        text={ tableName === ENWORDS.roleTable ? ENWORDS.deleteRoleText : ENWORDS.removeSiteText}
                        selectedName={toDeleteName}
                        selectedId={toDeleteId}
                        screenName={ tableName === ENWORDS.companyTable ? ENWORDS.deleteCompanyScreen : tableName === ENWORDS.roleTable ? ENWORDS.deleteRole :  ENWORDS.supplierDetails }
                      />

              }

              <table className={styles.siteTable}>
                  <thead className={tableStyles.tableHead}>
                      <tr className={supplierTable.theadHeader}>
                          {
                            Object.keys(headerData).map(key =>
                                <th className={key === 'supplierLevel' ? supplierTable.smallField : key === 'supplierSiteCode' || key === 'supplierName' ? supplierTable.largeField : supplierTable.midField} key={key}>
                                    {key === 'action' || key === 'roleAction' || key === 'companyAction' ? headerData[ key ] : (
                                        <button onClick={() => onGlobalSortBy(key)} className={styles.filterBtn}>
                                            <p>{headerData[ key ]}</p>
                                            <img src={sortState === 'asc' && selectedSort === key ? arrowUpLight : arrowDownLight} alt={ENWORDS.showIcon} />
                                        </button>
                                    )}
                                </th>
                            )}
                      </tr>
                  </thead>
                  <tbody className={styles.siteTableBody}>

                      {slicedData.map((item) => {
                        
                        return (
                            <tr className={supplierTable.tableContent}
                              key={item[ Object.keys(item)[ 0 ] ] + item[ Object.keys(item)[ 1 ] ]}
                              onClick={
                                tableName === ENWORDS.roleTable ?
                                  () => tblRowClicked(item.roleId, item.roleName === userData.role) : 
                                  tableName === ENWORDS.companyTable ? 
                                    () => tblRowClicked(item.id) : 
                                    tableName === ENWORDS.supplierTable ? () => tblRowClicked(item.supplierName) : null}>
                                {Object.keys(headerData).map((key) =>
                                  key === 'action' ? (
                                      <td key='action' className={styles.actionIcon}>
                                          <div>
                              
                                              <button className = {tableStyles.btnOne} onClick={(event) => invoiceVerficationCall(event, item.id, item.supplierName, 'supplierID')} >
                                                  <img src={binIcon} alt={ENWORDS.deleteIcon} className={tableStyles.iconOne} />
                                              </button>
                                              <button className={tableStyles.btnTwo} onClick={(event) => onEditSupplier(event, item.supplierName)}>
                                                  <img src={editPenIcon} alt={ENWORDS.editPenIcon} className={tableStyles.iconTwo} />
                                              </button>

                                          </div>
                                      </td>

                                  ) : key === 'roleAction' ?
                                      <td key='action' className={styles.actionIcon}>
                                          {
                                            (item.roleName !== 'Default User')  &&  (item.roleName !== userData.role)
                                              ?

                                                  <div>
                                                      <button className = {tableStyles.btnOne} onClick={(event) => showPopUp(event, item.roleName,item.roleId)} >
                                                          <img src={binIcon} alt={ENWORDS.deleteIcon} className={tableStyles.iconOne} />
                                                      </button>
                                                      <button className={tableStyles.btnTwo} onClick={(event) => editRole(event, item.roleId)}>
                                                          <img src={editPenIcon} alt={ENWORDS.editPenIcon} className={tableStyles.iconTwo} />
                                                      </button>
                                                  </div>
                                            
                                              :<div style = {{ marginLeft:'1.5rem' }}>-</div>
                                          }
                                            
                                      </td>:
                                    key === 'supplierAction'?
                                        <td key='action' className={styles.actionIcon}>
                                            <div>
                                                
                                                {
                                                  tableData.length > 1?
                                                      <button className = {tableStyles.btnOne} onClick={(event) => supplierVerficationCall(event, item.id, item.supplierName)} >
                                                          <img src={binIcon} alt={ENWORDS.deleteIcon} className={tableStyles.iconOne} />
                                                      </button>:null
                                                }
                                                
                                                <button className={tableStyles.btnTwo} onClick={(event) => onEditSupplier(event, item.supplierName)}>
                                                    <img src={editPenIcon} alt={ENWORDS.editPenIcon} className={tableStyles.iconTwo} />
                                                </button>

                                            </div>
                                        </td>

                                      : key === 'companyAction' ?
                                          <td key='action' className={styles.actionIcon}>
                                              {
                                                  <div>
                                                      <button className = {tableStyles.btnOne} onClick={(event) => invoiceVerficationCall(event, item.id, item.name, 'companyID'  )} >
                                                          <img src={binIcon} alt={ENWORDS.deleteIcon} className={tableStyles.iconOne} />
                                                      </button>
                                                      <button className = {tableStyles.btnTwo} onClick={(event) => onEditCompany(event, item.id)}>
                                                          <img src={editPenIcon} alt={ENWORDS.editPenIcon}   className={tableStyles.iconTwo} />
                                                      </button>

                                                  </div>
                                              }
                                           
                                          </td> :
 
                                        key.includes('effective') ?(
                                            <td key={key}><p>{isoStringToDate(item[ key ])}</p></td>)
                                          :
                                          ( 
                                              <td key={key}><p>{item[ key ]}</p></td>
                                          )                 
                                )
                                }
                            </tr>
                        )
                      })}
                  </tbody>
              </table>
              <div>
              </div>
          </div>
          <Paginator
            filteredData={filteredData}
            dataLength={tableData.length}
            setSlicedData={setSlicedData}
            isFromPage={isFromPage}
          />
      </div>
  )
}

export default BaseConfigurationTable