import { SET_REGIONS_DATA, REGIONS_FILTER, REGIONS_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setRegionsTable = (data)=>{
  return{
    type: SET_REGIONS_DATA,
    payload: data,
  }
}

export const updateRegionFilter = (data)=>{
  return{
    type: REGIONS_FILTER,
    payload: data,
  }
}
export const updateRegionPageNumber = (data) => {
  return {
    type:REGIONS_PAGE_NUMBER,
    payload:data,
  }
}