import React, { useState, useEffect, useRef } from 'react'
import globalPopUpStyles from '../../../styles/popUp.module.css'
import styles from '../../../styles/fileUploadPopUp.module.css'
import cancelIcon from '../../../assets/icons/cancelIcon.svg';
import fileIcon from '../../../assets/icons/filesIcon.svg'
import warning from '../../../assets/icons/warningSign.svg'
import PropTypes from 'prop-types';
import { FILETYPE } from '../../../shared/constants/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setProgressbarFlag } from '../../../redux/actions';
import '../../../styles/invoiceListing/inputDateCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';

import { uploadExcludedContractNumbersExcel } from '../../../api/services/adtDailyLogService';
import arrowDownFilled from '../../../assets/icons/arrowDownFilled.svg'
import arrowUpFilled from '../../../assets/icons/arrowUpFilled.svg';
import successIcon from '../../../assets/icons/successIcon.png';
import DownloadCSVBtn from '../../../shared/Components/DownloadCSVBtn';
import uploadBtnStyles from '../../../styles/invoiceListing/InvoiceListingScreen.module.css';
import { CONTRACT_NUMBER_EXPORT_HEADERS, CONTRACT_NUMBER_EXPORT_FILE_PREFIX } from '../adtMonitoringConstant';
const ENWORDS  = require('../../../shared/local/en.json')

const ContractNumberFileUploadPopUp = (props) => {
  const fileInputRef = useRef();
  const dispatch = useDispatch()
  const excludedContractNumbers = useSelector(state => state.dailyLogsReducer.excludedContractNumbers)
  const [ show, setShow ] = useState(false);
  const [ fileName, setFileName ] = useState('');
  const [ isErrorStatus, setIsErrorStatus ] = useState(false);
  const [ isServerArrStatus, setIsArrServerStatus ] = useState(false);
  const [ isServerStatus, setIsServerStatus ] = useState(false);
  const [ selectedFile, setSelectedFile ] = useState();
  const [ isFilePicked, setIsFilePicked ] = useState(false);
  const [ isHiddenValidation, setIsHiddenValidation ] = useState(false)
  const [ erroMessage, setErrorMessage ] = useState('');
  const [ willReplaceFile, setWillReplaceFile ] = useState(false);
  const [ serverErrorArr, setServerErrorArr ] = useState([]);
  const [ serverError, setServerError ] = useState('');
  const [ isFileUploaded, setIsFileUploaded ] = useState(false);
  const [ progressValueEnabled, setProgressValueEnabled ] = useState(false);
  const [ progressValue, setProgressValue ] = useState(10);
  const [ timerValues, setTimerValues ] = useState({
    incrementValue:1,
    timeout: 1000,
  })
  const [ contractNumbers, setContractNumbers ] = useState([])
  const setDefault = () =>{
    setIsFilePicked(false)
    setIsErrorStatus(false)
    setIsArrServerStatus(false)
    setIsServerStatus(false)
    setIsFileUploaded(false);
    setSelectedFile()
  }
  const closeHandler = (e) => {
    setShow(false);
    setDefault()
    props.onClose(false);
  };

  useEffect(()=>{
    if (progressValueEnabled){
      dispatch(setProgressbarFlag({
        progressFlag:true,
        progressPercentValue:progressValue,
        progressBarMsg :  ENWORDS.uploadedProgressBarText,
      }))

      const timer = setInterval(() => {
        setProgressValue(
          (beforeValue) => (beforeValue >= 90 ? 90
            : beforeValue + timerValues.incrementValue));
      }, timerValues.timeout);
      return () => {
        clearInterval(timer);
      };

    }
  },[ progressValueEnabled, timerValues ])
  useEffect(()=>{
    if (Math.floor((progressValue+1)/10)>0){
      setTimerValues({
        incrementValue:Math.floor(progressValue/10)+1,
        timeout: (progressValue+1)*1000,
      })

    }
  }, [ progressValue ])

  useEffect(() => {
    setContractNumbers(excludedContractNumbers ? excludedContractNumbers : []);
  }, [ excludedContractNumbers ]);

  const uploadFile =  async (file) =>{
    setProgressValueEnabled(true);

    const formdata = new FormData();
    formdata.append('deleteIfExists', !willReplaceFile);
    formdata.append('', file);
    const res = await uploadExcludedContractNumbersExcel(formdata)
    dispatch(setProgressbarFlag({
      progressFlag:false,
      progressPercentValue:0,
    }))
    setProgressValueEnabled(false);
    setProgressValue(10);
    if (res.res.status === 200){
      if( res.res?.data?.message && res.res?.data?.statusCode === 409){
        setIsErrorStatus(true)
        setWillReplaceFile(true)
        setErrorMessage( res.res?.data?.message)
      } else {
        setDefault()
        setIsFileUploaded(true)
        if(props.onUploaded){
          props.onUploaded();
        }
      }      
    }
    else{
      setIsErrorStatus(false);
      setWillReplaceFile(false)
      if (Array.isArray(res.res)){
        setIsArrServerStatus(true)
        setServerErrorArr(res.res)
      }
      else{
        setIsServerStatus(true);
        setServerError(res.res)
      }
    }
  }

  const filePickHandler = async (e) =>{
    e.preventDefault();
    if(e.target.files[ 0 ].type === 'text/csv'){
      setSelectedFile(e.target.files[ 0 ]);
      setIsFilePicked(true)
    }
    else{
      setIsServerStatus(true);
      setServerError('Please select a CSV Excel file!')
    }
  }
  const onDoneClicked = () =>{
    setIsErrorStatus(false)
    setWillReplaceFile(false);
    setServerErrorArr([])
    setIsArrServerStatus(false)
    setIsServerStatus(false)
    fileInputRef.current.click()
  }

  useEffect(() => {
    if (isFilePicked){
      setFileName(selectedFile.name)
    }
  }, [ isFilePicked, selectedFile ])

  useEffect(() => {
    setShow(props.show);
  }, [ props.show ]);

  // Render components
  const fileDetailsBlock = () =>{
    return (
        <div className={styles.fileDetailsBox}>
            <div className={styles.iconContainer}>
                <img src={fileIcon} alt= {ENWORDS.fileIcon} />
            </div>
            <div className={styles.fileInfoContainer}>
                <div className={styles.topBox}>
                    <p>{ENWORDS.file}</p>
                </div>
                <div className={styles.bottomBox}>
                    <p>{fileName}</p>
                    <button onClick={onDoneClicked}>{ENWORDS.change}</button>
                </div>
            </div>
        </div> 
    )
  }

  const errorBlock = () =>{
    return (

        <div className={styles.invoiceStatusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <img src={warning} alt={ENWORDS.warningIcon}  className = {styles.statusIcon} />
                    <p className={styles.invalidText}>
                        {erroMessage}
                    </p>
                </div>
            </div>
            <div className={styles.checkContainer}>
                <input type="checkbox" checked= {!willReplaceFile} onChange={(e)=> setWillReplaceFile(!willReplaceFile)} />
                <p className={styles.invalidText}>
                    {ENWORDS.invoiceCheckboxMsg}
                </p>
            </div>
        </div>
    )
  }

  const serverErrorArrBlock = () =>{

    return (
        <div className={styles.statusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <img src={warning} alt={ENWORDS.warningIcon}  className = {styles.statusIcon} />
                    <p className={styles.invalidCount}>
                        {serverErrorArr.length}
                    </p>
                    <p className={styles.invalidText}>
                        {ENWORDS.invalidEntries}
                    </p>
                </div>
                <div className={styles.hideValidationContainer}>
                    <button onClick={ ()=> setIsHiddenValidation(!isHiddenValidation)} >
                  
                        <img src={isHiddenValidation ?  arrowUpFilled: arrowDownFilled} alt= {ENWORDS.arrow} />
                    </button>
                </div>
            </div>
            <div className={styles.bottomErrorContainer}>
                {
                  isHiddenValidation  ?
                      <div >

                          <div className={styles.errorHeader}>
                              <div className={styles.erroCol1}>
                                  <p>{ENWORDS.fieldName}</p>
                              </div>
                              <div className={styles.erroCol2}>
                                  <p>{ENWORDS.errormsg}</p>
                              </div>
                          </div>
            
                          {
                            serverErrorArr &&  serverErrorArr.map((item)=>{
                              return (

                                  <div className={styles.errorHeader}>
                                      <div className={styles.errorBodyCol1}>
                                          <p>
                                              {item.fieldName}
                                          </p>
                                      </div>
                                      <div className={styles.errorBodyCol2}>
                                          <p>
                                              {item.issue}
                                          </p>
                                      </div>
                                  </div>
                              )
                    
                            })
                          }
                      </div>

                    : <div></div>
                }

            </div>
        </div>
    )

  }

  const serverErrorBlock = () =>{

    return (
        <div className={styles.statusContainer}>
            <div className={styles.topStatusContainer}>
                <div className={styles.statusValueContainer}>
                    <p className={styles.invalidText}>
                        {serverError.message ? serverError.message : serverError}
                    </p>
                </div>
            </div>
               
        </div>
    )

  }
  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
          display: 'flex',
          alignItems: 'center',
        }}
        className={globalPopUpStyles.overlay}
      >
          <div className={styles.dailyLogPopUpContainer}>   

              <div className={globalPopUpStyles.topSection}>
                  <p>{ENWORDS.uploadExcludedContractNumber}</p>
                  <button className={globalPopUpStyles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt={ENWORDS.cancelIcon}/>
                  </button>
              </div>
              <div className={styles.topSection}>
                  <p>Note : Uploaded file should contain only one column with header 'contractNumbers'</p>
              </div>
              {
                isFileUploaded ?
              
                    <div className={styles.successStatusValueContainer}>
                        <div>
                            <img src={successIcon} alt = {ENWORDS.done} />
                        </div>
                        <p className={styles.invalidText}>
                            {ENWORDS.fileUploaded}
                        </p>
                    </div>
                  :
                    <div>
                        {
                          isFilePicked ? 
                            fileDetailsBlock()
                            :null
                        }
                        {
                          isErrorStatus ?
                            errorBlock()
                            : null
                        }
                        {
                          isServerArrStatus ?
                            serverErrorArrBlock()
                            :null
                        }
                        {
                          isServerStatus?
                            serverErrorBlock():
                            null
                        }

                        <input
                          type="file"
                          accept={FILETYPE}
                          onChange={filePickHandler}
                          onClick={(event)=> { 
                            event.target.value = null
                          }}
                          ref={fileInputRef}
                          hidden
                        />
                        <div className={globalPopUpStyles.btnSection}>
                            <div className={uploadBtnStyles.mainBtnContainer}>
                                {contractNumbers.length > 0 ?<DownloadCSVBtn
                                  tableData={contractNumbers}
                                  popUpText={ENWORDS.downloadContractCSVText}
                                  popUpTitle = {ENWORDS.downloadContractCSVFile}
                                  screenName = {ENWORDS.invoiceListingCSV}
                                  toolTitle = {ENWORDS.downloadContractTooltip}
                                  headers={CONTRACT_NUMBER_EXPORT_HEADERS}
                                  fileNamePrefix={CONTRACT_NUMBER_EXPORT_FILE_PREFIX}
                                />:<></>}
                            </div>
                            <button className={globalPopUpStyles.btnNo} style={{ marginRight: '0rem' }} onClick={closeHandler}>{ENWORDS.cancel}</button>
                            {
                              isFilePicked?(
                                  <button 
                                    className={  willReplaceFile || isServerStatus || isServerArrStatus? globalPopUpStyles.btnYesDisabled: globalPopUpStyles.btnYes} 
                                    onClick = {()=>{uploadFile(selectedFile)}} disabled = { willReplaceFile || isServerStatus || isServerArrStatus}>{ ENWORDS.upload }</button>
                              ):(
                                  <button className={globalPopUpStyles.btnYes} onClick={onDoneClicked}>{ ENWORDS.select }</button>
                              )
                            }
                        </div>
                    </div>
              }
              
          </div>

      </div>
  )
}

ContractNumberFileUploadPopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default ContractNumberFileUploadPopUp