import { SET_SETTING_DATA } from '../../shared/constants/actionType';

const INIT_STATE = {
  settingsData : '',
}

const settingReducer = (state = INIT_STATE, action) => {
  switch(action.type){
  case SET_SETTING_DATA:
    return{
      ...state,
      settingsData: action.payload,
    }
  default : return state
  }
}
export default settingReducer