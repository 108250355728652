// Module configuration table headers
export const TABLEHEADERS = {
  moduleName: 'Module',
  itemName: 'Item Name',
  prefix: 'Prefix',
  supplierName: 'Supplier',
  moduleAction: 'Action',
}

export const FIELDNAME = {
  freeText: 'Free Text',
}
export const FIELDDATATYPE = {
  integer: 'Integer',
  double: 'Double',
  string: 'String',
  percent: 'Percent',
  date:'Date',

}
export const MANDATE = {
  no: 'No',
  yes: 'Yes',
}

export const fieldNameDBList = {
  'Station ID' : 'Station ID',
  'Name' : 'Name',
  'Attendance':'Attendance',
  'Product Code':'Product Code',
  'Service Details':'Service Details',
  'Units':'Units',
  'MFG Unit Price':'MFG Unit Price',
  'Net Amount':'Net Amount',
  'vat Rate' :'vat Rate',
  'vat Amount':'vat Amount',
  'Commission' :'Commission',
  'Gross Amount' : 'Gross Amount',
  'Billing From' : 'Billing From',
  'Billing To' :'Billing To',
  'Charge To CM' :'Charge To CM',
  'Visit Fee' :'Visit Fee',
}

export const CONFIG_EXCEPTIONS_TYPES = {
  'exception 1' : 'Exception 1',
  'exception 2' : 'Exception 2 (Scratchcard Weekly)',
  'exception 3' : 'Exception 3 (Self Billing)',
}
