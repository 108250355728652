import React,{ useState, useEffect } from 'react';
import styles from '../../../../styles/baseconfiguration/BaseConfigurationScreen.module.css'
import plusIcon from '../../../../assets/icons/plusIcon.svg';
import { useNavigate } from 'react-router-dom';
import CustomSearchBox from '../../../../shared/Components/CustomSearchBox';
import { ROUTES }  from '../../../../shared/constants/appRoute';
import ENWORDS from '../../../../shared/local/en.json';
import globalStyle from '../../../../styles/global.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateComponyPageNumber } from '../../../../redux/actions/companyManagement'

const CompanyHeader = ({ setFilterData,roleAccess }) =>{
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const companyData = useSelector(state => state.companyManagement.companyTable);
  const [ filteredData, setFilteredData ]= useState(companyData);
  const [ searchText, setSearchText ] = useState('');
  const AddCompany = () =>{
    navigate(ROUTES.ADDCOMPANY)
  }
  const filteration = () =>{
    let userFilterData = companyData;

    if (searchText.length  >0){
      userFilterData = userFilterData.filter((item)=>{ 

        const companyName  = (item[ 'name' ]?.toLowerCase());
        const netSuiteId = (item[ 'netSuiteId' ]?.toLowerCase());
        const vatNumber = (item[ 'vatNumber' ]?.toLowerCase());
        const type = (item[ 'type' ]?.toLowerCase());
        return companyName?.includes((searchText.trim())?.toLowerCase()) || netSuiteId?.includes((searchText.trim())?.toLowerCase())
        || vatNumber?.includes((searchText.trim())?.toLowerCase())  || type?.includes((searchText.trim())?.toLowerCase())
      })
    }
    return userFilterData
  }
  useEffect(()=>{
    setFilterData(filteredData)
  },[ filteredData ])

  useEffect(()=>{
    setFilteredData(filteration())
  },[ searchText ])

  const onChangeSearch = (e) =>{
    setSearchText((e.target.value).trimStart());
    dispatch(updateComponyPageNumber(0))
  }
  return (
      <div className={styles.siteHeaderContainer}>
          <div className={styles.filterContainer}>
              <CustomSearchBox 
                placeholder = {ENWORDS.companyPlaceholder} 
                title = {ENWORDS.search}  
                onChange = {e => onChangeSearch(e)}/>  
              <div className = { globalStyle.filterContainerTwo}>
                  { 
                    roleAccess && roleAccess.writeAccess ? 
                        <div className={globalStyle.addContainer}>
                            <button onClick={AddCompany}>
                                <img src={plusIcon} alt={ENWORDS.plusIcon} />
                            </button>
                        </div>
                      :null}
              </div>

          </div>
      </div>
  )
}
export default CompanyHeader;