import React from 'react';
import styles from '../../../styles/Login/Login.module.css';
import dataProcessorLogo from '../../../assets/icons/dataProcessorLogo.svg';

function LoginScreen() {

  return (
      <div className={styles.entireScreen}>
          <div className={styles.loginContainer} >
              <div>
                  <img id="logoAnimate" src={dataProcessorLogo} className={styles.logo} alt="Motor Fuel Group" />
              </div>
          </div>
      </div>
  );
}

export default LoginScreen;
