export const BASE_URL = process.env.REACT_APP_BASE_URL; 
export const POST = 'post';
export const GET = 'get';
export const PUT = 'put';
export const DELETE  = 'delete';
export const PATCH = 'patch';
export const APITIMEOUT = 900000;
export const datePlus30days = 2629800000
export const datePlus1day = 86400000
export const datePlus15days = 1296000000
export const globalDateFormat = 'dd/MM/yyyy';
export const GLOBAL_DATE_FORMAT_DB = 'yyyy-MM-dd';
export const globalYearMonthDateFormat = 'MM/yyyy'; //'yyyy-MM'
export const FILETYPE = '.csv, .xlsx, .xls' ;
export const IMAGETYPE = '.png, .jpg, .jpeg, .svg'
const en = require('../local/en.json');
export const ENDIR = en;

export const GET_ROLES = 'api/authenticate/allroles';
export const GET_USERS = 'api/authenticate/allusers';
export const GET_AZUREUSER = '/api/authenticate/getADUsers';
export const API_HEADER = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

export const ALL_EMAIL_RG = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const EMAIL_RG = /^\w*[A-Za-z]+(?:([._]?\w+)*)\@motorfuelgroup.com$/; //only for domain
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#?\$%\^&\*])(?=.{8,})/;
export const OTP_REGEX = /^[0-9]{4}$/;
export const NUMBER_REGEX = /^[0-9]+$/;
export const NUMBER_REGEXX = /^\d*\.?\d*$/;
export const ALPHA_NUM_REGEX  =/[a-zA-Z]/;
export const ALPHA_NUMBER_REGEX = /^[a-zA-Z0-9]+$/;
export const IS_ALPHA = /^[a-zA-Z ]+$/;
export const IS_PERCENT_IN_RANGE = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
export const NOT_ROLE_RANGE = /([0-9])+/

export const TABS = {
  invoices : 'Accounts Receivable',
  moduleConfiguration : 'Module Configuration',
  userManagement : 'User Management',
  // baseConfiguration : 'Base Configuration',
  setup : 'Setup',
  stationListing : 'Station Listing',
  adtAlarmMonitoring : 'Alarm Monitoring',
}