import React, { useState, useEffect } from 'react';
import tableStyles from '../../styles/table.module.css';
import arrowUp from '../../assets/icons/arrowLightUp.svg';
import arrowDown from '../../assets/icons/arrowDownLight.svg';
import { convertTimetoMinutes, defaultSortBy, globalSortBy } from '../commonFunctions';
import Paginator from './Paginator';
import { GLOBALSTATUS } from '../../modules/BaseConfiguration/baseConfigurationConstant';
import { format } from 'date-fns';
import CustomInput from './CustomInput';
import editPenIcon from '../../assets/icons/editPenIcon.svg';
import CustomDropDown from './CustomDropDown';
import CustomTimePicker from './CustomTimePicker';
import moment from 'moment';
import { BASE_TIME } from '../../modules/StationListing/stationListingConstant';
import CustomDatePicker from './CustomDatePicker';
import { globalDateFormat } from '../constants/appConstants';
const ENWORDS = require('../local/en.json');

const CustomTable = ({
  data,
  headerData,
  onClickKey = 'id',
  onRowClick = () =>{},
  actions=[],
  statusStyle,
  statusList = GLOBALSTATUS,
  editableColumns = [],
  editRowCondition,
  onSaveClick = (e, row)=>{ e.stopPropagation() },
  selectedRows = [],
  setSelectedRows,
  writeAccess = true,
  isFromPage,
  sortOrder = ENWORDS.ascending,
}) => {
  const [ filteredData, setfilteredData ] = useState(data);
  const [ sortState, setSortState ] = useState(sortOrder);
  const [ selectedSort, setSelectedSort ] = useState('');
  const [ slicedData, setSlicedData ] = useState(data);
  const [ isAllChecked, setIsAllChecked ] = useState(false);
  const [ editRow, setEditRow ] = useState({})

  const isSelectAll = (e) =>{
    e.stopPropagation();
    const isActive = e.target.checked
    
    setIsAllChecked(isActive)
    if(setSelectedRows){
      if (isActive){
        setSelectedRows(filteredData.map(value => value.id));
      }
      else{
        setSelectedRows([]);
      }
    }
  }

  const isRowChecked = (event,id) =>{
    event.stopPropagation();
    const existingid = new Set(selectedRows);
    if(existingid.has(id)){
      existingid.delete(id);
    }else{
      existingid.add(id);
    }
    if(setSelectedRows){
      setSelectedRows(Array.from(existingid));
      setIsAllChecked(Array.from(existingid).length === filteredData.length);
    }
  }

  const displayTableData = () =>
    slicedData
      .map((item, index) => {
        return (
            <tr
              key={item[ Object.keys(item)[ 0 ] ] + item[ Object.keys(item)[ 2 ] ]}
            >
                {Object.keys(headerData).map((key) =>{
                  const editableCol =  editableColumns.find(col=>col.colName === key)
                  if(key?.toLowerCase().includes('action')){
                    return (
                        <td key={key}>
                            <div className={tableStyles.contentRow}>
                                {actions?.map(action => {
                                  if( action.columnName.toLowerCase() === key.toLowerCase() && 
                                      (
                                        !action.condition ||
                                       (action.condition &&  action.condition?.values.includes(item[ action.condition?.columnName ]) &&
                                       (!action.condition?.restrictAccess) ) 
                                      )
                                  ){
                                    return (
                                      action.src? 
                                        (  <button className={tableStyles.btnOne} key={action.name}>
                                            <img
                                              src={action.src}
                                              alt={action.name}
                                              title={action.actionToolTipTxt}
                                              className={tableStyles.iconOne}
                                              onClick={(event) =>
                                                action.onClick(event,item[ onClickKey ], item)
                                              }
                                            />
                                        </button>):
                                        (
                                            <button className={tableStyles.btnConfirmAction} onClick={(event)=>{ action.onClick(event,item[ onClickKey ], item)}}>
                                                {action.name}
                                            </button>
                                        )
                                    )
                                  }
                                })}
                                {key.toLowerCase() === 'actions' && editableColumns.length > 0 &&  editRow[ onClickKey ] !== item[ onClickKey ] &&
                               ( !editRowCondition || 
                                (editRowCondition && editRowCondition.values.find((obj) => obj.value === item[ editRowCondition.columnName ] && obj.condition))) && writeAccess &&
                                <button className={tableStyles.btnOne}>
                                    <img
                                      src={editPenIcon}
                                      alt={'Edit'}
                                      title={ENWORDS.editDataTxt}
                                      className={tableStyles.iconOne}
                                      onClick={(event) =>{
                                        event.stopPropagation();
                                        setEditRow(item)
                                      }
                                      }
                                    />
                                </button>
                                }
                                {  key.toLowerCase() === 'actions' && editRow[ onClickKey ]  === item[ onClickKey ] && 
                                <>
                                    <button  className={tableStyles.btnRejectAction} onClick={(e)=>{e.stopPropagation();setEditRow({})}}>
                                        Cancel
                                    </button>
                                    <button className={tableStyles.btnConfirmAction} onClick={(e)=>{ e.stopPropagation();setEditRow({}); onSaveClick(editRow)}}>
                                        save
                                    </button>
                                </>
                                }
                            </div>
                        </td>
                    )
                  }
                  else if(editableCol && editRow[ onClickKey ]  === item[ onClickKey ]){
                    if(editableCol.inputType === 'dropdown'){
                      return (
                          <td key={key} className={tableStyles.tdStyle}>
                              <CustomDropDown                          
                                fieldValues={editableCol.options}
                                type={key}
                                selectedValue={editRow[ key ]}                              
                                onChange={e =>{
                                  setEditRow(prevState => {return { ...prevState, [ key ] : e.target.value }})
                                }
                                }
                                wrapperClass = 'customDropDown'
                              ></CustomDropDown>
                          </td>
                      )
                    }
                    else if(editableCol.inputType === 'time'){
                      return(
                          <td key={key}>
                              <CustomTimePicker
                                onChange={(time)=>{ 
                                  setEditRow(prevState => {return { ...prevState, [ key ] :convertTimetoMinutes(time) }})
                                }}
                                defaultTime={moment(BASE_TIME).add(editRow[ key ], 'minutes').toDate()}
                                wrapperClass = 'adtLogsDatePicker'
                              />
                          </td>
                      )
                    }
                    else if(editableCol.inputType === 'date'){
                      return(
                          <td key={key}>
                              <CustomDatePicker
                                wrapperStyle={{ margin:'0.5rem' , minWidth: '8rem' }}
                                startDate = { new Date(editRow[ key ]) }
                                dateFormat = {globalDateFormat}
                                onChange={(date)=>{ 
                                  setEditRow(prevState => {return { ...prevState, [ key ] : date }})
                                }}
                                wrapperClass='reportLogsDatePicker'
                                endDate={new Date()}
                              />
                          </td>
                      )
                    }
                    else{
                      return (
                          <td key={key}>
                              <div style={{ margin: '0.5rem' }}>
                                  <CustomInput
                                    type={editableCol.inputType}
                                    value={ editRow[ key ]}
                                    defValue={editRow[ key ]}
                                    onChange={e =>{
                                      setEditRow(prevState => {return { ...prevState, [ key ] : e }})
                                    }
                                    }
                                    wrapperClass = 'customInput' 
                                  ></CustomInput>
                              </div>
                          </td>
                      )
                    }

                  }
                  else if(key?.toLowerCase().includes('status')){
                    return(
                        <td key={key} onClick={
                          (event) => { if(editRow[ onClickKey ] !== item[ onClickKey ]) onRowClick(event, item[ onClickKey ])}
                        }>
                            <div style={statusStyle === ENWORDS.counterStatus ? { minWidth: '12rem' } : null}
                              className={statusStyle? statusStyle[ item[ key ] ]: '' 
                              }
                            ></div>
                            {typeof item[ key ] ==='number'? statusList[ item[ key ] ]: item[ key ]}
                        </td>)
                  }
                  else if(key === 'select'){
                    return (
                      (<td key={key}> 
                          <div className={tableStyles.contentRow} >
                              <input type="checkbox" 
                                checked ={selectedRows.includes(item[ onClickKey ])} 
                                onChange = {(event)=> isRowChecked(event,item[ onClickKey ])}/>
                          </div>
                      </td>)
                    )
                  }
                  return (
                    item[ key ] === null || item[ key ] === '' ? 
                      (<td key={key} onClick={
                        (event) => { if(editRow[ onClickKey ] !== item[ onClickKey ]) onRowClick(event, item[ onClickKey ])}
                      }>
                          <p>{'-'}</p>
                      </td>) :
                    
                      <td key={key} title = {item[ key ]} onClick={
                        (event) => { if(editRow[ onClickKey ] !== item[ onClickKey ]) onRowClick(event, item[ onClickKey ])}
                      }>
                          <p>{ key?.toLowerCase().includes('date') ? item[ key ] ? ( !isNaN(Date.parse(item[ key ])) ? format(new Date(item[ key ]) , globalDateFormat): item[ key ]): '' 
                            :  (key?.toLowerCase().includes('time') && typeof item[ key ] === 'number'? 
                              format(moment(BASE_TIME).add(item[ key ], 'minutes').toDate(),'HH:mm')
                              :item[ key ] 
                            ) }</p>
                      </td>

                  ) })}
            </tr>
        );
      });

  const onGlobalSortBy = (keyValue) => {
    const x = globalSortBy(filteredData, keyValue, sortState);
    setfilteredData([ ...x[ 0 ] ]);
    setSelectedSort(keyValue);
    setSortState(x[ 1 ]);
  };

  const onDefaultSort = () => {
    setSortState(sortOrder);
    const response = defaultSortBy(headerData, data);
    setfilteredData(response[ 0 ]);
    setSelectedSort(response[ 1 ]);
  };

  useEffect(() => {
    if (sortOrder === ENWORDS.ascending) {
      onDefaultSort();
    } else {
      setfilteredData(data);
      setSelectedSort(sortOrder);
    }
    setIsAllChecked(false)
  }, [ data ]);

  useEffect(() => {
    if (selectedRows.length === 0){
      setIsAllChecked(false)
    }
  }, [ selectedRows ]);

  return (
      <div className={tableStyles.dataTable}>

          <div className={tableStyles.tableContainer}>
              <table className={tableStyles.tableTag}>
                  <thead className={tableStyles.tableHead}>
                      <tr>
                          {Object.keys(headerData).map((key) => (
                              <th
                                className={tableStyles.header}
                                key={key}>
                                  {key?.toLowerCase().includes('action')? (
                                    headerData[ key ]
                                  ) : 
                                    key === 'select' ? 
                                      (
                                          <div className={tableStyles.contentRow}>
                                              <input
                                                type="checkbox" checked={isAllChecked}  onChange = {(e)=>isSelectAll(e)}/>
                                              <p>{headerData[ key ]}</p>
                                          </div>   
                                      ) :
                                      (
                                          <div className={tableStyles.sortContainer}>
                                              <button
                                                className={tableStyles.btnSort}
                                                onClick={() => onGlobalSortBy(key)}
                                              >
                                                  <p>{headerData[ key ]}</p>
                                                  <img
                                                    src={
                                                      sortState === ENWORDS.ascending && selectedSort === key
                                                        ? arrowUp
                                                        : arrowDown
                                                    }
                                                    alt={ENWORDS.sortBy}
                                                  />
                                              </button>
                                          </div>
                                      )}
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>{displayTableData()}</tbody>
              </table>
              <div></div>
          </div>
          <Paginator
            filteredData={filteredData}
            dataLength={data?.length ?? 0}
            setSlicedData={setSlicedData}
            isFromPage={isFromPage}
          />
      </div>
  );
};
export default CustomTable;
