import React from 'react'
import styles from  '../../styles/userManagement/UserManagementScreen.module.css'
import globalStyle from  '../../styles/global.module.css'
import searchIcon from '../../assets/icons/searchIcon.svg'
const ENWORDS = require('../local/en.json')
const CustomSearchUser = ({ placeholder, title, value , onChange, fieldValues, onSelect }) => {
  const valueSelect = (e) => {
    onSelect(e) 
  }
  const searchChange = (e) => {
    onChange(e.target.value)
  }
  return (
      <div className={styles.searchContainer}>
          <p className={globalStyle.searchBoxSelect}>{title}</p>
          <div className={globalStyle.searchBox}>
              <img src={searchIcon} alt= {ENWORDS.searchIcon} className={globalStyle.searchIcon} />
              <input type='text' 
                placeholder = {placeholder} 
                value = {value} 
                onChange={(e) => searchChange(e)} 
                autoFocus={true} 
                maxLength={100} />
          </div>{
            fieldValues ?
                <div className = {globalStyle.userDataContainer}>
                    {
                      fieldValues.map((val) => {
                        return (
                            <div>
                                <label className={globalStyle.listItem} onClick ={()=> valueSelect(val.id)}>{val.givenName } ({val.mail })</label>
                            </div>
                        )
                      })
                    }
                </div> : null
          }
      </div>
  )
}

export default CustomSearchUser