import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import ListingHeader from '../../../shared/Components/ListingHeader';
import styles from '../../../styles/moduleConfiguration/addconfig.module.css';
import globalStyles from '../../../styles/global.module.css';
import arrowDown from '../../../assets/icons/arrowDownDark.svg'
import arrowUp from '../../../assets/icons/arrowUp.svg'
import editPenIcon from '../../../assets/icons/editPenWhiteIcon.svg'
import binIcon from '../../../assets/icons/binIcon.svg';
import { ROUTES } from '../../../shared/constants/appRoute';
import { deleteModuleById, getModuleById, getModuleStationMapping, getRequiredFieldsData } from '../../../api/services/moduleConfigurationService';
import { setLoadingFlag } from '../../../redux/actions';
import { checkIfInvoiceExist, getCompany, getUserRoles } from '../../../api/services/commonService';
import inputStyles from '../../../styles/inputs.module.css'
import CompanyDropdown from '../../../shared/Components/CompanyDropdown.js';
import InvoiceVerificationPopUp from '../../../shared/Components/InvoiceVerificationPopUp';
import { getSupplierList } from '../../../api/services/supplierManagementServices';
import { TABS } from '../../../shared/constants/appConstants';
import { CONFIG_EXCEPTIONS_TYPES } from '../moduleConfigurationConstant.js';
const ENWORDS = require('../../../shared/local/en.json')

const ViewModuleScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch()
  const [ fieldList, setFieldList ] = useState()
  const [ showDetails, setShowDetails ] = useState(true)
  const [ showFields, setShowField ] = useState(true)
  const [ visibility, setVisibility ] = useState(false);
  const [ toDeleteModuleId, setToDeleteModuleId ] = useState(0);
  const [ toDeleteModuleName, setToDeleteModuleName ] = useState('');
  const [ moduleData, setModuleData ] = useState();
  const [ invoiceList, setInvoiceList ] = useState([]);
  const [ visibleSection, setVisibleSection ] = useState('moduleDetails');
  const [ companyData, setCompanyData ] = useState([]);
  const moduleRef = useRef(null)
  const fieldRef = useRef(null)
  const mainRef = useRef(null)

  const sectionRefs = [
    { section: 'moduleDetails', ref: moduleRef },
    { section: 'fieldDetails', ref: fieldRef }
  ];

  const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const scrollTo = ele => {
    window.scrollTo({
      top: ele.offsetTop - mainRef.current.offsetTop,
      left : 0,
      behavior: 'smooth',
    });
  };

  const toggleModuleClass = () => {
    scrollTo(moduleRef.current);
  };

  const toggleFieldClass = () => {
    scrollTo(fieldRef.current);
  };

  const getModuleDetailsById = async (moduleId) => {
    const moduleRes = await getModuleById(moduleId)
    const moduleResData = moduleRes.res;

    if (moduleResData) {
      return moduleResData
    }

  }

  const fetchUserRoles = async () => {
    const res = await getUserRoles()
    const rolesRes = res.res
    if (rolesRes) {
      return rolesRes;
    }

  }

  const getRequired = async () => {
    const fieldRes = await getRequiredFieldsData()

    const fieldData = fieldRes.res;

    if (fieldData) {
      return fieldData.map((item, id) => (
        {
          id: id,
          fieldName: '',
          fieldNameDB: item.fieldNameDB,
          fieldDataType: item.dataType,
          mandate: 1,
        }
      ))
    }

  }

  const getStationData = async () => {
    const stationRes = await getModuleStationMapping()
    const stationResData = stationRes.res;

    if (stationResData) {
      return stationResData.map((val) => ({
        value: val, label: val,
      }))
    }
  }

  const onEditModuleClicked = async () => {
    dispatch(setLoadingFlag(true))
    const moduleDetails = await getModuleDetailsById(locationState.data.id);

    const fieldData = await getRequiredFieldsData()

    const stationData = await getStationData();

    const userRole = await fetchUserRoles();

    const companyResponse = await getCompany();
    const supplierData = await getSupplierList();

    if (moduleDetails && fieldData.res && stationData && userRole) {
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.EDITMODULECONFIGURATION,
        {
          state:
          {
            data: moduleDetails,
            fieldValues: fieldData.res,
            stationValue: stationData,
            roleValue: userRole,
            companyList: companyResponse.res,
            supplierDataList : supplierData.res,
          },
        })
    }
  }

  const showPopUp = (moduleName, moduleID) => {
    setVisibility(true);
    setToDeleteModuleName(moduleName);
    setToDeleteModuleId(moduleID)
  }

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(moduleRef?.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ visibleSection ]);

  useEffect(() => {
    if (locationState) {

      setModuleData(locationState.data);
      setFieldList(locationState.data.fieldMapper);

      setCompanyData(locationState.data.companyAssignment);
    }
  }, [])

  const invoiceVerficationCall = async (formId, formName) => {

    dispatch(setLoadingFlag(true));
    const verificationCall = await checkIfInvoiceExist(formId, 'moduleId');

    if (verificationCall.statusCode === 200) {
      dispatch(setLoadingFlag(false))
      setInvoiceList(verificationCall.res);
      showPopUp(formName, formId);
    }
  };
  const deleteRow = () => {
    dispatch(setLoadingFlag(true));
    dispatch(deleteModuleById(toDeleteModuleId));
    navigate(ROUTES.MODULECONFIGURATION);
  };

  return (
      <div className={styles.mainContainer}  ref={mainRef}>
          {
            moduleData ?
                <InvoiceVerificationPopUp
                  onClose={popupCloseHandler}
                  show={visibility}
                  title={ENWORDS.deleteModuleTitle}
                  text={invoiceList.length >0 ?'The module'+ ENWORDS.deleteCMText: ENWORDS.commonCheckboxMsg}
                  checkboxMsg={ENWORDS.commonCheckboxMsg}
                  selectedName={toDeleteModuleName}
                  selectedId={toDeleteModuleId}
                  screenName={ENWORDS.editStation}
                  invoiceList={invoiceList ?? []}
                  onDelete={deleteRow}
                />
              : null}

          <div className={styles.leftContainer}>
              <div className={styles.innerSideContainer}>
                  <p className={visibleSection === 'moduleDetails' ? styles.activeText : styles.inactiveText} onClick={toggleModuleClass} >{ENWORDS.moduleDetails}</p>
                  <p className={visibleSection === 'fieldDetails' ? styles.activeText : styles.inactiveText} onClick={toggleFieldClass}>{ENWORDS.fieldDetails}</p>
              </div>
          </div>

          {
            moduleData ?
                <div className={styles.rightContainer} id='moduleDetails' ref={moduleRef}>
                    <div className={styles.moduleDetailsTopContainer}>
                        <ListingHeader
                          title={ENWORDS.viewModule}
                          navigationPath={ROUTES.MODULECONFIGURATION}
                        />
                        {
                          locationState.roleAccess &&
                          locationState.roleAccess.writeAccess &&
                          locationState.moduleAccess === 2 && moduleData?.moduleName !== TABS.adtAlarmMonitoring
                            ?

                                <div className={styles.buttonContainer}>
                                    <button className={styles.btnCancel} onClick={() => invoiceVerficationCall(moduleData.id, moduleData.moduleName)}>
                                        <img src={binIcon} alt={ENWORDS.deleteIcon} />
                                    </button>
                                    <button className={styles.btnDone} onClick={onEditModuleClicked}>
                                        <img src={editPenIcon} alt={ENWORDS.editPenIcon} />
                                    </button>
                                </div>
                            : null}
                    </div>
                    <div className={styles.detailsContainer} >
                        <div className={styles.headerContainer}>
                            <p>{ENWORDS.moduleDetails}</p>
                            <button onClick={() => setShowDetails(!showDetails)}>
                                <img src={showDetails ? arrowUp : arrowDown} alt="arrow" className={styles.downArrow} />
                            </button>

                        </div>
                        <div className={showDetails ? styles.viewDetailsTopContainer : styles.detailsTopContainerHidden}>
                            <div className={inputStyles.detailsRow}>
                                <div className={styles.displayContainer}>
                                    <p className={globalStyles.filterText}>{ENWORDS.moduleName}</p>
                                    <p className={globalStyles.displayText}>{moduleData.moduleName}</p>
                                </div>
                                <div className={styles.displayContainer}>
                                    <p className={globalStyles.filterText}>{ENWORDS.itemName}</p>
                                    <p className={globalStyles.displayText}>{moduleData.itemName}</p>
                                </div>
                                <div className={styles.displayContainer}>
                                    <p className={globalStyles.filterText}>{ENWORDS.transactionType}</p>
                                    <p className={globalStyles.displayText}>{moduleData.transactionType}</p>
                                </div>
                                <div className={styles.displayContainer}>
                                    <p className={globalStyles.filterText}>{ENWORDS.prefix}</p>
                                    <p className={globalStyles.displayText}>{moduleData.prefix}</p>
                                </div>
                                <div className={styles.displayContainer}>
                                    <p className={globalStyles.filterText}>{ENWORDS.supplierDetails}</p>
                                    <p className={globalStyles.displayText}>{moduleData.supplierName}</p>
                                </div>
                                <CompanyDropdown
                                  title={ENWORDS.companies}
                                  name={ENWORDS.companies}
                                  fieldValues={companyData}
                                />
                                <div className={styles.displayContainer}>
                                    <p className={globalStyles.filterText}>{ENWORDS.exception}</p>
                                    <p className={globalStyles.displayText}>{CONFIG_EXCEPTIONS_TYPES[ moduleData.exception ]}</p>
                                </div>
                                <div className={styles.displayCheckboxContainer}>
                                    <input className={styles.checkboxInput} 
                                      type="checkbox" 
                                      id="arExport" 
                                      name="arExport" 
                                      value={moduleData.arExport}
                                      checked={moduleData.arExport}
                                      disabled/>
                                    <p className={styles.exportButtons}>{ENWORDS.arExport}</p>
                                </div>
                                <div className={styles.displayCheckboxContainer}>
                                    <input className={styles.checkboxInput} 
                                      type="checkbox" 
                                      id="apExport" 
                                      name="apExport" 
                                      value={moduleData.apExport}
                                      checked={moduleData.apExport}
                                      disabled/>
                                    <p className={styles.exportButtons}>{ENWORDS.apExport}</p>
                                </div>
                                <div className={styles.displayCheckboxContainer}>
                                    <input className={styles.checkboxInput} 
                                      type="checkbox" 
                                      id="nlExport" 
                                      name="nlExport" 
                                      value={moduleData.nlExport}
                                      checked={moduleData.nlExport}
                                      disabled/>
                                    <p className={styles.exportButtons}>{ENWORDS.nlExport}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.fieldContainer} id='fieldDetails' ref={fieldRef}>
                        <div className={styles.headerContainer}>
                            <p>{ENWORDS.fieldDetails}</p>
                            <button onClick={() => setShowField(!showFields)}>
                                <img src={showFields ? arrowUp : arrowDown} alt={ENWORDS.arrow} className={styles.downArrow} />
                            </button>
                        </div>
                        <div className={showFields ? styles.innerFieldContainer : styles.innerFieldContainerHidden}>
                            {
                              fieldList && fieldList.map((fieldData, fieldId) => {
                                return (
                                    <div className={styles.viewFieldDetailsBlock} key={fieldData.id}>
                                        <div className={styles.displayContainer}>
                                            <p className={globalStyles.filterText}>{ENWORDS.fieldName}</p>
                                            <p className={globalStyles.displayText}>{fieldData.fieldName}</p>
                                        </div>
                                        <div className={styles.displayContainer}>
                                            <p className={globalStyles.filterText}>{ENWORDS.fieldNameDB}</p>
                                            <p className={globalStyles.displayText}>{fieldData.fieldNameDB}</p>
                                        </div>
                                        <div className={styles.displayContainer}>
                                            <p className={globalStyles.filterText}>{ENWORDS.fieldDatatype}</p>
                                            <p className={globalStyles.displayText}>{fieldData.fieldDataType}</p>
                                        </div>
                                        <div className={styles.displayContainer}>
                                            <p className={globalStyles.filterText}>{ENWORDS.mandate}</p>
                                            <p className={globalStyles.displayText}>{fieldData.mandate === 1 ? ENWORDS.yes : ENWORDS.no}</p>
                                        </div>
                                        <div className={styles.displayContainer}>
                                            <p className={globalStyles.filterText}>{ENWORDS.defaultValueTitle}</p>
                                            <p className={globalStyles.displayText}>{fieldData.defaultValue === null ? ENWORDS.hyphen : fieldData.defaultValue}</p>
                                        </div>
                                    </div>
                                )

                              })
                            }
                        </div>

                    </div>
                </div>
              : null
          }

      </div>
  )
}

export default ViewModuleScreen