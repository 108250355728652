import React from 'react';
import  styles from '../../styles/dashboard/AdminDashboardScreen.module.css';
const ENWORDS = require('../../shared/local/en.json')

const CustomDropDown = ({ title, selectedValue,onChange, dropdownData }) => {
  const changeDropDown = (e) =>{
    if(onChange){
      onChange(e);
    }
  }

  return (

      <div className={styles.siteContainer}>
          <p className={styles.filterText}>{title}</p>
          <select name={title} id={title}  onChange={changeDropDown} value = { selectedValue}>
              {
                !dropdownData?
                    <option  hidden  >{ENWORDS.all}</option>
                  :null
              }
              {
                dropdownData && dropdownData.map((item)=>{
                  return (
                      <option value={Object.values(item)[ 0 ]} 
                        datakey = {Object.keys(item)[ 0 ]}
                        key={Object.keys(item)[ 0 ]}>
                          { Object.values(item)[ 0 ] } 
                      </option>
                  );
                })
              }
          </select>
      </div>
                
  )
}
export default CustomDropDown