import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../styles/inputs.module.css'
import globalStyles from '../../../styles/global.module.css';
import { MODULEASSIGNMENT } from '../userManagementConstants'
import editPenWhite from '../../../assets/icons/editPenWhiteIcon.svg';
import { ROUTES } from '../../../shared/constants/appRoute';
import { useNavigate, useLocation } from 'react-router-dom';
import ListingHeader from '../../../shared/Components/ListingHeader';
import tableStyles from '../../../styles/userManagement/UserManagementTable.module.css'
import CompanyDropdown from '../../../shared/Components/CompanyDropdown';
import { getCompany } from '../../../api/services/commonService';
import InvoiceAssignmentTable from '../../../shared/Components/InvoiceAssignmentTable';
const ENWORDS  =  require('../../../shared/local/en.json')

const UserDetailsScreen = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const companyModule = useSelector((state)=> state.common.companyModuleAccess)
  const [ moduleTable, setModuleTable ] = useState([]);
  const [ userData, setUserData ] = useState();
  const [ profileImg, setProfileImg ] = useState();
  const [ companyData, setCompanyData ] = useState([]);
  const [ access, setAccess ]  = useState([]);
  
  const onEditClicked = async () =>{
    const company = await getCompany();
    navigate(ROUTES.EDITUSERSCREEN, { state:{
      data: userData,
      rolesData: locationState.rolesData,
      companyData : company.res,
    } })
  }

  useEffect(() => {
    if (locationState){
      setUserData(locationState.data);
      setAccess(locationState.data.assignedModules)
      if(locationState.data.profilePicture !== ''){
        setProfileImg(ENWORDS.base64Prefix+ locationState.data.profilePicture)
      }else{
        setProfileImg(locationState.data.profilePicture)
      }
      setCompanyData(locationState.data.companyAssignment)
    }
  }, [])

  const moduleFilteration = () =>{
    const filteredCompanyData = companyModule.filter(e => e.cd.some(k=> companyData.some(j=> j.companyID === k.id)))
    setModuleTable(filteredCompanyData)
  }

  useEffect(()=>{
    
    if (companyData.length !== 0 && companyModule.length!== 0){
      moduleFilteration()
    }

  },[ companyModule, companyData ])
  
  return (
      <div className={styles.mainContainer}>
          {
            userData ? 
                <div className={styles.subContainer}>
                    <div className={styles.userDetailsTopContainer}>
                        <ListingHeader
                          title ={ENWORDS.userDetails}
                          navigationPath = {ROUTES.USERMANAGEMENTSCREEN}
                          state={locationState}
                        />
                        {
                          locationState.roleAccess && locationState.roleAccess.writeAccess 
                            ? 
                                <div className={styles.buttonContainer}>
                                    <button className={styles.btnDone} onClick={onEditClicked}>
                                        <img src={editPenWhite} alt ={ENWORDS.editPenIcon}/>
                                    </button>
                                </div>
                            :null
                        }
                       
                    </div>

                    <div className={styles.mainDetailsContainer}>
                        <p>{ENWORDS.userDetails}</p>
                        <div className={styles.detailsContainer} key = { userData.email }>
                            <div className={styles.topContainer}>
                                <div className={styles.col1}>
                                    <div className={globalStyles.inputContainer}>
                                        <p className={globalStyles.filterText}>{ENWORDS.firstName}</p>
                                        <p className={globalStyles.displayText}>{userData.firstName}</p>
                                    </div>
                                </div>
                                <div className={styles.col2}>
                                    <div className={globalStyles.inputContainer}>
                                        <p className={globalStyles.filterText}>{ENWORDS.lastName}</p>
                                        <p className={globalStyles.displayText}>{userData.lastName}</p>
                                    </div>  
                                </div>
                                <div className={styles.col4}>
                                    <div className={globalStyles.inputContainer}>
                                        <p className={globalStyles.filterText}>{ENWORDS.email}</p>
                                        <p className={globalStyles.displayTextLower}>{userData.email}</p>
                                    </div>
                                </div>
                                <div className={styles.col4}>
                                    <div className={globalStyles.inputContainer}>
                                        <p className={globalStyles.filterText}>{ENWORDS.role}</p>
                                        <p className={globalStyles.displayText}>{userData.roleName}</p>
                                    </div>
                                </div>
                                <CompanyDropdown
                                  title = {ENWORDS.companies} 
                                  name = {ENWORDS.companies}
                                  fieldValues = {companyData} />
            
                            </div>
                            <div className={styles.imageContainer}>
                                <p>{ENWORDS.profileImg}</p>
                                {
                                  profileImg === ''
                                    ?
                                        <div className={`${ tableStyles.profileAvatar } ${ tableStyles.avatarLetters }`}>
                                            <p className= {tableStyles.avatarChars}>{userData.firstName.charAt(0) + userData.lastName.charAt(0)}</p>
                                            
                                        </div>
                                    :
                                        <div className = {styles.profileImageContainer}>
                                            <img src={profileImg} alt = {ENWORDS.userProfileImg} className={styles.profileImg} />
                                        </div>
                                }
                               
                            </div>
                        </div>
                        <div className={styles.divider}>
                        </div>
                        {
                          access ?
                              <div className={styles.mainModuleContainer}>
                                  <p>{ENWORDS.moduleAssignment}</p>
                                  {
                                    moduleTable && moduleTable.length > 0?
                                        <InvoiceAssignmentTable
                                          headerData={MODULEASSIGNMENT}
                                          onSetAccessDetails = {access} 
                                          data={moduleTable} 
                                          canEdit = {false} />
                                      :null
                                  }

                              </div>

                            :
                              <div></div>
                        }

                    </div>

                </div>
              :null
          }
        
      </div>
  )
}

export default UserDetailsScreen