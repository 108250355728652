import {  SET_STATION_TABLE, STATION_FILTER, STATION_PAGE_NUMBER } from '../../shared/constants/actionType';

const INIT_STATE = {
  stationTable :[],
  stationStatusFilter: '',
  pageNumber: 0,
}

const stationListingReducer = (state = INIT_STATE, action) =>{
  switch(action.type){
  case SET_STATION_TABLE : 
    return{
      ...state,
      stationTable : action.payload,
    }
  case STATION_FILTER : 
    return{
      ...state,
      stationStatusFilter : action.payload,
    }
  case STATION_PAGE_NUMBER : 
    return{
      ...state,
      pageNumber : action.payload,
    }
  default : return state;
  }
}

export default stationListingReducer