import { SET_OPS_REGIONS_DATA, OPS_REGIONS_FILTER, OPS_REGIONS_PAGE_NUMBER } from '../../shared/constants/actionType';

export const setOpsRegionsTable = (data)=>{
  return{
    type: SET_OPS_REGIONS_DATA,
    payload: data,
  }
}
export const updateOpsRegionFilter = (data)=>{
  return{
    type: OPS_REGIONS_FILTER,
    payload: data,
  }
}

export const updateOpsRegionPageNumber = (data) => {
  return {
    type:OPS_REGIONS_PAGE_NUMBER,
    payload:data,
  }
}