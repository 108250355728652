import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../styles/invoiceVerificationPopup.module.css';
import globalStyles from '../../styles/global.module.css';
import PropTypes from 'prop-types';
import cancelIcon from '../../assets/icons/cancelIcon.svg';
import { setLoadingFlag } from '../../redux/actions';

const ENWORDS = require('../../shared/local/en.json');

const SupplierVerificationPopUp = (props) => {
  const dispatch = useDispatch();
  const [ show, setShow ] = useState(false);

  const [ shouldDelete, setShouldDelete ] = useState(false);
  const [ supplierDataList, setSupplierDataList ] = useState([]);

  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [ props.show, props.selectedUserName ]);

  const onDeleteHandler = () => {
    dispatch(setLoadingFlag(true));
    props.onDelete(supplierDataList);
    setShow(false);
    props.onClose(false);
  };

  const onSupplierDropdownUpdate = (supplierId, index) =>{
    supplierDataList[ index ].supplierId  = supplierId;
  }

  useEffect(()=>{
    let moduleCopy = []  
    if (props.assignedModuleList.length && props.supplierDataList.length){
      // Setting default value for each module
      props.assignedModuleList.forEach((assignModuleItem)=>{
        const moduleSupplierStruct = {
          moduleId : assignModuleItem.moduleId,
          supplierId : props.supplierDataList[ 0 ].id,
        }
        moduleCopy = [ ...moduleCopy, moduleSupplierStruct ];
      })
    }
    setSupplierDataList(moduleCopy)
  }, [ props.assignedModuleList, props.supplierDataList ])

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={styles.overlay}
      >
          <div className={styles.popup}>
              <div className={styles.topSection}>
                  <p>{props.title}</p>
                  <button className={styles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt={ENWORDS.cancelIcon} />
                  </button>
              </div>
              {
                props.invoiceList.length === 0 && props.assignedModuleList.length === 0
                  ?
                      <div className={styles.middleSection}>
                          <p>{props.text}</p>
                      </div>
                  :null
              }

              { props.invoiceList.length > 0 ? (
                  <div>
                      <div className={styles.middleSection}>
                          <p>{ENWORDS.assignedInvoiceMsg}</p>
                      </div>
                      <div className={styles.listingSection}>
                          {props.invoiceList.map((item, value) => {
                            return <p>{item.invoiceNumber}</p>;
                          })}
                      </div>
                  </div>

              ) : null}

              {
                props.assignedModuleList.length ?
                    <div>
                        <div className={styles.middleSection}>
                            <p>{ENWORDS.assignedSupploierMsg}</p>
                        </div>
                        <div className={styles.moduleSupplierMainSection}>
                            <div className={styles.errorHeader}>
                                <div className={styles.erroCol1}>
                                    <p>{ENWORDS.moduleName}</p>
                                </div>
                                <div className={styles.erroCol2}>
                                    <p>{ENWORDS.supplierName}</p>
                                </div>
                            </div>
  
                            {
                              props.assignedModuleList &&  props.assignedModuleList.map((item, index)=>{
                                return (

                                    <div className={styles.errorHeader}>
                                        <div className={styles.errorBodyCol1}>
                                            <p>{item.moduleName}</p>
                                        </div>
                                        <div className={globalStyles.roleContainer}>
                                            <div className={ styles.selectionBox }>
                                                <select 
                                                  onChange={(e)=> onSupplierDropdownUpdate(Number(e.target.value), index)}
                                                >
                                                    {
                                                      props.supplierDataList && props.supplierDataList.map((supplierItem) => {
                                                        return <option
                                                          key={supplierItem[ 'id' ]}
                                                          value={supplierItem[ 'id' ]?? supplierItem}
                                                          datakey={supplierItem[ 'id' ]}
                                                        >{supplierItem[ 'supplierName' ]} </option>
                                                      }) 
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )
          
                              })
                            }
                        </div> 
                    </div>
                  :null
              }

              {
                props.invoiceList.length || props.assignedModuleList.length ?
                    <div className={styles.checkContainer}>
                        <input
                          type="checkbox"
                          checked={!shouldDelete}
                          onChange={(e) => setShouldDelete(!shouldDelete)}
                        />
                        <p className={styles.invalidText}>{props.checkboxMsg}</p>
                    </div>
                  :null
              }

              <div className={styles.btnSection}>
                  <button className={styles.btnNo} onClick={closeHandler}>
                      {ENWORDS.no}
                  </button>
                  <button
                    className={shouldDelete ? styles.btnYesDisabled : styles.btnYes}
                    onClick={onDeleteHandler}
                  >
                      {ENWORDS.yes}
                  </button>
              </div>
          </div>
      </div>
  );
};
SupplierVerificationPopUp.propTypes = {
  selectedId: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  selectedModuleID: PropTypes.number,
};
export default SupplierVerificationPopUp;
