import React, { useState, useEffect } from 'react';
import headingTableStyle from '../../../styles/baseconfiguration/SupplierManagement.module.css';
import styles from '../../../styles/baseconfiguration/BaseConfigurationScreen.module.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import tableContainerStyles from '../../../styles/table.module.css';
import tableIcon from '../../../styles/table.module.css';
import binIcon from '../../../assets/icons/binIcon.svg';
import menuIcon from '../../../assets/icons/menu.svg'
import disabledMenuIcon from '../../../assets/icons/menuDisabled.svg'
import configStyles from '../../../styles/adtConfiguration/ConfigurationScreen.module.css'
const ENWORDS = require('../../../shared/local/en.json');

const ConfigTable = ({
  tableData,
  headerData,
  editClicked,
  saveClicked,
  actionIcon,
}) => {

  const [ data , setData ] = useState(tableData);
  const handleDragEnd = e => {
    if (!e.destination) return;
    const tempData = data;
    const [ sourceData ] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, sourceData);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    display:'flex',
    alignItems:'center',
    padding: '0rem',
    width:'100%',
    textAlign: 'inherit',
    fontSize: 'var(--global-table-08rem)',
    fontWeight: '400',
    fontFamily: 'Roboto-Regular',
    letterSpacing: '0.02rem',
    color: 'var(--tableContent-color)',
    textTransform: 'capitalize',
    justifyContent: 'space-around',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  useEffect(() => {
    setData(tableData);

  }, [ tableData ]);

  return (

      <DragDropContext onDragEnd={handleDragEnd}>
          <div className={`${ tableContainerStyles.siteTableContainer } ${ configStyles.tableContainer }`}>
              <table>
                  <thead>
                      <tr style={{ display:'flex' }} className={headingTableStyle.theadConfigHeaders}>
                          {Object.keys(headerData).map(key => (
                              <th key={key}>{headerData[ key ]}</th>
                          ))}
                      </tr>
                  </thead>
                  
                  <Droppable droppableId='droppable-1'>
                      { (provider) => (
                          <tbody 
                            className={styles.siteTableBody}
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                          >
                              {data?.map((item, index) => (
                                  <Draggable
                                    key={item?.penaltyId}
                                    draggableId={(item?.penaltyId)?.toString()}
                                    index={index}
                                  >
                                      {(providers, snapshots) => (
                                          <div className={editClicked ? configStyles.row: configStyles.disabledRow}>
                                              <tr
                                                {...providers.draggableProps}
                                                ref={providers.innerRef}
                                                style={ getItemStyle(
                                                  snapshots.isDragging,
                                                  providers.draggableProps.style
                                                ) }
                                              >
                                                  { editClicked ?
                                          
                                                      <td className={configStyles.tableRowCell}
                                                        {...providers.dragHandleProps}
                                                      >  
                                                          <img src={menuIcon} alt={'='}/>
                                                      </td>
                                          
                                                    :  
                                                      <td className={configStyles.tableRowCell}>  
                                                          <img src={disabledMenuIcon} alt={'='}/>
                                                      </td>
                                                  }
                                      
                                                  <td className={configStyles.tableRowCell}>{item.type}</td>
                                                  <td className={configStyles.tableRowCell}>{item.applicableFine}</td>
                                                  <td className={configStyles.tableRowCell}>{item.vatCode}</td>
                                                  <td key='action' className={styles.actionIcon} style={{ width:'3rem' }}>
                                                      { editClicked === true ?
                                                  
                                                          <div>                                                                 
                                                              <button className = {tableIcon.btnOne} onClick={(event) => actionIcon(event, item?.penaltyId )} >
                                                                  <img  title={ENWORDS.deleteDataTxt} src={binIcon} alt={ENWORDS.deleteIcon} className={tableIcon.iconOne} />
                                                              </button>
                                                          </div>
                                                  
                                                        : <div> - </div>
                                                      }
                                                  </td> 
                                              </tr>
                                          </div>
                                      )}
                                  </Draggable>
                              ))}
                              {provider.placeholder}
                      
                          </tbody>
                  
                      )}
                  </Droppable>
              </table>

          </div>
      </DragDropContext>

  );
};
export default ConfigTable;
