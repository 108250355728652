import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import styles from '../../../styles/inputs.module.css'
import { MODULEASSIGNMENT } from '../userManagementConstants'
import ImageUploadPopUp from '../../../shared/Components/ImageUploadPopUp';
import cancelIcon from '../../../assets/icons/cancelIcon.svg';
import { ROUTES } from '../../../shared/constants/appRoute';
import CustomInput from '../../../shared/Components/CustomInput';
import CustomDropDown from '../../../shared/Components/CustomDropDown';
import { editUserDetails, getUserDetails } from '../../../api/services/userManagementService';
import { setLoadingFlag } from '../../../redux/actions';
import ListingHeader from '../../../shared/Components/ListingHeader';
import MultiSelectDropdown from '../../../shared/Components/MultiSelectDropdown';
import InvoiceAssignmentTable from '../../../shared/Components/InvoiceAssignmentTable';
import { getCompanyModuleMapping } from '../../../api/services/commonService';
import { commonScrollFunction } from '../../../shared/commonFunctions';
const ENWORDS  =  require('../../../shared/local/en.json')
const ERRORWORDS  =  require('../../../shared/local/error.json');
const PLACEHOLDER  =  require('../../../shared/local/placeholder.json');

const EditUserScreen = (props) => {

  const companyModule = useSelector((state)=> state.common.companyModuleAccess)
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch();
  
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ azureID, setAzureID ] = useState('');
  const [ roleIndex, setRoleIndex ]  = useState(); 
  const [ roleName, setRoleName ]  = useState(''); 
  const [ visibility, setVisibility ] = useState(false);
  const [ profileImg, setProfileImg ] = useState(null)
  const [ isValidated, setIsValidated ] = useState(false)
  const [ access, setAccess ]  = useState();
  const [ userData, setUserData ] = useState();
  const [ rolesArr, setRolesArr ] = useState();
  const [ error, setError ] = useState({});
  const [ base64Profile, setBase64Profile ] = useState();
  const [ companyArr, setCompanyArr ] = useState([]);
  const [ companyData, setCompanyData ] = useState([]);
  const [ displayData, setDisplayData ] = useState([]);
  const currentUserData = useSelector(state => state.auth.user)
  const [ moduleTable, setModuleTable ] = useState([]);
  const [ isDone, setIsDone ] = useState(false);
  const accessState = (receivedAccessValues) =>{
    
    setAccess(receivedAccessValues)
  } 

  const showPopUp = () =>{
    setVisibility(true);
  }
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const addImg = (receivedImg) =>{
    setProfileImg(receivedImg);
    const index  = receivedImg.indexOf(',')
    setBase64Profile(receivedImg.slice(index+1))

  }

  const checkValidation = () => {
    let errorCopy = error
    if (companyData && companyData.length === 0) {
      errorCopy = { ...errorCopy, companyError: ERRORWORDS.companyError }
    }
    
    const isErrorEmpty = Object.keys(errorCopy).filter((item)=>{
      return errorCopy[ item ] !== ''
    })
    
    if (
      isErrorEmpty.length === 0
    ){
      setError({})
      setIsValidated(true)
      
    }
    setError(errorCopy);
    setIsDone(!isDone);
  }

  const validated = async () =>{
    
    const requestBody = {
      id: userData.id,
      azureID : azureID,
      firstname:firstName, 
      lastname: lastName, 
      profilePicture: base64Profile,
      role:Number(roleIndex),
      assignedModules: access, 
      companyAssignment:companyData,
    }
    
    dispatch(setLoadingFlag(true))
    
    const res = await editUserDetails(requestBody)
    
    const successRes = res.res
    if (successRes){
      dispatch(getUserDetails());
      dispatch(setLoadingFlag(false))
      navigate(ROUTES.USERMANAGEMENTSCREEN, { state: locationState })
    }
    setIsValidated(false);

  }
  const onDoneClicked = ()=>{
    checkValidation();
  }

  const setData = async () =>{
    setAzureID(userData.azureID);
    setFirstName(userData.firstName)
    setLastName(userData.lastName)
    setEmail(userData.email)
    setRoleIndex(userData.roleId)
    setRoleName(userData.roleName);
    setAccess(userData.assignedModules)
    if(userData.profilePicture !== ''){
      setProfileImg(ENWORDS.base64Prefix+ userData.profilePicture)
    }else{

      setProfileImg(userData.profilePicture)
    }
    setBase64Profile(userData.profilePicture);
  }

  const removeProfileImg = ()=>{
    setProfileImg(null)
    setBase64Profile(null)
  }
  const goBack = () => {
    navigate(ROUTES.USERMANAGEMENTSCREEN, { state: locationState })
  }

  useEffect(() => {

    if (isValidated)
    {
      validated();
    }
  }, [ isValidated ])

  useEffect(()=>{
    if (userData){
      setData()
    }
  }, [ userData ])

  useEffect(()=>{
    setRoleIndex(roleIndex)
  },[ roleIndex ] )

  useEffect(()=>{
    let errorCopy = error
    if (companyData.length > 0){
      errorCopy = { ...errorCopy, companyError: '' }
    }

    setError(errorCopy)
  }, [  companyData ])
  
  const updateRoleIndex = (e) =>{
    setRoleIndex(e.target.value)
  }

  const updateCompanyData = (e) =>{
    e.forEach((item)=>{
      delete item[ 'companyName' ]
      delete item[ 'userID' ]
    })
    setCompanyData(e)
  }
  
  const moduleFilteration = () =>{
    const filteredCompanyData = companyModule.filter(e => e.cd.some(k=> companyData.some(j=> j.companyID === k.id)));
    setModuleTable(filteredCompanyData)

  }

  useEffect(()=>{
    
    if (companyData.length === 0 ){
      setModuleTable([])
      setAccess([])
    }else{
      moduleFilteration()
    }
  },[ companyData, companyModule ])

  useEffect(()=>{
    commonScrollFunction();
  }, [ isDone ])
  useEffect(() => {
    if (locationState){
      setUserData(locationState.data);
      setRolesArr(locationState.rolesData)
      setCompanyArr(locationState.companyData)
      setDisplayData(locationState?.data.companyAssignment);
      dispatch(getCompanyModuleMapping());
    }
  }, [])

  return (
      <div className={styles.mainContainer}>
          <ImageUploadPopUp
            uploadType={ENWORDS.userScreen}
            show={visibility}
            onClose={popupCloseHandler}
            onAddImg= {addImg}

          />
          {
            userData ? 
                <div className={styles.subContainer}>
                    <ListingHeader
                      title ={ENWORDS.editUser}
                      navigationPath = {ROUTES.USERMANAGEMENTSCREEN}
                      state={locationState}
                    />
                    <div className={styles.mainDetailsContainer}>
                        <p>{ENWORDS.userDetails}</p>
                        <div className={styles.detailsContainer}>
                            <div className={styles.detailsRow}>

                                <CustomInput
                                  title = {ENWORDS.firstName} 
                                  value = {firstName} 
                                  error= {error.firstName}
                                  isFocused = {true}
                                  placeholder= {PLACEHOLDER.firstname}
                                  onChange={e=> setFirstName(e)}/>
                                <CustomInput
                                  title = {ENWORDS.lastName} 
                                  value = {lastName} 
                                  error= {error.lastName}
                                  placeholder= {PLACEHOLDER.lastname}
                                  onChange={e=> setLastName(e)}/>

                                <CustomInput
                                  title = {ENWORDS.email} 
                                  type = 'email'
                                  value = {email} 
                                  error= {error.email}
                                  readOnly= {true}/>

                                {
                                  rolesArr && currentUserData.email !== email ?
                                      <CustomDropDown
                                        title={ENWORDS.role}
                                        name = 'role'
                                        fieldValues={ rolesArr }
                                        selectedValue = { roleIndex }
                                        onChange={e=> 
                                          updateRoleIndex(e)}
                                      />:
                                      <CustomInput
                                        title={ENWORDS.role}
                                        value = {roleName} 
                                        isCapital={false}
                                        readOnly= {true}/>
                                }

                                <MultiSelectDropdown
                                  title={ENWORDS.companies}
                                  fieldValues = {companyArr}
                                  value = {displayData}
                                  error = {error.companyError}
                                  onChange = {e => updateCompanyData(e)} />
                            </div>
                            <div className={styles.imageContainer}>
                                <p>{ENWORDS.profileImg}</p>

                                {profileImg === '' || profileImg === null ? (                          
                                    <button className={styles.btnAddImage} onClick={showPopUp}>
                                        <p>{ENWORDS.addImg}</p>
                                    </button>
                                )  : (
                                    <div className = {styles.profileImageContainer}>
                                        <div className={styles.cancelContainer} onClick={removeProfileImg}>
                                            <img src={cancelIcon} alt = {ENWORDS.cancelIcon} />
                                        </div>
                                        <div  onClick = {showPopUp}>
                                            <img src={profileImg} alt = {ENWORDS.userProfileImg} className={styles.profileImg} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.divider}>
                        </div>
                        {
                          access ?
                              <div className={styles.mainModuleContainer}>
                                  <p>{ENWORDS.invoiceAssignment}</p>
                                  {
                                    companyData.length === 0 ?
                                        <h1 className={styles.invoiceMessage}>{ENWORDS.invoiceMessage}</h1>
                                      :null
                                  }
                                  {
                                    moduleTable && moduleTable.length > 0?
                                        <InvoiceAssignmentTable 
                                          headerData={MODULEASSIGNMENT}  
                                          data={moduleTable} 
                                          onGetAccessDetails={accessState} 
                                          isValidatedAccess={isValidated}  
                                          canEdit = {currentUserData.email !== email || ((currentUserData.role)?.toLowerCase().includes('super') && (currentUserData.role)?.toLowerCase().includes('admin'))} 
                                          onSetAccessDetails = {access}
                                        />
                                      :null
                                  }

                              </div>

                            :
                            null
                        }

                        <div className={styles.buttonContainer}>
                            <button className={styles.btnCancel} onClick={goBack}>{ENWORDS.cancel}</button>
                            <button className={styles.btnDone} onClick={onDoneClicked}>{ENWORDS.done}</button>
                        </div>

                    </div>
                </div>:null
          }
        
      </div>
  )
}

export default EditUserScreen