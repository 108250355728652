import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import styles from '../../styles/popUp.module.css';
import PropTypes from 'prop-types';
import cancelIcon from '../../assets/icons/cancelIcon.svg'
import { setLoadingFlag } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants/appRoute';
import { deleteRole } from '../../api/services/roleManagementService';
import { deleteStation, getStationData, syncAllStations } from '../../api/services/stationManagementService';

const ENWORDS = require('../../shared/local/en.json')

const PopUp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [ show, setShow ] = useState(false);
  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    setShow(props.show);
  }, [ props.show, props.selectedUserName, props.selectedName ]);

  const onDeleteHandler = () => {

    if (props.screenName === ENWORDS.editModuleScreen) {
      props.onDelete(props.selectedId, props.selectedModuleID)
      setShow(false);
      props.onClose(false);
    } 
    else if (props.screenName === ENWORDS.deleteRole) {
      dispatch(setLoadingFlag(true))
      setShow(false);
      dispatch(deleteRole(props.selectedId))
      props.onClose(false);
      navigate(ROUTES.ROLEMANAGEMENT)
    } 
    else if (props.screenName === ENWORDS.stationTable) {
      dispatch(setLoadingFlag(true))
      setShow(false);
      dispatch(deleteStation(props.selectedId))
      dispatch(getStationData())
      props.onClose(false);
      navigate(ROUTES.STATIONLISTING)
    }
    else if (props.screenName === ENWORDS.syncStation) {
      dispatch(syncAllStations());
      setShow(false);
      props.onClose(false);
    }else if(props.screenName === ENWORDS.dashboardCSV || props.screenName === ENWORDS.stationCSV 
      || props.screenName === ENWORDS.invoiceListingCSV){
      props.downloadCSVData()
    }else if(props.screenName === ENWORDS.userManagement){
      props.downloadCSVData()//function is to delele user 
      setShow(false)
      props.onClose(false)
    }
  }

  return (
      <div
        style={{
          visibility: show ? 'visible' : 'hidden',
          opacity: show ? '1' : '0',
        }}
        className={styles.overlay}
      >
          <div className={styles.popup}>
              <div className={styles.topSection}>
                  <p> {props.title}</p>
                  <button className={styles.btnCancel} onClick={closeHandler}>
                      <img src={cancelIcon} alt={ENWORDS.cancelIcon} />
                  </button>
              </div>
              <div className={styles.middleSection}>
                  {
                    props.uploadType === 'deletesite' ? (<p>{props.text} <span> {props.selectedName} , {props.selectedUserName} ?</span></p>)
                      : (<p>{props.text} <span> {props.selectedName}?</span></p>)
                  }

              </div>

              <div className={styles.btnSection}>
                  <button className={styles.btnNo} onClick={closeHandler}>{ENWORDS.no}</button>
                  <button className={styles.btnYes} onClick={props.onClickYes? props.onClickYes : onDeleteHandler} >{ENWORDS.yes}</button>
              </div>
          </div>
      </div>
  )
}
PopUp.propTypes = {
  selectedName: PropTypes.string,
  selectedId: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  screenName: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  selectedModuleID: PropTypes.number,
  downloadCSVData : PropTypes.func,
};
export default PopUp