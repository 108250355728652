import React from 'react'
import ViewInvoiceDetails from './ViewInvoiceDetails'

const ViewInvoice = () => {
  return (
      <ViewInvoiceDetails/>
  )
}

export default ViewInvoice
